import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { VentaService } from '../../../../../core/services/venta.service';

@Component({
  selector: 'app-select-profesor',
  templateUrl: './select-profesor.component.html',
  styleUrls: ['./select-profesor.component.scss']
})
export class SelectProfesorComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  hidden;
  desactivar;
  ocultarSesiones = [];
  check = this.ventaService.check;
  ordenarPorSesion = true;
  ordenarPorProfesor = false;
  ordenacionForm: FormGroup;
  profesores = [];
  noAforo = this.sesionService.noAforoValue;
  horariosListProfesores = [];

  constructor(
    private ventaService: VentaService,
    private productosService: ProductosService,
    private sesionService: SesionService,
    private formBuilder: FormBuilder
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.ordenacionForm = this.formBuilder.group({
      sesion: true,
      profesor: false,
    });
    this.marcarCheck();
  }

  clickRadio(evt, value) {
    this.ordenacionForm.reset();
    this.ordenacionForm.get(value).patchValue(value);
    switch (value) {
      case 'sesion':
        this.ordenarPorSesion = true;
        this.ordenarPorProfesor = false;
        break;
      case 'profesor':
        this.sesionService.getSesionesFromProductosProfesor(this.productosService.getGrupoInternet().gi);
        this.horariosListProfesores = this.ventaService.compraValue.productos.map(producto => {
          return {
            sesiones: this.sesionService.getSesionesPorDiaYProductoProfesor(this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY'), producto.producto),
            producto
          };
        });
        this.ordenarPorSesion = false;
        this.ordenarPorProfesor = true;
        break;
    }
  }

  estaOculto(profesor, producto, i) {
    this.hidden = true;
    this.ventaService.compraValue.productos.forEach(p => {
      if (p.sesionesSeleccionadas) {
        if (p.sesionesSeleccionadas[0].Profesor === profesor.guia.iGuiaId &&
          p.sesionesSeleccionadas[0].ProductoId === producto.ProductoId) {
          this.añadirOcultarSesion(profesor, producto);
          this.hidden = false;
          return this.hidden;
        }
      }
    });
    if (i === 0 && !this.desactivar) {
      this.añadirOcultarSesion(profesor, producto);
      this.hidden = false;
      return this.hidden;
    }
    this.ocultarSesiones.forEach(item => {
      if (item.guia === profesor.guia.iGuiaId && item.producto === producto.ProductoId) {
        this.hidden = false;
        return this.hidden;
      }
    });
    return this.hidden;
  }

  añadirOcultarSesion(profesor, producto) {
    const sesion = { guia: profesor.guia.iGuiaId, producto: producto.ProductoId };
    const ocultar = this.ocultarSesiones.find(item => item.guia === profesor.guia.iGuiaId && item.producto === producto.ProductoId);
    if (ocultar) {
      this.ocultarSesiones = this.ocultarSesiones.filter(item => item.guia !== profesor.guia.iGuiaId);
    } else {
      this.ocultarSesiones.push(sesion);
    }
  }

  ocultar(profesor, producto) {
    this.desactivar = true;
    this.añadirOcultarSesion(profesor, producto);

  }

  selectProfesor(producto, profesor) {
    this.ventaService.compraValue.productos.forEach(item => {
      if (item.producto.ProductoId === producto.ProductoId) {
        item.sesionesSeleccionadas = { ...item.sesionesSeleccionadas, Profesor: profesor.iGuiaId, ProfesorNombre: profesor.NombreGuia };
      }
    });
  }

  isSelectedProfesor(producto, profesor) {
    let seleccionada = false;
    this.ventaService.compraValue.productos.forEach(item => {
      if (item.producto.ProductoId === producto.ProductoId) {
        if (item.sesionesSeleccionadas) {
          if (item.sesionesSeleccionadas[0].Profesor === profesor.iGuiaId) {
            seleccionada = true;
          }
        }
      }
    });
    return seleccionada;
  }

  selectSesionProfesor(profesor, sesion, producto) {
    this.ventaService.compraValue.productos.forEach(item => {
      if (item.producto.ProductoId === producto.ProductoId) {
        item.sesionesSeleccionadas = { ...sesion, Profesor: profesor.guia.iGuiaId, ProfesorNombre: profesor.guia.nombre };
      }
    });
  }

  isSelectedSesionProfesor(profesor, sesion, producto) {
    let seleccionada = false;
    this.ventaService.compraValue.productos.forEach(item => {
      if (item.producto.ProductoId === producto.ProductoId) {
        if (item.sesionesSeleccionadas) {
          if (item.sesionesSeleccionadas[0].HoraInicio === sesion.HoraInicio && sesion.IdRecinto === item.sesionesSeleccionadas[0].IdRecinto && item.sesionesSeleccionadas[0].Profesor === profesor.guia.iGuiaId) {
            seleccionada = true;
          }
        }
      }
    });
    return seleccionada;
  }

  checkBoxChange() {
    this.ventaService.check = !this.ventaService.check;
    if (!this.ventaService.check) {
      this.ventaService.compraValue.productos.forEach(item => {
        item.sesionesSeleccionadas = { ...item.sesionesSeleccionadas, Profesor: null, ProfesorNombre: null };
      });
    }
    return this.ventaService.check;
  }

  marcarCheck() {
    if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
      if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].Profesor) {
        this.ventaService.check = true;
        return this.ventaService.check;
      }
    } else {
      return this.ventaService.check;
    }
  }

  profesorPorSesion(producto) {
    const profesores = this.profesores.find(p => p.Producto === producto.ProductoId);
    if (profesores) {
      return profesores.Profesores;
    } else {
      return;
    }
  }

  seleccionarProfesorAutomatico(sesion, producto) {
    let horaInicio;
    let profesoresProd;
    if (this.ventaService.compraValue.productos) {
      if (this.ventaService.compraValue.productos[0]) {
        if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
          horaInicio = this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].HoraInicio;
        }
      }
    }
    if (sesion.HoraInicio === horaInicio) {
      profesoresProd = { Producto: producto.ProductoId, Profesores: sesion.Profesores };
      this.profesores.push(profesoresProd);
    }
  }

  isSelected(sesion, producto) {
    let seleccionada = false;
    this.seleccionarProfesorAutomatico(sesion, producto);
    this.ventaService.compraValue.productos.forEach(item => {
      if (item.producto.ProductoId === producto.ProductoId) {
        if (item.sesionesSeleccionadas) {
          if (item.sesionesSeleccionadas[0].HoraInicio === sesion.HoraInicio && sesion.IdRecinto === item.sesionesSeleccionadas[0].IdRecinto) {
            seleccionada = true;
          }
        }
      }
    });
    return seleccionada;
  }

  selectSesionPorProducto(sesion, producto) {
    if (sesion !== '0') {
      const profesoresProd = { Producto: producto.ProductoId, Profesores: sesion.Profesores };
      if (this.profesores.length) {
        this.profesores = this.profesores.filter(p => p.Producto !== producto.ProductoId);
        this.profesores.push(profesoresProd);
      } else {
        this.profesores.push(profesoresProd);
      }
      if (sesion.Disponible !== '0') {
        if (this.productosService.muestraSesionesPorProducto) {
          const p = this.ventaService.compraValue.productos.find(prod => prod.producto.ProductoId === producto.ProductoId);
          if (p.cantidad <= parseInt(sesion.Disponible)) {
            this.ventaService.compraValue.agregarSesionAProducto(sesion, producto);
          } else {
            this.sesionService.setNoAforo([true, producto.ProductoId]);
            this.sesionService.noAforo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(aforo => this.noAforo = aforo); 
          }
        }
      }

    }
  }

  getVentaService(){
    return this.ventaService;
  }
  
}
