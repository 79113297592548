import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../user.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { VentaService } from "../../core/services/venta.service";
import { TranslateService } from "src/app/shared/translate.service";
import { ModalService } from "src/app/shared/modal.service";
import { ProductosService } from "../../business/productos.service";
import { take } from "rxjs/operators";
import { DialogoPopUp } from "src/app/shared/components/alert/dialog/popup.service";
import { MatDialog } from "@angular/material/dialog";
import { ButacasService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.service";
// import { ButacasService } from 'src/app/shared/components/selector-butacas/servicios/butacas.service';

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	@Input() id: string;

	loading: boolean;
	loginForm: FormGroup;
	element;
  showPasswordInput = false;
  showPasswordInputModal = false;
	modal = false;

	constructor(
		private ventaService: VentaService,
		private formBuilder: FormBuilder,
		private userService: UserService,
		private router: Router,
		private toast: ToastrService,
		private el: ElementRef,
		private modalService: ModalService,
		private translateService: TranslateService,
		private productSerive: ProductosService,
		private route: Router,
		public dialog: MatDialog,
		private butacasService: ButacasService
	) {
		this.element = el.nativeElement;
		/*  if (this.userService.currentUserValue) {
       this.router.navigate(['/shop']);
     } */
	}
	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		this.element.remove();
	}
	ngOnInit() {
		/* this.loginForm = this.formBuilder.group({
      nombre: ['IACPOS.', [Validators.required]],
      contrasena: ['Prueba123456+'],
    }); */

		this.userService.currentUser$.subscribe((user) => {
			if (user && user.DeBaja == "1") {
				//y la fecha de baja es menor que la actual
				var fecbajstr = user.FechaBaja;
				let fecbajahora = fecbajstr.split(" ");
				let fecbaja = fecbajahora[0];
				let fecbajarr = fecbaja.split("/");
				let fechastring =
					fecbajarr[2] + "-" + fecbajarr[1] + "-" + fecbajarr[0];
				let dateBaja = new Date(fechastring);
				let fechaHoy = new Date();

				this.userService.logged.emit(false);

				if (fechaHoy > dateBaja || user.FechaBaja == "") {
					/*           this.toast.error(this.translateService.data.clientedebaja, '', {
            timeOut: 6000,
            positionClass: 'toast-top-right',
          }); */

					//el suauro esta de baja Aceptar??
					//this.userService.logout();

					const dialogRef = this.dialog.open(DialogoPopUp, {
						width: "400px",
						data: {
							titulo: this.translateService.data.clientedebaja,
							texto: "",
							eleccion: "",
							ok: this.translateService.data.ACEPTAR,
							ko: "",
						},
					});

					dialogRef.afterClosed().subscribe((result) => {
						/*             if (result === 'SI') {
              this.manageDel(this.tableName, row);
            } */
						this.userService.logout();
					});
				}
			}
		});

		this.loginForm = this.formBuilder.group({
			nombre: ["", [Validators.required]],
			contrasena: [""],
		});
		/* 
    [Validators.required, Validators.maxLength(255)]
     */
		const modal = this;
		if (!this.id) {
			//console.error('modal must have an id');
			return;
		}

		document.body.appendChild(this.element);
		// tslint:disable-next-line:only-arrow-functions
		this.element.addEventListener("click", function (e: any) {
			if (e.target.className === "jw-modal") {
				modal.close();
			}
		});
		this.modalService.add(this);
	}
	open(): void {
		this.element.style.display = "block";
		this.modal = true;
		document.body.classList.add("jw-modal-open");
	}
	close(): void {
		this.element.style.display = "none";
		this.modal = false;
		document.body.classList.remove("jw-modal-open");
	}
	get f() {
		return this.loginForm.controls;
	}
	loginClicked() {
		this.loading = true;
		if (this.loginForm.invalid) {
			return;
		}

		this.userService.login(this.loginForm.value, 1, 1).subscribe(
			(res) => {
				if (res && res.DatosResult) {
					this.userService.logged.emit(true);
					if (
						this.route.url === "/tienda/productos" ||
						this.productSerive.getEnlaceMenuId().chTiposClientes ==
							res.DatosResult.TipoClienteId
					) {
						this.userService.setDatosUserValue(res.DatosResult);
						this.ventaService.mapCliente(res.DatosResult);
						this.ventaService.setEnlaceMenuCarrito(
							this.ventaService.enlaceMenuCarritoValue
						);
						if (this.modal) {
							this.close();
						}
						this.modalService.close("LoginAndRegister");

						if (this.route.url === "/tienda/productos") {
							//Incio de comentado por OJ 28/03/2023 porque hace 2 veces el llamado a productos y espectaculos
							/*
            //this.productSerive.setEnlacesMenuValue([]);
            this.productSerive.getTodosEspectaculos('').pipe(take(1)).subscribe(res =>{
              this.productSerive.setTodosEspectaculosCat(res);
              this.productSerive.setEspectaculosCat(res && res.EspectaculosDisponible?res.EspectaculosDisponible:null)
              this.productSerive.setBackupEspectaculos(this.productSerive.espectaculosCatValue);
           });
           
       

             this.productSerive.getTodosProductos('').pipe(take(1))
             .subscribe(res =>{
               //this.productSerive.setProductosHeaderValue(Object.values(res.DictTarifasEntradas).length > 0 ? Object.values(res.DictTarifasEntradas) : res.ListTarifasEntradas)               
               if (res) {
               this.productSerive.setTodosProductosCat(res);               
               this.productSerive.setProductosCat(res && res.DictTarifasEntradas && Object.values(res.DictTarifasEntradas).length > 0 ? Object.values(res.DictTarifasEntradas) : res.ListTarifasEntradas);
               this.productSerive.setBackupProds(this.productSerive.productosCatValue);
               this.butacasService.getEstadosButacasInicial();                
               }


             });
             */
							//Fin de comentado por OJ 28/03/2023 porque hace 2 veces el llamado a productos y espectaculos

							this.router.routeReuseStrategy.shouldReuseRoute = () => false;
							this.router.onSameUrlNavigation = "reload";
							this.route.navigate(["/tienda/productos"]);
						}
						/* this.userService.setDatosUserValue(res.DatosResult)
          this.ventaService.mapCliente(res.DatosResult);
          this.ventaService.setEnlaceMenuCarrito(this.ventaService.enlaceMenuCarritoValue) */
						// this.router.navigate(['/tienda/producto/1']);
					} else {
						this.userService.logged.emit(false);
						this.toast.error(
							this.translateService.data.usarioNoCorresponde,
							"",
							{
								timeOut: 3000,
								positionClass: "toast-top-right",
							}
						);
						this.userService.logout(false);
					}
				} else if (res.Mensajes[0]["CodigoMensaje"] == "-6") {
					this.toast.error(
						this.userService.getErrorTranslateLogin(
							res.Mensajes[0]["CodigoMensaje"]
						) +
							" " +
							this.translateService.data.DebeCambiarContrasena,
						"",
						{
							timeOut: 3000,
							positionClass: "toast-top-right",
						}
					);
					this.userService.setUserNameValue(this.loginForm.value.nombre);
					this.userService.setOldPassValue(this.loginForm.value.contrasena);
					this.userService.setPassCaducadaValue(true);
				} else {
					this.toast.error(
						this.userService.getErrorTranslateLogin(
							res.Mensajes[0]["CodigoMensaje"]
						),
						"",
						{
							timeOut: 3000,
							positionClass: "toast-top-right",
						}
					);
				}
				this.loading = false;
			},
			(err) => {
				this.toast.error(this.translateService.data[err], "Error", {
					timeOut: 3000,
					positionClass: "toast-top-right",
				});
				this.loading = false;
			}
		);
	}
	irRecover() {
		//this.router.navigate(['/account/recover']);
		this.modalService.forgetPassword("LoginAndRegister", true);
	}

  toggleShowPassword(isModal: boolean = false) {
    if (isModal) {
      this.showPasswordInputModal = !this.showPasswordInputModal;
    } else {
      this.showPasswordInput = !this.showPasswordInput;
    }
  }
}
