import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { repetirValidar } from '../repetir-validar.validator';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { InitService } from 'src/app/core/init/init.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { take } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  newPassForm: FormGroup;
  loading: boolean;
  token: string;
  email: string;
  tokenCaducado = false;
  lang: string;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toast: ToastrService,
    private initService: InitService,
    private translateService: TranslateService,
    private config: AppConfiguration
  ) {

  }
  regPassw = /^(?=.*\d)(?=.*[\u0021-\u002f\u003a-\u0040\u005b-\u0060 \\ \[\]\^])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token;
    this.email = this.route.snapshot.queryParams.email;
    this.lang = this.route.snapshot.queryParams.lang;
    this.translateService.setLocale(this.lang);
    this.translateService.use(this.lang, this.config.getProyecto());
    this.initService.getInitData().pipe(take(1)).subscribe(res => {
      this.userService.existeToken(this.token).subscribe((valToken:any) => {
        if (valToken.DatosResult == true) {
        } else {
          this.tokenCaducado = true;
          this.toast.error(this.translateService.data.tokenCaducadoNoExiste, 'Error', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      })
    });
    this.newPassForm = this.formBuilder.group({
      nuevaContrasena: ['', [Validators.required, Validators.pattern(new RegExp(this.regPassw))]],
      repiteContrasena: ['', [Validators.required]],
    }, {
      validator: repetirValidar('nuevaContrasena', 'repiteContrasena')
    });
  }
  get f() {
    return this.newPassForm.controls;
  }
  nuevaContrasenaClicked() {
    this.loading = true;
    if (this.newPassForm.invalid) {
      return;
    }

    this.userService.cambiaContrasena(this.newPassForm.value, this.email, this.token)
      .subscribe((res:any) => {
        if (res.DatosResult === true) {
          this.router.navigate(['/tienda/productos']);
          this.toast.success(this.translateService.data.contrasenaCambiadaCorrectamente, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        } else {
          this.toast.error(this.userService.getErrorTranslate(res["Mensajes"][0]["CodigoMensaje"]), 'Error', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        this.loading = false;
      }
      );
  }
}


