import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfiguration } from 'src/app/app.configuration';
import { ModalService } from '../../modal.service';
import { TranslateService } from '../../translate.service';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  private element: any;
  @Input() id: string;
  proyecto = this.config.getProyecto(); //Yaribel 20220729 añadimos proyecto para obtener los assets genericamente
  constructor(
    private el: ElementRef,
    private modalService: ModalService,
    private router: Router,
    private config: AppConfiguration,
    private translate: TranslateService
    ) {
    this.element = el.nativeElement;
   }


  ngOnInit(): void {
    const modal = this;
    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }
  open(flag?): void {
    if(flag){
      /* this.initService.cookies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cookies=>{
        this.cookies = cookies
        this.check=true
      }) */
     // this.getCookiesExterno()
    }
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');

  }
  ngOnDestroy(): void {

/*     this.modalService.remove(this.id);
       this.ngUnsubscribe.next();
       this.ngUnsubscribe.complete();
     */    
      // this.modalService.remove(this.id);
    this.element.remove();
  }
  close(): void {
    this.router.navigate(['/tienda/productos']);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
}
