import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfiguration } from "src/app/app.configuration";
import { ModalService } from "src/app/shared/modal.service";
import { CarritoComponent } from "../carrito/carrito.component";

@Component({
  selector: "app-modal-carrito",
  templateUrl: "./modal-carrito.component.html",
  styleUrls: ["./modal-carrito.component.scss"],
})
export class ModalCarritoComponent implements OnInit {
  @Input() id: string;
  private element: any;

  butaca: any;
  posicionVenta: any;
  proyecto = this.config.getProyecto();

  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private config: AppConfiguration,
    private carrito: CarritoComponent
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;

    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "modalCarrito") {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = "block";
  }

  modify() {
    this.carrito.modificar(this.modalService.butacas);
    this.close();
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("modalCarrito");
    this.modalService.info=null
    this.modalService.butacas=null
  }
}
