import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InitService } from 'src/app/core/init/init.service';
import { ProductosService } from '../productos.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/core/config/configuration.service';
import { VentaService } from '../../core/services/venta.service';
import { CalendarService } from '../ticket-sales/pages/date-selector/calendar/calendar.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  menuProductos;
  grupoProductos;
  radioButton: FormGroup;
  producto;
  value;
  mostrarFiltros = this.calendarService.filtrosValue;
  pantallasFiltros;
  productos;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private initService: InitService,
    private productosService: ProductosService,
    private configurationService: ConfigurationService,
    private ventaService: VentaService,
    private calendarService: CalendarService) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.ventaService.setModificarValue(false);
    this.value = this.ventaService.value;
    this.initService.grupoProductos$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => this.grupoProductos = res);
    this.productosService.productosMenu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(prod => this.productos = prod);
    this.productosService.nombreGrupoMenu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => this.menuProductos = res);
    if (this.value === 'part') {
      this.radioButton = this.formBuilder.group({
        part: 'part',
        grup: '',
      });
    } else if (this.value === 'grup') {
      this.radioButton = this.formBuilder.group({
        part: '',
        grup: 'grup',
      });
    }
  }

  clickRadio(evt, value) {
    this.producto = undefined;
    this.radioButton.reset();
    this.radioButton.get(value).patchValue(value);
  }

  irProductos(gI, em) {
    this.producto = { g: parseInt(gI, 10), m: em };
  }

  irSiguiente() {
    this.productosService.setFiltroTipoValue('');
    this.productosService.setFiltroGrupoValue('');
    this.productosService.setFiltroNivelValue('');
    this.productosService.setCargandoValue(true);
    const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === this.producto.m);
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);
    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(enlaceMenu.pkid);
    if (this.producto.g === 7 && enlaceMenuNombre === 'SKI_SNOW') {
      this.productosService.setOtrosProductosValue('EQUIPO');
    } else if (this.producto.g === 7 && enlaceMenuNombre === 'PARTICULARES ACTIVIDADES') {
      this.productosService.setOtrosProductosValue('EQUIPO');
    } else {
      this.productosService.setOtrosProductosValue(enlaceMenuNombre);
    }
    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa, m: enlaceMenuNombre,
      gi: this.producto.g, userId: JSON.parse(this.productosService.datosInitValue).userId, lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u, p: JSON.parse(this.productosService.datosInitValue).p
    });
    sessionStorage.setItem('token', this.initService.funcionEncript(this.productosService.datosInitValue));
    this.productosService.getProductos()
      .pipe(map(res => {
        if(res && res.DictTarifasEntradas){
        this.productosService.setnombreGrupoValue(res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].NombreGrupo);
        this.productosService.setProductosValue(
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].DictTarifasProductos,
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].EnlaceMenuId,
          this.productosService.getGrupoInternet().gi,
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].TipoReservaId,
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].NombreGrupo);
        this.productosService.setInfoGrupoValue(res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi]);
        }
      })).pipe(take(1)).subscribe(
        () => {
          this.productosService.setCargandoValue(false);
          this.router.navigate(['/tienda/producto/1']);
        }
      );
  }

  selectedProductoClass(gi, em) {
    if (this.producto) {
      if (this.producto.g === parseInt(gi, 10) && this.producto.m === em) {
        return 'prodselected';
      } else {
        return 'unselected';
      }
    }
  }

  nombreGi(gi, em) {
    const grupo = this.productosService.productosMenuValue.find(prod => prod.EnlaceMenuId === em && prod.GrupoInternetId === gi.toString());
    if (grupo) {
      return grupo.NombreGrupo;
    }
  }

  precios(gi, em) {
    const grupo = this.productosService.productosMenuValue.find(prod => prod.EnlaceMenuId === em && prod.GrupoInternetId === gi.toString());
    let precioMin = 0;
    if (grupo) {
      const productos = Object.values(grupo.DictTarifasProductos);
      productos.forEach((p: any) => {
        if (precioMin !== 0) {
          if (precioMin > Number.parseFloat(parseFloat(p.PVPInternet.replace(',', '.')).toFixed(4))) {
            precioMin = Number.parseFloat(parseFloat(p.PVPInternet.replace(',', '.')).toFixed(4));
          }
        } else {
          precioMin = Number.parseFloat(parseFloat(p.PVPInternet.replace(',', '.')).toFixed(4));
        }
      });
    }
    return precioMin;
  }
  
  getDivisasValue(){
    return this.configurationService.divisasValue;
  }
}
