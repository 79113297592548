import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'filtrarProfesores',
    pure: false
})
export class FiltrarProfesoresPipe implements PipeTransform {

    constructor() { }

    transform(key: any): any {
        return key.filter(guia => guia.horasList.length);
    }

}