import { Producto } from './producto.model';
import * as moment from 'moment';

export interface Sesion {
  Disponibilidad: string;
}

export interface LineaProducto {
  producto: Producto;
  cantidad: number;
  posicionVenta?: number;
  datos?: any;
  sesionesSeleccionadas?: any[];
}

export class Compra {
  productos: LineaProducto[];

  constructor(
    productos: LineaProducto[] = []) {
    this.productos = productos;
  }

  getCantidadPorProducto(producto: Producto) {
    if (this.existeProducto(producto)) {
      return this.existeProducto(producto).cantidad;
    }
    return 0;
  }

  getPrecioPorProducto(producto: Producto) {
    if (this.existeProducto(producto)) {
      if(producto.DesglosarComision == "0")
      {
        let precioRound = Number.parseFloat(Number.parseFloat((producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) + Number.parseFloat(Number.parseFloat((producto.ComisionInternet || producto.Comision || '0').replace(/,/, '.')).toFixed(4));
        return precioRound;
      }else{
        let precioRound = Number.parseFloat((producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4);
        return precioRound;
      }
      // return this.existeProducto(producto).cantidad;
    }
    return 0;
  }

  getCantidadMotivosPorProductoYId(producto: Producto, motivoId: any) {
    if (this.existeProducto(producto)) {
      const p = this.existeProducto(producto);
      if (p.producto.motivos) {
        const filtro: any[] = p.producto.motivos.filter((grat: any) => grat.PkId === motivoId);
        if (filtro.length > 0) {
          return filtro[0].cantidad;
        } else {
          return 0;
        }
      }
    }
    return 0;
  }

  agregarProductoModificar(producto) {
    this.productos.push(
      {
        posicionVenta: producto.posicionVenta,
        producto: producto.producto,
        datos: producto.producto.datos,
        sesionesSeleccionadas: producto.sesionesSeleccionadas,
        cantidad: producto.cantidad
      });
  }

  agregarProducto(producto : Producto) {
    if (this.existeProducto(producto)) {     
      this.existeProducto(producto).cantidad++;
      //Yaribel 20230222 modificamos para gestionar butacas
      if(producto.butacas && producto.butacas.length > 0)
      {
        this.existeProducto(producto).producto.butacas.push(producto.butacas[0]);
      }
    } else {
      this.productos.push({ producto, cantidad: 1 });
    }
  }

  eliminarProducto(producto: Producto) {
    if (!this.existeProducto(producto)) {
      return;
    }
    const miProducto = this.existeProducto(producto);

    if (!sessionStorage.getItem('admin')) {
      if (miProducto.cantidad === 1) {
      
        const index = this.productos.findIndex(e => e.producto.ProductoId === producto.ProductoId && e.producto.TipoProducto === producto.TipoProducto);
        this.productos.splice(index, 1);
      } else {     
        if(producto.butacas && producto.butacas.length > 0)
        {
        const index = this.productos.findIndex(e => e.producto.ProductoId === producto.ProductoId && e.producto.TipoProducto === producto.TipoProducto);
        const indexB = this.productos[index].producto.butacas.findIndex(b=> b.idRecintoButaca === producto.butacas[0].idRecintoButaca);
        this.productos[index].producto.butacas.splice(indexB, 1);
        } 

        this.existeProducto(producto).cantidad--;
      }
    }else {
      if (miProducto.cantidad === 1) {
      
        const index = this.productos.findIndex(e => e.producto.ProductoId === producto.ProductoId && e.producto.TipoProducto === producto.TipoProducto);
        this.productos.splice(index, 1);
      } else {     
        if(producto.butacas && producto.butacas.length > 0)
        {
        const index = this.productos.findIndex(e => e.producto.ProductoId === producto.ProductoId && e.producto.TipoProducto === producto.TipoProducto);
        const indexB = this.productos[index].producto.butacas.findIndex(b=> b.idRB === producto.butacas[0].idRB);
        this.productos[index].producto.butacas.splice(indexB, 1);
        } 

        this.existeProducto(producto).cantidad--;
      }
    }
   
  }

  eliminarProductoModal(producto: Producto) {
    if (!this.existeProducto(producto)) {
      return;
    }
    
    //<<Yaribel 20210513 Cambiamos el filtar por un  splice del producto para eliminar solo el producto con mismo ProductoId y tipoProducto y asi diferenciar las entradas y las promociones
    const index = this.productos.findIndex(e => e.producto.ProductoId === producto.ProductoId && e.producto.TipoProducto === producto.TipoProducto); 
    this.productos.splice(index, 1);
    //this.productos = this.productos.filter(e => e.producto.ProductoId !== producto.ProductoId);
  
    //Yaribel 20210513 Cambiamos el filtar por un  splice del producto para eliminar solo el producto con mismo ProductoId y tipoProducto y asi diferenciar las entradas y las promociones>>

  }

  private existeProducto(producto: Producto) {

   return this.productos.find(e => e.producto.ProductoId === producto.ProductoId && e.producto.TipoProducto === producto.TipoProducto); //Yaribel 20210513 Añadimos discriminacion por tipoProducto para diferenciar las entradas y las promociones con mismo IdProducto
    
  }

  TieneDescuento(){
    let res = false;
    this.productos.forEach(producto => {
      if(Number.parseFloat(Number.parseFloat((producto.producto.ComisionInternet).replace(/,/, '.')).toFixed(4)) > 0){
        res = true;
      }
    });
    return res;
  }
  
  DesglosaComision(){
    let res = false;
    this.productos.forEach(producto => {
      if(producto.producto.DesglosarComision == "1"){
        res = true;
      }
    });
    return res;
  }

  calcularComision(): Number{
    let precioTotal = 0 ;
    this.productos.forEach(producto => {
      if(producto.producto.DesglosarComision == "1"){ 
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + Number.parseFloat(Number.parseFloat((producto.producto.ComisionBase).replace(/,/, '.')).toFixed(4)) * producto.cantidad;
      }       
    });

    /* Validar 3er dígito */    
    let strPrecio = precioTotal.toString();
    let arrPrecio = [];
    if(strPrecio.includes('.')) {
      arrPrecio = strPrecio.split('.');
      if(arrPrecio[1].length > 2) {
        precioTotal = arrPrecio[1][2] === '5' ? Number.parseFloat(`${arrPrecio[0]}.${arrPrecio[1]}`) + 0.001 : Number.parseFloat(`${arrPrecio[0]}.${arrPrecio[1]}`);
      }       
    } 
    return Number.parseFloat((Math.ceil(precioTotal * 100) / 100).toFixed(2));
    //return Number.parseFloat(precioTotal.toFixed(2));
  }

  calcularIVAComision(){
    let precioTotal = 0 ;
    this.productos.forEach(producto => {
      if(producto.producto.DesglosarComision == "1"){
        //let comisionInternet = Number.parseFloat((producto.producto.ComisionInternet).replace(/,/, '.'))
        //let comisionBase = Number.parseFloat((producto.producto.ComisionBase).replace(/,/, '.'))
        //let IvaComision = Number.parseFloat((Number.parseFloat(comisionInternet.toFixed(4)) - Number.parseFloat(comisionBase.toFixed(4))).toFixed(4))
        //precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (IvaComision * producto.cantidad);

        let comisionInternet = Number.parseFloat((producto.producto.ComisionInternet).replace(/,/, '.')).toFixed(4);
        let comisionBase = Number.parseFloat((producto.producto.ComisionBase).replace(/,/, '.')).toFixed(4);
        let IvaComision = Number.parseFloat((Number.parseFloat(comisionInternet) - Number.parseFloat(comisionBase)).toFixed(4));
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (IvaComision * producto.cantidad);

      }
    });
    //return Number.parseFloat(precioTotal.toFixed(4));
    return Math.floor(precioTotal * 100) / 100;
  }

  getTwoDecimal(num) {
    /* let string = num.toString();
    if(string.indexOf('.') !== -1) {
      return Number(string.substring(0, string.indexOf('.') + 3));
    }
    return Number(string.substring(0, string.indexOf('.') + 3)); */
    if(!num) {
      return 0;
    }    
    if(typeof num !== 'number') {
      num = Number.parseFloat(num);
    }
    return Number.parseFloat((num).toFixed(2));
  }  

  calcularDescuento() {
    let precioTotal = 0;
    this.productos.forEach(producto => { 
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + Number.parseFloat((Number.parseFloat((producto.producto.Descuento).replace(/,/, '.')).toFixed(4))) * producto.cantidad;
    });    
    precioTotal = Number.parseFloat(precioTotal.toFixed(4));
    return this.getTwoDecimal(precioTotal);
  }

  calcularPrecioTotal() {
    let precioTotal = 0;
    this.productos.forEach(producto => { 
      if(producto.producto.DesglosarComision == "0"){
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat((producto.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) + Number.parseFloat(Number.parseFloat((producto.producto.ComisionInternet || "0").replace(/,/, '.')).toFixed(4))) * producto.cantidad;
      }else{
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + Number.parseFloat(Number.parseFloat((producto.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) * producto.cantidad;
      }
      // precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + Number.parseFloat(Number.parseFloat((producto.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) * producto.cantidad;
      //precioTotal = Math.round(precioTotal * 100) / 100;
    });
    //return Number.parseFloat(precioTotal.toFixed(4));
    return Number.parseFloat(precioTotal.toFixed(2));
  }

  calcularCantidadTotal() {
    let cantidadTotal = 0;
    this.productos.forEach(linea => {
      cantidadTotal = cantidadTotal + linea.cantidad;
    });
    return cantidadTotal;
  }

  agregarSesionAProducto(sesion, producto) {
    const sesiones = [];
    this.productos.forEach(item => {

      if (item.producto.ProductoId === producto.ProductoId && item.producto.TipoProducto === producto.TipoProducto) { //Yaribel 20210513 Añadimos discriminacion por tipoProducto para diferenciar las entradas y las promociones con mismo IdProducto

        sesiones.push(sesion)
        item.sesionesSeleccionadas = sesiones;
      }
    });
  }

  seHaRepetidoHora(sesion, sesionesYaSeleccionadas): boolean {
    if (sesionesYaSeleccionadas) {
      if (parseInt(sesion.GrupoRecintoId) > 0) {
        if (sesionesYaSeleccionadas.find(s => s && s.Fecha === sesion.Fecha && s.HoraInicio === sesion.HoraInicio && s.IdRecinto !== sesion.IdRecinto
          && s.GrupoRecintoId !== sesion.GrupoRecintoId && s.TipoAforo !== 'SD')) {
          return true;
        }
      } else {
        if (sesionesYaSeleccionadas.find(s => s && s.Fecha === sesion.Fecha && s.HoraInicio === sesion.HoraInicio && s.IdRecinto !== sesion.IdRecinto && s.TipoAforo !== 'SD')) {
          return true;
        }
      }
    }
  }

  seSolapanHorarios(sesion, sesionesYaSeleccionadas): boolean {
    if (sesionesYaSeleccionadas) {
      let sesionesOtroRecinto;
      if (parseInt(sesion.GrupoRecintoId) > 0) {
        sesionesOtroRecinto = sesionesYaSeleccionadas.filter(s => s.IdRecinto !== sesion.IdRecinto
          && s.GrupoRecintoId !== sesion.GrupoRecintoId && s.TipoAforo !== 'SD');
      } else {
        sesionesOtroRecinto = sesionesYaSeleccionadas.filter(s => s.IdRecinto !== sesion.IdRecinto && s.TipoAforo !== 'SD');
      }
      //<<Yaribel 20210525 añadimos comprobaciones isSame y cambiamos la estructura del moment porque no estaba comparando correctamente las fechas y el between no incluye los extremos 
      if (sesionesOtroRecinto.find(s => ( 
        moment(`${sesion.HoraInicio} ${sesion.Fecha}`, 'HH:mm DD/MM/YYYY').isBetween(moment( `${s.HoraInicio} ${s.Fecha}`, 'HH:mm DD/MM/YYYY'), moment(`${s.HoraFin} ${s.Fecha}`, 'HH:mm DD/MM/YYYY')))
        || moment(`${sesion.HoraFin} ${ sesion.Fecha}`, 'HH:mm DD/MM/YYYY').isBetween(moment(`${s.HoraInicio} ${ s.Fecha}`, 'HH:mm DD/MM/YYYY'), moment(`${s.HoraFin} ${s.Fecha}`, 'HH:mm DD/MM/YYYY'))
        || moment(`${sesion.HoraInicio} ${sesion.Fecha}`, 'HH:mm DD/MM/YYYY').isSame(moment( `${s.HoraInicio} ${s.Fecha}`, 'HH:mm DD/MM/YYYY'))
        || moment(`${sesion.HoraInicio} ${sesion.Fecha}`, 'HH:mm DD/MM/YYYY').isSame(moment( `${s.HoraFin} ${s.Fecha}`, 'HH:mm DD/MM/YYYY'))
        || moment(`${sesion.HoraFin} ${sesion.Fecha}`, 'HH:mm DD/MM/YYYY').isSame(moment( `${s.HoraInicio} ${s.Fecha}`, 'HH:mm DD/MM/YYYY'))
        || moment(`${sesion.HoraFin} ${sesion.Fecha}`, 'HH:mm DD/MM/YYYY').isSame(moment( `${s.HoraFin} ${s.Fecha}`, 'HH:mm DD/MM/YYYY'))
        )) {
        return true;
      } 
      
      // if (sesionesOtroRecinto.find(s =>
      //   moment(sesion.HoraInicio, sesion.Fecha, 'HH:mm DD/MM/YYYY').isBetween(moment(s.HoraInicio, s.Fecha, 'HH:mm DD/MM/YYYY'), moment(s.HoraFin, s.Fecha, 'HH:mm DD/MM/YYYY')))) {
      //   return true;\Plataforma IACPOS\_plans
      // }
      // if (sesionesOtroRecinto.find(s =>
      //   moment(sesion.HoraFin, sesion.Fecha, 'HH:mm DD/MM/YYYY').isBetween(moment(s.HoraInicio, s.Fecha, 'HH:mm DD/MM/YYYY'), moment(s.HoraFin, s.Fecha, 'HH:mm DD/MM/YYYY')))) {
      //   return true;
      // }
      //Yaribel 20210525 añadimos comprobaciones isSame y cambiamos la estructura del moment porque no estaba comparando correctamente las fechas y el between no incluye los extremos >>
    }
  }

  comprobarSesiones(sesion) {
    const sesionesYaSeleccionadas = this.productos.map(item => item.sesionesSeleccionadas);    
    if(!sesionesYaSeleccionadas[0]) {
      return;
    } 
    if (this.seHaRepetidoHora(sesion, sesionesYaSeleccionadas[0])) { 
      return true;
    }
    if (this.seSolapanHorarios(sesion, sesionesYaSeleccionadas[0])) { 
      return true;
    }
  }

  // Para productos con mas de 1 sesion 
  agregarSesionATodosProducto1(sesion) {
    this.productos.forEach((item: any) => {
      const flag = sesion.ProductoId ? item.producto.ProductoId === sesion.ProductoId : true;
      if (flag) {
        const sesionesArray = [];
        if (item.sesionesSeleccionadas && item.sesionesSeleccionadas.length >= 0) {
          item.sesionesSeleccionadas.forEach((s: any) => {
            if (parseInt(sesion.GrupoRecintoId) > 0) {
              if (sesion.GrupoRecintoId !== s.GrupoRecintoId) {
                sesionesArray.push(s);
              }
            } else {
              if (s.IdRecinto !== sesion.IdRecinto) {
                sesionesArray.push(s);
              }
            }
          });
          sesionesArray.push(sesion);
          item.sesionesSeleccionadas = sesionesArray;
        } else {
          item.sesionesSeleccionadas = [];
          item.sesionesSeleccionadas.push(sesion);
        }
      }
    });
    return false;
  }

  agregarSesionATodosProducto1Varios(sesiones) {
    const sesionesArray = []
    sesiones.forEach(sesion => {
      if (sesion.sesion !== '') {
        sesionesArray.push(sesion.sesion);
      }
    });
    this.productos.forEach(item => {
      item.sesionesSeleccionadas = [];
      item.sesionesSeleccionadas = sesionesArray;
    });
  }

  quitarSesionAProducto(sesion, producto) {
    this.productos.forEach(item => {
      if (item.producto.ProductoId === producto.producto.ProductoId && item.producto.TipoProducto === producto.producto.TipoProducto) {   //Yaribel 20210513 Añadimos discriminacion por tipoProducto para diferenciar las entradas y las promociones con mismo IdProducto
        item.sesionesSeleccionadas = sesion;
      }
    });
  }

  quitarSesionAProductoSinAforo(sesion) {
    this.productos.forEach(item => {
      if (item.sesionesSeleccionadas) {
        item.sesionesSeleccionadas = item.sesionesSeleccionadas.filter(s => sesion.IdRecinto !== s.IdRecinto);
      }
    });
  }

  actualizarProducto(producto, nuevoProducto){

    let index = this.productos.findIndex(p => p == producto); 
    this.productos[index].producto = {...producto.producto, ...nuevoProducto}
  }
}


