import { FormGroup } from '@angular/forms';
import { GlobalService } from '../shared/services/global/global.service';

export function documentoValidar(tipoDocumento: string, documento: string) {
    const globalService = new GlobalService();
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[tipoDocumento];
        const matchingControl = formGroup.controls[documento];

        const dniReg = /^(\d{8})([A-Z]|[a-z])$/;
        const nieReg = /^[XYZ][0-9]{7}[A-Z]$/;

        if (control.value === 'DNI') {
            if (dniReg.test(matchingControl.value)) {
                matchingControl.setErrors(null);
            } else {
                matchingControl.setErrors({ patern: true });
            }
        }
        if (control.value === 'NIE') {
            if (nieReg.test(matchingControl.value)) {
                matchingControl.setErrors(null);
            } else {
                matchingControl.setErrors({ patern: true });
            }
        }
        if (control.value === 'Otro') {

            matchingControl.setErrors(null);
        }
        if (control.value === 'CIF') {
            if(globalService.VerificarCIF(matchingControl.value)) {
                matchingControl.setErrors(null);
            } else {
                matchingControl.setErrors({ patern: true });
            }
        }
    };
}
