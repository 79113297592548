import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateSelectorComponent } from './pages/date-selector/date-selector.component';
import { HourSelectorComponent } from './pages/hour-selector/hour-selector.component';
import { ClientDataComponent } from './pages/client-data/client-data.component';
import { TicketSalesComponent } from './ticket-sales.component';
import { RouterModule } from '@angular/router';
import { TicketSalesRoutingModule } from './ticket-sales-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared/shared.module';
import { FiltrosComponent } from './pages/date-selector/filtros/filtros.component';
import { SelectProfesorComponent } from './pages/hour-selector/select-profesor/select-profesor.component';
import { CalendarsSelectorComponent } from './pages/hour-selector/calendars-selector/calendars-selector.component';
import { CalendarModalComponent } from './pages/hour-selector/calendar-modal/calendar-modal.component';
import { SelectorButacasModule } from '../espectaculos-butacas/mapa/selector-butacas.module';
// import { SelectorButacasModule } from 'src/app/shared/components/selector-butacas/selector-butacas.module';

@NgModule({

  declarations: [DateSelectorComponent, HourSelectorComponent, ClientDataComponent,
    TicketSalesComponent, FiltrosComponent, SelectProfesorComponent, CalendarsSelectorComponent, CalendarModalComponent],
  imports: [
    FontAwesomeModule,
    TicketSalesRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    SelectorButacasModule
  ],
})
export class TicketSalesModule {
  // constructor() {
  //   library.add(fas, far, fab);
  // }
   
    
  constructor(library: FaIconLibrary, config: FaConfig) {
    config.fallbackIcon = faBan;
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fab);
  }
}
