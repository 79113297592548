import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
  ViewContainerRef, ComponentRef, ComponentFactory, ComponentFactoryResolver,
  OnChanges, SimpleChanges, AfterViewInit, ContentChild } from '@angular/core';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';


@Component({
selector: 'app-panelcustoman',
templateUrl: './panel.customan.html',
styleUrls: ['./panel.customan.css']
})
export class PanelCustoman implements OnInit, OnChanges, AfterViewInit {

@ViewChild('target', { read: ViewContainerRef }) target: ViewContainerRef;
@ViewChild("panel") panel: HTMLElement;

componentRef: ComponentRef<any>;
/* @Output() close = new EventEmitter(); */
@Output() update = new EventEmitter();
@Input() pkId;
@Input() mode;
@Input() ConfigPanel;



constructor(private resolver: ComponentFactoryResolver) {
}
    ngOnInit() {

    }

    ngAfterViewInit() {
        this.createComponent(this.ConfigPanel,this.pkId,this.mode);
        //this.panel.scroll({top:0});
        //this.panel.scrollIntoView({ behavior: 'smooth', block: 'center' });

    }

    ngOnChanges(changes: SimpleChanges ){
        //if(changes.paneldoc_config)
            //this.appStatus.paneldoc_open.emit();
    }

    addDocument(docid:string, doctype:string){
    }

  /*   destroy(){
        //this.appStatus.paneldoc_close.emit();
        this.close.emit(null);
    } */

   /*  save() {
        let _that = this;
        this.componentRef.instance.actualizar("guardando...");
        
    } */
    /* submit(){
        this.componentRef.instance.submit();
        this.update.emit(null)

    } */

    createComponent(ConfigPanel,pkId,mode) {
        this.target.clear();
        if(ConfigPanel){
            const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(ConfigPanel);
            this.componentRef = this.target.createComponent(factory);
            this.componentRef.instance.pkId = pkId;
            this.componentRef.instance.mode = mode;
        }
    
      }
}
