import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { UserComponent } from './user/user.component';
import { BusinessComponent } from './business/business.component';
import { InitComponent } from './core/init/init.component';
import { ThankyouComponent } from './business/thankyou/thankyou.component';
import { ErrorComponent } from './core/error/error.component';
import { PoliticasComponent } from './shared/politicas/politicas.component';

const routes: Routes = [
  { path: '', redirectTo: 'reservarEntradas', pathMatch: 'full' },
  { path: 'reservarEntradas', component: InitComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'thankko', component: ErrorPageComponent },
  {path: 'thankko/:tipoPago/:pedido', component: ErrorPageComponent},
  {path: 'thankko/:datosPago', component: ErrorPageComponent},
  { path: 'thankyou', component: ThankyouComponent },
  {path: 'thankyou/:tipoPago/:medioPago/:pedido', component: ThankyouComponent},
  {path: 'thankyou/:datosPago', component: ThankyouComponent},
  {path: 'politicascompra', component: PoliticasComponent},
  { path: 'account', component: UserComponent, loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'tienda', component: BusinessComponent, loadChildren: () => import('./business/business.module').then(m => m.BusinessModule) },
  { path: '**', redirectTo: 'reservarEntradas' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
