import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from 'src/app/shared/translate.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarInfoService {
  translation;
  constructor(private snackBar:MatSnackBar,
    private translator: TranslateService) { 
      this.translation = this.translator.data;
    }

  openSnackBar(message: string, action: string, color: string, codigo: string = "") {
 
    if (codigo!="") {
      var cod = parseInt(codigo);
      if( isNaN(cod) ){
        message =  this.translation[codigo];
      } else {
          message = this.translation["CodErr_"+cod];
      }
      if (!message) message = "Error desconocido";
    }

    this.snackBar.open(message, action, {
      duration: 2500,
      panelClass: [color],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
