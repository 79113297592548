import { Component, OnInit } from '@angular/core';
import { InitService } from 'src/app/core/init/init.service';
import { ProductosService } from '../productos.service';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { VentaService } from '../../core/services/venta.service';
import { CalendarService } from '../ticket-sales/pages/date-selector/calendar/calendar.service';

@Component({
  selector: 'app-index-products',
  templateUrl: './index-products.component.html',
  styleUrls: ['./index-products.component.scss']
})
export class IndexProductsComponent implements OnInit {
  grupoProductos;
  mostrarFiltros = this.calendarService.filtrosValue;
  pantallasFiltros;

  constructor(
    private calendarService: CalendarService,
    private tokenService: TokenManagerService,
    private ventaService: VentaService,
    private router: Router,
    private initService: InitService,
    private productosService: ProductosService) { }

  ngOnInit() {
    if (!this.initService.startedValue) {
      this.initService.traerDatosInicio();
    }
    if (this.ventaService.carritoValue.compras.length === 0) {
      this.ventaService.cargarCesta();
      this.ventaService.cargarFechaSeleccionadasSinSesion();
    }
    this.initService.grupoProductos$.subscribe(res => this.grupoProductos = res);
  }

  irMenu(prod) {
    if (prod === 'ESCUELA' && this.grupoProductos === 'ONLINE') {
      this.productosService.setOtrosProductosValue('ESCUELA');
    }
    if (prod === 'FORFAIT' && this.grupoProductos === 'ONLINE') {
      this.productosService.setOtrosProductosValue('SKI_SNOW');
    }
    if (prod === 'ACTIVIDADES' && this.grupoProductos === 'ONLINE') {
      this.productosService.setOtrosProductosValue('DIVIERTETE');
    }

    if (prod === 'ESCUELA' && this.grupoProductos === 'COLEGIOS') {
      this.productosService.setOtrosProductosValue('COLEGIOS ESCUELA');
    }
    if (prod === 'SKI-SNOW' && this.grupoProductos === 'COLEGIOS') {
      this.productosService.setOtrosProductosValue('COLEGIOS ACTIVIDADES');
    }

    if (prod === 'ESCUELA' && this.grupoProductos === 'EMPRESAS') {
      this.productosService.setOtrosProductosValue('EMPRESAS ESCUELA');
    }
    if (prod === 'SKI-SNOW' && this.grupoProductos === 'EMPRESAS') {
      this.productosService.setOtrosProductosValue('EMPRESAS ACTIVIDADES');
    }

    if (prod === 'ESCUELA' && this.grupoProductos === 'CLUBES') {
      this.productosService.setOtrosProductosValue('CLUBES ESCUELA');
    }
    if (prod === 'SKI-SNOW' && this.grupoProductos === 'CLUBES') {
      this.productosService.setOtrosProductosValue('CLUBES ACTIVIDADES');
    }

    if (prod === 'ESCUELA' && this.grupoProductos === 'ADMIN') {
      this.productosService.setOtrosProductosValue('PARTICULARES ESCUELA');
    }
    if (prod === 'FORFAIT' && this.grupoProductos === 'ADMIN') {
      this.productosService.setOtrosProductosValue('PARTICULARES ACTIVIDADES');
    }
    if (prod === 'ACTIVIDADES' && this.grupoProductos === 'ADMIN') {
      this.productosService.setOtrosProductosValue('PARTICULARES DIVIERTETE');
    }
    this.productosService.setnombreGrupoMenuValue(prod);
    this.productosService.getProductosMenu().pipe(take(1)).subscribe(res => this.productosService.setProductosMenuValue(Object.values(res.DictTarifasEntradas)));
    this.router.navigate(['/tienda/menu']);
  }

  irEquipacion(em, gI) {
    this.productosService.setFiltroTipoValue('');
    this.productosService.setFiltroGrupoValue('');
    this.productosService.setFiltroNivelValue('');
    this.productosService.setCargandoValue(true);
    this.productosService.setOtrosProductosValue('EQUIPO');
    const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === em);
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);
    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(enlaceMenu.pkid);
    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa, m: enlaceMenuNombre,
      gi: gI, userId: JSON.parse(this.productosService.datosInitValue).userId, lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u, p: JSON.parse(this.productosService.datosInitValue).p
    });
    sessionStorage.setItem('token', this.initService.funcionEncript(this.productosService.datosInitValue));
    this.calendarService.setFiltros(false);
    this.productosService.getProductos()
      .pipe(map(res => {
        if(res && res.DictTarifasEntradas)
        {
        this.productosService.setnombreGrupoValue(res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].NombreGrupo);
        this.productosService.setProductosValue(
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].DictTarifasProductos,
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].EnlaceMenuId,
          this.productosService.getGrupoInternet().gi,
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].TipoReservaId,
          res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].NombreGrupo);
        this.productosService.setInfoGrupoValue(res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi]);
        }
      })).pipe(take(1)).subscribe(
        () => {
          this.productosService.setCargandoValue(false);
          this.router.navigate(['/tienda/producto/1']);
        }
      );
  }

  irMenuEquipacion() {
    this.productosService.setOtrosProductosValue('EQUIPO');
    this.productosService.setnombreGrupoMenuValue('EQUIPO');
    this.productosService.getProductosMenu().pipe(take(1)).subscribe(res => this.productosService.setProductosMenuValue(Object.values(res.DictTarifasEntradas)));
    this.router.navigate(['/tienda/menu']);
  }
}
