import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { ProductosService } from 'src/app/business/productos.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/core/config/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  private temporadasSubject: BehaviorSubject<any>;
  public temporadas$: Observable<any>;

  constructor(
    private config: AppConfiguration,
    private http: HttpClient,
    private tokenManager: TokenManagerService,
    private productosService: ProductosService,
    private configurationService: ConfigurationService,
  ) {

    this.temporadasSubject = new BehaviorSubject<any>([]);
    this.temporadas$ = this.temporadasSubject.asObservable();
  }

  public get temporadasValue() {
    return this.temporadasSubject.value;
  }

  setTemporadasValue(temporada) {
    this.temporadasSubject.next(temporada);
  }

  getIdCamposData() {
    const body = {
      iConexionId: this.tokenManager.currentConexionIdValue,
      iEnlaceMenuId: this.productosService.getEnlaceMenuId().pkid,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/TraerReservaPersonasConfig`, body).pipe(map((res: any) => {
      if (res.LstConfigs.length) {
        return res.LstConfigs[0].pkid;
      }
    }));
  }
  getCamposData(id) {
    const body = {
      iConfigId: id
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/TraerReservaPersonasConfigCampos`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  getTemporadas() {
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/GetTemporadas`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

}
