import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfiguration } from './app/app.configuration';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

 if (!environment.console) {
   window.console.log = function(){};
} 

/* let config :  ;
let project = config.getProyecto() */

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));









