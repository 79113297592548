import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { ProductosService } from 'src/app/business/productos.service';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { take } from 'rxjs/operators';
import { InitService } from 'src/app/core/init/init.service';
import { TranslateService } from 'src/app/shared/translate.service';

@Component({
  selector: 'app-my-operations',
  templateUrl: './my-operations.component.html',
  styleUrls: ['./my-operations.component.scss']
})
export class MyOperationsComponent implements OnInit {
  loading: boolean;
  isLoading: boolean = true;
  email;
  nombre;
  telefono;
  datosUsuario$;
  editForm: FormGroup;
  user;
  provincias;
  paises;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toast: ToastrService,
    private productosService: ProductosService,
    private tokenManager: TokenManagerService,
    private initService: InitService,
    private translateService: TranslateService


  ) { }

  ngOnInit() {
    // this.isLoading = true;
    // this.initService.getInitData().pipe(take(1)).subscribe(res =>{
    //   this.isLoading=false;
    // });
    
    this.isLoading = false;
    this.initService.traerDatosInicio(false);
    const conexionIacpos = this.tokenManager.currentConexionIdValue;

    //this.tokenManager.setCurrentConexionIdValue(res.pkid);
    //this.initService.getInitData();
  }

  

}


