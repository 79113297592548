import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TicketSalesComponent } from './ticket-sales/ticket-sales.component';
import { ResumeComponent } from './resume/resume.component';
import { ShopGuardGuard } from './shop-guard.guard';
import { IndexProductsComponent } from './index-products/index-products.component';
import { MenuComponent } from './menu/menu.component';
import { ProductsCatalogComponent } from './products-catalog/products-catalog.component';
import { LoginComponent } from '../user/login/login.component';
import { EspectaculosButacasComponent } from './espectaculos-butacas/espectaculos-butacas.component';

const routes: Routes = [
  { path: 'producto', component: TicketSalesComponent, loadChildren: () => import('./ticket-sales/ticket-sales.module').then(m => m.TicketSalesModule) },
  {path: 'espectaculo',component:  EspectaculosButacasComponent, loadChildren: () => import('./espectaculos-butacas/espectaculos.module').then(m => m.EspectaculosModule)},
  { path: 'index', component: IndexProductsComponent, canActivate: [ShopGuardGuard] },
  { path: 'menu', component: MenuComponent, canActivate: [ShopGuardGuard] },
  { path: 'resumen', component: ResumeComponent, canActivate: [ShopGuardGuard] },
  { path: 'productos', component: ProductsCatalogComponent, canActivate: [ShopGuardGuard] },
  { path: 'productos/:iPadre', component: ProductsCatalogComponent, canActivate: [ShopGuardGuard] },
  {path: 'login', component: LoginComponent},

  { path: '**', redirectTo: 'reservarEntradas' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessRoutingModule { }
