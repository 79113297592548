import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, mapTo } from 'rxjs/operators';
import { TimerService } from 'src/app/core/services/timer.service';
import { TimerDirective } from 'src/app/core/timer/timer.directive';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit {

  @ViewChild('start', { static: true })
  startBtn: ElementRef;

  @ViewChild('pause', { static: true })
  pauseBtn: ElementRef;

  @ViewChild('reset', { static: true })
  resetBtn: ElementRef;
  
  btnStart;

  constructor(private t: TimerService, private d: TimerDirective) { }

  ngOnInit(): void {
    //TODO
    //this.d.updateState(25, 'minutes')
  }

  ngOnDestroy() {
    this.btnStart.unsubscribe()
  }

  ngAfterViewInit(): void {

    this.btnStart = fromEvent(this.startBtn.nativeElement, 'click')
      .pipe(debounceTime(300))
      .subscribe(res => this.t.start());
    
    this.btnStart = fromEvent(this.pauseBtn.nativeElement, 'click')
      .pipe(debounceTime(300))
      .subscribe(res => this.t.pause());
    
    this.btnStart = fromEvent(this.resetBtn.nativeElement, 'click')
      .pipe(debounceTime(300))
      .subscribe(res => this.t.reset());

  }

}
