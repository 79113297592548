import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from 'src/app/business/productos.service';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  nombreGrupo;
  nombreGrupoMenu;
  proyecto = this.config.getProyecto();
  constructor(
    private router: Router,
    private config: AppConfiguration,
    private productoService: ProductosService) { }

  ngOnInit() {
    this.productoService.nombreGrupo$.subscribe(res => this.nombreGrupo = res);
    this.productoService.nombreGrupoMenu$.subscribe(res => this.nombreGrupoMenu = res);
  }

  getRouterStartWith(ruta){
    return this.router.url.startsWith(ruta);
  }

  getRoute(ruta){
    return this.router.url === ruta;
  }

}
