import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VentaService } from '../core/services/venta.service';
import { ProductosService } from './productos.service';
import { AppConfiguration } from '../app.configuration';

@Injectable({
  providedIn: 'root'
})
export class ShopGuardGuard implements CanActivate {

  constructor(
    private router: Router,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private config: AppConfiguration) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.productosService.productosValue.length === 0 && route.url[0].path === '1') {
      this.router.navigateByUrl('/reservarEntradas');
      return false;
    }
    if (this.ventaService.compraValue.productos.length === 0 && route.url[0].path === '2') {
      this.router.navigateByUrl('/reservarEntradas');
      return false;
    }
    if (this.ventaService.compraValue.productos.length === 0 && route.url[0].path === '3') {
      this.router.navigateByUrl('/reservarEntradas');
      return false;
    }
    if (!this.productosService.nombreGrupoMenuValue && route.url[0].path === 'menu') {
      this.router.navigateByUrl('/tienda/index');
      return false;
    }
    if (!sessionStorage.getItem('token') && route.url[0].path === 'index') {
      this.router.navigateByUrl('/reservarEntradas');
      return false;
    }


    if (this.config.getProyecto() === 'SNOWZONE') {
      if (route.url[0].path === 'productos') {
        this.router.navigateByUrl('/reservarEntradas');
        return false;
      }
    }
    return true;
  }

}
