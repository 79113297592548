import { Component, ElementRef, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ButacasService } from '../../mapa/servicios/butacas.service';
import { ModalService } from 'src/app/shared/modal.service';

@Component({
  selector: 'iacpos-modal-no-numerado',
  templateUrl: './modal-no-numerado.component.html',
  styleUrls: ['./modal-no-numerado.component.css']
})
export class ModalNONumeradoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string;
  butacaMarcada = [];
  variosTiposButaca = false;
  private element: any;
  tiposEntradas: any;
  butaca: any;
  claveEmpresa: string;
  ruta: string;

  selector = false;

  butacasNONumeradaMarcada$;
  getButacaTarifasEstado$;

  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private butacasService: ButacasService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.ruta = this.router.url;
    const modal = this;
    this.tiposEntradas = [];



    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'iacpos-modal-no-numerado') {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    this.claveEmpresa = this.butacasService.getConfig('chClaveEmpresa');
  }

  ngAfterViewInit(): void {
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    if (this.butacasNONumeradaMarcada$) {
      this.butacasNONumeradaMarcada$.unsubscribe();
    }
    if (this.getButacaTarifasEstado$) {
      this.getButacaTarifasEstado$.unsubscribe();
    }

  }

  // open modal
  open(): void {
    this.selector = true;
    this.element.style.display = 'block';
    this.butacaMarcada = [];

    this.variosTiposButaca = false;

    this.butacasNONumeradaMarcada$ = this.butacasService.butacasNONumeradaMarcada.pipe(first()).subscribe(butaca => {
      if (butaca != undefined && Array.isArray(butaca) && butaca.length > 0) {
        if (butaca.length > 1) {
          this.variosTiposButaca = true;
        }

        const listaPKids = butaca.map(e => e.PKIDRB).join(',');
        butaca.forEach(butacaPorTipo => {
          if (butacaPorTipo.tarifas != undefined) {
            butacaPorTipo.tarifas.forEach(element => {

              this.butacaMarcada.push(element);
            });
          }
        });
        //TODO Yaribel 20230223 comentamos el if y else de no modificacion de reserva
         //if (!this.butacasService.modificarValue) {
          this.getButacaTarifasEstado$ = this.butacasService.getButacaTarifasEstado(listaPKids).subscribe(respuestaTarifasEstado => {

                 if (respuestaTarifasEstado && respuestaTarifasEstado.TarifasEstados) {
                   let tarifasDeshabilitadas;

                   respuestaTarifasEstado.TarifasEstados.forEach(respuetatarifas => {
                     if (+respuetatarifas.Estado === 0) {
                       tarifasDeshabilitadas = respuetatarifas.IdTarifas;
                     }
                   });

                   const updateTarifas = this.butacaMarcada.map(tarifasButaca => {
                     if (tarifasDeshabilitadas && tarifasDeshabilitadas.map(e => +e).includes(+tarifasButaca.IdTarifa)) {
                       tarifasButaca.Habilitado = 0;
                     } else {
                       tarifasButaca.Habilitado = 1;
                     }
                     return tarifasButaca;
                   });

                   if (updateTarifas && updateTarifas.length > 0) {
                     this.butacaMarcada = updateTarifas;
                   } else {
                     this.butacaMarcada = this.butacaMarcada.map(tarifa => {
                       tarifa.Habilitado = 1;
                       return tarifa;
                     });
                   }

                 } else {
                   this.butacaMarcada = this.butacaMarcada.map(tarifa => {
                     tarifa.Habilitado = 1;
                     return tarifa;
                   });
                 }

            this.butaca = butaca[0];
        });

        // } else {
        //   this.butacaMarcada = this.butacaMarcada.map(tarifa => {
        //     tarifa.Habilitado = 1;
        //     return tarifa;
        //   });
        //   this.butaca = butaca[0];
        // }

      }
    });
    document.body.classList.add('selectorNONumerado');
  }

  // close modal
  close(): void {
    this.selector = false;
    this.element.style.display = 'none';
    document.body.classList.remove('selectorNONumerado');
  }


}
