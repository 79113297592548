import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-espectaculos-butacas',
  templateUrl: './espectaculos-butacas.component.html',
  styleUrls: ['./espectaculos-butacas.component.scss']
})
export class EspectaculosButacasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
