import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VentaService } from '../../core/services/venta.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { PaymentService } from 'src/app/core/payment/payment.service';
import { InitService } from 'src/app/core/init/init.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { mergeMap, concatMap, retry, take, takeUntil, map } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment/locale/es';
import { Observable, of, Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/core/config/configuration.service';
import { ProductosService } from '../productos.service';
import { PaymentType } from 'src/app/core/payment/paymentType.enum';
import { Carrito } from 'src/app/models/carrito.model';
import { GoogleAnalyticsEventsServiceService } from 'src/app/shared/google-analytics-events-service.service';
import { TimerService } from 'src/app/core/services/timer.service';

declare var gtag;
declare var fbq;
@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  cliente: any;
  entradas: any;
  pedido: any;
  localizador: any;
  entradasList = [];
  carrito$: any;
  fechaActual: any;
  precioTotal;
  tokenCompra;
  imprimirPDF;
  fechaCompra;
  loading;
  itemsGoogle;
  descuento;
  totalDescuento;
  localizadores: any = [];
  enviarEmail;
  mostrarConfirmacionEmail: boolean;
  pdfDescargado;
  tipoPago;
  medioPago;
  mostrarMensaje: boolean;
  proyecto = this.appConfig.getProyecto();
  reserva: any;
  emailEnviado;
  estaPagada;
  generarFactura;
  sessionConFechaSinSession =[];
  comision;
  IVAComision;
  precioFinal;
  descuentoCliente;
  sesionIdCompra;
  operacionesTarjeta: any;


  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    private ventaService: VentaService,
    private appConfig: AppConfiguration,
    private paymentService: PaymentService,
    private initService: InitService,
    private translate: TranslateService,
    private productosService: ProductosService,
    private GAService: GoogleAnalyticsEventsServiceService,
    private config: AppConfiguration,
    private timerService : TimerService
    ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterContentInit() {
    this.GAService.callStepView('thankYou');

  }

  ngOnInit() {
    this.ventaService.setCarrito(new Carrito());

    this.productosService.setCargandoValue(true);
    this.productosService.cargando$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(carga => this.loading = carga);
    this.paymentService.pdfDescargado$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(descargado => this.pdfDescargado = descargado);
    if(this.route.snapshot.params.datosPago){
      let datosPago = JSON.parse(this.initService.funcionDesencript(this.route.snapshot.params.datosPago));
      this.tipoPago = parseInt(datosPago.tipoPago);
      this.pedido =  datosPago.pedido? datosPago.pedido: 0;
      this.sesionIdCompra = datosPago.sesionIdCompra;
      this.medioPago=datosPago.medioPago;
      sessionStorage.setItem('payment', this.tipoPago);

    } else if (this.route.snapshot.params.tipoPago) {
      this.tipoPago = parseInt(this.route.snapshot.params.tipoPago);
      this.pedido = this.route.snapshot.params.pedido ? this.route.snapshot.params.pedido : 0;
      sessionStorage.setItem('payment', this.tipoPago);
    } else {
      this.tipoPago = this.paymentService.paymentType;
    }
    sessionStorage.removeItem('cesta');
    sessionStorage.removeItem('fechaSeleccionadaSinSession');
    this.carrito$ = this.ventaService.carrito$;
    this.fechaActual = moment().format('DD/MM/YYYY');
    if (this.tipoPago === PaymentType.PAGO) {
      this.initPago();
    } else if (this.tipoPago === PaymentType.MODIFICARRESERVA || this.tipoPago === PaymentType.RESERVA || this.tipoPago === PaymentType.PHONEANDSELL) {

      this.initReservaPhoneSell();
    }
    if (sessionStorage.getItem('cookies')) {
      this.GAService.getCookies(JSON.parse(sessionStorage.getItem('cookies')));
    }
    this.ventaService.setNumCarrito();
  }

  initPago() {
    this.initService.getInitData()
      .pipe(retry(3), concatMap(() => {
        if (this.route.snapshot.params.tipoPago || this.route.snapshot.params.datosPago) {
          this.paymentService.traerReserva(this.pedido, '', 1)
            .pipe(map((reserva: any) => { 
              this.localizador = [reserva.ClaveReserva];
              this.cliente = reserva.clienteCompra;
              this.operacionesTarjeta = reserva.OperacionesTarjeta ? reserva.OperacionesTarjeta[0] : null;
              //this.operacionesTarjeta = reserva.OperacionesTarjeta[0];
              const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid == reserva.EnlaceMenu);           //cargamos los datos de datosInitValue
              this.productosService.setDatosInitValue({ empresa: this.config.getProyecto(), m: enlaceMenu.chNombre, gi: reserva.ProductosCompra[0].GrupoInternet });
              this.emailEnviado = reserva.ReservasAdicionales.EmailEnviado;
              //Cargamos los datos de enlace menu en  el sesion storage
              sessionStorage.setItem('token', this.initService.funcionEncript(this.productosService.datosInitValue));
            })).subscribe();
        } else {
          this.pedido = sessionStorage.getItem('pedido');
          this.localizador = [sessionStorage.getItem('localizador')];
        }

        this.tokenCompra = JSON.parse(sessionStorage.getItem('currentToken'));
        //this.tokenCompra = JSON.parse(sessionStorage.getItem(''));
        return this.paymentService.traerReservaEstado(this.pedido);

      })).pipe(mergeMap((estado: any) => {
        if (!estado.DatosResult) {
          return this.paymentService.confirmarPago(this.tokenCompra, this.pedido, window.location.href, this.sesionIdCompra,this.medioPago);
        } else {
          this.estaPagada = estado.DatosResult.Pagada;
          this.generarFactura = estado.DatosResult.GenerarFactura;
          this.localizador = [estado.DatosResult.Localizador];
          if (estado.DatosResult.Pagada == 0) {
            return this.paymentService.confirmarPago(this.tokenCompra, this.pedido, window.location.href, this.sesionIdCompra,this.medioPago);
          } else if (estado.DatosResult.Pagada == 1) {
            return of({ DatosResult: true });
          }
        }
      }))
      .pipe(mergeMap((res: any) => {
        if (!res) {
          if(this.route.snapshot.params.datosPago){
            let datosPago = JSON.parse(this.initService.funcionDesencript(this.route.snapshot.params.datosPago));
            this.router.navigate(['/thankko' + '/' + datosPago.tipoPago + '/' + datosPago.medioPago + '/' + datosPago.pedido]);

          }else if (this.route.snapshot.params.tipoPago && this.route.snapshot.params.pedido) {
            this.router.navigate(['/thankko' + '/' + this.route.snapshot.params.tipoPago + '/' + this.route.snapshot.params.medioPago + '/' + this.route.snapshot.params.pedido]);
          } else {
            this.router.navigate(['/thankko']);
          }
          return;
        } else if (res.DatosResult === false) {
          if(this.route.snapshot.params.datosPago){
            let datosPago = JSON.parse(this.initService.funcionDesencript(this.route.snapshot.params.datosPago));
            this.router.navigate(['/thankko' + '/' + datosPago.tipoPago + '/' + datosPago.medioPago + '/' + datosPago.pedido]);

          } else if (this.route.snapshot.params.tipoPago && this.route.snapshot.params.pedido) {
            this.router.navigate(['/thankko' + '/' + this.route.snapshot.params.tipoPago + '/' + this.route.snapshot.params.medioPago + '/' + this.route.snapshot.params.pedido]);
          } else {
            this.router.navigate(['/thankko']);
          }
          return;
        } else if (res.DatosResult === true) {

          const iSolFact = JSON.parse(sessionStorage.getItem('iSolicitaFactura'));

          if (Number(iSolFact) === 1 && Number(this.generarFactura) === 1) {
            this.paymentService.generarFactura(this.pedido, this.localizador).pipe(take(1)).subscribe();
          }

          const reserve = this.paymentService.traerReserva(this.pedido, '', 1)
            .pipe(mergeMap((reserva: any) => {
              this.ventaService.ordenacionTraerReserva(reserva);
              this.entradas = this.ventaService.carritoValue;
              this.cliente = reserva.clienteCompra;
              this.operacionesTarjeta = reserva.OperacionesTarjeta ? reserva.OperacionesTarjeta[0] : null;
              //this.operacionesTarjeta = reserva.OperacionesTarjeta[0];
              this.emailEnviado = reserva.ReservasAdicionales.EmailEnviado;
              reserva.listLocalizadores.forEach(loc => {
                this.localizadores.push(loc.ClaveReserva);
              });
              this.totalDescuento = reserva.TotalReserva;
              this.IVAComision = this.calcularIVAComision();
              this.comision = this.calcularComision();
              this.descuentoCliente = this.calcularDescuentoCliente();
              if (JSON.parse(sessionStorage.getItem('descuento')) || this.comision || this.descuentoCliente) {
                //this.precioTotal = reserva.ImporteInicial;
                this.precioTotal = this.calcularPrecioTotal().toString();
                this.precioFinal = reserva.TotalReserva;
              } else {
                this.precioTotal = reserva.TotalReserva;
              }
              this.fechaCompra = reserva.FechaCreacion;
              return of(reserva);
            }));
           
            return reserve;
        }
      }))
      .pipe(take(1)).subscribe(() => {
        this.mapEntradas();
        if (!this.estaPagada || this.estaPagada == 0) {
          this.snowzoneCookies();
        }
        this.llamadaPDF();
        this.resetValues();
      });
  }

  llamadaPDF() {
    let enviarEmail;
    if (this.envioEmail()) {
      enviarEmail = 1;
    } else {
      enviarEmail = 0;
    }
    this.paymentService.irPDFCompra(this.pedido, 0, enviarEmail).pipe(take(1)).subscribe((pdf: any) => {
      if (pdf.DatosResult) {
        this.imprimirPDF = pdf.DatosResult.PDF;
        this.paymentService.setPdfDescargado(true);
      }
    });
  }

  initReservaPhoneSell() {

    this.initService.getInitData()
      .pipe(retry(3), concatMap(() => {
        this.pedido = sessionStorage.getItem('pedido');
        this.localizador = [sessionStorage.getItem('localizador')];
        this.tokenCompra = JSON.parse(sessionStorage.getItem('currentToken'));
        if (JSON.parse(sessionStorage.getItem('factura'))) {
          // this.paymentService.generarFactura(this.pedido, this.localizador).pipe(take(1)).subscribe();
        }
        return this.paymentService.traerReserva(this.pedido, '', 0);
      }))
      .pipe(mergeMap((reserva: any) => {
        this.ventaService.ordenacionTraerReserva(reserva);
        this.entradas = this.ventaService.carritoValue;
        this.cliente = reserva.clienteCompra;
        this.emailEnviado = reserva.ReservasAdicionales.EmailEnviado;
        reserva.listLocalizadores.forEach(loc => {
          this.localizadores.push(loc.ClaveReserva);
        });
        this.totalDescuento = reserva.TotalReserva;
        // if (JSON.parse(sessionStorage.getItem('descuento'))) {
        this.IVAComision = this.calcularIVAComision();
        this.comision = this.calcularComision();
        this.descuentoCliente = this.calcularDescuentoCliente();
        if (JSON.parse(sessionStorage.getItem('descuento')) || this.comision || this.descuentoCliente) {
          // this.precioTotal = reserva.ImporteInicial;
          this.precioTotal = this.calcularPrecioTotal().toString();
          this.precioFinal = reserva.TotalReserva;
        } else {
          this.precioTotal = reserva.TotalReserva;
        }

        this.fechaCompra = reserva.FechaCreacion;
        return of(reserva);
      })).pipe(take(1)).subscribe(() => {
        this.mapEntradas();
        this.snowzoneCookies();
        this.llamadaPDF();
        this.resetValues();
      });
  }

  resetValues() {
    this.ventaService.setModificandoReserva(null);
    this.ventaService.setCarrito(new Carrito());
    this.paymentService.localizadores = [];
    sessionStorage.removeItem('factura');
    sessionStorage.removeItem('localizador');
    sessionStorage.removeItem('payment');
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('estaPagada');
    sessionStorage.removeItem('reservaOriginal');
    sessionStorage.removeItem('clienteContacto');
    sessionStorage.removeItem('cliente');
    sessionStorage.removeItem('aceptaCookies');
    this.productosService.setCargandoValue(false);
    this.timerService.reset();
  }

  mapEntradas() {

    this.entradas.compras.forEach(entrada => {
      let detallesEntrada;
      let coment = '';
      let fechaHora = '';
      if (entrada.sesionesSeleccionadas) {
        if (entrada.sesionesSeleccionadas.length) {
          if (entrada.sesionesSeleccionadas[0].Comentarios) {
            coment = entrada.sesionesSeleccionadas[0].Comentarios;
          }
          if (entrada.sesionesSeleccionadas[0].Fecha) {
            fechaHora = entrada.sesionesSeleccionadas[0].Fecha + ' ' + entrada.sesionesSeleccionadas[0].HoraInicio;
          }
        }
      }
      detallesEntrada = {
        Id: entrada.producto.ProductoId,
        Tipo: entrada.producto.NombreProducto,
        Comentarios: coment,
        Precio: (entrada.producto.PVPSinDescuento || entrada.producto.PVPInternet || entrada.producto.PvpProductoUd),
        Cantidad: entrada.cantidad,
        Importe: entrada.cantidad *  Number.parseFloat(parseFloat(entrada.producto.PVPSinDescuento || entrada.producto.PVPInternet || entrada.producto.PvpProductoUd).toFixed(4)),
        Fecha: fechaHora,
        posicionVenta: entrada.posicionVenta,
        producto: entrada.producto,
        datos: entrada.datos,
        sesionesSeleccionadas: entrada.sesionesSeleccionadas,
        butacas: entrada.butacas
      };
      this.entradasList.push(detallesEntrada);
    });
    this.entradasList = this.entradasList.reduce((r, a) => {
      r[a.posicionVenta] = r[a.posicionVenta] || [];
      r[a.posicionVenta].push(a);
      return r;
    }, []).filter(item => item);

    let flag = false;
    this.entradasList.map(compra=>{
      flag= false
      compra.map(product=>{

        if(product.sesionesSeleccionadas && !flag){
          flag= true;
          this.sessionConFechaSinSession.push(product.sesionesSeleccionadas);
        }
      })

    })
    if (JSON.parse(sessionStorage.getItem('descuento'))) {
      this.descuento = parseFloat((this.precioTotal || '0').replace(/,/, '.')) - parseFloat((this.totalDescuento || '0').replace(/,/, '.')) - this.comision;
    }
    this.comision = this.calcularComision();
    this.IVAComision = this.calcularIVAComision();
    this.descuentoCliente = this.calcularDescuentoCliente();
    /** Calculate SUM final price */
    const commissions =  (parseFloat(this.comision) + parseFloat(this.IVAComision));
    const totalPricePlusCommisions = (parseFloat(this.precioTotal) + commissions);
    const totalFinal = totalPricePlusCommisions - parseFloat(this.descuentoCliente);
    this.precioFinal =  totalFinal;
    // parseFloat((this.precioTotal || '0').replace(/,/, '.')) + this.comision + this.IVAComision - this.descuentoCliente;

  }

  irEnviarEmail() {
    this.productosService.setCargandoValue(true);
    this.paymentService.irPDFCompra(this.pedido, 0, 1).pipe(take(1)).subscribe((pdf: any) => {
      if (pdf.DatosResult) {
        this.imprimirPDF = pdf.DatosResult.PDF;
        this.mostrarConfirmacionEmail = true;
        this.productosService.setCargandoValue(false);
        this.mostrarMensaje = false;
        this.paymentService.setPdfDescargado(true);
      }
    });
  }

  irAlPDF() {
    const linkSource = 'data:application/pdf;base64,' + this.imprimirPDF + '\n';
    const downloadLink = document.createElement('a');
    let fileName;
    if (this.tipoPago === PaymentType.PAGO || this.tipoPago === PaymentType.PHONEANDSELL) {
      if (this.proyecto == "MAPFRE") {
        fileName = 'ReservaConfirmada' + this.localizador + '.pdf';
      } else {
        if (this.proyecto =="PEDRERA") 
          fileName = 'ReservaConfirmada' + ' MSB ' + this.localizador + '.pdf';
        else 
          fileName = 'ReservaConfirmada_' + this.proyecto + '_'+ this.localizador + '.pdf';
      }
    } else if (this.tipoPago === PaymentType.RESERVA) {
      if (this.proyecto == "MAPFRE") {
        fileName = 'Reserva' + this.localizador + '.pdf';
      }else{
        if (this.proyecto =="PEDRERA") 
          fileName = 'ReservaConfirmada' + ' MSB ' + this.localizador + '.pdf';
        else 
          fileName = 'ReservaConfirmada_' + this.proyecto + '_'+ this.localizador + '.pdf';
      }
    } else {
      fileName = 'Reserva' + this.localizador + '.pdf'; //Yaribel 20210712  Cambiamos el nombre del archivo de modificación a Reserva
    }
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  googleAnalitics() {
    const productos = [];
    this.entradas.compras.forEach((producto, i) => {
      const item = {
        id: producto.producto.ProductoId,
        name: producto.producto.NombreGrupoInternet + ' - ' + producto.producto.NombreProducto,
        list_name: '',
        brand: '',
        category: '',
        variant: '',
        list_position: i + 1,
        quantity: 1,
        price: producto.producto.PrecioEnDivisaReferencia
      };
      productos.push(item);
    });
    this.itemsGoogle = {
      transaction_id: this.pedido,
      affiliation: 'Snowzone venta',
      value: this.precioTotal,
      currency: 'EUR',
      tax: '',
      shipping: 0,
      items: productos
    };
    return this.itemsGoogle;
  }

  tagManager() {
    const productos = [];
    this.entradas.compras.forEach((producto, i) => {
      const item = {
        sku: producto.producto.ProductoId,
        name: producto.producto.NombreGrupoInternet + ' - ' + producto.producto.NombreProducto,
        quantity: +producto.producto.Cantidad,
        price: +producto.producto.PrecioEnDivisaReferencia
      };
      productos.push(item);
    });
    this.itemsGoogle = {
      transactionId: this.pedido,
      transactionAffiliation: 'Món Sant Benet',
      transactionTotal: +this.precioTotal,
      transactionTax: '',
      transactionShipping: 0,
      transactionProducts: productos
    };
    return this.itemsGoogle;
  }

  tagManagerECMejorado() {
    const productos = [];

    this.entradas.compras.forEach((producto, i) => {
      const item = {
        id: producto.producto.ProductoId,
        name: producto.producto.NombreGrupoInternet + ' - ' + producto.producto.NombreProducto,
        quantity: +producto.producto.Cantidad,
        price: producto.producto.PrecioEnDivisaReferencia,
        // brand: '',
        // category:'',
        // variant:'',
        // coupon: '' // Optional fields may be omitted or set to empty string
      };
      productos.push(item);
    });

    const datosCompra = {
      id: this.pedido,
      affiliation: 'Món Sant Benet',
      revenue: +this.precioTotal,
      tax: 0,
      shipping: 0,
      // coupon: ''
    }


    this.itemsGoogle = {
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: datosCompra,
          products: productos
        }
      }
    };
    return this.itemsGoogle;
  }
  tagManager2() {
    const productos = [];
    this.entradas.compras.forEach((producto, i) => {
      const item = {
        id: producto.producto.ProductoId,
        name: producto.producto.NombreGrupoInternet + ' - ' + producto.producto.NombreProducto,
        quantity: +producto.producto.Cantidad,
        price: producto.producto.PrecioEnDivisaReferencia,
        // brand: '',
        // category:'',
        // variant:'',
        // list_position: i,
        // list_name:"",
      };
      productos.push(item);
    });

    this.itemsGoogle = {
      transaction_id: this.pedido,
      affiliation: 'Món Sant Benet',
      value: +this.precioTotal,
      tax: 0,
      shipping: 0,
      currency: 'EUR',
      items: productos
    };
    return this.itemsGoogle;
  }

  gtagEcommerceMejorado() {
    const productos = [];
    this.entradas.compras.forEach((producto, i) => {
      const item = {
        id: producto.producto.ProductoId,
        name: producto.producto.NombreGrupoInternet + ' - ' + producto.producto.NombreProducto,
        quantity: +producto.producto.Cantidad,
        price: producto.producto.PrecioEnDivisaReferencia,
        // brand: '',
        // category:'',
        // variant:'',
        // list_position: i,
        // list_name:"",
      };
      productos.push(item);
    });

    this.itemsGoogle = {
      transaction_id: this.pedido,
      affiliation: 'Món Sant Benet',
      value: +this.precioTotal,
      tax: 0,
      shipping: 0,
      currency: 'EUR',
      items: productos
    };
    return this.itemsGoogle;

  }
  snowzoneCookies() {
    if (this.appConfig.getProyecto() === 'SNOWZONE') {
      const cookies = JSON.parse(sessionStorage.getItem('cookies'));
      const facebook = cookies.find(c => c.Nombre === 'Facebook (_fbq)');
      const google = cookies.find(c => c.Categoria === 'ESTADISTICA');
      if (facebook.aceptar === true) {
        //fbq('track', 'Purchase', { value: this.precioTotal, currency: 'EUR' });
      }
      if (google.aceptar === true) {
        //gtag('event', 'purchase', this.googleAnalitics());
        //gtag('event', 'conversion', { 'send_to': 'AW-700163194/E6ZCCL-N6MMBEPrI7s0C', 'transaction_id': this.pedido });
      }
    } else {
      //this.registrarEventosTM();
      if (sessionStorage.getItem('aceptaCookies'))
        this.GAService.dataLayerEcommerce(this.tagManagerECMejorado());
    }
  }

  registrarEventosTM() {
    if (this.initService.aceptaCookieGoolgeValue)
      this.GAService.emitEvent('event', 'purchase', this.tagManager2());
    this.GAService.callStepView('purchase');
  }

  envioEmail() {
    if (this.tipoPago === PaymentType.RESERVA || this.tipoPago === PaymentType.MODIFICARRESERVA) {
      this.enviarEmail = this.productosService.getEnlaceMenuId() ? JSON.parse(this.productosService.getEnlaceMenuId().CaracteristicasAdicionales)
        .CaracteristicasAdicionales.MailConfirmacionReservaOpcional : undefined;
    } else if (this.tipoPago === PaymentType.PAGO || this.tipoPago === PaymentType.PHONEANDSELL) {
      if (this.emailEnviado && this.emailEnviado == 1) {
        this.enviarEmail = 1;
      } else {
        this.enviarEmail = this.productosService.getEnlaceMenuId() ? JSON.parse(this.productosService.getEnlaceMenuId().CaracteristicasAdicionales)
          .CaracteristicasAdicionales.MailConfirmacionVentaOpcional : undefined;
      }
    }
    if (!this.enviarEmail) {
      this.mostrarConfirmacionEmail = true;
      return true;
    } else if (this.enviarEmail === 0) {
      this.mostrarConfirmacionEmail = true;
      return true;
    } else if (this.enviarEmail === 1) {
      this.mostrarConfirmacionEmail = true;
      this.mostrarMensaje = true;
      return false;
    } else if (this.enviarEmail === 2) {
      this.mostrarConfirmacionEmail = true;
      this.mostrarMensaje = true;
      return false;
    }
  }

  async comprobarReserva(pedido: string) {
    this.paymentService.traerReservaEstado(pedido).
      pipe(map((res: any) => {
        this.estaPagada = res.DatosResult.Pagada;
        if (!res.DatosResult || res.DatosResult.Pagada == 0) {
          return this.paymentService.confirmarPago(this.tokenCompra, this.pedido, window.location.href, this.sesionIdCompra,this.medioPago).pipe(res => {
            this.actualizarDatos(res);
            return res;
          }).subscribe();
        } else if (res.DatosResult.Pagada == 1) {
          this.estaPagada = res.DatosResult.Pagada;
          return of({ DatosResult: true }).pipe(res => {
            this.actualizarDatos(res);
            return res;
          }).subscribe();
        }
      })).subscribe();
  }

  actualizarDatos(res) {
    if (!res) {
      if (this.route.snapshot.params.tipoPago && this.route.snapshot.params.pedido) {
        this.router.navigate(['/thankko' + '/' + this.route.snapshot.params.tipoPago + '/' + this.route.snapshot.params.medioPago + '/' + this.route.snapshot.params.pedido]);
      } else {
        this.router.navigate(['/thankko']);
      }
      return;
    } else if (res.DatosResult === false) {
      if (this.route.snapshot.params.tipoPago && this.route.snapshot.params.pedido) {
        this.router.navigate(['/thankko' + '/' + this.route.snapshot.params.tipoPago + '/' + this.route.snapshot.params.medioPago + '/' + this.route.snapshot.params.pedido]);
      } else {
        this.router.navigate(['/thankko']);
      }
      return;
    } else if (res.DatosResult === true) {
      if (JSON.parse(sessionStorage.getItem('factura'))) {
        this.paymentService.generarFactura(this.pedido, this.localizador).pipe(take(1)).subscribe();
      }
      return this.paymentService.traerReserva(this.pedido, '', 1)
        .pipe(mergeMap((reserva: any) => {
          this.ventaService.ordenacionTraerReserva(reserva);
          this.entradas = this.ventaService.carritoValue;
          this.cliente = reserva.clienteCompra;
          reserva.listLocalizadores.forEach(loc => {
            this.localizadores.push(loc.ClaveReserva);
          });
          this.totalDescuento = reserva.TotalReserva;
          // if (JSON.parse(sessionStorage.getItem('descuento'))) {
          this.IVAComision = this.calcularIVAComision();
          this.comision = this.calcularComision();
          this.descuentoCliente = this.calcularDescuentoCliente();
          if (JSON.parse(sessionStorage.getItem('descuento')) || this.comision || this.descuentoCliente) {
            // this.precioTotal = reserva.ImporteInicial;
            this.precioTotal = this.calcularPrecioTotal().toString();
            this.precioFinal = reserva.TotalReserva;
          } else {
            this.precioTotal = reserva.TotalReserva;
          }
          this.fechaCompra = reserva.FechaCreacion;
          return of(reserva);
        })).subscribe(() => {

          this.mapEntradas();
          if (!this.estaPagada) {
            this.snowzoneCookies();
            this.llamadaPDF();
          }
          this.resetValues();
        }
        );
    }
  }
  public tieneRecintos(entrada)
  {
    
    if(entrada.sesionesSeleccionadas && entrada.sesionesSeleccionadas != undefined && entrada.sesionesSeleccionadas != null && entrada.sesionesSeleccionadas.length > 0)
    {
      return true;
    } else
    {
      return false;
    }
  }
  public recintos(entrada)
  {
    return(entrada.sesionesSeleccionadas && entrada.sesionesSeleccionadas.length > 0)? entrada.sesionesSeleccionadas: [];
  }
  getDivisasValue(){
    return this.configurationService.divisasValue;
  }

    calcularComision() : Number{
    let precioTotal = 0;
    this.entradas.compras.forEach(entrada => {
      if(this.ventaService.incluirComisionValue && entrada.producto.DesglosarComision == '1'){
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat(entrada.producto.ComisionBase).toFixed(4))  * entrada.cantidad);    
    }
    });
     /* Validar 3er dígito */    
     let strPrecio = precioTotal.toString();
     let arrPrecio = [];
     if(strPrecio.includes('.')) {
       arrPrecio = strPrecio.split('.');
       if(arrPrecio[1].length > 2) {
         precioTotal = arrPrecio[1][2] === '5' ? Number.parseFloat(`${arrPrecio[0]}.${arrPrecio[1]}`) + 0.001 : Number.parseFloat(`${arrPrecio[0]}.${arrPrecio[1]}`);
       }       
     }
     return Number.parseFloat((Math.ceil(precioTotal * 100) / 100).toFixed(2));
     //return Number.parseFloat(precioTotal.toFixed(2));
  }
  calcularIVAComision(){
    let precioTotal = 0 ;
    this.entradas.compras.forEach(entrada => {
      if(this.ventaService.incluirComisionValue && entrada.producto.DesglosarComision == "1"){
        let comisionInternet = Number.parseFloat((entrada.producto.ComisionInternet).replace(/,/, '.')).toFixed(4);
        let comisionBase = Number.parseFloat((entrada.producto.ComisionBase).replace(/,/, '.')).toFixed(4);
        let IvaComision = Number.parseFloat((Number.parseFloat(comisionInternet) - Number.parseFloat(comisionBase)).toFixed(4));
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (IvaComision * entrada.cantidad);       
//        let IvaComision = Number.parseFloat((Number.parseFloat(entrada.producto.ComisionInternet.toFixed(2)) - Number.parseFloat(entrada.producto.ComisionBase.toFixed(2))).toFixed(2));
//        precioTotal = Number.parseFloat(precioTotal.toFixed(2)) + (IvaComision * entrada.cantidad);
      }
      //precioTotal = Math.round(precioTotal * 100) / 100
    });
    return Math.floor(precioTotal * 100) / 100;
    //return Number.parseFloat(precioTotal.toFixed(2));
  }

  getTwoDecimal(num) {
    /*let string = num.toString();
    return Number(string.substring(0, string.indexOf('.') + 3));*/
    if(!num) {
      return 0;
    }    
    if(typeof num !== 'number') {
      num = Number.parseFloat(num);
    }
    return Number.parseFloat((num).toFixed(2));
  }  

  calcularDescuentoCliente(){
    let precioTotal = 0;
    this.entradas.compras.forEach(entrada => {
      //precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(entrada.producto.Descuento)  * entrada.cantidad)    
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (this.getTwoDecimal(Number.parseFloat(entrada.producto.Descuento))  * entrada.cantidad)    
    });
    //return Number.parseFloat(precioTotal.toFixed(2));
    precioTotal = Number.parseFloat(precioTotal.toFixed(4));
    return this.getTwoDecimal(precioTotal);
  }

  calcularPrecioTotal(){
    let precioTotal = 0;
    this.entradas.compras.forEach(entrada => { 
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(entrada.producto.PVPSinDescuento) * entrada.cantidad);    
      //precioTotal = Math.round(precioTotal * 100) / 100
    });
    return Number.parseFloat(precioTotal.toFixed(2));
  }

  

  tieneButacas(entrada)  {
    
    if(entrada.butacas && entrada.butacas != undefined && entrada.butacas != null && entrada.butacas.length > 0)
    {
      return true;
    } else
    {
      return false;
    }
  }
}

