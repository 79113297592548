import {
  Injectable, Inject, LOCALE_ID
} from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  moment = moment();
  currentDate = moment();
  today = moment();

  year = this.currentDate.year();
  month = this.currentDate.month();
  selectedMonth: string;
  selectedYear: string;
  isMonthSelected: boolean;

  private tieneCalendarioPorRecintoSubject: BehaviorSubject<any>;
  public tieneCalendarioPorRecinto$: Observable<any>;

  private fechasDisponibilidadSubject: BehaviorSubject<any>;
  public fechasDisponibilidad$: Observable<any>;

  private diaDisponibleSubject: BehaviorSubject<any>;
  public diaDisponible$: Observable<any>;

  private mostrarSubject: BehaviorSubject<any>;
  public mostrar$: Observable<any>;

  private mostrarFiltroDiasSubject: BehaviorSubject<any>;
  public mostrarFiltroDias$: Observable<any>;

  private calendarioPaso2Subject: BehaviorSubject<any>;
  public calendarioPaso2$: Observable<any>;

  private sesionesCargadasSubject: BehaviorSubject<any>;
  public sesionesCargadas$: Observable<any>;

  private filtrosSubject: BehaviorSubject<any>;
  public filtros$: Observable<any>;

  private filtrosObjectSubject: BehaviorSubject<any>;
  public filtrosObject$: Observable<any>;

  private fechaSubject: BehaviorSubject<any>;
  public fecha$: Observable<any>;

  private mesesSubject: BehaviorSubject<any>;
  public meses$: Observable<any>;
  constructor(
    @Inject(LOCALE_ID) protected localeId

  ) {
    localeId = localStorage.getItem("lastlang");
    this.currentDate.locale(localeId);
    this.moment.locale(localeId);
   

    this.tieneCalendarioPorRecintoSubject = new BehaviorSubject(false);
    this.tieneCalendarioPorRecinto$ = this.tieneCalendarioPorRecintoSubject.asObservable();

    this.fechasDisponibilidadSubject = new BehaviorSubject(undefined);
    this.fechasDisponibilidad$ = this.fechasDisponibilidadSubject.asObservable();

    this.diaDisponibleSubject = new BehaviorSubject(false);
    this.diaDisponible$ = this.diaDisponibleSubject.asObservable();

    this.mostrarFiltroDiasSubject = new BehaviorSubject(false);
    this.mostrarFiltroDias$ = this.mostrarFiltroDiasSubject.asObservable();

    this.mostrarSubject = new BehaviorSubject(false);
    this.mostrar$ = this.mostrarSubject.asObservable();

    this.calendarioPaso2Subject = new BehaviorSubject(false);
    this.calendarioPaso2$ = this.calendarioPaso2Subject.asObservable();

    this.sesionesCargadasSubject = new BehaviorSubject(false);
    this.sesionesCargadas$ = this.sesionesCargadasSubject.asObservable();

    this.filtrosSubject = new BehaviorSubject(false);
    this.filtros$ = this.filtrosSubject.asObservable();

    this.filtrosObjectSubject = new BehaviorSubject(undefined);
    this.filtrosObject$ = this.filtrosObjectSubject.asObservable();

    moment.locale(localeId);

    this.fechaSubject = new BehaviorSubject(moment());
    this.fecha$ = this.fechaSubject.asObservable();

    this.mesesSubject = new BehaviorSubject(moment.months());
    this.meses$ = this.mesesSubject.asObservable();
  }

  public get tieneCalendarioPorRecinto() {
    return this.tieneCalendarioPorRecintoSubject.value;
  }

  public setTieneCalendarioPorRecinto(tieneCalendario: boolean) {
    this.tieneCalendarioPorRecintoSubject.next(tieneCalendario);
  }

  public get fechasDisponibilidadValue() {
    return this.fechasDisponibilidadSubject.value;
  }

  public setFechasDisponibilidad(fechas) {
    this.fechasDisponibilidadSubject.next(fechas);
  }

  public get diaDisponibleValue() {
    return this.diaDisponibleSubject.value;
  }

  public setDiaDisponible(disponible) {
    this.diaDisponibleSubject.next(disponible);
  }

  public get mostrarValue() {
    return this.mostrarSubject.value;
  }

  public setMostrar(mostrar) {
    this.mostrarSubject.next(mostrar);
  }

  public get mostrarFiltroDiasValue() {
    return this.mostrarFiltroDiasSubject.value;
  }

  public setMostrarFiltroDias(mostrar) {
    this.mostrarFiltroDiasSubject.next(mostrar);
  }

  public get calendarioPaso2Value() {
    return this.calendarioPaso2Subject.value;
  }

  public setCalendarioPaso2(mostrar) {
    this.calendarioPaso2Subject.next(mostrar);
  }

  public get sesionesCargadasValue() {
    return this.sesionesCargadasSubject.value;
  }

  public setSesionesCargadas(boolean) {
    this.sesionesCargadasSubject.next(boolean);
  }

  public get filtrosValue() {
    return this.filtrosSubject.value;
  }

  public setFiltros(mostrar) {
    this.filtrosSubject.next(mostrar);
  }

  public get filtrosObjectValue() {
    return this.filtrosObjectSubject.value;
  }

  public setFiltrosObject(filtros) {
    this.filtrosObjectSubject.next(filtros);
  }

  public get meses() {
    return this.mesesSubject.value;
  }

  public setMeses(locale = this.localeId) {
    if (locale === 'es-ES') {
      locale = 'es';
    }
    moment.updateLocale("es", {});
    this.currentDate.locale("es");
    this.moment.locale("es");
    this.mesesSubject.next(moment.months());
  }

  public get fechaValue() {
    return this.fechaSubject.value;
  }

  public setFecha(fecha) {
    this.fechaSubject.next(fecha);
  }

  mostrarCalendario(paso, caracteristicasCalendario, grupoInternet) {
    const pantallas = caracteristicasCalendario.GINoMostrarCalendario;
    let mostrarCalendarioPaso;
    if (caracteristicasCalendario.VisibilidadAngular) {
      const VisibilidadAngular = caracteristicasCalendario.VisibilidadAngular;
      let paso1 = [];
      if (VisibilidadAngular.Paso1 !== '') {
        paso1 = JSON.parse('[' + VisibilidadAngular.Paso1 + ']');
      }
      let paso2 = [];
      if (VisibilidadAngular.Paso2 !== '') {
        paso2 = JSON.parse('[' + VisibilidadAngular.Paso2 + ']');
      }
      if (paso1.find(res => res === grupoInternet)) {
        mostrarCalendarioPaso = 'Paso1';
      } else if (paso2.find(res => res === grupoInternet)) {
        mostrarCalendarioPaso = 'Paso2';
      } else {
        mostrarCalendarioPaso = VisibilidadAngular.Defecto;
      }
    } else {
      mostrarCalendarioPaso = caracteristicasCalendario.Visibilidad;
    }
    this.setCalendarioPaso2((mostrarCalendarioPaso == 'Paso2'));
    if (mostrarCalendarioPaso !== paso) {
      this.setMostrar(false);
    } else {
      if (pantallas) {
        pantallas.find(res => {
          if (res === grupoInternet) {
            this.setMostrar(false);
          }
        });
      }
    }
  }

  calendarioPorRecinto(caracteristicasCalendario, grupoInternet, recintos) {
    if (!caracteristicasCalendario.CalendarioPorRecintoGI) {
      this.setTieneCalendarioPorRecinto(false);
    } else if (recintos.length <= 1) {
      this.setTieneCalendarioPorRecinto(false);
    } else if (caracteristicasCalendario.CalendarioPorRecintoGI.find(gi => parseInt(gi, 10) === 0) === 0) {
      this.setTieneCalendarioPorRecinto(true);
    } else if (caracteristicasCalendario.CalendarioPorRecintoGI.find(gi => parseInt(gi, 10) === parseInt(grupoInternet, 10))) {
      this.setTieneCalendarioPorRecinto(true);
    }
  }
}
