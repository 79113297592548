import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { IndexService } from './core/services/index.service';
import { TranslateService } from './shared/translate.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfiguration {

  // tslint:disable-next-line:ban-types
  private config: Object = null;
  // tslint:disable-next-line:ban-types
  private env: Object = null;

  constructor(
    private http: HttpClient,
    private index: IndexService,
    private translateService: TranslateService) { }

  public getGoogleId() {
      return environment['chGoogleId'];
    }

  public getProyecto() {
    return environment['chClaveEmpresa'];
  }

  public getConfig(key: any) {
    //return this.config[key];
    return environment[key];
  }

  public getEnv(key: any) {
    return environment[key];
  }


  public load() {
    this.translateService.use(navigator.language, this.getProyecto());
    this.index.loadIndex(environment);

    /* return new Promise((resolve, reject) => {
      this.http.get('assets/configurations/env.json').pipe(map((res: any, err: any) => {
        if (err) {
          resolve(true);
          return Observable.throw(err.json().error || 'Server error');
        }
        return res;
      })).pipe(take(1)).subscribe((envResponse) => {
        this.env = envResponse;
        let request: any = null;

        switch (envResponse.env) {
          case 'production':
            request = this.http.get('assets/configurations/config.' + envResponse.env + '.json');
            break;

          case 'development':
            request = this.http.get('assets/configurations/config.' + envResponse.env + '.json');
            break;

          case 'test':
            request = this.http.get('assets/configurations/config.' + envResponse.env + '.json');
            break;

          case 'default':
            console.error('Environment file is not set or invalid');
            resolve(true);
        }
        if (request) {
          request
            .pipe(map(res => res))
            .pipe(catchError((error: any) => {
              console.error('Error reading ' + envResponse.env + ' configuration file');
              resolve(error);
              return Observable.throw(error.json().error || 'Server error');
            }))
            .pipe(take(1)).subscribe((responseData) => {
              this.config = responseData;
              this.translateService.use(navigator.language, this.getProyecto());
              this.index.loadIndex(this.config);
              resolve(true);
            });
        } else {
          console.error('Env config file "env.json" is not valid');
          resolve(true);
        }
      });
    }); */
  }
}
