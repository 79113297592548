export const environment = {
  production: false,
  console: true,
  API_URL: "https://apides.apiacpos.com/api_bridge/",
  numSerie: "WEBAN$A$l06$A$GULAR$A$AM3[jC)",
  chClaveEmpresa: "museocr",
  chNombreProyecto: "VENTAONLINE",
  chEntornoConexion: "DESARROLLO",
  chOrigenConexion: "INTERNET",
  chPasarelaEntorno: "DESARROLLO",
  pasarelaRedsys: "DESARROLLO",
  pasarelaPhoneSell: "PHONE_M_PRE",
  pasarelaEntornoPhoneSell: "CONSULTA_DES",
  pasarelaPaypal: "PAYPAL_DES",
  URL_PDF: "http://grecpruebas.iacpos.com/grec-api/tickets/",
  URLOK: "https://tickets.museocr.iacposdes.com/thankyou",
  URLKO: "https://tickets.museocr.iacposdes.com/thankko",
  URL: "https://tickets.museocr.iacposdes.com/",
  siteKey: "6LfXS2IUAAAAADr2WUPQDzAnTEbSQzE1Jxh0Zi0a",
  timer: 10,
};
