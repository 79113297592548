import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { map, take } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { InitService } from 'src/app/core/init/init.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { Compra } from 'src/app/models/compra.model';
import { ModalService } from 'src/app/shared/modal.service';

@Component({
  selector: 'app-menu-deplegable',
  templateUrl: './menu-deplegable.component.html',
  styleUrls: ['./menu-deplegable.component.scss']
})
export class MenuDeplegableComponent implements OnInit {
  confirmado;
  grupoProductos;
  producto;
  
  constructor(
    private router: Router,
    private modalService: ModalService,
    private initService: InitService,
    private ventaService: VentaService,
    private productosService: ProductosService
  ) { }

  ngOnInit() {
    this.initService.grupoProductos$.subscribe(res => this.grupoProductos = res);
  }

  irA() {
    this.ventaService.setModificarValue(false);
    this.confirmado = false;
    // tslint:disable-next-line:max-line-length
    if ((this.ventaService.cambiosValue && this.ventaService.compraValue.productos.length !== 0) ||
      this.ventaService.modificarValue && this.ventaService.cambiosValue) {
      this.modalService.open('aviso');
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$.pipe(takeWhile(() => !this.confirmado)).subscribe(confirmado => {
      this.confirmado = confirmado;
      if (this.confirmado) {
        this.productosService.setCargandoValue(true);
        this.ventaService.setCompra(new Compra());
        this.ventaService.setCambios(false);
        this.modalService.setConfirmadoValue(false);
        this.productosService.setCargandoValue(false);
        this.router.navigate(['/tienda/index']);
      }
    });
  }

  irMenu(prod, value) {
    this.ventaService.setModificarValue(false);
    this.confirmado = false;
    // tslint:disable-next-line:max-line-length
    if ((this.ventaService.cambiosValue && this.ventaService.compraValue.productos.length !== 0) ||
      this.ventaService.modificarValue && this.ventaService.cambiosValue) {
      this.modalService.open('aviso');
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$.pipe(takeWhile(() => !this.confirmado)).subscribe(confirmado => {
      this.confirmado = confirmado;
      if (this.confirmado) {
        this.productosService.setCargandoValue(true);
        this.ventaService.setCompra(new Compra());
        this.ventaService.setCambios(false);
        this.modalService.setConfirmadoValue(false);
        if (prod === 'ESCUELA' && value === 'part' && this.grupoProductos === 'ONLINE') {
          this.ventaService.value = 'part';
          this.productosService.setOtrosProductosValue('ESCUELA');
        }
        if (prod === 'ESCUELA' && value === 'grup' && this.grupoProductos === 'ONLINE') {
          this.ventaService.value = 'grup';
          this.productosService.setOtrosProductosValue('ESCUELA');
        }
        if (prod === 'FORFAIT' && value === 'part' && this.grupoProductos === 'ONLINE') {
          this.ventaService.value = 'part';
          this.productosService.setOtrosProductosValue('SKI_SNOW');
        }
        if (prod === 'FORFAIT' && value === 'grup' && this.grupoProductos === 'ONLINE') {
          this.ventaService.value = 'grup';
          this.productosService.setOtrosProductosValue('SKI_SNOW');
        }
        if (prod === 'ACTIVIDADES' && this.grupoProductos === 'ONLINE') {
          this.productosService.setOtrosProductosValue('DIVIERTETE');
        }
        if (prod === 'ESCUELA' && this.grupoProductos === 'COLEGIOS') {
          this.productosService.setOtrosProductosValue('COLEGIOS ESCUELA');
        }
        if (prod === 'SKI-SNOW' && this.grupoProductos === 'COLEGIOS') {
          this.productosService.setOtrosProductosValue('COLEGIOS ACTIVIDADES');
        }
        if (prod === 'ESCUELA' && this.grupoProductos === 'EMPRESAS') {
          this.productosService.setOtrosProductosValue('EMPRESAS ESCUELA');
        }
        if (prod === 'SKI-SNOW' && this.grupoProductos === 'EMPRESAS') {
          this.productosService.setOtrosProductosValue('EMPRESAS ACTIVIDADES');
        }
        if (prod === 'ESCUELA' && this.grupoProductos === 'CLUBES') {
          this.productosService.setOtrosProductosValue('CLUBES ESCUELA');
        }
        if (prod === 'SKI-SNOW' && this.grupoProductos === 'CLUBES') {
          this.productosService.setOtrosProductosValue('CLUBES ACTIVIDADES');
        }
        if (prod === 'ESCUELA' && value === 'part' && this.grupoProductos === 'ADMIN') {
          this.ventaService.value = 'part';
          this.productosService.setOtrosProductosValue('PARTICULARES ESCUELA');
        }
        if (prod === 'ESCUELA' && value === 'grup' && this.grupoProductos === 'ADMIN') {
          this.ventaService.value = 'grup';
          this.productosService.setOtrosProductosValue('PARTICULARES ESCUELA');
        }
        if (prod === 'FORFAIT' && value === 'part' && this.grupoProductos === 'ADMIN') {
          this.ventaService.value = 'part';
          this.productosService.setOtrosProductosValue('PARTICULARES ACTIVIDADES');
        }
        if (prod === 'FORFAIT' && value === 'grup' && this.grupoProductos === 'ADMIN') {
          this.ventaService.value = 'grup';
          this.productosService.setOtrosProductosValue('PARTICULARES ACTIVIDADES');
        }
        if (prod === 'ACTIVIDADES' && this.grupoProductos === 'ONLINE') {
          this.productosService.setOtrosProductosValue('PARTICULARES DIVIERTETE');
        }
        this.productosService.setnombreGrupoMenuValue(prod);
        this.productosService.getProductosMenu().pipe(take(1)).subscribe(res => this.productosService.setProductosMenuValue(Object.values(res.DictTarifasEntradas)));
        this.productosService.setCargandoValue(false);
        this.router.navigate(['/tienda/menu']);
      }
    });
  }

  irMenuEquipacion() {
    this.ventaService.setModificarValue(false);
    this.confirmado = false;
    if ((this.ventaService.cambiosValue && this.ventaService.compraValue.productos.length !== 0) ||
      this.ventaService.modificarValue && this.ventaService.cambiosValue) {
      this.modalService.open('aviso');
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$.pipe(takeWhile(() => !this.confirmado)).subscribe(confirmado => {
      this.confirmado = confirmado;
      if (this.confirmado) {
        this.productosService.setCargandoValue(true);
        this.ventaService.setCompra(new Compra());
        this.ventaService.setCambios(false);
        this.modalService.setConfirmadoValue(false);
        this.productosService.setOtrosProductosValue('EQUIPO');
        this.productosService.setnombreGrupoMenuValue('EQUIPO');
        this.productosService.getProductosMenu().subscribe(res => this.productosService.setProductosMenuValue(Object.values(res.DictTarifasEntradas)));
        this.productosService.setCargandoValue(false);
        this.router.navigate(['/tienda/menu']);
      }
    });
  }

  nombreGi(gi, em) {
    const grupo = this.productosService.productosHeaderValue.find(prod => prod.EnlaceMenuId === em && prod.GrupoInternetId === gi.toString());
    if (grupo) {
      return grupo.NombreGrupo;
    }
  }

  irSiguiente(gI, em) {
    this.ventaService.setModificarValue(false);
    this.productosService.setFiltroTipoValue('');
    this.productosService.setFiltroGrupoValue('');
    this.productosService.setFiltroNivelValue('');
    this.confirmado = false;
    if ((this.ventaService.cambiosValue && this.ventaService.compraValue.productos.length !== 0) ||
      this.ventaService.modificarValue && this.ventaService.cambiosValue) {
      this.modalService.open('aviso');
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$.pipe(takeWhile(() => !this.confirmado)).subscribe(confirmado => {
      this.confirmado = confirmado;
      if (this.confirmado) {
        this.productosService.setCargandoValue(true);
        this.ventaService.setCompra(new Compra());
        this.ventaService.setCambios(false);
        this.modalService.setConfirmadoValue(false);
        this.producto = { g: gI, m: em };
        const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === this.producto.m);
        const calendario = JSON.parse(enlaceMenu.chCalendario);
        this.initService.setChCalendarioValue(calendario);
        const datosUrl = JSON.parse(this.productosService.datosInitValue);
        const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(enlaceMenu.pkid);
        if (this.producto.g === 7 && enlaceMenuNombre === 'SKI_SNOW') {
          this.productosService.setOtrosProductosValue('EQUIPO');
        } else {
          this.productosService.setOtrosProductosValue(enlaceMenuNombre);
        }
        this.productosService.setDatosInitValue({
          empresa: datosUrl.empresa, m: enlaceMenuNombre,
          gi: this.producto.g, userId: JSON.parse(this.productosService.datosInitValue).userId,
          u: JSON.parse(this.productosService.datosInitValue).u, p: JSON.parse(this.productosService.datosInitValue).p
        });
        sessionStorage.setItem('token', this.initService.funcionEncript(this.productosService.datosInitValue));
        this.productosService.getProductos()
          .pipe(map(res => {
            if(res && res.DictTarifasEntradas){
            this.productosService.setnombreGrupoValue(res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].NombreGrupo);
            this.productosService.setProductosValue(
              res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].DictTarifasProductos,
              res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].EnlaceMenuId,
              this.productosService.getGrupoInternet().gi,
              res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].TipoReservaId,
              res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi].NombreGrupo);
            this.productosService.setInfoGrupoValue(res.DictTarifasEntradas[this.productosService.getGrupoInternet().gi]);
            }
          })).pipe(take(1)).subscribe(
            () => {
              this.productosService.setCargandoValue(false);
              this.router.navigate(['/tienda/producto/1']);
            }
          );
      }
    });
  }
}
