import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import {FaConfig, FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { PageTitleComponent } from './page-title/page-title.component';
import { BarraUserComponent } from './barra-user/barra-user.component';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { MenuDeplegableComponent } from './header/menu-deplegable/menu-deplegable.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BannerComponent } from './banner/banner.component';


@NgModule({
  declarations: [
    HeaderComponent, 
    PageTitleComponent, 
    BarraUserComponent, 
    FooterComponent, 
    BannerComponent, 
    MenuDeplegableComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    MatDialogModule
  ],
  exports: [
    HeaderComponent,
    PageTitleComponent,
    BarraUserComponent,
    FooterComponent,
    BannerComponent
  ]
})
export class LayoutsModule {
  // constructor() {
  //   library.add(fas, far, fab);
  // }
  constructor(library: FaIconLibrary, config: FaConfig) {
    config.fallbackIcon = faBan;
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fab);
  }
}
