import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { Sesion } from 'src/app/models/compra.model';

interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  enabled?: boolean;
  complete?: boolean;
  ultimas?: boolean;
  selectable?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CalendarModalService {
  moment = moment();
  currentDateModal = moment();
  todayModal = moment();

  yearModal = this.currentDateModal.year();
  selectedMonthModal: string;
  selectedYearModal: string;
  isMonthSelectedModal: boolean;
  diaSeleccionado = false;
  
  private sesionesModalSubject: BehaviorSubject<Sesion[]>;
  public sesionesModal$: Observable<Sesion[]>;

  private recintoSubject: BehaviorSubject<any>;
  public recinto$: Observable<any>;

  private fechasDisponibilidadSubject: BehaviorSubject<any>;
  public fechasDisponibilidad$: Observable<any>;

  private diaDisponibleSubject: BehaviorSubject<any>;
  public diaDisponible$: Observable<any>;

  private mostrarSubject: BehaviorSubject<any>;
  public mostrar$: Observable<any>;

  private sesionesCargadasSubject: BehaviorSubject<any>;
  public sesionesCargadas$: Observable<any>;

  private fechaSubject: BehaviorSubject<any>;
  public fecha$: Observable<any>;

  private mesesSubject: BehaviorSubject<any>;
  public meses$: Observable<any>;

  private weeksModalSubject: BehaviorSubject<CalendarDate[][]>;
  public weeksModal$: Observable<CalendarDate[][]>;

  private fechaSeleccionadaModalSubject: BehaviorSubject<any>;
  public fechaSeleccionadaModal$: Observable<any>;

  constructor(
    @Inject(LOCALE_ID) protected localeId

  ) {
    this.currentDateModal.locale(localeId);
    this.moment.locale(localeId);

    this.sesionesModalSubject = new BehaviorSubject([]);
    this.sesionesModal$ = this.sesionesModalSubject.asObservable();

    this.recintoSubject = new BehaviorSubject(undefined);
    this.recinto$ = this.recintoSubject.asObservable();

    this.fechasDisponibilidadSubject = new BehaviorSubject(undefined);
    this.fechasDisponibilidad$ = this.fechasDisponibilidadSubject.asObservable();

    this.diaDisponibleSubject = new BehaviorSubject(false);
    this.diaDisponible$ = this.diaDisponibleSubject.asObservable();

    this.mostrarSubject = new BehaviorSubject(false);
    this.mostrar$ = this.mostrarSubject.asObservable();

    this.weeksModalSubject = new BehaviorSubject([]);
    this.weeksModal$ = this.weeksModalSubject.asObservable();


    this.sesionesCargadasSubject = new BehaviorSubject(false);
    this.sesionesCargadas$ = this.sesionesCargadasSubject.asObservable();

    this.fechaSeleccionadaModalSubject = new BehaviorSubject(moment());
    this.fechaSeleccionadaModal$ = this.fechaSeleccionadaModalSubject.asObservable().pipe(distinctUntilChanged()).pipe(shareReplay(1));
    
    moment.locale(localeId);

    this.fechaSubject = new BehaviorSubject(moment());
    this.fecha$ = this.fechaSubject.asObservable();

    this.mesesSubject = new BehaviorSubject(moment.months());
    this.meses$ = this.mesesSubject.asObservable();
  }
  public get fechaSeleccionadaModalValue() {
    return this.fechaSeleccionadaModalSubject.value;
  }

  get sesionesModal() {
    return this.sesionesModalSubject.value;
  }

  public setSesionesModal(sesiones) {
    this.sesionesModalSubject.next(sesiones);
  }

  setFechaSeleccionadaModalValue(fecha = moment()) {
    this.fechaSeleccionadaModalSubject.next(fecha);
  }

  public get recintoValue() {
    return this.recintoSubject.value;
  }

  public setRecinto(recinto) {
    this.recintoSubject.next(recinto);
  }

  public get weeksModalValue() {
    return this.weeksModalSubject.value;
  }

  public setWeeksModal(weeks) {
    this.weeksModalSubject.next(weeks);
  }

  public get fechasDisponibilidadValue() {
    return this.fechasDisponibilidadSubject.value;
  }

  public setFechasDisponibilidad(fechas) {
    this.fechasDisponibilidadSubject.next(fechas);
  }

  public get diaDisponibleValue() {
    return this.diaDisponibleSubject.value;
  }

  public setDiaDisponible(disponible) {
    this.diaDisponibleSubject.next(disponible);
  }

  public get mostrarValue() {
    return this.mostrarSubject.value;
  }

  public setMostrar(mostrar) {
    this.mostrarSubject.next(mostrar);
  }

  

  public get sesionesCargadasValue() {
    return this.sesionesCargadasSubject.value;
  }

  public setSesionesCargadas(boolean) {
    this.sesionesCargadasSubject.next(boolean);
  }

  public get meses() {
    return this.mesesSubject.value;
  }

  public setMeses(locale = this.localeId) {
    if (locale === 'es-ES') {
      locale = 'es';
    }
    moment.updateLocale(locale, {});
    this.mesesSubject.next(moment.months());
  }

  public get fechaValue() {
    return this.fechaSubject.value;
  }

  public setFecha(fecha) {
    this.fechaSubject.next(fecha);
  }

}
