import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ModalLoginAndRegisterComponent } from '../shared/dialogs/modal-login-and-register/modal-login-and-register.component';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { BusinessRoutingModule } from './business-routing.module';
import { BusinessComponent } from './business.component';
import { CartComponent } from './cart/cart.component';
import { CartModule } from './cart/cart.module';
import { IndexProductsComponent } from './index-products/index-products.component';
import { InterestProductsComponent } from './interest-products/interest-products.component';
import { MenuComponent } from './menu/menu.component';
import { OtherProductsComponent } from './other-products/other-products.component';
import { EspectaculosComponent } from './products-catalog/espectaculos/espectaculos.component';
import { ProductsCatalogComponent } from './products-catalog/products-catalog.component';
import { VisitasComponent } from './products-catalog/visitas/visitas.component';
import { CarritoComponent } from './resume/componentes/carrito/carrito.component';
import { DescuentosComponent } from './resume/componentes/descuentos/descuentos.component';
import { FacturaComponent } from './resume/componentes/factura/factura.component';
import { FormularioClienteComponent } from './resume/componentes/formulario-cliente/formulario-cliente.component';
import { ResumeComponent } from './resume/resume.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { TicketSalesModule } from './ticket-sales/ticket-sales.module';
import { ModalCarritoComponent } from './resume/componentes/modal-carrito/modal-carrito.component';
import { TimerComponent } from './timer/timer.component';
import { TimeFormatPipe } from '../business/timer/timer.pipe';
import { ControlsComponent } from './timer/controls/controls.component';
import { DisplayComponent } from './timer/display/display.component';
import { ModalTimerComponent } from './resume/componentes/modal-timer/modal-timer.component';
import { ModalCancelarComponent } from './resume/componentes/modal-cancelar/modal-cancelar.component';

@NgModule({
  declarations: [
    BusinessComponent,
    ResumeComponent,
    ThankyouComponent,
    InterestProductsComponent,
    OtherProductsComponent,
    IndexProductsComponent, MenuComponent,
    ProductsCatalogComponent,
    DescuentosComponent,
    FacturaComponent,
    FormularioClienteComponent,
    CarritoComponent,
    ModalLoginAndRegisterComponent,
    VisitasComponent,
    EspectaculosComponent,
    ModalCarritoComponent,
    TimeFormatPipe,
    TimerComponent,
    ControlsComponent,
    DisplayComponent,
    ModalTimerComponent,
    ModalCancelarComponent
    ],
  imports: [
    BusinessRoutingModule,
    RouterModule,
    CommonModule,
    CartModule,
    TicketSalesModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    DragDropModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    UserModule,
    MaterialModule,
  ],
  exports: [
    BusinessComponent,
    CartComponent,
    OtherProductsComponent,
    MenuComponent,
    ModalLoginAndRegisterComponent,
    CarritoComponent
  ],providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment["siteKey"],
      } as RecaptchaSettings,
    },
    { provide: LOCALE_ID, useValue: "es-ES" }
  ],
})

export class BusinessModule {
  // constructor() {
    constructor(library: FaIconLibrary) {
    //library.add(fas, far, fab);
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fab);
  }
 }
