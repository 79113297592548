import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { repetirValidar } from '../repetir-validar.validator';
import { mergeMap, map, concatMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { InitService } from 'src/app/core/init/init.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { ModalService } from 'src/app/shared/modal.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passForm: FormGroup;
  loading: boolean;
  user;
  token
  regex= /^(?=.*\d)(?=.*[\u0021-\u002f\u003a-\u0040\u005b-\u0060 \\ \[\]\^])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/
  isLoading: boolean =true;
  cambiarCaducada = false;
  modal = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toast: ToastrService,
    private tokenService: TokenManagerService,
    private initService: InitService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) {

   }

  ngOnInit() {
    this.loadData();
    // if (!this.initService.startedValue) {
    //   this.initService.getInitData().pipe(take(1)).subscribe(res =>{
    //     this.loadData()
    //   })
    // }else{
    //   this.loadData()
    // }
    
    
  }
  loadData(){
    this.user = this.userService.getUser();
    this.cambiarCaducada = this.userService.passCaducadaValue;
    if(!this.userService.passCaducadaValue)
    {
      this.userService.changeContrasena(this.user).subscribe(res=>{    
        if(res.DatosResult && res.DatosResult.Token){
          this.token = res.DatosResult.Token
        }     
      });
    }else{
      this.modal = true;
    }
    this.passForm = this.formBuilder.group({
      nuevaContrasena: ['', [Validators.required, Validators.pattern(new RegExp(this.regex))]],
      repiteContrasena: ['', [Validators.required]],
    }, {
        validator: repetirValidar('nuevaContrasena', 'repiteContrasena')
      });
      this.isLoading=false;

  }

  get f() {
    return this.passForm.controls;
  }

  error(mensaje) {
    this.toast.error(mensaje, 'Error', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }

  cambiarContrasenaClicked() {
    
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.loading = true;
    if (this.passForm.invalid) {
      return;
    }
    
    this.userService.existeToken(this.token)
      .pipe(
        concatMap(res => {
          if (!res || !res.DatosResult) {
            this.error(this.translateService.data.tokenCaducado);
            return of(res);
          }
          return this.userService.cambiaContrasena(this.passForm.value,this.user.Email,this.token)
            // tslint:disable-next-line:no-shadowed-variable
            .pipe(concatMap(res => {
              if (!res) {
                this.error(this.translateService.data.AlgoFalla);
                return of(null);
              } else if(!res.DatosResult){
                this.error(this.userService.getErrorTranslate(res["Mensajes"][0]["CodigoMensaje"]));
                return of(null);
              }
              let user={nombre:this.user.EMail,...this.passForm.value}
              return this.userService.login(user,1,1,false)
            }));
        }
        )
      ).subscribe(res => {
        if (res) {

          this.userService.loginUser = {
            NombreCliente: JSON.parse(sessionStorage.getItem('currentUser')).NombreCliente,
            Contrasena: res.DatosResult.Contrasena,
            NombreClienteOriginal: JSON.parse(sessionStorage.getItem('currentUser')).NombreClienteOriginal,
            Telefono: JSON.parse(sessionStorage.getItem('currentUser')).Telefono
          };
          //sessionStorage.setItem('currentUser', JSON.stringify(this.userService.loginUser));
          this.userService.setCurrentUserValue(this.userService.loginUser);
          this.loading = false;
          this.toast.success(this.translateService.data.contrasenaCambiadaCorrectamente, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',

          });
          this.router.navigate(['/account/my']);
        } else {
          this.loading = false;
          this.error(this.translateService.data.errorNoHaModificado);
        }
      }
      );
  }

  cambiarContrasenaCaducada(){
    let userName = {NombreCliente: this.userService.userNameValue};
    let error = ''; 
    this.userService.cambiaContrasenaCaducada(userName,this.passForm.value).pipe(
      concatMap(res => {       
          if (!res) {
            return of(null);
          } else if(!res.DatosResult || res["Mensajes"][0] ){
            error = this.userService.getErrorTranslate(res["Mensajes"][0]["CodigoMensaje"]);
            return of(null);
          }
          let user={nombre:userName.NombreCliente,...this.passForm.value}
          return this.userService.login(user,1,1,false)
    })
  ).subscribe(res => {
    if (res) {
      this.userService.loginUser = {
        NombreCliente: this.userService.userNameValue,
        Contrasena: res.DatosResult.Contrasena,
        NombreClienteOriginal: JSON.parse(sessionStorage.getItem('currentUser')).NombreClienteOriginal,
        Telefono: JSON.parse(sessionStorage.getItem('currentUser')).Telefono
      };
      this.userService.setCurrentUserValue(this.userService.loginUser);
      this.loading = false;
      this.toast.success(this.translateService.data.contrasenaCambiadaCorrectamente, '', {
        timeOut: 3000,
        positionClass: 'toast-top-right',

      });
      this.userService.setPassCaducadaValue(false);
      this.modalService.close("LoginAndRegister");
    } else {
      this.loading = false;
      this.error(this.translateService.data.errorNoHaModificado + '. ' + error);
    }
  }
  );
  }
  volverLogin() {
    this.userService.setPassCaducadaValue(false);
    this.modalService.forgetPassword("LoginAndRegister",false)
    //this.router.navigate(['/account/login']);
  }
  
}


