import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalService } from "src/app/shared/modal.service";
import { Router } from "@angular/router";
import { ProductosService } from "src/app/business/productos.service";
@Component({
  selector: 'app-modal-timer',
  templateUrl: './modal-timer.component.html',
  styleUrls: ['./modal-timer.component.scss']
})
export class ModalTimerComponent implements OnInit {

  @Input() id: string;
  private element: any;
  private level : number = -1

  constructor(
    private modalService: ModalService,
    private router: Router,
    private el: ElementRef,
    private productosService: ProductosService
  ) { 
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;

    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "modalTimer") {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = "block";
    // document.body.classList.add("selectorNONumerado");
    this.level = this.modalService.info;
    // this.posicionVenta = this.modalService.butacas[0];
  }

  modify() {
    // this.carrito.modificar(this.modalService.butacas);
    this.close();
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("modalTimer");
    // necesitamos pasarnos a teinda menu cuando se vence el tiempo
    this.ir();
    // this.modalService.info=null
    // this.modalService.butacas=null
  }

  ir() {   

    let enlaceMenu = this.productosService.getEnlaceMenuId();
    if(enlaceMenu && enlaceMenu.iPadre){
      this.router.navigate(["/tienda/productos"],{ queryParams: { iPadre: enlaceMenu.iPadre}});
    }else{
      this.router.navigate(['/tienda/productos']);
    }
  }

}
