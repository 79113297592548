import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "src/app/shared/modal.service";
import { Butaca, Estados, validateMinMax, TarifasDisponibles, TarifasCantidad, InfoObj, Marcado } from "../../modelos/butaca.model";
import { Entrada } from "../../modelos/entrada";
import { ButacasService } from "../servicios/butacas.service";
import { ButacasMultiplesService } from "../servicios/butacas.multiples.service";

@Component({
  selector: "iacpos-modal-multiple",
  templateUrl: "./modal-multiple.component.html",
  styleUrls: ["./modal-multiple.component.css"],
})
export class ModalMultipleComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;
  tiposEntradas: any;
  butaca: any;
  claveEmpresa: string;
  ruta: string;
  estados: Estados;
  valMinMax: validateMinMax;
  listTarifas: TarifasDisponibles[]; 
  tarifasForm: FormGroup;
  msgErrorNum: boolean;
  msgErrorMax: boolean;
  allButacas: number[] = [];
 
  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private butacasService: ButacasService,
    private butacasMultiService: ButacasMultiplesService,
    private toast: ToastrService,
   
    private fb: FormBuilder,
  ) {
    this.element = el.nativeElement;
  }


  ngOnInit(): void {
    this.ruta = this.router.url;
    const modal = this;
    this.tiposEntradas = [];
    this.butacasMultiService.butacaMarcada.subscribe((butaca) => {
      this.butaca = butaca;      
    });

    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "iacpos-modal-multiple") {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    this.claveEmpresa = this.butacasService.getConfig("chClaveEmpresa");
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void { 
    this.butacasMultiService.setCheckMultipleValue(false);
    this.butacasMultiService.setTotalButacas(0);
    this.butacasMultiService.setTotalAcompana(0);
    this.butacasMultiService.setTotalDiscapa(0);
    this.butacasMultiService.setTotalGeneral(0);
    this.butacasMultiService.setTotalProfe(0);
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    if (this.butaca) {
      this.comprobarButaca();      
      const stringTarifas = JSON.stringify(this.butaca.tarifas);
      const copyTarifas = JSON.parse(stringTarifas);
      this.initTarifasForm(copyTarifas);
    }
  }

  private initTarifasForm = (tarifas) => { 
    if(tarifas && tarifas.length > 0) { 
      this.listTarifas = [];
      let obj: TarifasDisponibles;
      tarifas.forEach((item) => {  
        obj = {
          idTarifa: item.IdTarifa,
          idProducto: item.ProductoId,
          nombre: item.NombreEntrada,
          descripcion: item.DescripcionEntrada ? item.DescripcionEntrada : '',  
          pvp: item.PVPSinDescuento,
          maximo: item.Maximo,
          maximoTarifa: item.MaximoTarifa 
        }  
        this.listTarifas.push(obj); 
      });    
      let formularioTmp: FormGroup;
      let jsonString = '';
      this.listTarifas.forEach((campo, index) => {
        jsonString += `"${campo.idTarifa}":"",`;
        if (index === this.listTarifas.length - 1) {
          jsonString = `{${jsonString.slice(0, jsonString.length - 1)}}`;
          formularioTmp = this.fb.group(JSON.parse(jsonString));
        }
      });  
      this.tarifasForm = formularioTmp;
      this.validate();
    }
  }

  private validate = () => {
    this.listTarifas.forEach((campo) => {       
      this.tarifasForm.get(campo.idTarifa).setValidators([
        Validators.maxLength(5),
        Validators.pattern("^[0-9]*$"),
        Validators.max(+campo.maximoTarifa)
      ]);
      this.tarifasForm.get(campo.idTarifa).updateValueAndValidity();      
    });
  }

  comprobarButaca() { 
    let copyButaca = { ...this.butaca };

    copyButaca.tipoSeleccionado = this.butaca.tarifas[0];
    copyButaca.fecha = this.butacasService.fechaSeleccionadaValue;
    copyButaca.hora = this.butacasService.horaSeleccionadaValue;
    this.butacasService
      .httpPostMarcarDesmarcarButaca(copyButaca, false)
      .subscribe((res: any) => {
        if (
          res &&
          res.DatosResult &&
          res.DatosResult.IdRB &&
          res.DatosResult.IdRB.includes(
            Number.parseInt(this.butaca.info.infoGeneral.recintoButacaId, 10)
          )
        ) {
          this.element.style.display = "block";
          document.body.classList.add("selectorMultiple");
        } else {
          this.toast.info(
            this.butacasService.getTranslate().data.ERR_BUTACA_NO_DISPONIBLE,
            "Info",
            {
              timeOut: 5000,
              positionClass: "toast-top-center",
            }
          );
          this.modalService.close("selectorMultiple");
        }
      });
  }  

  // close modal
  close(): void { 
    this.msgErrorMax = false;
    this.msgErrorNum = false;
    this.element.style.display = "none";   
    document.body.classList.remove("selectorMultiple");
  }

  nodoble() {
    return false;
  }

  selectTipo(butaca: any, tarifa:number): void {   
    if (+butaca.Habilitado !== 0) {
      if (this.modalService.butacas) {
        this.modalService.butacas.forEach((b: Butaca) => {
          const entrada = new Entrada();
          entrada.Id = +this.butacasService.butacasMap[b.idRecintoButaca];
          entrada.Nombre = butaca.NombreEntrada;
          entrada.Precio = butaca.PrecioInternet;
 
          this.butacasService.addProduct(butaca);
        });
        this.modalService.butacas = undefined;
      } else {         
        butaca.tipoSeleccionado = this.butaca.tarifas.find(item => +item.IdTarifa === tarifa);
        
        //11932 manieva
        //idRecinto y TipoButacaId tomarlo de la seleccion multiple
        //sobreescribiendo esta seleccion
        butaca.tipoSeleccionado.idRecinto = butaca.dataIdRecinto;
        butaca.tipoSeleccionado.TipoButacaId = butaca['data-tipo-butaca'];
        //manieva
 
        butaca.fecha = this.butacasService.fechaSeleccionadaValue;
        butaca.hora = this.butacasService.horaSeleccionadaValue;   
 
        this.updateDatosButacaMarcada(butaca);
        this.modalService.close("selectorMultiple"); 
      }
    }
  }

  private updateDatosButacaMarcada(butaca) {
    const espectaculo = {
      ...this.butacasService.espectaculoValue,
      fecha: this.butacasService.fechaSeleccionadaValue,
      hora: this.butacasService.horaSeleccionadaValue,
    };
    const datosExtra = {
      idEspectaculoAsociado:
        this.butacasService.espectaculoValue.EspectaculosId,
    };        
    const copyButaca = { ...butaca };
    // delete copyButaca.tarifas;
    //delete copyButaca.info.tiposDeEntrada;
    const butacaDom = document.querySelector(
      "circle[_PKIDRB='" + butaca.PKIDRB + "']"
    );
    butacaDom.removeAttribute("style");
    butacaDom.setAttribute(
      "style",
      "fill: rgb(0,0,255)"
    );
    butacaDom.classList.add("marcada");
    this.butacasService.addProduct({ ...copyButaca, ...datosExtra });
    if (
      !this.butacasService.butacasSeleccionadas.includes(+butaca.PKIDRB)
    ) {
      this.butacasService.butacasSeleccionadas.push(+butaca.PKIDRB);
      this.butacasService.selectedSeats.push(butaca);
    }    
  } 

  public validateCantidades = () => {  
    if(this.tarifasForm.status === "INVALID") { 
      Object.keys(this.tarifasForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.tarifasForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => { 
            if(keyError === 'max') {
              this.msgErrorMax = true; 
              this.msgErrorNum = false;
            }   
            if(keyError === 'pattern') {
              this.msgErrorNum = true; 
              this.msgErrorMax = false; 
            }            
          });
        }
      });
    } else { 
      this.msgErrorNum = false;
      this.msgErrorMax = false;      
    }
  }  

  private validateEspecial = () => { 
    let valido = false;
    const { 
      iMaxDisponible, 
      iTotalSeleccionadas, 
      idDependiente, 
      idDisabled, 
      idTeacher } = this.butacasService.findSpecialRates(this.butaca);
    const tarifas = this.butaca.tarifas;
    const val = this.tarifasForm.value;
    
    let tarifaDiscapas = tarifas.find(item => item.ProductoId === idDisabled);
    let tarifaAcompana = tarifas.find(item => item.ProductoId === idDependiente);
    let tarifaProfe = tarifas.find(item => item.ProductoId === idTeacher);

    if(!tarifaAcompana && !tarifaProfe){
      valido = true;
    } else { 
      // Valido discapacitado
        if(tarifaAcompana){
          let discapacitados = (tarifaDiscapas && val[tarifaDiscapas.IdTarifa] !== "") ? parseInt(val[tarifaDiscapas.IdTarifa]) : 0;
          let acompana = (tarifaAcompana && val[tarifaAcompana.IdTarifa] !== "") ? parseInt(val[tarifaAcompana.IdTarifa]) : 0;
          const newDiscapa = this.butacasMultiService.totalDiscapaValue + discapacitados;
          const newAcompana = this.butacasMultiService.totalAcompanaValue + acompana;
          if(newAcompana <= newDiscapa) {
            valido = true;   
            this.butacasMultiService.setTotalDiscapa(newDiscapa);       
            this.butacasMultiService.setTotalAcompana(newAcompana);            
          }     
        }
      // Valido profesor  
        if(tarifaProfe) {
          let profesores = (tarifaProfe && val[tarifaProfe.IdTarifa] !== "") ? parseInt(val[tarifaProfe.IdTarifa]) : 0;      
          let alumnos = 0;
          for (let key in val) {         
            if(key !== tarifaProfe.IdTarifa && val[key] !== "") {
              alumnos += parseInt(val[key]); 
            }        
          }
          const newAlumnos = this.butacasMultiService.totalGeneralValue + alumnos;
          const newProfesores = this.butacasMultiService.totalProfeValue + profesores; 
          const maximo = (newAlumnos >= +iTotalSeleccionadas) ? Math.floor((newAlumnos / +iTotalSeleccionadas) * +iMaxDisponible) : 0;
          if(newProfesores <= maximo) {
            valido = true;    
            this.butacasMultiService.setTotalGeneral(newAlumnos);          
            this.butacasMultiService.setTotalProfe(newProfesores);              
          }           
        }      
    }
    return valido;
  }

  private validateTotal = () : boolean => {       
    const max = this.listTarifas[0].maximo;
    const valores = this.tarifasForm.value;
    let sum = 0;
    let valido = false;
    
    for (let key in valores) {         
      if(valores[key] !== "") {         
        sum += parseInt(valores[key]);
      }        
    }

    const original = this.butacasMultiService.totalButacasValue;     
    const actual = original + sum; 
    if(actual > +max) { 
      this.tarifasForm.setErrors({ 'invalid': true });
      this.msgErrorMax = true;
      valido = false;
    } else { 
      if(!this.validateEspecial()) {
        valido = false;
        this.msgErrorMax = true;
      } else {
          this.butacasMultiService.setTotalButacas(actual);
          this.msgErrorNum = false;
          this.msgErrorMax = false;
          valido = true;
      }     
    }
    return valido;
  }

  private getListTarifas = () : TarifasCantidad[] => {     
    let listaCantidad: TarifasCantidad[] = [];
    let tarifasCantidad: TarifasCantidad;
    const val = this.tarifasForm.value;

    this.listTarifas.forEach((item) => {
      if(val[item.idTarifa] !== "") {
        tarifasCantidad = {
          idTarifa: +item.idTarifa,
          Cantidad: +val[item.idTarifa]
        }
      } 
      if(tarifasCantidad){
        listaCantidad.push(tarifasCantidad); 
      }      
    });

    let TarifasN = new Set([...listaCantidad, tarifasCantidad]);  
    listaCantidad = [...TarifasN]; 
    
    return listaCantidad; 
  }

  public sendMultiple = () => {    
    if(this.tarifasForm.status === "INVALID" || !this.validateTotal()) {
      return;
    }    
    // Formateo valores del formulario y transformo para request de butacas multiple
    const listaCantidad = this.getListTarifas();  
    let marcadasObj: Marcado;
    let listMarcadas: Marcado[] = [];     
    this.butacasMultiService.getButacasMultiples(+this.butaca.PKIDRB, listaCantidad).subscribe((res) => { 
      if(res && res.length > 0) { 
        this.butacasMultiService.setDisabledMultiple(true);
        res.forEach((item) => {                   
          if(item.IdRB && item.IdRB.length > 0) { 
            item.IdRB.forEach((id) => {
              this.allButacas = [...this.allButacas, id];
            });             
            marcadasObj = {
              idtarifa: item.idTarifa.toString(),
              RecintoButacaIds: item.IdRB.toString()
            } 
            listMarcadas = [...listMarcadas, marcadasObj];   
            this.butacasMultiService.arrMarcadas = [... this.butacasMultiService.arrMarcadas, marcadasObj];                
          }                    
        }); 
                
        // Marco todas las butacas en base e datos     
        this.butacasMultiService.setlistDesmarcar(this.butacasMultiService.arrMarcadas);
        this.butacasMultiService.marcarDesmarcarButacasMultiple(listMarcadas, "1").subscribe();

        // Agrego información del Recinto a todas las butacas  
        this.butacasMultiService.getButacasInfoMultiple(this.allButacas.toString()).subscribe((info) =>{ 
          res.forEach((val) => {
            if(val.IdRB && val.IdRB.length > 0) { 
              val.IdRB.forEach((idrb) => { 
                let infoGral: InfoObj;
                let butaca = this.butacasService.butacasConPrecio.find(item => +item.PKIDRB === idrb); 
                let infoButaca = info.find(inf => +inf.recintoButacaId === idrb);            

                if(butaca && infoButaca) {
                  infoGral = {
                    infoGeneral: infoButaca
                  }
                  butaca.info = infoGral;
                  // convierto butacas en entradas
                  this.selectTipo(butaca, val.idTarifa);
                }                
              });            
            }           
          }); // fin foreach butacas
        }); // fin response info butacas
      }
    }); // fin response butacas multiples
  }
}
