import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductosService } from 'src/app/business/productos.service';
import { InitService } from 'src/app/core/init/init.service';
import { Compra } from 'src/app/models/compra.model';
import { CalendarService } from '../calendar/calendar.service';
import { VentaService } from '../../../../../core/services/venta.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from 'src/app/shared/translate.service';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  niveles: any = [];
  rangoEdad: any = [];
  tipoTabla: any = [];
  idiomaVisita: any = [];
  dropForm: FormGroup;
  submitted = false;
  mostrar: boolean;
  mostrarFiltroDias: boolean;
  filtrosIndiviudal;
  pantallas;
  filtrar;
  filtroDias;

  @Output() datosFiltrosForm = new EventEmitter<FormGroup>();
  constructor(
    private productosService: ProductosService,
    private ventaService: VentaService,
    private initService: InitService,
    private calendarService: CalendarService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.dropForm = this.formBuilder.group({
      tipos: ['', [Validators.required]],
      grupos: ['', [Validators.required]],
      niveles: ['', [Validators.required]],
      dias: ['', [Validators.required]],
      idiomas: ['', [Validators.required]]
    });
    this.mostrarFiltrosIndividual();
    if (this.mostrar) {
      if (this.productosService.filtroGrupoValue || this.productosService.filtroTipoValue || this.productosService.filtroNivelValue) {
        this.productosService.setMostrarProductosValue(true);
      } else {
        this.productosService.setMostrarProductosValue(false);
      }
    }
  }

  get g() {
    return this.dropForm.controls;
  }

  mostrarFiltrosIndividual() {
    this.calendarService.setFiltros(false);
    this.pantallas = this.initService.chCalendarioValue.CaracteristicasCalendario.FiltrosPorGI;
    if (this.pantallas) {
      let filtros;
      if (this.pantallas[0]) {
        filtros = this.pantallas[0];
      } else if (this.pantallas[parseInt(this.productosService.getGrupoInternet().gi, 10)]) {
        filtros = this.pantallas[parseInt(this.productosService.getGrupoInternet().gi, 10)];
      }
      if (filtros) {
        this.calendarService.setFiltrosObject(filtros);
        this.calendarService.setFiltros(true);
        this.calendarService.filtros$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mostrar => this.mostrar = mostrar);
        this.calendarService.filtrosObject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(filtros => {
          this.filtrosIndiviudal = filtros;
          this.cargarFiltros();
          if (filtros.CalendariosDia) {
            this.calendarService.setMostrarFiltroDias(true);
          }
          //Comprobamos que solo sea el idioma que es un filtro opcional 
          if(!filtros.CalendariosDia && !filtros.NivelEducativo && !filtros.TipoTabla && !filtros.RangoEdad && filtros.IdiomaVisita && filtros.IdiomaVisita.length > 0){
            this.productosService.setMostrarProductosValue(true);
          }
        });
      }
    } else {
      this.productosService.setMostrarProductosValue(true);
    }
  }

  optionsDrop(nombreCampo: string) {
    switch (nombreCampo) {
      case 'Tipo tabla': return this.tipoTabla;
      case 'Rango de edad': return this.rangoEdad;
      case 'Niveles': return this.niveles;
      case 'Idiomas': return this.idiomaVisita;
      default: return 'unknown';
    }
  }

  filtrosEntradas(tipo, grupos, niveles, idiomas) {

    if (this.productosService.filtroGrupoValue && this.productosService.filtroTipoValue && this.productosService.filtroNivelValue) {
      if (this.productosService.filtroGrupoValue !== grupos ||
        this.productosService.filtroTipoValue !== tipo ||
        this.productosService.filtroNivelValue !== niveles) {
        this.ventaService.setCompra(new Compra());
      }
    }
    if (!tipo && !grupos && !niveles && !idiomas) {
      this.productosService.setMostrarProductosValue(false);
      this.productosService.setFiltroTipoValue(tipo);
      this.productosService.setFiltroTipoValue(grupos);
      this.productosService.setFiltroTipoValue(niveles);
      this.productosService.setFiltroTipoValue(idiomas);
    } else {
      this.productosService.setCargandoComponente2Value(true);
      this.productosService.setMostrarProductosValue(true);
      this.productosService.setCargandoComponente2Value(false);
      if (tipo) {
        this.productosService.setFiltroTipoValue(tipo);
      }
      if (grupos) {
        this.productosService.setFiltroGrupoValue(grupos);
      }
      if (niveles) {
        this.productosService.setFiltroNivelValue(niveles);
      }
      if(idiomas){
        this.productosService.setFiltroIdiomaVisitaValue(idiomas);
      }
    }
  }

  cargarFiltros() {
    if (this.filtrosIndiviudal.NivelEducativo) {
      this.productosService.GetTiposNiveles('').pipe(take(1)).subscribe(res => {
        res.ListadoTiposNiveles.forEach(element => {
          this.niveles.push({ value: element.Codigo });
        });
      });
    }
    if (this.filtrosIndiviudal.TipoTabla) {
      this.productosService.GetTiposProductos('').pipe(take(1)).subscribe(res => {
        res.ListadoTiposProductos.forEach(element => {
          this.tipoTabla.push({ value: element.Codigo });
        });
      });
    }
    if (this.filtrosIndiviudal.RangoEdad) {
      this.productosService.GetGruposAsociados('').pipe(take(1)).subscribe(res => {
        res.ListadoGruposAsociados.forEach(element => {
          this.rangoEdad.push({ value: element.Codigo });
        });
      });
    }
    if(this.filtrosIndiviudal.IdiomaVisita && this.filtrosIndiviudal.IdiomaVisita.length > 0){
      this.filtrosIndiviudal.IdiomaVisita.forEach(element=>{
        this.idiomaVisita.push({value: element})
      });
    }
    this.dropForm.get('tipos').setValue(this.productosService.filtroTipoValue);
    this.dropForm.get('grupos').setValue(this.productosService.filtroGrupoValue);
    this.dropForm.get('niveles').setValue(this.productosService.filtroNivelValue);
    this.dropForm.get('dias').setValue(this.productosService.filtroDiasValue);
    if(!this.productosService.filtroIdiomaVisitaValue)
        this.productosService.setFiltroIdiomaVisitaValue(this.translateService.getLocale())
    this.dropForm.get('idiomas').setValue(this.productosService.filtroIdiomaVisitaValue);
  }

  onSubmit() {
    this.submitted = true;
    if (this.dropForm.invalid) {
      return;
    }
  }

  cantidadDias(value) {
    if (!isNaN(parseInt(value))) {
      if (parseInt(value) > 31) {
        value = '31';
      }
      if (parseInt(value) < 1) {
        value = '1';
      }
      this.productosService.setFiltroDiasValue(value);
    }
  }
  dias() {
    if (this.productosService.filtroDiasValue) {
      return this.productosService.filtroDiasValue;
    } else {
      return 'Días';
    }

  }

}
