import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, interval, merge, of } from 'rxjs';
import { concatMap, delay, mapTo, scan,  switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { TimerDirective } from 'src/app/core/timer/timer.directive';
import { VentaService } from './venta.service';
import { ProductosService } from 'src/app/business/productos.service';
import { PaymentService } from '../payment/payment.service';
import { ButacasService } from 'src/app/business/espectaculos-butacas/mapa/servicios/butacas.service';
import { Compra } from 'src/app/models/compra.model';
import { Carrito } from 'src/app/models/carrito.model';

enum PRIORITY {
  START = 0,
  INFORMATION = 1,
  WARNING = 2,
  DANGER = 3 ,
  NOTIME = 4,
} 

enum MODAL {
  INFORMATION = 900,
  WARNING = 600,
  DANGER = 300,
  NOTIME = 0,
} 

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private prioritySub = new BehaviorSubject<number>(0);
  public priority$ = this.prioritySub.asObservable();

  public getCountDown() {
    return this.proj.getEnv('timer');
  }

  private readonly startSub = new Subject(); 
  readonly start$ = this.startSub.asObservable();

  private readonly pauseSub = new Subject(); 
  readonly pause$ = this.pauseSub.asObservable();

  private readonly resetSub = new Subject(); 
  readonly reset$ = this.resetSub.asObservable();

  private readonly resumeSub = new Subject(); 
  readonly resume$ = this.resumeSub.asObservable();

  private readonly intervalSub = new Subject(); 
  public readonly interval$ = this.intervalSub.asObservable();


  current = () => new Date().getTime();

  // se calcula el tiempo real, que seria el tiempo de localstorage
  // menos el tiempo de inactividad por estar en otra pagina
  getTimestamp() {

    const timestamp = JSON.parse(sessionStorage.getItem('timestamp'));
    if (timestamp) {
      const updatetime = Math.floor((this.current() - timestamp) / 1000);
      return updatetime
    }

    return 0;
  }

  //TODO catch por windows
  getTimer() {
    // el valor guardado
    let timer = JSON.parse(sessionStorage.getItem('timer')); 

    if(timer) {
      // el desfasaje que se haya producido si se fue de ventana.
      timer = timer - this.getTimestamp();
      // entrar en el carrito despues de que vencio el tiempo
      // no tenemos un carrito valido, paso mucho tiempo fuera del carrito
      // se anula
      if (timer < 0) 
        timer = 0;
        
    }
    return timer;
  }

  //TODO catch por windows
  setTimer(value:number) {
    sessionStorage.setItem('timer', JSON.stringify(value));
    // guardamos el momento en que obtuvimos este tick
    sessionStorage.setItem('timestamp', JSON.stringify(this.current()));
  }

  cleanTimer() {
    sessionStorage.removeItem('timer');
    sessionStorage.removeItem('timestamp');
    sessionStorage.removeItem('cesta');
    this.ventaService.setModificandoReserva(null);
    this.ventaService.setCarrito(new Carrito());
    this.ventaService.setNumCarrito();
  }

  start() { 
    this.startSub.next(true); 
  }

  pause() {
    this.pauseSub.next(false); 
  }
  
  reset() { 
    //se reinicia

    //
    this.resetSub.next(null);
    //se elimina el timer
    this.cleanTimer()
    //dejamos el tiempo inicial
    this.d.updateState(this.getCountDown(), 'minutes');
    //modales de advertencia
    this.prioritySub.next(PRIORITY.START);
  }

  resume() {
    // existe una compra en curso
    if(this.getTimer())
      this.start();
    
  }

  notime() {
    // se quedo sin tiempo
    // mostrara el modal y limpiara el carrito

    // timer en 0
    this.zero$.next(null);
    // el mecanismo de limpiar el carrito
    // cuando eltiempo se agoto
    this.borrarCarrito()
    // modal
    this.prioritySub.next(PRIORITY.NOTIME);
    // reiniciamos el contador
    this.reset();
  }

  priorities(value:number) {

    switch(true) {

      case value === MODAL.NOTIME:
        this.notime();
        break;

      case value < MODAL.DANGER:
        if(this.prioritySub.value !== PRIORITY.DANGER)
          this.prioritySub.next(PRIORITY.DANGER);
        break;

      case value < MODAL.WARNING:
        if (this.prioritySub.value !== PRIORITY.WARNING)
          this.prioritySub.next(PRIORITY.WARNING);
        break;

      case value < MODAL.INFORMATION:
        if(this.prioritySub.value !== PRIORITY.INFORMATION)
          this.prioritySub.next(PRIORITY.INFORMATION);
        break;
    }

  }

  interval() { return this.interval$; }
  //---------------------------------------------------------------------
  
  public readonly zero$ = new Subject();
  private readonly stateChange$ = this.d.obs$.pipe(mapTo(null)); 

  constructor(
    public d: TimerDirective, 
    private proj: AppConfiguration,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private paymentService: PaymentService,
    private butacasService: ButacasService
    ) 
    
    {

    this.interval$ = merge(this.start$, this.pause$, this.reset$, this.stateChange$, this.zero$).pipe(
      switchMap(isCounting => {
        if (isCounting === null) return of(null);
        return isCounting ? interval(1000) : of();
      }),
      scan((accumulatedValue, currentValue) => {
        
        if (accumulatedValue === 0 && currentValue !== null) {
          this.notime();
          return accumulatedValue;
        }

        const timer : number = this.getTimer();

        // si tengo guardado previo un valor
        if (timer || timer == 0) 
          return timer;

        if (currentValue === null || !accumulatedValue) return this.d.getTotalSeconds();

        this.setTimer(accumulatedValue);

        // modales de advertencia
        this.priorities(accumulatedValue);

        return --accumulatedValue;
      })
    );

  }

  // manieva
  // Borrar toda el carrito desde cualquier punto de la aplicacion
  // necesario para cuando no estamos en la pagina del carrito
  borrarCarrito() {

    if (JSON.parse(sessionStorage.getItem("estaPagada")))
      return;

    //borrar descuentos de forma general
    this.paymentService.borrarDescuentos();

    // borrado de lo que ha quedado sin haber llegado al carrito
    this.borrarButacas();

    // borrado de inicializadores de servicios
    this.borrarServicios();

    // borrado del carrito en el caso de existir uno
    this.ventaService.validaCesta().forEach(compra => this.borrarCarritoCompra(compra));

  }

  private async borrarButacas() {

    await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => { 
      this.butacasService.MarcarDesmarcarButacas("1", []);
    });

    this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();

    // manieva 11519
    // en la session del usuario dejaba la butaca como previamente seleccionada
    // excluia que luego vuelva a utilizarse, no afectaba la compra.
    this.butacasService.selectedSeats = [];

  }

  private borrarServicios() {

    this.butacasService.addMore = true;
    this.productosService.setVentaEspectaculo(false);
    this.productosService.setEspectaculoValue('');
    this.ventaService.setModificarValue(false);

  }

  // manieva
  private async borrarCarritoCompra(compra) {

    if (compra.cantidad <= 1)
      this.ventaService.filtrarFechaSeleccionadaSinSesion(compra.posicionVenta);
  
    if (this.ventaService.modificandoReservaValue) {
      // si se produjo modificacoin de reservas
      // se cambia fechas
      // se reordena
      // ninguno de estas cosas aplica

    } else {

      // 11481
      // el desmarcado de butacas se extrajo para cuando no hay carrito

      this.ventaService.carritoValue.eliminarTodasEntradasPorTipoEntrada(compra);
      this.ventaService.setCarrito(this.ventaService.carritoValue);
      this.ventaService.guardarCesta();

      // la cancelacion del aforo es inicial
      // this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
      // this.productosService.reservaAforoCarrito();

      if (compra.sesionesSeleccionadas) {
        if (compra.sesionesSeleccionadas[0]) {
          if (
            compra.sesionesSeleccionadas[0].TipoAforo === "SP") {
            this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(compra);
            //this.productosService.reservaAforoCarrito();
            this.ventaService.setCarrito(this.ventaService.carritoValue);
            this.ventaService.guardarCesta();
          }
        }
      }
    }

    // resta la cantidad en cada una
    this.ventaService.carritoValue.eliminarEntrada(compra);

    this.ventaService.setCarrito(this.ventaService.carritoValue);
    this.ventaService.guardarCesta();
    this.ventaService.setNumCarrito();

    this.ventaService.setCompra(new Compra());

    // agregamos de forma redundante la inicializacion 
    // de los servicios
    this.borrarServicios();

  }
  
}