import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() id: string;
  private element: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef) { this.element = el.nativeElement; }

  ngOnInit(): void {
    const modal = this;
    if (!this.id) {
      //console.error('modal must have an id');
      return;
    }

    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.modalService.setConfirmadoValue(false);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  confirmar() {
    this.modalService.setConfirmadoValue(true);
    this.close();
  }

}
