import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { repetirValidar } from '../repetir-validar.validator';
import { ModalService } from 'src/app/shared/modal.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  loading: boolean;
  recoverForm: FormGroup;
  userSendRquest=false;
  proyecto = this.config.getProyecto(); //Yaribel 20220729 añadimos proyecto para obtener los assets genericamente
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toast: ToastrService,
    private modalService: ModalService,
    private config: AppConfiguration,
    private translate: TranslateService

  ) { }

  ngOnInit() {
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')]],
    });
  }
  get f() {
    return this.recoverForm.controls;
  }
  recoverClicked() {
    this.loading = true;
    if (this.recoverForm.invalid) {
      return;
    }
    this.userService.recoverContrasena(this.recoverForm.value)
      .subscribe(res => {
        this.userSendRquest = true;
        this.toast.success(this.translate.data.peticionEnviada, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
       // this.modalService.forgetPassword("LoginAndRegister",false)

        this.loading = false;
      }
      );
  }
  volverLogin() {
    this.modalService.forgetPassword("LoginAndRegister",false)
    //this.router.navigate(['/account/login']);
  }
}
