import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { VentaService } from '../../../../core/services/venta.service';
import { Router } from '@angular/router';
import { Compra } from 'src/app/models/compra.model';
import { CompraCarrito } from 'src/app/models/compra-carrito.model';
import { ClientDataService } from './client-data.service';
import { FormData } from './form-data';
import { CalendarService } from '../date-selector/calendar/calendar.service';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment/locale/es';
import { ProductosService } from 'src/app/business/productos.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { Location } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { GoogleAnalyticsEventsServiceService } from 'src/app/shared/google-analytics-events-service.service';
import { InitService } from 'src/app/core/init/init.service';
import { GlobalService } from 'src/app/shared/services/global/global.service';

@Component({
  selector: 'app-client-data',
  templateUrl: './client-data.component.html',
  styleUrls: ['./client-data.component.scss']
})
export class ClientDataComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  currentDate = moment();
  productos;
  mostrar;
  items: FormArray;
  datosForm: FormGroup = new FormGroup({});
  entradas: any[] = [];
  campos: FormData[] = [];
  check = true;
  //tipoDocumento;
  error = false;
  isLoading = true;
  cont = 0;
  i = 0;
  cantidadBono = 0;
  datos = 0;
  cantidadEntradas = 0;
  entradasModificadas = [];
  loading;
  mensaje;
  trimestreSeleccionado;
  arrayDias = [];
  productosOrdenados = [];

  fields = {
    Nombre : 'txtNombre',
    Apellido : 'txtApellido',
    Trimestre : 'cboTrimestre',
  }
  paises: any=[];

  constructor(
    private formBuilder: FormBuilder,
    private ventaService: VentaService,
    private router: Router,
    private clientDataService: ClientDataService,
    private calendarService: CalendarService,
    private productosService: ProductosService,
    private translateService: TranslateService,
    private _location: Location,
    private config: AppConfiguration,
    private GAService: GoogleAnalyticsEventsServiceService,
    private initService: InitService,
    private global: GlobalService,
  ) {
    this.getPaises()
   }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.mensaje = undefined;
    this.productosService.setCargandoComponenteValue(true);
    this.productosService.cargandoComponente$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(carga => this.loading = carga);
    this.isLoading = true;
    this.mostrar = this.calendarService.mostrar$;
    this.getTemporadas();
    this.clientDataService.getIdCamposData().pipe(mergeMap((id: any) => {
      return this.clientDataService.getCamposData(id);
    })).pipe(takeUntil(this.ngUnsubscribe)).subscribe(campos => {
      this.campos = campos.LstCampos;
      this.datosForm = this.formBuilder.group({
        items: this.formBuilder.array([this.createFormItems()])
      });
      this.ventaService.compraValue.productos.forEach(element => {
        let bono = false;
        this.cantidadBono = 0;
        if (element.producto.NumeroTotalEntradasBono !== '0') {
          element.producto.ProductosBono.forEach(bon => {
            if (bon.CuentaNominada === '1') {
              this.cantidadBono++;
              this.cantidadEntradas = parseInt(bon.NumeroEntradasBono, 10) * this.cantidadBono;
            }
          });
          this.datos = element.cantidad * this.cantidadEntradas;
          bono = true;
          element.producto.EsBono = true;
        } else {
          this.cantidadBono++;
          this.datos = this.cantidadBono * element.cantidad;
          element.producto.EsBono = false;
        }
        for (let i = 0; i < this.datos; i++) {
          this.entradas.push(element);
        }
      });

      //se guardaba esta variable publica de items, pero luego siempre se consulta el array directamente
      for (let i = 1; i < this.entradas.length; i++) {
        this.addItem();
      }

      this.isLoading = false;
      //this.customValidators();
      this.productosService.setCargandoComponenteValue(false);
    });
  }

  // helpers
  getFormControls(): FormArray {
    return <FormArray>this.datosForm.controls.items;
  }

  customValidators() {
    const a = this.getControls(this.datosForm).controls[0];
    const b = this.getControls(this.datosForm);
    if (a['controls']['cboTipoDNI']) {
      b.controls.forEach(form => {
        form['controls']['cboTipoDNI'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(checked => {
          if (checked) {
            form['controls']['txtDNI'].setValidators([Validators.pattern(this.documentoValidar(form['controls']['cboTipoDNI'].value))]);
          }
        });
      });
    }
    if (a['controls']['cboTrimestre']) {
      b.controls.forEach(form => {
        form['controls']['cboTrimestre'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(checked => {
          if (checked) {
            this.trimestreSeleccionado = form['controls']['cboTrimestre'].value;
          }
        });
      });
    }
  }

  getControls(datosForm: FormGroup) {
    return (<FormArray>datosForm.controls.items);
  }

  createFormItems(): FormGroup {
    const group = {};
    const productos = this.ventaService.compraValue.productos;
    const cantidad = productos.reduce((acc:number, elem) => acc=+elem.cantidad);   
    
    if (this.ventaService.modificarValue && productos && !productos[0].datos) { 
      productos[0].datos = [];
      if (sessionStorage.getItem("cesta")) {
        let basket = JSON.parse(sessionStorage.getItem("cesta"));
        if (basket.compras.length > 0) {
          let arrBasket = basket.compras.filter(item => item.posicionVenta === productos[0].posicionVenta);
          if(arrBasket.length > 0) {
            arrBasket.forEach((item) => {
              item.datos.forEach((res) => {
                productos[0].datos.push(res);
              });              
            });            
          }
          
        }
      }       
    } 

    if (productos[this.cont] && (this.ventaService.modificarValue || this.ventaService.compraValue.productos[0].datos)) {
      const group = {};
      this.campos.forEach((campo: any) => { 
        switch (campo.chCampo) {
          case 'txtNombre':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '',
              [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]);
          case 'txtApellidos':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '',
              [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]);
          case 'cboTipoDNI':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '');    
          case 'txtDNI':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : ''); 
         /* case 'cboTipoDNI':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '',
              campo.required ? [Validators.required,this.ValidarDocNieDni('cboTipoDNI')] : [Validators.nullValidator,this.ValidarDocNieDni('cboTipoDNI')]);    
          case 'txtDNI':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '',
              campo.required ? [Validators.required,this.ValidarDocNieDni('cboTipoDNI')] : [Validators.nullValidator,this.ValidarDocNieDni('cboTipoDNI')]);    */
          case 'txtEmail1':
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '',
              [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')]);
          case 'cboTrimestre':
            let nombre;
            const pkId = productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : null;
            if (pkId) {
              nombre = this.productosService.getTrimestreNombreByTrimestrePkId(pkId);
              this.trimestreSeleccionado = nombre;
            }
            return group[campo.chCampo] = new FormControl(nombre ? nombre : ''
              , Validators.required);
          default:
            return group[campo.chCampo] = new FormControl(
              productos[this.cont].datos ? productos[this.cont].datos[this.i][campo.chCampo] : '',
              Validators.required);
        }
      },
      );
      if (productos[this.cont].datos) {
        if ((this.i + 1) === productos[this.cont].datos.length) {
          this.i = 0;
          this.cont++;
        } else {
          this.i++;
        }
      } else {
        this.i = 0;
        this.cont++;
      }
      const apiFormGroup = new FormGroup(group);
      return apiFormGroup;
    } else {
      this.campos.forEach((campo: any) => {
        switch (campo.chCampo) {
          case 'txtNombre':
            return group[campo.chCampo] = new FormControl(
              '',
              [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]);
          case 'txtApellidos':
            return group[campo.chCampo] = new FormControl(
              '',
              [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]);
          case 'txtEmail1':
            return group[campo.chCampo] = new FormControl(
              '',
              [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')]);
          default:
            return group[campo.chCampo] = new FormControl(
              '',
              Validators.required);
        }
      },
      );
      const apiFormGroup = new FormGroup(group);
      return apiFormGroup;
    }
  }

  get f() {
    return this.datosForm.controls;
  }

  addItem(): void {
    this.items = this.datosForm.get('items') as FormArray;
    this.items.push(this.createFormItems());

  }

  controlSnowzone() {
    if (this.config.getProyecto() === 'SNOWZONE') {
      if (this.productosService.getGrupoInternet().gi !== 13 &&
        this.productosService.getGrupoInternet().gi !== 12 && this.productosService.getGrupoInternet().gi !== 22 && this.productosService.getGrupoInternet().gi !== 100) {
        (this.datosForm.get('items') as FormArray).value.forEach(entrada => {
          const edad = parseInt(moment().format('YYYY'), 10) - parseInt(entrada.txtEdad, 10);
          const rango = this.productosService.filtroGrupoValue;
          if (rango.split(' a ')[1]) {
            const edadMin = rango.split(' a ')[0];
            const edadMax = rango.split(' a ')[1];
            if (edad < parseInt(edadMin, 10) || edad > parseInt(edadMax, 10)) {
              this.mensaje = this.translateService.data.RANGOEDADERROR + rango;
              return;
            }
          } else {
            if (edad < 13) {
              this.mensaje = this.translateService.data.RANGOEDADERROR + rango;
              return;
            }
          }
        });
      }
    }
  }

  getCodigoTrimestre(nombre:string) {
    let codigo = this.productosService.getTrimestrePkIdByTrimestreNombre(nombre);
    if(codigo) 
     return codigo;

    return nombre;
  }

  irCarrito() {
    this.mensaje = undefined;
    this.controlSnowzone();
    if (this.mensaje) {
      return;
    }
    let posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta ?
      this.ventaService.compraValue.productos[0].posicionVenta : this.ventaService.calcularposicionVenta();
    if (this.ventaService.modificarValue) {
      posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta;
      this.entradas.forEach((entrada, i) => {
        const datos = (this.datosForm.get('items') as FormArray).at(i).value;
        if (this.calendarService.filtrosValue) {
          Object.keys(datos).map(() => {
            datos.cboNivel = this.productosService.filtroNivelValue;
            datos.cboTipoTabla = this.productosService.filtroTipoValue;
            datos.cboRangoEdad = this.productosService.filtroGrupoValue;
            datos.cboIdiomaVisita = this.productosService.filtroIdiomaVisitaValue;
          });
        }
        entrada = { cantidad: entrada.cantidad, producto: entrada.producto, datos, sesionesSeleccionadas: entrada.sesionesSeleccionadas };
        this.entradasModificadas.push(entrada);
      });
      this.entradas = this.entradasModificadas;
      this.ventaService.carritoValue.eliminarProductosPorposicionVenta(posicionVenta);
    }

    let compraHolder;
    let index = 0;
    const compras = [];

    this.entradas.forEach((entrada, i) => {
      // Map cliente con primera entrada nominada
      if (i === 0) {
        this.mapClienteCompra(i);
      }
      if (entrada.producto.sesionesSeleccionadas && !entrada.sesionesSeleccionadas) {
        entrada.sesionesSeleccionadas = entrada.producto.sesionesSeleccionadas;
      }

      //const datos = (this.datosForm.get('items') as FormArray).at(i).value;
      const datos = this.getFormControls().at(i).value;

      // compra base
      // necesita spread, sobre todo idProducto
      const compraHolderAbstract = {
        posicionVenta,
        producto: entrada.producto,
        datos: [datos],
        sesionesSeleccionadas: entrada.sesionesSeleccionadas
      };

      // si el formulario tiene la referencia a trimestre
      if (datos[this.fields.Trimestre])
        datos.cboTrimestre = this.getCodigoTrimestre(datos[this.fields.Trimestre]);

      if (this.calendarService.filtrosValue) {
        //No es necesario un map
        Object.keys(datos).map(object => {
          datos.cboNivel = this.productosService.filtroNivelValue;
          datos.cboTipoTabla = this.productosService.filtroTipoValue;
          datos.cboRangoEdad = this.productosService.filtroGrupoValue;
          datos.cboIdiomaVisita = this.productosService.filtroIdiomaVisitaValue;
        });
      }

      if (entrada.producto.EsBono) {
        const bonosNominadas = entrada.producto.ProductosBono.filter(bono => bono.CuentaNominada === '1');
        if (bonosNominadas.length < (index + 1)) {
          index = 0;
        }
        const bono = bonosNominadas[index];
        const ProductoId = bono.ProductoId;

        if (!compraHolder) {

          compraHolder = {...compraHolderAbstract, datos: [{ ...datos, ProductoId }]};

          if (parseInt(bono.NumeroEntradasBono, 10) <= 1) {
            index++;
          }

        } else {

          if (entrada.producto.ProductoId === compraHolder.producto.ProductoId && compraHolder.datos.length < parseInt(bono.NumeroEntradasBono, 10)) {
            
            compraHolder.datos.push({ ...datos, ProductoId });
            if (compraHolder.datos.length === parseInt(bono.NumeroEntradasBono, 10)) {
              index++;
            }

          } else {

            compras.push(compraHolder)
            compraHolder = undefined;
            compraHolder = {
              posicionVenta,
              producto: entrada.producto,
              datos: [{ ...datos, ProductoId }],
              sesionesSeleccionadas: entrada.sesionesSeleccionadas
            };

            if (parseInt(bono.NumeroEntradasBono, 10) <= 1) {
              index++;
            }

          }
        }
      } else {

        if (!compraHolder) {

          const ProductoId = entrada.producto.ProductoId;
          compras.push({...compraHolderAbstract, datos: [{ ...datos, ProductoId }]});

        } else {

          compras.push(compraHolder)
          compraHolder = undefined;
          // compraHolder = { posicionVenta, producto: entrada.producto, datos: [datos], sesionesSeleccionadas: entrada.sesionesSeleccionadas };
          // compras.push(compraHolder)
          compras.push({...compraHolderAbstract});

        }
      }

    });

    if (compraHolder) {
      compras.push(compraHolder)
      compraHolder = undefined;
    }
    // No se agruparan los de snowzone escuela
    this.agrupar(compras);
    this.corregirCantidadesBono();
    this.productosOrdenados.forEach((producto: CompraCarrito) => {
      this.ventaService.sendCompra(producto);
    });
    this.ventaService.setCompra(new Compra());
    this.ventaService.guardarCesta();
    if(this.initService.aceptaCookieGoolgeValue)
    this.GAService.emitEvent('event', 'paso-3'); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
    this.GAService.callStepView('paso-3');

    this.router.navigate(['/tienda/resumen']);
  }

  corregirCantidadesBono() {
    this.productosOrdenados = this.productosOrdenados.slice().map(prod => {
      if (prod.producto.ProductosBono.length) {
        const cantidadEntradas = prod.producto.ProductosBono.filter(bono => bono.CuentaNominada === '1')
          .map(entrada => parseInt(entrada.NumeroEntradasBono, 10))
          .reduce((sum, current) => sum + current);
        return { ...prod, cantidad: prod.datos.length / cantidadEntradas };
      } else {
        return { ...prod };
      }
    });
  }

  agrupar(compras) {
    compras.map(producto => this.agruparProducto(producto));
  }

  agruparProducto(producto) {

    // solo se usaba en este metodo
    const prod = this.productosOrdenados.find(e => e.producto.ProductoId === producto.producto.ProductoId);

    if (prod) {
      //const prod = this.existeProducto(producto);
      prod.cantidad++;

      // no era necesario el uso de auxiliares
      if(prod.datos)
        prod.datos = prod.datos.concat(producto.datos);

    } else {

      this.productosOrdenados.push(
        {
          posicionVenta: producto.posicionVenta,
          producto: producto.producto,
          datos: producto.datos,
          sesionesSeleccionadas: producto.sesionesSeleccionadas,
          cantidad: 1
        });

    }
  }

  disabledButton() {
    if (this.datosForm) {
      if (!this.check || this.datosForm.invalid) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  campoTipo(chTipoControl: string) {
    
    switch (chTipoControl) {
      case 'DropDownList': return 'dropDown';
      case 'TextBox': return 'text';
      default: return 'text';
    }
  }

  nombreCampo(nombreCampo: string) {
    switch (nombreCampo) {
      case 'txtNombre': return 'Nombre';
      case 'txtApellidos': return 'Apellidos';
      case 'cboTipoDNI': return 'Tipo documento';
      case 'txtDNI': return 'Documento';
      case 'txtNacionalidad': return 'Nacionalidad';
      case 'txtEdad': return 'Año de nacimiento';
      case 'cboSexo': return 'Sexo';
      case 'txtEmail1': return 'Email';
      case 'txtEmail2': return 'Email otro';
      case 'txtDireccion': return 'Dirección';
      case 'txtCiudad': return 'Ciudad';
      case 'txtPais': return 'Pais';
      case 'cboPaises': return 'Pais';
      case 'txtCodigoPostal': return 'Código Postal';
      case 'txtPrefijoInternacional': return 'Prefijo internacional';
      case 'txtTelefono': return 'Telefono';
      case 'txtEmailEnvio': return 'Email envio';
      case 'cboDia': return 'Dia';
      case 'cboTrimestre': return 'Trimestre';
      case 'cboNivel': return 'Nivel';
      case 'cboIdiomaVisita': return 'Idioma';
      case 'txtObservaciones': return 'Observaciones';
      case 'cboTipoTabla': return 'Tipo de tabla';
      default: return 'unknown';
    }
  }

  optionsFilter(arr, form, nombreCampo) {

    const selected = form[nombreCampo].value;
    const arr_selected = arr.filter(elem => elem.value == selected);
    const arr_not_selected = arr.filter(elem => elem.value !== selected);

    return [...arr_not_selected, ...arr_selected];
  }

/*  ValidarDocNieDni(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let otherControl: AbstractControl;
      const a = this.getControls(this.datosForm)?.controls[0];

      if (a) {
        otherControl = a.get(otherControlName);
      }
      if (otherControl) {
        const subscription: Subscription = otherControl
          .valueChanges
          .subscribe(() => {
            control.updateValueAndValidity();
            subscription.unsubscribe();
          });
      }

      let documento = control? (control.value == undefined? "":control.value):"";;
       let tipoDocumento = otherControl ? (otherControl.value == undefined? "":otherControl.value):"";
        return (!this.ValidarDocumento(tipoDocumento, documento)) ? { match: true } : null;
    }
  }

    ValidarDocumento(tipoDocumento: string,numeroDocumento : string) {
      let numValido = true;
      const control = tipoDocumento;
      if (control === 'NIE') {
        numValido = this.global.VerificarNIE(numeroDocumento)
      }
      if (control === 'DNI') {
        numValido = this.global.VerificarDNI(numeroDocumento)
      }
      if (control === 'CIF') {
        numValido = this.global.VerificarDNI(numeroDocumento)
      }
      return numValido;
    }
*/
  optionsDrop(nombreCampo: string, form) {
    
    switch (nombreCampo) {
      case 'cboTipoDNI': return this.optionsFilter([{ value: 'DNI' }, { value: 'NIE' }, { value: 'Otro' }], form, nombreCampo);
      case 'cboSexo': return [{ value: 'Hombre' }, { value: 'Mujer' }];
      case 'cboDia':
        if (this.productosService.getGrupoInternet().gi === 12 && this.productosService.getEnlaceMenuId().pkid === '394' ||
          this.productosService.getGrupoInternet().gi === 22 && this.productosService.getEnlaceMenuId().pkid === '395') {
          let dias;
          this.getDiasTemporadas();
          this.arrayDias.forEach(trim => {
            if (form.value.cboTrimestre === trim.trimestre) {
              dias = trim.dias;
            }
          });
          return dias;
        } else {
          return this.getDias();
        }
      case 'cboTrimestre': return this.getTrimestres();
      case 'cboNivel': return [{ value: 'A' }, { value: 'B' }, { value: 'C' }];
      case 'cboTipoTabla': return [{ value: 'SKI' }, { value: 'SNOW' }];
      case 'cboPaises': return  this.optionsFilter(this.paises, form, nombreCampo);
      case 'txtEdad': return this.añoDeNacimiento();
      case 'cboIdiomaVisita': return [{ value: 'Chino' }, { value: 'Japones' }];
      default: return 'unknown';
    }
  }

  getPaises(){
    this.productosService.GetPaises(this.translateService.getLocale()).pipe(take(1)).subscribe(res => {
      res.DatosListas.forEach(element => {
        this.paises.push({value:element.Nombre});
      });
    })
  }
 
  getTemporadas() {
    if (this.productosService.getGrupoInternet().gi === 12 && this.productosService.getEnlaceMenuId().pkid === '394' ||
      this.productosService.getGrupoInternet().gi === 22 && this.productosService.getEnlaceMenuId().pkid === '395') {
      this.clientDataService.getTemporadas().pipe(take(1)).subscribe(res => {
        this.productosService.setListaTrimestres(res.ListadoTemporadas);
        this.clientDataService.setTemporadasValue(res);
      });
      return;
    } else {
      this.productosService.setListaTrimestres([{ value: '1' }, { value: '2' }, { value: '3' }]);
      return;
    }
  }

  getTrimestres() {
    if (this.productosService.getGrupoInternet().gi === 12 && this.productosService.getEnlaceMenuId().pkid === '394' ||
      this.productosService.getGrupoInternet().gi === 22 && this.productosService.getEnlaceMenuId().pkid === '395') {
      const temporadas = this.clientDataService.temporadasValue;
      const trimestres = [];
      temporadas.ListadoTemporadas.forEach(temp => {
        trimestres.push({ value: temp.Nombre });
      });
      return trimestres;
    } else {
      return [{ value: '1' }, { value: '2' }, { value: '3' }];
    }
  }

  getDiasTemporadas() {
    this.arrayDias = [];
    const temporadas = this.clientDataService.temporadasValue;
    const trimestres = temporadas.ListadoTemporadas;
    trimestres.forEach(trimestre => {
      const dias = [];
      if (trimestre.Lunes === 1) {
        dias.push({ value: this.translateService.data.L });
      }
      if (trimestre.Martes === 1) {
        dias.push({ value: this.translateService.data.M });
      }
      if (trimestre.Miercoles === 1) {
        dias.push({ value: this.translateService.data.X });
      }
      if (trimestre.Jueves === 1) {
        dias.push({ value: this.translateService.data.J });
      }
      if (trimestre.Viernes === 1) {
        dias.push({ value: this.translateService.data.V });
      }
      if (trimestre.Sabado === 1) {
        dias.push({ value: this.translateService.data.S });
      }
      if (trimestre.Domingo === 1) {
        dias.push({ value: this.translateService.data.D });
      }
      this.arrayDias.push({ trimestre: trimestre.Nombre, dias });
    });
  }

  getDias() {
    return [{ value: this.translateService.data.L },
    { value: this.translateService.data.M },
    { value: this.translateService.data.X },
    { value: this.translateService.data.J },
    { value: this.translateService.data.V }];
  }

  añoDeNacimiento() {
    const year = this.currentDate.year();
    const años = [];
    for (let i = year; i >= 1920; i--) {
      años.push({ value: i });
    }
    return años;
  }

  documentoValidar(tipoDocumento: string) {
    const control = tipoDocumento;
    const nifReg = /^(\d{8})([A-Z]|[a-z])$/;
    const nieReg = /^[XYZ][0-9]{7}[A-Z]$/;
    if (control === 'NIE') {
      return nieReg;
    }
    if (control === 'DNI') {
      return nifReg;
    }
    if (control === 'Otro') {
      return null;
    }
  }

  mapClienteCompra(i) {
    if (!this.ventaService.clienteValue) {
      const ctrl: AbstractControl = (this.datosForm.get('items') as FormArray).at(i);
      const cliente = {
        NombreContacto: ctrl.value.txtNombre,
        Apellidos: ctrl.value.txtApellidos,
        Email: ctrl.value.txtEmail1,
        Documento: ctrl.value.txtDNI,
        CodigoPostal: ctrl.value.txtCodigoPostal,
        Telefono: ctrl.value.txtTelefono,
        TipoDocumento: ctrl.value.cboTipoDNI,
        CodigoPais: ctrl.value.cboPaises,
      };
      this.ventaService.mapCliente(cliente);
    }
  }

  backClicked() {
    this._location.back();
  }
}


