export class ModelFormasPagoProyecto {
    tarjeta: boolean;
    paypal: boolean;
    bizum: boolean;

    //Por defecto solo se paga por tarjeta, los demas dependeran del proyecto
    constructor() {
        this.tarjeta = true;
        this.paypal = false;
        this.bizum = false;
      }
}
