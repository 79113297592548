import { Component, OnInit, Input, OnDestroy } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';
//import { ButacasService } from '../../mapa/servicios/butacas.service';



@Component({
  selector: 'iacpos-selector-no-numeradas',
  templateUrl: './selector-no-numeradas.component.html',
  styleUrls: ['./selector-no-numeradas.component.css']
})
export class SelectorNoNumeradasComponent 
//implements OnInit, OnDestroy 
{
//   @Input() variosTiposDeButaca = false;
//   limiteMaximo = false;
//   datosButacaActualizados = false;
//   cantidad = 0;
//   cantidadTarifas = 0;
//   butacaMarcada: any;
//   componenteActual = false;
//   enProceso = false;
//   @Input() itemTarifa: any;
//   @Input() firstInit: boolean;

//   butacasNONumeradaMarcada$;
//   entradasSeleccionadas$;
//   entradasSeleccionadas2$;
//   constructor(
//     private butacasService: ButacasService,
//     private toast: ToastrService
//   ) { }

//   ngOnInit() {

//     this.butacasNONumeradaMarcada$ = this.butacasService.butacasNONumeradaMarcada.subscribe(b => {
//       if (b) {
//         this.butacaMarcada = { ...b.filter(butacaItem => +butacaItem['data-tipo-butaca'] === +this.itemTarifa.TipoButacaId)[0] };
//         this.butacaMarcada.tipoSeleccionado = { ...this.itemTarifa };
//        if (!this.butacaMarcada.EspectaculosId) {
//          this.butacaMarcada.idEspectaculoAsociado = this.butacasService.espectaculoValue.EspectaculosId;
//         }

//         this.entradasSeleccionadas$ = this.butacasService.entradasSeleccionadas.subscribe(entradas => {
//           if (this.componenteActual || this.firstInit) {
//             this.firstInit = false;

//             this.cantidad = entradas.filter(
//               e =>
//                 e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
//                 e.fecha.format('DD/MM/YYYY') === this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//                 e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
//                 +e.tipoSeleccionado.IdTarifa === +this.butacaMarcada.tipoSeleccionado.IdTarifa &&
//                 +e.dataIdRecinto === +this.butacaMarcada.dataIdRecinto
//             ).length;

//             this.cantidadTarifas = entradas.filter(
//               e =>
//                 e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
//                 e.fecha.format('DD/MM/YYYY') === this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//                 e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
//                 +e.tipoSeleccionado.IdTarifa === +this.butacaMarcada.tipoSeleccionado.IdTarifa
//             ).length;
//           }
//           this.componenteActual = false;
//         });

//       }
//     });

//     this.entradasSeleccionadas2$ = this.butacasService.entradasSeleccionadas.subscribe(entradas => {
//         this.cantidad = entradas.filter(
//           e =>
//             e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
//             e.fecha.format('DD/MM/YYYY') === this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//             e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
//             +e.tipoSeleccionado.IdTarifa === +this.butacaMarcada.tipoSeleccionado.IdTarifa &&
//             +e.dataIdRecinto === +this.butacaMarcada.dataIdRecinto
//         ).length;
//       });


//     this.butacasService.datosButacaActualizados.subscribe((data: boolean) => {
//       this.datosButacaActualizados = data;
//     });


//   }

//   ngOnDestroy(): void {
//     this.butacasNONumeradaMarcada$.unsubscribe();
//     this.entradasSeleccionadas$.unsubscribe();
//     this.entradasSeleccionadas2$.unsubscribe();
//     // this.entradasService.datosButacaActualizados.next(false);
//   }

//   private marcarButaca() {
//     this.enProceso = true;

//     if (!this.limiteMaximo &&
//       this.butacaMarcada.tipoSeleccionado.Maximo &&
//       this.cantidadTarifas < +this.butacaMarcada.tipoSeleccionado.Maximo) {

//       let cantidadCarroOriginal = 0;
//       if (this.butacasService.entradasSeleccionadasValue.carroReservaInicial) {
//         cantidadCarroOriginal = this.butacasService.entradasSeleccionadasValue.carroReservaInicial.filter(
//           e =>
//             e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
//             e.fecha.format('DD/MM/YYYY') === this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//             e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
//             +e.tipoSeleccionado.IdTarifa === +this.butacaMarcada.tipoSeleccionado.IdTarifa &&
//             +e.dataIdRecinto === +this.butacaMarcada.dataIdRecinto
//         ).length;
//       }

//       if (cantidadCarroOriginal <= this.cantidad) {

//       const marcada = {
//         idTarifa: this.butacaMarcada.tipoSeleccionado.IdTarifa,
//         Cantidad: '1'
//       };
//       const marcadas = [];
//       marcadas.push(marcada);

//       this.butacasService
//         .marcarNoNumeradas(
//           1,
//           marcadas,
//           true,
//           +this.butacaMarcada.dataIdRecinto,
//           this.butacasService.espectaculoValue.EspectaculosId,
//           this.butacasService.fechaSeleccionadaValue,
//           this.butacasService.horaSeleccionadaValue,
//           this.butacaMarcada['data-tipo-butaca']
//         )
//         .subscribe(res => {
//           if (res && res.Butacas != null && res.Butacas != undefined) {
//             const espectaculo = {
//               ...this.butacasService.espectaculoValue,
//               fecha: this.butacasService.fechaSeleccionadaValue,
//               hora: this.butacasService.horaSeleccionadaValue
//             };
//             //YS 20221215  Comentamos servicios o componentes que no existen
//             //this.cestaService.pushEspectaculo(espectaculo);

//             this.addDatosButaca(res);

//           }
//           // cantida empieza en 0 por eso se le suma uno
//           if (this.butacaMarcada.tipoSeleccionado.Maximo && this.cantidad >= +this.butacaMarcada.tipoSeleccionado.Maximo) {
//             this.limiteMaximo = true;
//           } else {
//             this.limiteMaximo = false;
//           }
//           this.enProceso = false;

//         });
//       } else {

//         const espectaculo = {
//           ...this.butacasService.espectaculoValue,
//           fecha: this.butacasService.fechaSeleccionadaValue,
//           hora: this.butacasService.horaSeleccionadaValue
//         };
//         //YS 20221215  Comentamos servicios o componentes que no existen
//         //this.cestaService.pushEspectaculo(espectaculo);

//         this.addDatosButaca({}, false);

//         // cantida empieza en 0 por eso se le suma uno
//         if (this.butacaMarcada.tipoSeleccionado.Maximo && this.cantidad >= +this.butacaMarcada.tipoSeleccionado.Maximo) {
//           this.limiteMaximo = true;
//         } else {
//           this.limiteMaximo = false;
//         }
//         this.enProceso = false;
//       }
//     } else {
//       this.enProceso = false;
//       alert(this.butacasService.getTranslate().data.ERR_SUPERADO_MAXIMO_TARIFA);
//        this.toast.info(this.butacasService.getTranslate().data.ERR_SUPERADO_MAXIMO_TARIFA, 'Info', {
//          timeOut: 5000,
//          positionClass: 'toast-top-center',
//    });
//     }
//   }

//   private desmarcarButaca() {
//     this.enProceso = true;
//  // si saco la cantidad del origen y la comparo con el actual puedo contiuar desmarcando hasta 0
//     let cantidadCarroOriginal = 0;
//     if (this.butacasService.entradasSeleccionadasValue.carroReservaInicial) {
//       cantidadCarroOriginal = this.butacasService.entradasSeleccionadasValue.carroReservaInicial.filter(
//       e =>
//         e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
//         e.fecha.format('DD/MM/YYYY') === this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//         e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
//         +e.tipoSeleccionado.IdTarifa === +this.butacaMarcada.tipoSeleccionado.IdTarifa &&
//         +e.dataIdRecinto === +this.butacaMarcada.dataIdRecinto
//     ).length;
//  }

//     if (cantidadCarroOriginal < this.cantidad || cantidadCarroOriginal === 0) {
//       const marcada = {
//         idTarifa: this.butacaMarcada.tipoSeleccionado.IdTarifa,
//         Cantidad: '1'
//       };
//       const marcadas = [];
//       marcadas.push(marcada);
//     this.butacasService
//       .marcarNoNumeradas(
//         1,
//         marcadas,
//         false,
//         this.butacaMarcada.dataIdRecinto,
//         this.butacasService.espectaculoValue.EspectaculosId,
//         this.butacasService.fechaSeleccionadaValue,
//         this.butacasService.horaSeleccionadaValue,
//         this.butacaMarcada['data-tipo-butaca']
//       )
//       .subscribe(res => {
//         if (res && res.Butacas && res.Butacas.length > 0) {
//           this.eliminarDatosButaca(res);
//         }

//         if (!this.butacaMarcada.tipoSeleccionado.Maximo || this.cantidad < +this.butacaMarcada.tipoSeleccionado.Maximo) {
//           this.limiteMaximo = false;
//         }

//         this.enProceso = false;
//       });
//     } else {
//       const respuestaSimuladaMarcar = {Butacas: [{
//         Numero: this.butacaMarcada.dataNumButaca.toString(),
//         Fila: this.butacaMarcada.dataNumFila.toString(),
//         EspectaculoId: this.butacaMarcada.idEspectaculoAsociado.toString(),
//         RecintoId: this.butacaMarcada.dataIdRecinto.toString(),
//         idRecintoButaca: this.butacaMarcada.PKIDRB.toString()
//       }]};

//       this.eliminarDatosButaca(respuestaSimuladaMarcar);
//       if (!this.butacaMarcada.tipoSeleccionado.Maximo || this.cantidad < +this.butacaMarcada.tipoSeleccionado.Maximo) {
//         this.limiteMaximo = false;
//       }

//       this.enProceso = false;

//     }
//   }

//   private addDatosButaca(respuestaMarcacion: any, nuevaButaca: boolean = true) {

//     let b;
//     if (nuevaButaca) {
//       b = { ...this.butacaMarcada };
//       b.dataNumButaca = respuestaMarcacion.Butacas[0].Numero;
//       b.dataNumFila = respuestaMarcacion.Butacas[0].Fila;
//       b.idEspectaculoAsociado = +respuestaMarcacion.Butacas[0].EspectaculoId;
//       b.PKIDRB = +respuestaMarcacion.Butacas[0].idRecintoButaca;
//       b.fecha = this.butacasService.fechaSeleccionadaValue;
//       b.hora = this.butacasService.horaSeleccionadaValue;
//       b.noNumerada = true;
//       // delete b.tarifas;
//       delete b.info.tiposDeEntrada;

//     } else {
//       const pkidsCarroActual = this.butacasService.entradasSeleccionadasValue.map(carro => +carro.PKIDRB);

//       b  = { ...this.butacasService.entradasSeleccionadasValue.carroReservaInicial.filter(
//         e =>
//        /*    e.idEspectaculoAsociado === this.ventaService.espectaculoValue.EspectaculosId &&
//           e.fecha.format('DD/MM/YYYY') === this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//           e.hora.format('HH:mm') === this.ventaService.horaSeleccionadaValue.format('HH:mm') &&
//           +e.tipoSeleccionado.IdTarifa === +this.butacaMarcada.tipoSeleccionado.IdTarifa &&
//           +e.dataIdRecinto === +this.butacaMarcada.dataIdRecinto && */
//           pkidsCarroActual.filter(idCarro => idCarro === +e.PKIDRB).length === 0
//       )[0]};
//     }

//     this.butacasService.pushButacaNoNumerada(b);
//     this.butacaMarcada = b;
//   }

//   private eliminarDatosButaca(respuestaMarcacion: any) {
//     this.butacasService.removeButacaNoNumerada(
//       +respuestaMarcacion.Butacas[0].EspectaculoId,
//       this.butacasService.fechaSeleccionadaValue,
//       this.butacasService.horaSeleccionadaValue,
//       respuestaMarcacion.Butacas[0].Numero,
//       respuestaMarcacion.Butacas[0].Fila,
//       respuestaMarcacion.Butacas[0].RecintoId,
//       +this.itemTarifa.IdTarifa
//     );

//     //YS 20221215  Comentamos servicios o componentes que no existen
//     let espectaculoAsociado = this.butacasService.espectaculoValue;
//      //const espectaculoAsociado = this.cestaService.espectaculos.find(
//     //   esp =>
//     //     esp.EspectaculosId === +respuestaMarcacion.Butacas[0].EspectaculoId &&
//     //     esp.fecha.format('DD/MM/YYYY') === this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
//     //     esp.hora.format('HH:mm') === this.ventaService.horaSeleccionadaValue.format('HH:mm')
//     // );

//     const quedanEntradas = this.butacasService.entradasSeleccionadasValue.filter(
//       entrada =>
//         entrada.idEspectaculoAsociado === espectaculoAsociado.EspectaculosId &&
//         entrada.fecha.format('DD/MM/YYYY') === espectaculoAsociado.fecha.format('DD/MM/YYYY') &&
//         entrada.hora.format('HH:mm') === espectaculoAsociado.hora.format('HH:mm')
//     );

//   }

//   sumaCantidad(e) {
//     this.componenteActual = true;
//     this.marcarButaca();
//     this.componenteActual = false;
//     e.stopPropagation();
//     e.preventDefault();
//   }

//   restaCantidad(e) {
//     if (this.cantidad - 1 < 0) {
//       return;
//     }
//     this.componenteActual = true;
//     this.desmarcarButaca();
//     this.componenteActual = false;

//     e.stopPropagation();
//     e.preventDefault();
//   }
}
