import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TicketSalesComponent } from '../ticket-sales/ticket-sales.component';
import { TicketSalesModule } from '../ticket-sales/ticket-sales.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [CartComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TicketSalesModule,
    SharedModule

  ],
  exports: [CartComponent]
})
export class CartModule { }
