import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProductosService } from "../productos.service";
import { map, take, takeUntil, takeWhile } from "rxjs/operators";
import { Router } from "@angular/router";
import { InitService } from "src/app/core/init/init.service";
import { VentaService } from "../../core/services/venta.service";
import { ModalService } from "src/app/shared/modal.service";
import { Compra } from "src/app/models/compra.model";
import { CalendarService } from "../ticket-sales/pages/date-selector/calendar/calendar.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-other-products",
  templateUrl: "./other-products.component.html",
  styleUrls: ["./other-products.component.scss"],
})
export class OtherProductsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  confirmado;
  enlaceMenu;
  grupoProductos;
  pantallas: [];
  mostrar = this.calendarService.mostrarValue;
  mostrarFiltros = this.calendarService.filtrosValue;
  pantallasFiltros;
  constructor(
    private calendarService: CalendarService,
    private modalService: ModalService,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private router: Router,
    private initService: InitService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.initService.grupoProductos$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.grupoProductos = res));
    this.productosService.otrosProductos$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => (this.enlaceMenu = res));
    this.modalService.open("LoginAndRegister");
  }

  irMenu(prod) {
    this.ventaService.setModificarValue(false);
    this.confirmado = false;
    if (
      (this.ventaService.cambiosValue &&
        this.ventaService.compraValue.productos.length !== 0) ||
      (this.ventaService.modificarValue && this.ventaService.cambiosValue)
    ) {
      this.modalService.open("aviso");
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        takeWhile(() => !this.confirmado)
      )
      .subscribe((confirmado) => {
        this.confirmado = confirmado;
        if (this.confirmado) {
          this.ventaService.setCompra(new Compra());
          this.ventaService.setCambios(false);
          this.modalService.setConfirmadoValue(false);

          if (prod === "ESCUELA" && this.grupoProductos === "ONLINE") {
            this.productosService.setOtrosProductosValue("ESCUELA");
          }
          if (prod === "FORFAIT" && this.grupoProductos === "ONLINE") {
            this.productosService.setOtrosProductosValue("SKI_SNOW");
          }
          if (prod === "ACTIVIDADES" && this.grupoProductos === "ONLINE") {
            this.productosService.setOtrosProductosValue("DIVIERTETE");
          }

          if (prod === "ESCUELA" && this.grupoProductos === "COLEGIOS") {
            this.productosService.setOtrosProductosValue("COLEGIOS ESCUELA");
          }
          if (prod === "SKI-SNOW" && this.grupoProductos === "COLEGIOS") {
            this.productosService.setOtrosProductosValue(
              "COLEGIOS ACTIVIDADES"
            );
          }

          if (prod === "ESCUELA" && this.grupoProductos === "EMPRESAS") {
            this.productosService.setOtrosProductosValue("EMPRESAS ESCUELA");
          }
          if (prod === "SKI-SNOW" && this.grupoProductos === "EMPRESAS") {
            this.productosService.setOtrosProductosValue(
              "EMPRESAS ACTIVIDADES"
            );
          }

          if (prod === "ESCUELA" && this.grupoProductos === "CLUBES") {
            this.productosService.setOtrosProductosValue("CLUBES ESCUELA");
          }
          if (prod === "SKI-SNOW" && this.grupoProductos === "CLUBES") {
            this.productosService.setOtrosProductosValue("CLUBES ACTIVIDADES");
          }

          if (prod === "ESCUELA" && this.grupoProductos === "ADMIN") {
            this.productosService.setOtrosProductosValue(
              "PARTICULARES ESCUELA"
            );
          }
          if (prod === "FORFAIT" && this.grupoProductos === "ADMIN") {
            this.productosService.setOtrosProductosValue(
              "PARTICULARES ACTIVIDADES"
            );
          }
          if (prod === "ACTIVIDADES" && this.grupoProductos === "ADMIN") {
            this.productosService.setOtrosProductosValue(
              "PARTICULARES DIVIERTETE"
            );
          }
          this.productosService.setnombreGrupoMenuValue(prod);
          this.productosService
            .getProductosMenu()
            .pipe(take(1))
            .subscribe((res) =>
              this.productosService.setProductosMenuValue(
                Object.values(res.DictTarifasEntradas)
              )
            );
          this.router.navigate(["/tienda/menu"]);
        }
      });
  }

  irEquipacion(em, gI) {
    this.ventaService.setModificarValue(false);
    this.productosService.setFiltroTipoValue("");
    this.productosService.setFiltroGrupoValue("");
    this.productosService.setFiltroNivelValue("");
    this.productosService.setCargandoValue(true);
    this.confirmado = false;
    if (
      (this.ventaService.cambiosValue &&
        this.ventaService.compraValue.productos.length !== 0) ||
      (this.ventaService.modificarValue && this.ventaService.cambiosValue)
    ) {
      this.modalService.open("aviso");
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        takeWhile(() => !this.confirmado)
      )
      .subscribe((confirmado) => {
        this.confirmado = confirmado;
        if (this.confirmado) {
          this.ventaService.setCompra(new Compra());
          this.ventaService.setCambios(false);
          this.modalService.setConfirmadoValue(false);
          this.productosService.setOtrosProductosValue("EQUIPO");
          const enlaceMenu = this.productosService.enlacesMenuValue.find(
            (e) => e.pkid === em
          );
          const calendario = JSON.parse(enlaceMenu.chCalendario);
          this.initService.setChCalendarioValue(calendario);
          const datosUrl = JSON.parse(this.productosService.datosInitValue);
          const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(
            enlaceMenu.pkid
          );
          this.productosService.setDatosInitValue({
            empresa: datosUrl.empresa,
            m: enlaceMenuNombre,
            gi: gI,
            userId: JSON.parse(this.productosService.datosInitValue).userId,
            lg: datosUrl.lg,
            u: JSON.parse(this.productosService.datosInitValue).u,
            p: JSON.parse(this.productosService.datosInitValue).p,
          });
          sessionStorage.setItem(
            "token",
            this.initService.funcionEncript(
              this.productosService.datosInitValue
            )
          );
          this.productosService
            .getProductos()
            .pipe(
              map((res) => {
                if (res && res.DictTarifasEntradas) {
                  this.productosService.setnombreGrupoValue(
                    res.DictTarifasEntradas[
                      this.productosService.getGrupoInternet().gi
                    ].NombreGrupo
                  );
                  this.productosService.setProductosValue(
                    res.DictTarifasEntradas[
                      this.productosService.getGrupoInternet().gi
                    ].DictTarifasProductos,
                    res.DictTarifasEntradas[
                      this.productosService.getGrupoInternet().gi
                    ].EnlaceMenuId,
                    this.productosService.getGrupoInternet().gi,
                    res.DictTarifasEntradas[
                      this.productosService.getGrupoInternet().gi
                    ].TipoReservaId,
                    res.DictTarifasEntradas[
                      this.productosService.getGrupoInternet().gi
                    ].NombreGrupo
                  );
                  this.productosService.setInfoGrupoValue(
                    res.DictTarifasEntradas[
                      this.productosService.getGrupoInternet().gi
                    ]
                  );
                }
              })
            )
            .pipe(take(1))
            .subscribe(() => {
              this.productosService.setCargandoValue(false);
              this.router.navigate(["/tienda/producto/1"]);
            });
        }
      });
  }

  irMenuEquipacion() {
    this.ventaService.setModificarValue(false);
    this.confirmado = false;
    if (
      (this.ventaService.cambiosValue &&
        this.ventaService.compraValue.productos.length !== 0) ||
      (this.ventaService.modificarValue && this.ventaService.cambiosValue)
    ) {
      this.modalService.open("aviso");
    } else {
      this.modalService.setConfirmadoValue(true);
      this.confirmado = false;
    }
    this.modalService.confirmado$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        takeWhile(() => !this.confirmado)
      )
      .subscribe((confirmado) => {
        this.confirmado = confirmado;
        if (this.confirmado) {
          this.ventaService.setCompra(new Compra());
          this.ventaService.setCambios(false);
          this.modalService.setConfirmadoValue(false);
          this.productosService.setOtrosProductosValue("EQUIPO");
          this.productosService.setnombreGrupoMenuValue("EQUIPO");
          this.productosService
            .getProductosMenu()
            .pipe(take(1))
            .subscribe((res) =>
              this.productosService.setProductosMenuValue(
                Object.values(res.DictTarifasEntradas)
              )
            );
          this.router.navigate(["/tienda/menu"]);
        }
      });
  }
}
