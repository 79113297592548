import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { TimerService } from 'src/app/core/services/timer.service';
import { TimerDirective } from 'src/app/core/timer/timer.directive';
import { ModalService } from 'src/app/shared/modal.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  //priorityObs$ = this.timerService.priority$;
  intervalObs$ = this.timerService.interval$;
  
  constructor(
    private timerService: TimerService,
    public modalService: ModalService,
    private timerDirective: TimerDirective
  ) {

    this.timerService.priority$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(level => { 
        if(level === 4) 
          this.showModalTimer(level) 
      });
  } 
  
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.timerDirective.updateState(this.timerService.getCountDown(), 'minutes')
  }

  async showModalTimer(level:number) {
    this.modalService.open("modalTimer", false, level);
  }

}
