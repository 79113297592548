import { Component, ElementRef, Input, OnInit, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { PaymentService } from 'src/app/core/payment/payment.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { UserService } from 'src/app/user/user.service';
import { ModalService } from '../../modal.service';
import { TranslateService } from '../../translate.service';


@Component({
  selector: 'app-modal-more-information',
  templateUrl: './modal-more-information.component.html',
  styleUrls: ['./modal-more-information.component.scss']
})
export class ModalMoreInformationComponent implements OnInit {
  private ngUnsubscribe = new Subject();


  private element: any;
  @Input() id: string;
  localizador: string;
  reserva: any;

  overFlowRegister=false
  user;

  displayedColumns: string[] = ['NombreProducto', 'Cantidad', 'PvpProductoUd', 'SubTotal','FechaHora'];
  dataSource; 
  ProductosCompraPorGrupo;

  constructor(
    private el: ElementRef,
    private modalService: ModalService,
    private router: Router,
    private translate: TranslateService,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private paymentService: PaymentService,
    private userervice: UserService

  ) {
    this.element = el.nativeElement;

   }

  ngOnInit() {
    this.user = this.userervice.getUser();
    const modal = this;
    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  getReserva(){
    let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
    this.paymentService.traerReserva('', this.localizador, this.user.TipoClienteId, currentuser.NombreCliente,currentuser.Contrasena).pipe(take(1)).subscribe(productos => {
      this.reserva = productos;
      if (productos.ProductosCompraPorGrupo["0"]) this.ProductosCompraPorGrupo = productos.ProductosCompraPorGrupo["0"];
      if (productos.ProductosCompraPorGrupo["1"]) this.ProductosCompraPorGrupo = productos.ProductosCompraPorGrupo["1"];
      //this.dataSource = productos.ProductosCompra;
    });
  }
  changeCss(flag){
    this.overFlowRegister=flag
  }
  moreInfo(localizador){
    this.localizador=localizador
    this.open();
  }
  
  open(flag?): void {
    this.getReserva()

    if(flag){
      /* this.initService.cookies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cookies=>{
        this.cookies = cookies
        this.check=true
      }) */
     // this.getCookiesExterno()
    }
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    //this.element.remove();
  }
  
  close(): void {
    //this.router.navigate(['/tienda/productos']);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
}
