import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";
import { InitService } from "src/app/core/init/init.service";
import { EspectaculosService } from "../../espectaculos-butacas/espectaculos.service";
import { ButacasService } from "../../espectaculos-butacas/mapa/servicios/butacas.service";
import { ProductosService } from "../../productos.service";
import { CalendarService } from "../../ticket-sales/pages/date-selector/calendar/calendar.service";

@Component({
  selector: "app-espectaculos",
  templateUrl: "./espectaculos.component.html",
  styleUrls: ["./espectaculos.component.scss"],
})
export class EspectaculosComponent implements OnInit {
  espectaculos;
  todosEspectaculos;
  totalEspectaculos;
  backupEspectaculos;
  espectaculosSub: Subscription;
  todosEspectaculosSub: Subscription;
  backupEspectaculosSub: Subscription;
  filtrarSub: Subscription;
  proyecto = this.config.getProyecto(); //Yaribel 20210713 añadimos proyecto para obtener los assets genericamente

  constructor(
    private productosService: ProductosService,
    private config: AppConfiguration,
    private initService: InitService,
    private calendarService: CalendarService,
    private router: Router,
    private butacasService: ButacasService,
    private espectaculoService: EspectaculosService
  ) {}

  ngOnInit(): void {
    this.espectaculosSub = this.productosService.espectaculosCat$.subscribe(
      (espectaculos) => {
        this.espectaculos = this.productosService.espectaculosCatValue;
      }
    );
    this.backupEspectaculosSub =
      this.productosService.backupEspectaculos$.subscribe((espectaculos) => {
        this.backupEspectaculos = this.productosService.backupEspectaculosValue;
      });
    this.todosEspectaculosSub =
      this.productosService.todosEspectaculosCat$.subscribe((espectaculos) => {
        this.todosEspectaculos =
          this.productosService.todosEspectaculosCatValue;
      });
    this.filtrarSub = this.productosService.filtroProductos$.subscribe(
      (filtro) => {
        if (filtro && filtro.length > 0) {
          this.espectaculos = this.backupEspectaculos;
          this.espectaculos = this.espectaculos.filter((p) =>
            p.NombreEspectaculo.toLowerCase().includes(filtro)
          );
        } else {
          this.espectaculos = this.backupEspectaculos;
        }
      }
    );
  }
  ngOnDestroy(): void {
    if (this.espectaculosSub) this.espectaculosSub.unsubscribe();
    if (this.todosEspectaculosSub) this.todosEspectaculosSub.unsubscribe();
    if (this.backupEspectaculosSub) this.backupEspectaculosSub.unsubscribe();
  }

  onImgError(event) {
    event.target.src =
      "assets/img/" + this.proyecto + "/catalog/default_catalog.png";
  }

  irEspectaculos(producto) {
    this.productosService.setCargandoValue(true);
    //const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === "613");
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === producto.EnlaceMenuId
    );
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);

    if (!this.productosService.datosInitValue) {
      this.productosService.setDatosInitValue({
        empresa: this.config.getProyecto(),
        m: enlaceMenu.chNombre,
        gi: producto.GrupoInternetId,
        espectaculoId: producto.EspectaculosId,
      });
    }
    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(
      enlaceMenu.pkid
    );

    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa,
      m: enlaceMenuNombre,
      gi: producto.GrupoInternetId,
      userId: JSON.parse(this.productosService.datosInitValue).userId,
      lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u,
      p: JSON.parse(this.productosService.datosInitValue).p,
      espectaculoId: producto.EspectaculosId,
    });

    this.espectaculoService.setFechaAutomatica(true);
    this.calendarService.setFiltros(false);
    sessionStorage.setItem(
      "token",
      this.initService.funcionEncript(this.productosService.datosInitValue)
    );

    this.productosService
      .getEspectaculos(producto.EspectaculosId)
      .pipe(
        map((res) => {
          if (res) {
            const espectaculo = res.EspectaculosDisponible.find(
              (x) =>
                x.EspectaculosId == producto.EspectaculosId &&
                x.GrupoRecintoId == producto.GrupoRecintoId &&
                x.EnlaceMenuId == producto.EnlaceMenuId &&
                x.GrupoInternetId == producto.GrupoInternetId
            );
            this.productosService.setnombreGrupoValue(espectaculo.NombreGrupo);
            this.productosService.setEspectaculoValue(espectaculo);
            this.productosService.setInfoGrupoValue(espectaculo);
          }
        })
      )
      .pipe(take(1))
      .subscribe(() => {
        this.butacasService.getEstadosButacasInicial();
        this.productosService.setVentaEspectaculo(true);
        this.productosService.setCargandoValue(false);
        this.router.navigate(["/tienda/espectaculo/1"]);
      });
  }
}
