import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { CoreModule } from './core/core.module';
import { UserModule } from './user/user.module';
import { BusinessModule } from './business/business.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppConfiguration } from './app.configuration';
import { HttpRequestInterceptor } from './core/http-helpers/http-request.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InitComponent } from './core/init/init.component';
import { TranslatePipe } from './shared/translate.pipe';
import { TranslateService } from './shared/translate.service';
import { SharedModule } from './shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';


/*export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => {
    service.use(navigator.language);
  };
}*/


@NgModule({
  declarations: [
    AppComponent,
    InitComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LayoutsModule,
    DragDropModule,
    FontAwesomeModule,
    FlexLayoutModule,
    CoreModule,
    UserModule,
    BusinessModule,
    SharedModule,

    
  ],
  providers: [
    //TranslateService,
    AppConfiguration,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfiguration) => () => config.load(),
      deps: [AppConfiguration],
      multi: true
    },
    TranslatePipe,
    /*{
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },*/
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    // {
    //   provide: LOCALE_ID,
    //   deps: [TranslateService],
    //   useFactory: (translateService) => translateService.getLocale()
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {



}
