import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenManagerService } from '../token/token-manager.service';



@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private tokenManager: TokenManagerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentToken = this.tokenManager.currentTokenValue;
    const conexion = this.tokenManager.currentConexionIdValue;
    if (currentToken && currentToken.token && conexion) {
      const ClienteApi = {
        Id: currentToken.sessionId,
        Token: currentToken.token
      };
      const ConexionIacpos = conexion;
      request = request.clone({
        body: { ...request.body, ClienteApi, ConexionIacpos }
      });
    }
    return next.handle(request);
  }
}
