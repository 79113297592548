import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatePipe } from 'src/app/shared/translate.pipe';
import { TranslateService } from 'src/app/shared/translate.service';
import { environment } from 'src/environments/environment';

const myRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 `+getde()+` ${length}`; }
  
  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} `+getde()+` ${length}`;
}

function getde() {

  let result="";

  switch (localStorage.getItem('lastlang')) {
    case "ca":
      result = "de";
      break;
    case "es-ES":
      result = "de";
      break;
    case "en-GB":
      result = "of";
      break;
  }
  return result;
}

  export function getTraduccionMatPag() {

  const paginatorIntl = new MatPaginatorIntl();

  switch (localStorage.getItem('lastlang')) {
    case "ca":
      paginatorIntl.itemsPerPageLabel = "Elements per plana: ";
      paginatorIntl.nextPageLabel = "Propera Plana";
      paginatorIntl.previousPageLabel = 'Plana Anterior';
      paginatorIntl.firstPageLabel = "Primera Plana";
      paginatorIntl.lastPageLabel = "Darrera Plana";
      break;
    case "es-ES":
      paginatorIntl.itemsPerPageLabel = "Elementos por página: ";
      paginatorIntl.nextPageLabel = "Próxima Página";
      paginatorIntl.previousPageLabel = 'Página Anterior';
      paginatorIntl.firstPageLabel = "Primera Página";
      paginatorIntl.lastPageLabel = "Última Página";
      break;
    case "en-GB":
      paginatorIntl.itemsPerPageLabel = "Items per page: ";
      paginatorIntl.nextPageLabel = "Next Page";
      paginatorIntl.previousPageLabel = 'Previus Page';
      paginatorIntl.firstPageLabel = "First Page";
      paginatorIntl.lastPageLabel = "Last Page";
      break;
  }


  paginatorIntl.getRangeLabel = myRangeLabel;
  
  return paginatorIntl;
}
