import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as d3 from "d3";
import * as moment from "moment"; //VO
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, forkJoin, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import {
  distinctUntilChanged,
  find,
  map,
  mergeMap,
  shareReplay,
} from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";
import { EspectaculosService } from "src/app/business/espectaculos-butacas/espectaculos.service";
import { ProductosService } from "src/app/business/productos.service";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { PaymentService } from "src/app/core/payment/payment.service";
import { VentaService } from "src/app/core/services/venta.service";
import { ModalService } from "src/app/shared/modal.service";
import { TranslateService } from "src/app/shared/translate.service";
import { environment } from "src/environments/environment";
import {
  Butaca,
  DisabledSeatType,
  RateJsonType,
  TeacherSeatType,
} from "../../modelos/butaca.model";
import { InfoMapasService } from "./info-mapas.service";
import { ButacasMultiplesService } from "./butacas.multiples.service";
import { CalendarService } from "src/app/business/ticket-sales/pages/date-selector/calendar/calendar.service";
import { PaymentGateway } from "src/app/core/payment/paymentGateway.enum";
import { Compra } from "src/app/models/compra.model";
import { importExpr } from "@angular/compiler/src/output/output_ast";

interface HashTable<T> {
  [key: string]: T;
}
@Injectable({
  providedIn: "root",
})
export class ButacasService {
  private sonButacasSinEspaciosSubject: BehaviorSubject<Boolean>;
  public sonButacasSinEspacios$: Observable<Boolean>;
  private espectaculoSubject: BehaviorSubject<any>;
  public espectaculo$: Observable<any>;
  private fechaSeleccionadaSubject: BehaviorSubject<any>;
  public fechaSeleccionada$: Observable<any>;
  private horaSeleccionadaSubject: BehaviorSubject<any>;
  public horaSeleccionada$: Observable<any>;
  private ngUnsubscribe = new Subject();
  private datosButacaActualizados = new BehaviorSubject(false);
  private estadosButacasSubject: BehaviorSubject<any>;
  public estadosButacas: Observable<HashTable<any>>;
  private asientosDeshabilitadosSubject: BehaviorSubject<any>;
  public asientosDeshabilitados: Observable<any>;
  private butacaMarcadaSubject: BehaviorSubject<any>;
  public butacaMarcada: Observable<any>;
  private butacasNONumeradaMarcadaSubject: BehaviorSubject<any>;
  public butacasNONumeradaMarcada: Observable<any>;
  private noNumeradaSubject: BehaviorSubject<any>;
  private reservationSubject: BehaviorSubject<any>;
  public noNumerada: Observable<any>;

  private userName: string;
  private userPass: string;

  public butacasMap: HashTable<string> = {};
  public butacaSeleccionada: boolean;
  public butacasConPrecio = [];
  public butacasDeshabilitadasPorFiltro = [];
  public butacasSeleccionadas = [];
  public butacasOcupadas = [];
  public butacasCombinadas = [];
  public butacasPasillo = [];
  public listaButacasDisponibles = "";
  public estadoButacasInicial = [];

  public leyenda = [];
  public leyendaSubject = new Subject();
  public nombreRecinto: any;
  public showDays: [] = [];

  public idRBCarroOriginal = [];

  public listaIdRecintosNUMERADOS: number[] = [];
  public listaIdRecintosNONUMERADOS: number[] = [];
  public listaRecintosPorSesion = [];
  public filtroPreparado = new BehaviorSubject(false);
  public maxFiltro: number;
  public minFiltro: number;

  public idsSeleccionados: number[] = [];
  public proyecto = this.config.getProyecto();

  public prefijo: string = "";

  public selectedSpecialSeats: Butaca[] = [];
  public specialsRates: RateJsonType;
  public sesiones = [];
  public selectedSeats: Butaca[] = [];
  public seatsNow: Butaca[] = [];
  public seatsNowAdmin = [];
  public realDate: string;
  public realHour: string;

  public isModificar: boolean;
  public addMore: boolean;

  public clic: string = "ClicInternet";
  public mostrar: string = "MostrarInternet";
  public coloresReserva: boolean;
  public c: any;

  public butaMarcadaAdmin = [];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private productosService: ProductosService,
    private espectaculosService: EspectaculosService,
    private paymentService: PaymentService,
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private infoMapasService: InfoMapasService,
    private ventaService: VentaService,
    private toast: ToastrService,
    private config: AppConfiguration,
    private butacasMultiService: ButacasMultiplesService,
    private calendarService: CalendarService
  ) {
    this.findCLient();
    this.findButacas(0);
    this.addMore = false;
    this.espectaculoSubject = new BehaviorSubject(undefined);
    this.espectaculo$ = this.espectaculoSubject.asObservable();
    this.sonButacasSinEspaciosSubject = new BehaviorSubject(true);
    this.sonButacasSinEspacios$ =
      this.sonButacasSinEspaciosSubject.asObservable();

    this.fechaSeleccionadaSubject = new BehaviorSubject(moment());
    this.fechaSeleccionada$ = this.fechaSeleccionadaSubject
      .asObservable()
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));

    this.horaSeleccionadaSubject = new BehaviorSubject(undefined);
    this.horaSeleccionada$ = this.horaSeleccionadaSubject
      .asObservable()
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));

    this.estadosButacasSubject = new BehaviorSubject([]);
    this.estadosButacas = this.estadosButacasSubject.asObservable();

    this.asientosDeshabilitadosSubject = new BehaviorSubject([]);
    this.asientosDeshabilitados =
      this.asientosDeshabilitadosSubject.asObservable();

    this.butacaMarcadaSubject = new BehaviorSubject(undefined);
    this.butacaMarcada = this.butacaMarcadaSubject.asObservable();

    this.butacasNONumeradaMarcadaSubject = new BehaviorSubject(undefined);
    this.butacasNONumeradaMarcada =
      this.butacasNONumeradaMarcadaSubject.asObservable();

    this.noNumeradaSubject = new BehaviorSubject(false);
    this.noNumerada = this.noNumeradaSubject.asObservable();

    if (this.proyecto === "REALESCUELA" || this.proyecto === "ESTEPONAEULEN") {
      this.infoMapasService
        .getRateSeats()
        .subscribe((data) => (this.specialsRates = data));
    }
    this.productosService.showHours$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((hours) => {
        if (hours && hours.length > 0) {
          this.sesiones = [...hours];
        }
      });
  }

  public setEspectaculoValue(espectaculo) {
    this.espectaculoSubject.next(espectaculo);
  }

  public get espectaculoValue() {
    return this.espectaculoSubject.value;
  }

  public setSonButacasSinEspaciosValue(espectaculo) {
    this.sonButacasSinEspaciosSubject.next(espectaculo);
  }

  public get sonButacasSinEspaciosValue() {
    return this.sonButacasSinEspaciosSubject.value;
  }

  public get fechaSeleccionadaValue() {
    return this.fechaSeleccionadaSubject.value;
  }

  setFechaSeleccionadaValue(fecha = moment()) {
    this.fechaSeleccionadaSubject.next(fecha);
  }

  public get horaSeleccionadaValue() {
    return this.horaSeleccionadaSubject.value;
  }

  setHoraSeleccionadaValue(hora = moment()) {
    this.horaSeleccionadaSubject.next(hora);
  }

  //estaba en butacas en token-manager.service.ts
  getEstadosButacasInicial(lang?) {
    const body = {
      Idioma: lang || this.getLocale(),
      Aplicacion: this.getPlataforma(),
    };

    this.http
      .post(`${this.getConfig("API_URL")}api/EstadosButacas`, body)
      .subscribe((data: any) => {
        this.estadoButacasInicial = data.DatosResult.Estados;
      });
  }

  //Obtener los datos del idioma de la aplicación
  getLocale() {
    return this.translate.getLocale();
  }

  //Obtener la plataforma que usamos en la aplicacion
  getPlataforma() {
    return this.productosService.getPlataforma();
  }

  //Obtenemos las variables de entorno
  getConfig(key: any) {
    return environment[key];
  }

  getAPIUrl() {
    return this.getConfig("API_URL") + "api/";
  }

  //añadimos la butaca al carrito
  addProduct(butaca) {
    this.espectaculosService.addProduct(butaca);
  }

  //obtenemos la instancia del servicio para las traducciones
  getTranslate() {
    return this.translate;
  }

  //obtenemos el enlace menu del espectaculo
  getEnlaceMenuId() {
    return this.espectaculoValue.EnlaceMenuId;
    //return this.productosService.getEnlaceMenuId()?this.productosService.getEnlaceMenuId().pkid: null;
  }

  //obtenemos el grupo internet del espectaculo
  getGrupoInternet() {
    return this.espectaculoValue.GrupoInternetId;
    //return this.productosService.datosInitValue?JSON.parse(this.productosService.datosInitValue).gi:null ;
  }

  traerReserva(numeroPedido, localizador, tipo) {
    return this.paymentService.traerReserva(numeroPedido, localizador, tipo);
  }

  getSessionID() {
    return this.configurationService.configurationValue.currentSession
      .sessionId;
  }

  //Yaribel 20230221  Iniciamos codigo para gestion de mapas antes estaba en entradasService

  public get estadosButacasValue() {
    return this.estadosButacasSubject.value;
  }

  public setEstadosButacaValue(estados) {
    this.estadosButacasSubject.next(estados);
  }
  public get asientosDeshabilitadosValue() {
    return this.asientosDeshabilitadosSubject.value;
  }

  public setAsientosDeshablitados(asientos) {
    const uniqueAsientos = Array.from(new Set(asientos));
    this.asientosDeshabilitadosSubject.next(uniqueAsientos);
  }

  public addAsientoDeshabilitado(asiento) {
    const asientos = this.asientosDeshabilitadosValue;
    asientos.push(asiento);
    this.setAsientosDeshablitados(asientos);
  }

  public get butacaMarcadaValue() {
    return this.butacaMarcadaSubject.value;
  }

  public setButacaMarcadaValue(butaca) {
    this.butacaMarcadaSubject.next(butaca);
  }

  public get ButacasNONumeradaMarcadaValue() {
    return this.butacasNONumeradaMarcadaSubject.value;
  }

  public setButacasNONumeradaMarcadaValue(butacas) {
    this.butacasNONumeradaMarcadaSubject.next(butacas);
  }

  DesmarcarTodoCarrito() {
    const body = {
      Accion: "0",
      SesionInternetId: this.getSessionID(),
      RecintosSesionesId: "0",
    };
    return this.http.post(
      `${this.getAPIUrl()}DesamarcarButacasBySesionId`,
      body
    );
  }
  async DesmarcarTodoCarritoPromesa(): Promise<any> {
    const body = {
      Accion: "0",
      SesionInternetId: this.getSessionID(),
      RecintosSesionesId: "0",
    };
    return this.http
      .post(`${this.getAPIUrl()}DesamarcarButacasBySesionId`, body)
      .toPromise();
  }

  MarcarTodoCarrito() {
    //Hacer una busqueda completa de las butacas del carrito y de la tarifa para enviarlas en el marcado
    let marcado = []; // asignamos aqui el objeto { idTarifa: IdTarifa, recintoButacasId: recintoButacaId }
    this.ventaService.carritoValue.compras.forEach((producto) => {
      if (producto.producto.butacas && producto.producto.butacas.length > 0) {
        let butacas = "";
        producto.producto.butacas.forEach((butaca) => {
          butacas = butacas.concat(butaca.idRB);
          butacas = butacas.concat(",");
        });
        butacas = butacas.substring(0, butacas.length - 1);
        let index = marcado.findIndex(
          (x) => x.idTarifa == producto.producto.IdTarifa
        );
        if (index > -1) {
          marcado[index] = {
            idTarifa: producto.producto.IdTarifa,
            RecintoButacaIds: marcado[index].RecintoButacaIds + "," + butacas,
          };
        } else {
          marcado.push({
            idTarifa: producto.producto.IdTarifa,
            RecintoButacaIds: butacas,
          });
        }
      }
    });
    return this.MarcarDesmarcarButacas("1", marcado);
  }

  MarcarDesmarcarButacas(marcar, marcado) {
    const body = {
      Marcar: marcar ? "1" : "0",
      Taquilla: "0",
      Plataforma: this.getPlataforma(), // plataforma,
      SesionInternetId: this.getSessionID(),
      Marcado: marcado,
    };
    return this.http.post(`${this.getAPIUrl()}MarcarDesmarcarButaca`, body);
  }

  httpPostMarcarDesmarcarDesdeCarrito(producto, marcar) {
    let marcado = [];
    if (producto.producto.butacas && producto.producto.butacas.length > 0) {
      let butacas = "";
      producto.producto.butacas.forEach((butaca) => {
        butacas = butacas.concat(butaca.idRB);
        butacas = butacas.concat(",");
      });
      butacas = butacas.substring(0, butacas.length - 1);
      let index = marcado.findIndex(
        (x) => x.idTarifa == producto.producto.IdTarifa
      );
      if (index > -1) {
        marcado[index] = {
          idTarifa: producto.producto.IdTarifa,
          RecintoButacaIds: marcado[index].RecintoButacaIds + "," + butacas,
        };
      } else {
        marcado.push({
          idTarifa: producto.producto.IdTarifa,
          RecintoButacaIds: butacas,
        });
      }
    }

    return this.MarcarDesmarcarButacas(marcar, marcado);
  }

  httpPostMarcarDesmarcarButaca(butaca: any, marcar) {
    let marcado = [
      {
        idtarifa: marcar
          ? butaca.tipoSeleccionado.IdTarifa
          : butaca.tarifas[0].IdTarifa,
        RecintoButacaIds: marcar
          ? butaca.info.infoGeneral.recintoButacaId
          : butaca.PKIDRB,
      },
    ];
    return this.MarcarDesmarcarButacas(marcar, marcado);
  }

  httpPostButacaInfo(butaca: any) {
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId:
        this.butacasMap[
          this.generateId(
            this.getSessionID(),
            butaca.idRecintoButaca,
            this.fechaSeleccionadaValue,
            this.horaSeleccionadaValue
          )
        ],
    };
    return this.http.post(`${this.getAPIUrl()}Butacainfo`, body);
  }

  httpPostButacaInfoSinFecha(butaca: any) {
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId: butaca,
    };
    return this.http.post(`${this.getAPIUrl()}Butacainfo`, body);
  }

  httpPostTodasButacasDetalles(butacas: any, filtroGI: boolean = true) {
    const gruposInternet = this.getGrupoInternet();
    const plataforma = this.getPlataforma();
    const findClientCurrent = JSON.parse(sessionStorage.getItem("cliente"));
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId: butacas,
      GruposInternetId: filtroGI ? gruposInternet : undefined,
      EnlaceMenuId: this.getEnlaceMenuId(),
      Plataforma: plataforma,
      Nombre: findClientCurrent ? findClientCurrent.Email : null,
      Contrasena: findClientCurrent ? findClientCurrent.Pass : null,
    };
    return this.http.post(`${this.getAPIUrl()}ButacaDetalle`, body);
  }

  httpPostRecintosPorSesion() {
    this.findDateSession();
    const gruposInternet = this.getGrupoInternet();
    const plataforma = this.getPlataforma();
    const body = {
      Idioma: this.getLocale(),
      EspectaculoId: this.espectaculoValue.EspectaculosId,
      RecintosId: null,
      FechaSesion: this.realDate
        ? this.realDate
        : this.fechaSeleccionadaValue.format("YYYY/MM/DD"),
      HoraInicioSesion: this.realHour
        ? this.realHour
        : this.horaSeleccionadaValue.format("HH:mm:ss"),
      SesionInternetId: this.getSessionID(),
      GruposInternet: gruposInternet,
      Plataforma: plataforma,
    };
    return this.http.post(`${this.getAPIUrl()}GetTipoButacasPorRecinto`, body);
  }

  httpPostButacaDetalles(butaca: any) {
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId:
        this.butacasMap[
          this.generateId(
            this.getSessionID(),
            butaca.idRecintoButaca,
            this.fechaSeleccionadaValue,
            this.horaSeleccionadaValue
          )
        ],
      Nombre: this.userName ? this.userName : null,
      Contrasena: this.userPass ? this.userPass : null,
      EnlaceMenuId: this.getEnlaceMenuId(),
    };
    return this.http.post(`${this.getAPIUrl()}ButacaDetalle`, body);
  }

  //TODO Yaribel  pendiente de gestionar no numeradas
  getInfoButacaNoNumeradas(butaca) {
    const gruposInternet = this.getGrupoInternet();
    const plataforma = this.getPlataforma();
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId:
        this.butacasMap[
          this.generateId(
            this.getSessionID(),
            butaca.idRecintoButaca,
            this.fechaSeleccionadaValue,
            this.horaSeleccionadaValue
          )
        ],
      GrupoInternet: gruposInternet,
      EnlaceMenuId: this.getEnlaceMenuId(),
      Plataforma: plataforma,
    };
    return this.http.post(`${this.getAPIUrl()}Butacainfo`, body).pipe(
      mergeMap((res: any) =>
        this.http
          .post(`${this.getAPIUrl()}ButacaDetalle`, {
            ...body,
            Nombre: this.userName ? this.userName : null,
            Contrasena: this.userPass ? this.userPass : null,
            GruposInternetId: gruposInternet,
          })
          .pipe(
            map((detalles: any) => {
              const infoButaca = {
                infoGeneral: res.DatosResult[0],
                tiposDeEntrada: detalles.DatosResult,
              };
              return infoButaca;
            })
          )
      )
    );
  }

  getInfoButaca(butaca) {
    const gruposInternet = this.getGrupoInternet();
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId:
        this.butacasMap[
          this.generateId(
            this.getSessionID(),
            butaca.idRecintoButaca,
            this.fechaSeleccionadaValue,
            this.horaSeleccionadaValue
          )
        ],
      GrupoInternet: gruposInternet,
    };
    return this.http.post(`${this.getAPIUrl()}Butacainfo`, body).pipe(
      mergeMap((res: any) => {
        const infoButaca = this.butacasConPrecio.map((item) => {
          const iButaca = {
            infoGeneral: res.DatosResult[0],
            tiposDeEntrada: item.tarifas,
          };
          return iButaca;
        });
        return infoButaca;
      })
    );
  }

  getInfoButacaClick(butaca) {
    const gruposInternet = this.getGrupoInternet();
    const body = {
      Idioma: this.getLocale(),
      RecintosButacasId:
        this.butacasMap[
          this.generateId(
            this.getSessionID(),
            butaca.idRecintoButaca,
            this.fechaSeleccionadaValue,
            this.horaSeleccionadaValue
          )
        ],
      GrupoInternet: gruposInternet,
    };
    return this.http.post(`${this.getAPIUrl()}Butacainfo`, body).pipe(
      map((res: any) => {
        const iButaca = {
          infoGeneral: res.DatosResult[0],
          // tiposDeEntrada: infoButaca[0].tarifas
        };

        return iButaca;
      })
    );
  }

  getButacaTarifasEstado(PKIDRB: string) {
    const gruposInternet = this.getGrupoInternet();

    const body = {
      Idioma: this.getLocale(),
      RecintoButacaId: PKIDRB,
      EnlaceMenuId: this.getEnlaceMenuId(),
      GrupoInternetId: gruposInternet,
    };
    return this.http.post(`${this.getAPIUrl()}ButacaTarifasEstado`, body).pipe(
      map((res: any) => {
        return res.DatosResult;
      })
    );
  }

  getButacasMarcadasPorSesion() {
    const body = {
      SesionInternetId: this.getSessionID(),
    };
    return this.http
      .post(`${this.getAPIUrl()}GetEspectaculosMarcacion`, body)
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            return res.DatosResult;
          }
          return [];
        })
      );
  }

  httpPostEstadosButacasPorSesion() {
    this.findDateSession();
    const body = {
      Idioma: this.getLocale(),
      EspectaculoId: this.espectaculoValue.EspectaculosId,
      RecintosId: "",
      RDGIds: "",
      FechaSesion: this.realDate
        ? this.realDate
        : this.fechaSeleccionadaValue.format("YYYY/MM/DD"),
      HoraInicioSesion: this.realHour
        ? this.realHour
        : this.horaSeleccionadaValue.format("HH:mm:ss"),
      SesionesInternetId: this.getSessionID(),
    };

    return this.http.post(`${this.getAPIUrl()}EstadosButacasPorSesion`, body);
  }
  //TODO Yaribel 20230308 pendiente de gestionar no numeradas
  marcarNoNumeradas(
    cantidad,
    marcadas,
    marcar,
    recinto = 0,
    idEspectaculoAsociado,
    fecha,
    hora,
    tipoButaca
  ) {
    let plataforma = this.getPlataforma();
    const body = {
      RecintoId: recinto,
      EspectaculoId: idEspectaculoAsociado,
      FechaEspectaculo: fecha.format("YYYY/MM/DD"),
      HoraEspectaculo: hora.format("HH:mm:ss"),
      Marcar: marcar ? 1 : 0,
      Taquilla: "0",
      Plataforma: plataforma,
      Cantidad: cantidad,
      TipoButaca: tipoButaca,
      SesionInternetId: this.getSessionID(),
      Marcado: marcadas,
    };
    return this.http
      .post(`${this.getAPIUrl()}MarcarDesmarcarNoNumerada`, body)
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }
          return res.DatosResult;
        })
      );
  }

  generateId(sessionId, svgId, fecha, hora) {
    return sessionId + svgId + fecha.valueOf() + hora.valueOf();
  }

  getStatusButacaById(id: any) {
    for (let i = 0; i < this.estadosButacasValue.length; ++i) {
      const status = this.estadosButacasValue[i].Estado;
      const correctStatus = this.estadosButacasValue[i].IdsRelacion.find(
        function (findId: any) {
          return findId.IdSvg === id;
        }
      );
      if (correctStatus) {
        return status;
      }
    }
    return 3;
  }

  private findCLient = (): void => {
    if (sessionStorage.getItem("cliente")) {
      const client = JSON.parse(sessionStorage.getItem("cliente"));
      //manieva el username debe ser un correo
      //this.userName = client["NombreCliente"];
      this.userName = client["Nombre"];
      this.userPass = client["Pass"];
    } else {
      this.userName = "";
      this.userPass = "";
    }
  };

  public findButacas = (position: number): void => {
    if (sessionStorage.getItem("seats")) {
      if (!sessionStorage.getItem("cesta")) {
        sessionStorage.removeItem("seats");
        return;
      } else {
        let basket = JSON.parse(sessionStorage.getItem("cesta"));
        if (basket.compras.length === 0) {
          sessionStorage.removeItem("seats");
          return;
        } else {
          let seats = JSON.parse(sessionStorage.getItem("seats"));
          basket.compras.forEach((buy) => {
            if (buy.producto.butacas && buy.producto.butacas.length > 0) {
              buy.producto.butacas.forEach((item) => {
                let index = seats.findIndex(
                  (seat) => seat.PKIDRB === item.idRB
                );
                let newSeats = [...seats];
                newSeats[index] = {
                  ...newSeats[index],
                  posicionVenta: buy.posicionVenta,
                };
                seats = newSeats;
              });
            }
          });
          this.selectedSeats = seats.filter((seat) => !!seat.posicionVenta);
          this.selectedSpecialSeats = this.selectedSeats.filter(
            (item) => item.posicionVenta === position
          );
        }
      }
    }
  };

  public findDateSession = () => {
    const position = this.getPosicionVenta();
    if (this.modificarValue() && position > 0) {
      if (sessionStorage.getItem("cesta")) {
        let basket = JSON.parse(sessionStorage.getItem("cesta"));
        if (basket.compras.length > 0) {
          const buyPosition = basket.compras.find(
            (pos) => pos.posicionVenta === position
          );
          /**
           * Validate if user is not admin and configure the date
           */
          if (
            buyPosition &&
            buyPosition.producto.butacas &&
            buyPosition.producto.butacas.length > 0 &&
            !sessionStorage.getItem("admin")
          ) {
            const selectedDate =
              this.fechaSeleccionadaValue.format("YYYY/MM/DD");
            const selectedHour = this.horaSeleccionadaValue.format("HH:mm:ss");
            const timestamp = Date.parse(selectedDate + " " + selectedHour);
            this.realDate = moment(timestamp).format("YYYY/MM/DD");
            this.realHour = moment(timestamp).format("HH:mm:ss");
          }

          /**
           * Validate if user is admin and configure the date
           */
          if (
            buyPosition &&
            buyPosition.producto.butacas &&
            buyPosition.producto.butacas.length > 0 &&
            sessionStorage.getItem("admin")
          ) {
            const selectedDate = buyPosition.producto.butacas[0].FechaSesion;
            const selectedHour = buyPosition.producto.butacas[0].HoraSesion;
            const timestamp = Date.parse(selectedDate + " " + selectedHour);
            this.realDate = moment(timestamp).format("YYYY/MM/DD");
            this.realHour = moment(timestamp).format("HH:mm:ss");
          }
        }
      }
    } else {
      if (!this.fechaSeleccionadaValue || !this.horaSeleccionadaValue) {
        if (this.sesiones.length > 0) {
          this.realDate = this.sesiones[0].diaSinHora;
          this.realHour = this.sesiones[0].horaSinDia;
          this.realHour = this.realHour + ":00";
        }
      } else {
        if (this.sesiones.length > 0) {
          const dateSel = this.fechaSeleccionadaValue.format("DD/MM/YYYY");
          const horaSel = this.horaSeleccionadaValue.format("HH:mm");
          const exist = this.sesiones.find(
            (item) => item.diaSinHora === dateSel && item.horaSinDia === horaSel
          );
          if (exist) {
            this.realDate = exist["diaSinHora"];
            this.realHour = exist["horaSinDia"];
            this.realHour = this.realHour + ":00";
          } else {
            this.realDate = this.sesiones[0].diaSinHora;
            this.realHour = this.sesiones[0].horaSinDia;
            this.realHour = this.realHour + ":00";
          }
        }
      }
    }
    if (this.realDate && this.realHour) {
      let arrDate = this.realDate.split("/");
      this.realDate =
        arrDate[0].length == 2
          ? arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0]
          : this.realDate;
    }
  };

  sendProductoModificar(producto) {
    this.espectaculosService.sendProductoModificar(producto);
  }

  //TODO Yaribel  pendiente de gestionar no numeradas
  clicRecintosNoNumerados(recintosNoNumerados: number) {
    const id = recintosNoNumerados;
    const listaEstadosDisponibles: number[] = [];
    const listaTiposButaca: number[] = [];
    const butacasMarcadasNONumeradas: any[] = [];
    let clic = this.getPlataforma() === "4" ? "ClicReserva" : "ClicInternet";
    const butacasPorRecinto = this.butacasConPrecio.filter((butaca) => {
      return butaca.dataIdRecinto === id;
    });

    this.estadoButacasInicial.forEach((item) => {
      if (+item[clic] === 1) {
        listaEstadosDisponibles.push(item.EstadoButaca);
      }
    });

    this.getInfoButacaClick(butacasPorRecinto[0]).subscribe(
      (infoButaca: any) => {
        if (infoButaca != null && infoButaca != undefined) {
          infoButaca.infoGeneral.recintoButacaId = "";
          infoButaca.infoGeneral.acceso = "";
        }

        butacasPorRecinto.forEach((butaca) => {
          const status = this.getStatusButacaById(butaca.idRecintoButaca);
          if (
            !listaTiposButaca.includes(+butaca["data-tipo-butaca"]) &&
            listaEstadosDisponibles.includes(status)
          ) {
            butaca.info = infoButaca;
            const nombreTipo = this.estadoButacasInicial.filter(
              (item) =>
                +item.EstadoButaca === +status &&
                +item.TipoButaca === +butaca["data-tipo-butaca"]
            );
            if (nombreTipo != undefined && nombreTipo.length > 0) {
              butaca.tarifas.forEach((element) => {
                element.NombreTipo = nombreTipo[0].Descripcion;
              });
            }
            listaTiposButaca.push(+butaca["data-tipo-butaca"]);
            butacasMarcadasNONumeradas.push(butaca);
          }
        });

        this.datosButacaActualizados.next(true);

        this.setButacasNONumeradaMarcadaValue(butacasMarcadasNONumeradas);

        this.modalService.open("selectorNONumerado");
      }
    );
  }

  public getColorButaca(
    butaca,
    noNumerada: boolean,
    modoReserva: boolean
  ): string {
    const status =
      butaca.estado || this.getStatusButacaById(butaca.idRecintoButaca);
    const clic = modoReserva ? "ClicReserva" : "ClicInternet";
    const mostrar = modoReserva ? "MostrarReserva" : "MostrarInternet";
    const returnColor = "#000000";
    // if (itemEstado.Color !== "#008800" && itemEstado.Color !== "#008888" && itemEstado.Color !== "#ff751a") {
    //   console.log('el color que busco', itemEstado.Color);
    // }
    if (
      sessionStorage.getItem("admin") &&
      this.modificarValue() === true &&
      sessionStorage.getItem("reservaOriginal")
    ) {
      const arrExistInReservation = [];
      const reservationOriginal = JSON.parse(
        sessionStorage.getItem("reservaOriginal")
      );
      reservationOriginal.compras.forEach((producto) => {
        if (
          +producto.posicionVenta ===
          +this.espectaculosService.posicionVentaValue
        ) {
          producto.butacas.forEach((but) => {
            if (+but.idRB === +butaca.PKIDRB) {
              arrExistInReservation.push(but);
            }
          });
        }
      });

      const findInitialStateBySeat = this.estadoButacasInicial.find(
        (item) =>
          Number(item.EstadoButaca) === Number(status) &&
          butaca["data-tipo-butaca"] === item.TipoButaca
      );

      if (arrExistInReservation.length === 0 && findInitialStateBySeat) {
        if (!noNumerada) {
          this.addColorLeyendaSiNoExiste(findInitialStateBySeat, butaca);
        }
        if (
          +findInitialStateBySeat[clic] === 0 ||
          butaca.tarifas.length === 0
        ) {
          this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
          this.addColorLeyendaSiNoExiste(findInitialStateBySeat, butaca);
        }
        return findInitialStateBySeat.Color;
      }

      if (arrExistInReservation.length > 0 && findInitialStateBySeat) {
        const searchTerm = [
          "General",
          "Persona con movilidad reducida y silla de ruedas",
          "Acompañante",
          "Preferencia",
        ];

        if (butaca.tarifas.length > 1) {
          const findGeneral = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[0].toLowerCase()
            )
          );
          const pcmrsr = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[1].toLowerCase()
            )
          );
          const companion = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[2].toLowerCase()
            )
          );
          const preference = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[3].toLowerCase()
            )
          );
          if (findGeneral.length > 0) {
            return "#008800";
          }
          if (pcmrsr.length > 0 || companion.length > 0) {
            return "#ff751a";
          }
          if (preference.length > 0) {
            return "#008888";
          }
        }
        if (butaca.tarifas.length === 1) {
          const findGeneral = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[0].toLowerCase()
            )
          );
          const pcmrsr = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[1].toLowerCase()
            )
          );
          const companion = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[2].toLowerCase()
            )
          );
          const preference = butaca.tarifas.filter((item) =>
            item.NombreProducto.toLowerCase().includes(
              searchTerm[3].toLowerCase()
            )
          );
          if (findGeneral.length > 0) {
            return "#008800";
          }
          if (pcmrsr.length > 0 || companion.length > 0) {
            return "#ff751a";
          }
          if (preference.length > 0) {
            return "#008888";
          }
        }
      }
      // return findInitialStateBySeat.Color;
    } else {
      for (
        let indexEstado = 0;
        indexEstado < this.estadoButacasInicial.length;
        indexEstado++
      ) {
        const itemEstado = this.estadoButacasInicial[indexEstado];

        if (
          Number(status) === Number(itemEstado.EstadoButaca) &&
          butaca["data-tipo-butaca"] === itemEstado.TipoButaca
        ) {
          if (!noNumerada) {
            this.addColorLeyendaSiNoExiste(itemEstado, butaca);
          }
          if (+itemEstado[clic] === 0 || butaca.tarifas.length === 0) {
            this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
            this.addColorLeyendaSiNoExiste(itemEstado, butaca);
          }

          return itemEstado.Color;
        }
      }
      const ocupada = this.estadoButacasInicial.filter(
        (e) => +e[clic] === 0 && +e[mostrar] === 1
      );
      if (ocupada.length > 0) {
        this.addColorLeyendaSiNoExiste(ocupada[0], butaca);

        this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
        return ocupada[0].Color;
      }
      this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
      return returnColor;
    }
  }

  private addColorLeyendaSiNoExiste(itemEstado, butaca) {
    if (this.leyenda.find((x) => x.Color === itemEstado.Color) == undefined) {
      this.leyenda.push({
        Descripcion: itemEstado.Descripcion,
        Color: itemEstado.Color,
      });
    }
  }

  mapaFiltradoPorPrecio(recinto, min, max) {
    let coloresReserva = this.getPlataforma() === "4" ? true : false;
    this.butacasDeshabilitadasPorFiltro = [];
    recinto.selectAll("circle").remove();
    this.butacasConPrecio.forEach((butaca) => {
      this.butacaSeleccionada = false;

      if (this.espectaculosService.comprobarSiButacaAgregada(butaca)) {
        this.butacaSeleccionada = true;
      }
      const esNoNumerada = this.listaIdRecintosNONUMERADOS.includes(
        butaca.dataIdRecinto
      );

      const c = recinto
        .append("circle")
        .attr("_pkid", butaca.idRecintoButaca)
        .style(
          "fill",
          this.butacaSeleccionada
            ? "rgb(0,0,255)"
            : this.getColorButaca(butaca, esNoNumerada, coloresReserva)
        )

        .attr("cx", butaca.cx)
        .attr("cy", butaca.cy)
        .attr("r", butaca.r)
        .style("display", () => {
          return esNoNumerada ? "none" : "block";
        })
        .on("click", () => {
          if (
            this.butacasDeshabilitadasPorFiltro.includes(butaca.idRecintoButaca)
          ) {
            return;
          }

          if (this.espectaculosService.comprobarSiButacaAgregada(butaca)) {
            const b = this.espectaculosService.existeButaca(butaca);
            this.httpPostMarcarDesmarcarButaca(butaca, false).subscribe(() => {
              this.eliminarButacaDesdeMapa(butaca, b);
              c.style("fill-opacity", 1);
              const butacaDom = document.querySelector(
                "circle[_PKIDRB='" + butaca.PKIDRB + "']"
              );
              butacaDom.classList.remove("marcada");
              return;
            });
          }
          if (this.comprobarDisponibilidadButaca(butaca)) {
            this.getInfoButaca(butaca).subscribe((infoButaca: any) => {
              butaca.info = infoButaca;
              this.setButacaMarcadaValue(butaca);
              this.modalService.open("selector");
            });
          }
        });
    });
  }

  public findSpecialRates = (butaca: Butaca) => {
    if (!this.specialsRates) {
      return;
    }
    const enlaceMenu = butaca["EnlaceMenuId"]
      ? butaca["EnlaceMenuId"]
      : this.getEnlaceMenuId();
    const iGroup = butaca["grupoInternet"]
      ? butaca["grupoInternet"]
      : this.getGrupoInternet();
    let selectTeacher: TeacherSeatType;
    let selectDisabled: DisabledSeatType;
    let idTeacher: string;
    let iTotalSeleccionadas: string;
    let iMaxDisponible: string;
    let idDisabled: string;
    let idDependiente: string;

    if (butaca.tipoSeleccionado) {
      selectTeacher = this.specialsRates.EnlaceMenu.find(
        (item) =>
          item.idTE === butaca.tipoSeleccionado.ProductoId &&
          item.EnlaceMenu === enlaceMenu
      );

      selectDisabled = this.specialsRates.TipoEntrada.find(
        (item) =>
          item.iGrupoInternetId === iGroup &&
          item.IdDependiente === butaca.tipoSeleccionado.ProductoId
      );
    }
    const dataTeacher = this.specialsRates.EnlaceMenu.find(
      (item) => item.EnlaceMenu === enlaceMenu
    );

    if (dataTeacher) {
      idTeacher = dataTeacher.idTE;
      iTotalSeleccionadas = dataTeacher.iTotalSeleccionadas;
      iMaxDisponible = dataTeacher.iMaxDisponible;
    }

    const dataDisabled = this.specialsRates.TipoEntrada.find(
      (item) => item.iGrupoInternetId === iGroup
    );

    if (dataDisabled) {
      idDisabled = dataDisabled.Id;
      idDependiente = dataDisabled.IdDependiente;
    }

    return {
      selectTeacher,
      selectDisabled,
      idTeacher,
      iTotalSeleccionadas,
      iMaxDisponible,
      idDisabled,
      idDependiente,
    };
  };

  private calculateMaxTeacher = (butaca) => {
    const { idTeacher, iMaxDisponible, iTotalSeleccionadas } =
      this.findSpecialRates(butaca);
    let numGeneral: number = 0;
    let numTeacher: number = 0;
    let maximo: number = 0;

    if (this.selectedSpecialSeats.length > 0) {
      this.selectedSpecialSeats.forEach((seat) => {
        if (seat?.tipoSeleccionado?.ProductoId !== idTeacher) {
          numGeneral++;
        } else {
          numTeacher++;
        }
      });
    }
    maximo = Math.floor((numGeneral / +iTotalSeleccionadas) * +iMaxDisponible);
    return { maximo, numTeacher };
  };

  private calculateMaxAssistant = (butaca) => {
    const { idDisabled, idDependiente } = this.findSpecialRates(butaca);
    let numDisabled: number = 0;
    let numAssistant: number = 0;

    if (this.selectedSpecialSeats.length > 0) {
      this.selectedSpecialSeats.forEach((butaca) => {
        if (butaca?.tipoSeleccionado?.ProductoId === idDisabled) {
          numDisabled++;
        } else if (butaca?.tipoSeleccionado?.ProductoId === idDependiente) {
          numAssistant++;
        }
      });
    }

    return { numDisabled, numAssistant };
  };

  public checkSpecialRates = (butaca: Butaca): Butaca => {
    const { selectTeacher, selectDisabled } = this.findSpecialRates(butaca);
    let result: number = 0;
    if (selectTeacher) {
      if (
        this.selectedSpecialSeats.length >= +selectTeacher.iTotalSeleccionadas
      ) {
        const { maximo } = this.calculateMaxTeacher(butaca);
        result = maximo;
      }
      butaca.tipoSeleccionado.MaximoEspecial = result.toString();
    }

    if (selectDisabled) {
      const { numDisabled } = this.calculateMaxAssistant(butaca);
      result = numDisabled;
      butaca.tipoSeleccionado.MaximoEspecial = result.toString();
    }
    return butaca;
  };

  public verifyQuantityStudentsByteacher = (butaca: Butaca) => {
    const { selectTeacher } = this.findSpecialRates(butaca);
    const { teacherAtReservation } =
      this.verifyifExistAteacherInReservationOriginal(butaca, selectTeacher);
    if (teacherAtReservation.length > 0) {
      return false;
    }
    // if (this.selectedSpecialSeats.length === 1 && selectTeacher) {
    //   console.log("llega aqui 2");
    //   return false;
    // }

    if (selectTeacher && selectTeacher !== undefined) {
      const verifyQuantity = this.ventaService.compraValue.productos.filter(
        (item) => +item.producto.productoId !== +selectTeacher
      );
      if (+verifyQuantity[0].cantidad < +selectTeacher.iTotalSeleccionadas) {
        console.log("llego 1");
        this.toast.info(this.getTranslate().data.CHECK_STUDENT, "Info", {
          timeOut: 5000,
          positionClass: "toast-top-center",
        });
        return true;
      }
      const filteredOnlyStudents = this.selectedSpecialSeats.filter(
        (item) =>
          +item.tipoSeleccionado.ProductoId !==
          +butaca.tipoSeleccionado.ProductoId
      );

      // if (
      //   +filteredOnlyStudents.length < +selectTeacher.iTotalSeleccionadas &&
      //   teacherAtReservation.length === 0
      // ) {
      //   console.log("llego 2");
      //   this.toast.info(this.getTranslate().data.CHECK_STUDENT, "Info", {
      //     timeOut: 5000,
      //     positionClass: "toast-top-center",
      //   });
      //   return true;
      // }
      if (
        +this.selectedSpecialSeats.length > +selectTeacher.iTotalSeleccionadas
      ) {
        const sum = +selectTeacher.iTotalSeleccionadas + 25;
        selectTeacher.iTotalSeleccionadas = `${sum}`;
      }
    }
    return false;
  };

  private verifyifExistAteacherInReservationOriginal = (
    butaca: Butaca,
    selecteacher
  ) => {
    const teacherAtReservation = [];
    const studentsAtReservation = [];
    const findInitialReservation = JSON.parse(
      sessionStorage.getItem("reservaOriginal")
    );
    if (findInitialReservation) {
      findInitialReservation.compras.forEach((purchase) => {
        if (
          +purchase.posicionVenta ===
          +this.espectaculosService.posicionVentaValue
        ) {
          if (
            selecteacher &&
            +purchase.producto.ProductoId === +selecteacher.idTE
          ) {
            purchase.butacas.forEach((but) => {
              if (+but.idRB === +butaca.PKIDRB) {
                teacherAtReservation.push(but);
              }
            });
          } else {
            purchase.butacas.forEach((but) => {
              if (+but.idRB === +butaca.PKIDRB) {
                studentsAtReservation.push(but);
              }
            });
          }
        }
      });
    }
    console.log("teacherAtReservation", teacherAtReservation);
    return { teacherAtReservation, studentsAtReservation };
  };

  public uncheckSpecialRates = (butaca: Butaca): boolean => {
    const {
      idTeacher,
      idDependiente,
      idDisabled,
      selectTeacher,
      selectDisabled,
    } = this.findSpecialRates(butaca);
    if (
      sessionStorage.getItem("admin") &&
      sessionStorage.getItem("reservaOriginal") &&
      !butaca.tipoSeleccionado
    ) {
      // console.log("id del teacher", idTeacher);
      // console.log("selectTeacher", selectTeacher);
      const { disableds, idDependents, idsTeachers } =
        this.uncheckSpecialRatesForAdmin(
          butaca,
          idDependiente,
          idDisabled,
          idTeacher
        );
      if (disableds.length > 0 && this.seatsNowAdmin.length === 0) {
        this.toast.info(this.getTranslate().data.UNCHECK_ASISTENTE, "Info", {
          timeOut: 5000,
          positionClass: "toast-top-center",
        });
        return false;
      }
      // console.log("idsTeachers", idsTeachers);
      if (
        idsTeachers.length === 0 &&
        this.seatsNowAdmin.length === 0 &&
        butaca?.tipoSeleccionado?.ProductoId !== idTeacher
      ) {
        this.toast.info(this.getTranslate().data.UNCHECK_PROFESOR, "Info", {
          timeOut: 5000,
          positionClass: "toast-top-center",
        });
        return false;
      }
      if (idDependents.length > 0) {
        this.seatsNowAdmin = idDependents;
      }
      if (idsTeachers.length > 0) {
        this.seatsNowAdmin = idsTeachers;
      }
    }
    const teachers = this.selectedSpecialSeats.filter(
      (item) => item?.tipoSeleccionado?.ProductoId === idTeacher
    );
    const assitants = this.selectedSpecialSeats.filter(
      (item) => item?.tipoSeleccionado?.ProductoId === idDependiente
    );
    const seatBackup = this.selectedSeats.find(
      (item) => item?.PKIDRB === butaca.PKIDRB
    );
    this.selectedSpecialSeats = this.selectedSpecialSeats.filter(
      (item) => item?.PKIDRB !== butaca.PKIDRB
    );
    this.selectedSeats = this.selectedSeats.filter(
      (item) => item?.PKIDRB !== butaca.PKIDRB
    );
    this.seatsNow = this.seatsNow.filter(
      (item) => item?.PKIDRB !== butaca.PKIDRB
    );

    if (teachers.length > 0) {
      const { maximo, numTeacher } = this.calculateMaxTeacher(butaca);
      if (numTeacher > maximo) {
        this.selectedSpecialSeats.push(seatBackup);
        this.selectedSeats.push(seatBackup);
        this.toast.info(this.getTranslate().data.UNCHECK_PROFESOR, "Info", {
          timeOut: 5000,
          positionClass: "toast-top-center",
        });
        return false;
      }
    }

    if (assitants.length > 0) {
      const { numDisabled, numAssistant } = this.calculateMaxAssistant(butaca);

      if (numDisabled < numAssistant) {
        this.selectedSpecialSeats.push(seatBackup);
        this.selectedSeats.push(seatBackup);
        this.toast.info(this.getTranslate().data.UNCHECK_ASISTENTE, "Info", {
          timeOut: 5000,
          positionClass: "toast-top-center",
        });
        return false;
      }
    }
    return true;
  };

  private uncheckSpecialRatesForAdmin = (
    butaca,
    idDependiente,
    idDisabled,
    idTeacher
  ): any => {
    const reservationOriginal = JSON.parse(
      sessionStorage.getItem("reservaOriginal")
    ).compras;
    const disableds = [];
    const idDependents = [];
    const idsTeachers = [];
    // console.log("butaca", butaca);

    reservationOriginal.forEach((purchase) => {
      if (
        +purchase.posicionVenta === +this.espectaculosService.posicionVentaValue
      ) {
        if (+purchase.producto.ProductoId === +idDisabled) {
          purchase.butacas.forEach((but) => {
            if (+but.idRB === +butaca.PKIDRB) {
              disableds.push(but);
            }
          });
        }

        if (+purchase.producto.ProductoId === +idDependiente) {
          purchase.butacas.forEach((but) => {
            if (+but.idRB === +butaca.PKIDRB) {
              idDependents.push(but);
            }
          });
        }

        if (+purchase.producto.ProductoId === +idTeacher) {
          purchase.butacas.forEach((but) => {
            if (+but.idRB === +butaca.PKIDRB) {
              idsTeachers.push(but);
            }
          });
        }
      }
    });

    return { disableds, idDependents, idsTeachers };
  };

  private async comprobarDisponibilidadButaca(butaca: Butaca) {
    const idsSeleccionados = this.espectaculosService.butacasSeleccionadas(); //Yaribel 20230224 obtenemos las butacas que tenemos ya en el carrito da igual la tarifa

    const minButaca =
      Math.min(...idsSeleccionados) - 1 === +butaca.idRecintoButaca;
    const maxButaca =
      Math.max(...idsSeleccionados) + 1 === +butaca.idRecintoButaca;

    if (
      this.modificarValue() === true &&
      sessionStorage.getItem("admin") &&
      sessionStorage.getItem("reservaOriginal")
    ) {
      let modficacionReserva = false;
      const initialReservation = JSON.parse(
        sessionStorage.getItem("reservaOriginal")
      ).compras;
      const seatsFound: Array<any> = [];
      const purchasePosition = this.espectaculosService.posicionVentaValue;

      const findPurchasePositionInProductsByGroup = initialReservation.filter(
        (res) => {
          if (+res.posicionVenta === +purchasePosition) {
            return res;
          }
        }
      );

      if (
        findPurchasePositionInProductsByGroup.length > 0 &&
        findPurchasePositionInProductsByGroup[0] == undefined
      ) {
        /** Aplica para cuando añades mas actividades siendo admin y no hay reservas */
        modficacionReserva = this.compraReservaInicial().some(
          (e) => +e.idRB === +butaca.PKIDRB
        );
      } else {
        findPurchasePositionInProductsByGroup.forEach((product) => {
          product.butacas.forEach((seat) => {
            if (+seat.idRB === +butaca.PKIDRB) {
              seatsFound.push(butaca);
            }
          });
        });

        if (seatsFound.length >= 1) {
          modficacionReserva = true;
        }
      }

      if (
        !modficacionReserva &&
        this.espectaculosService.comprobarSiButacaAgregada(butaca)
      ) {
        return false;
      }
      if (
        !modficacionReserva &&
        this.asientosDeshabilitadosValue.includes(+butaca.idRecintoButaca)
      ) {
        this.toast.info(
          this.getTranslate().data.ERR_BUTACA_NO_DISPONIBLE,
          "Info",
          {
            timeOut: 5000,
            positionClass: "toast-top-center",
          }
        );
        return false;
      }
      return true;
    } else {
      let modficacionReserva = false;
      if (this.modificarValue() === true) {
        modficacionReserva = this.espectaculosService
          .compraReservaInicial()
          .some((e) => +e.idRB === +butaca.PKIDRB);
      }
      if (
        !modficacionReserva &&
        this.espectaculosService.comprobarSiButacaAgregada(butaca)
      ) {
        return false;
      }
      if (
        !modficacionReserva &&
        this.asientosDeshabilitadosValue.includes(+butaca.idRecintoButaca)
      ) {
        this.toast.info(
          this.getTranslate().data.ERR_BUTACA_NO_DISPONIBLE,
          "Info",
          {
            timeOut: 5000,
            positionClass: "toast-top-center",
          }
        );
        return false;
      }
      return true;
    }
  }

  async getReservationCurrent() {
    const reservations = await this.traerReserva(
      this.ventaService.modificandoReservaValue,
      "",
      1
    ).toPromise();
    const productsByGroup = reservations.ProductosCompraPorGrupo;
    const purchasePosition = this.espectaculosService.posicionVentaValue;
    const findPurchasePositionInProductsByGroup =
      productsByGroup[purchasePosition];
    return findPurchasePositionInProductsByGroup;
  }

  ComprobarNoDejarButacasLibres() {
    if (this.productosService.espectaculoValue.ValidarAsientosVacios === 1) {
      this.butacasCombinadas = this.butacasOcupadas.concat(
        this.butacasSeleccionadas
      );
      this.butacasCombinadas.sort((n1, n2) => n1 - n2);
      this.butacasPasillo.sort((n1, n2) => n1 - n2);
      if (this.butacasSeleccionadas.length >= 1) {
        let valorSeleccionada = "N";
        this.butacasSeleccionadas.sort((n1, n2) => n1 - n2);
        for (let i = 0; i < this.butacasSeleccionadas.length; i++) {
          if (
            this.butacasSeleccionadas[i + 1] - this.butacasSeleccionadas[i] ===
            2
          ) {
            if (
              !this.butacasOcupadas.includes(this.butacasSeleccionadas[i] + 1)
            ) {
              valorSeleccionada = "S";
            }
            if (
              !this.listaButacasDisponibles.includes(
                (this.butacasSeleccionadas[i + 1] - 1).toString()
              )
            ) {
              valorSeleccionada = "N";
            }
          }
        }
        if (valorSeleccionada === "N") {
          for (let i = 0; i < this.butacasCombinadas.length; i++) {
            if (
              this.butacasCombinadas[i + 1] - this.butacasCombinadas[i] ===
              2
            ) {
              if (
                !this.butacasPasillo.includes(this.butacasCombinadas[i] + 1)
              ) {
                if (
                  (this.butacasOcupadas.includes(this.butacasCombinadas[i]) &&
                    this.butacasOcupadas.includes(
                      this.butacasCombinadas[i + 1]
                    )) === false
                ) {
                  valorSeleccionada = "S";
                }
                if (
                  !this.listaButacasDisponibles.includes(
                    (this.butacasCombinadas[i + 1] - 1).toString()
                  )
                ) {
                  valorSeleccionada = "N";
                }
              }
            }
          }

          for (let i = 0; i < this.butacasSeleccionadas.length; i++) {
            for (let j = 0; j < this.butacasPasillo.length; j++) {
              if (!this.butacasPasillo.includes(this.butacasSeleccionadas[i])) {
                if (
                  Math.abs(
                    this.butacasSeleccionadas[i] - this.butacasPasillo[j]
                  ) === 1
                ) {
                  if (
                    !this.butacasCombinadas.includes(this.butacasPasillo[j])
                  ) {
                    valorSeleccionada = "S";
                  }
                }
              }
            }
          }
        }
        if (valorSeleccionada === "S") {
          this.setSonButacasSinEspaciosValue(false);
        } else {
          this.setSonButacasSinEspaciosValue(true);
        }
      } else {
        this.setSonButacasSinEspaciosValue(true);
      }
    }
  }

  /**
   * @description Get the states of the seats
   * @param {any} recinto - description of parameter
   * @param {any} nombreRecinto - description of parameter
   * @return {void}
   */
  getEstadosButacas(recinto: any, nombreRecinto: any) {
    this.findCLient();
    this.listaIdRecintosNONUMERADOS = [];
    this.listaIdRecintosNUMERADOS = [];

    /**
     * //NOTE: This is for admin mode, when the cart is empty and session user current has as ADMIN role, we need to send the products to the sendProductoModificar() function
     *
     */
    if (
      sessionStorage.getItem("admin") &&
      this.ventaService.compraValue.productos.length === 0 &&
      this.modificarValue() === true
    ) {
      const cartReservationNow = this.ventaService.validaCesta();
      const refined = cartReservationNow[0];
      if (cartReservationNow.length > 0) {
        refined.forEach((producto) => {
          this.ventaService.sendProductoModificar(producto);
        });
      }
    }

    let idRBCarroOriginalMarcadosMod = [];
    let idRBCarroOriginalMarcados = [];
    this.idRBCarroOriginal = [];

    if (this.getPlataforma() === "4") {
      this.coloresReserva = true;
      this.clic = "ClicReserva";
      this.mostrar = "MostrarReserva";
    }
    if (this.modificarValue()) {
      const position = this.getPosicionVenta();
      this.findButacas(position);
      this.idRBCarroOriginal =
        this.espectaculosService.idsCompraReservaInicial();
      idRBCarroOriginalMarcadosMod =
        this.espectaculosService.idsCompraReservaTodos();
      this.idRBCarroOriginal.forEach((miButaca) => {
        let miButaca2 = miButaca;
        let ocupadaButIndex = idRBCarroOriginalMarcadosMod.findIndex(
          (f) => f == miButaca2
        );
        idRBCarroOriginalMarcadosMod.splice(ocupadaButIndex, 1);
      });
      idRBCarroOriginalMarcados = idRBCarroOriginalMarcadosMod;
      this.butacasSeleccionadas = this.idRBCarroOriginal;
      this.setSonButacasSinEspaciosValue(true);
    } else {
      idRBCarroOriginalMarcados =
        this.espectaculosService.idsCompraReservaTodos();
    }

    this.datosButacaActualizados.next(false);
    this.asientosDeshabilitadosSubject.next([]);

    //se hace la primera llamada a butacas por sesion para traer todas las butacas para la sesion indicada

    this.postEstadosButacasPorSesion(
      idRBCarroOriginalMarcados,
      recinto,
      nombreRecinto
    );
  }

  postEstadosButacasPorSesion(
    idRBCarroOriginalMarcados,
    recinto,
    nombreRecinto
  ) {
    this.httpPostEstadosButacasPorSesion()
      .pipe(
        mergeMap((res: any) => {
          return this.getButacasMarcadasPorSesion().pipe(
            map((butacasMarcadas) => {
              res.butacasMarcadas = butacasMarcadas;
              return res;
            })
          );
        })
      )
      .subscribe((res: any) => {
        let ocupadaBut;
        let ocupadaButIndex;
        let indexEncontrado;
        let estadoEncontrado = false;

        let resEstadoOcupado = res.DatosResult.Butacas.findIndex(
          (f) => f.Estado === "3"
        );

        res.DatosResult.Butacas.forEach((estado) => {
          if (estado.Estado !== "2") {
            estado.IdsRelacion.forEach((id) => {
              if (!this.butacasOcupadas.includes(+id.IdRB)) {
                this.butacasOcupadas.push(+id.IdRB);
              }
            });
          }
        });

        idRBCarroOriginalMarcados.forEach((miButaca) => {
          let miButaca2 = miButaca;
          for (let index = 0; index < res.DatosResult.Butacas.length; index++) {
            if (index !== resEstadoOcupado) {
              ocupadaBut = res.DatosResult.Butacas[index].IdsRelacion.find(
                (f) => f.IdRB === miButaca2.toString()
              );
              ocupadaButIndex = res.DatosResult.Butacas[
                index
              ].IdsRelacion.findIndex((f) => f.IdRB === miButaca2.toString());
              if (ocupadaBut) {
                indexEncontrado = index;
                break;
              }
            }
          }
          if (ocupadaBut) {
            if (resEstadoOcupado && resEstadoOcupado > -1)
              res.DatosResult.Butacas[resEstadoOcupado].IdsRelacion.push(
                ocupadaBut
              );
            res.DatosResult.Butacas[indexEncontrado].IdsRelacion.splice(
              ocupadaButIndex,
              1
            );
          }
        });

        this.listaButacasDisponibles = "";
        this.idRBCarroOriginal.forEach((idsOriginal) => {
          this.listaButacasDisponibles += idsOriginal + ",";
        });
        const idsEstadosDisponibles: number[] = this.estadoButacasInicial.map(
          (itemEstado) => {
            if (
              +itemEstado[this.clic] === 1 &&
              +itemEstado[this.mostrar] === 1
            ) {
              return +itemEstado.EstadoButaca;
            }
            return null;
          }
        );
        res.DatosResult.Butacas.forEach((item) => {
          if (idsEstadosDisponibles.includes(+item.Estado)) {
            item.IdsRelacion.forEach((subItem) => {
              estadoEncontrado = false;
              if (idRBCarroOriginalMarcados.length > 0) {
                idRBCarroOriginalMarcados.forEach((idsOrigMarcados) => {
                  if (idsOrigMarcados == subItem.IdRB) {
                    estadoEncontrado = true;
                  }
                });
                if (!estadoEncontrado) {
                  this.listaButacasDisponibles += subItem.IdRB + ",";
                }
              } else {
                this.listaButacasDisponibles += subItem.IdRB + ",";
              }
            });
          }
        });
        this.listaButacasDisponibles = this.listaButacasDisponibles.slice(
          0,
          -1
        );

        this.setEstadosButacaValue(res.DatosResult.Butacas); //Se asignan las butacas que vienen en estado butacas por sesion
        this.getButacasMap();

        //aqui obtenemos la info de las butacas del json de los assets
        this.getInfoButacasJsonAssets(nombreRecinto, recinto);
      });
  }

  getButacasMap() {
    this.estadosButacasValue
      .map((estado) => estado.IdsRelacion)
      // .flat()
      .reduce((acc, val) => acc.concat(val), [])
      .forEach((butaca) => {
        const id = this.generateId(
          this.getSessionID(),
          butaca.IdSvg,
          this.fechaSeleccionadaValue,
          this.horaSeleccionadaValue
        );
        this.butacasMap[id] = butaca.IdRB;
      });
  }

  getInfoButacasJsonAssets(nombreRecinto, recinto) {
    this.infoMapasService
      .getButacas(nombreRecinto)
      .subscribe((butacas: [any]) => {
        this.butacasConPrecio = butacas;
        this.estadosButacasValue.map((butacasConEstado) => {
          butacasConEstado.IdsRelacion.map((cadaButaca) => {
            this.butacasConPrecio.forEach((butacasDelJSON) => {
              if (cadaButaca.IdSvg === butacasDelJSON.idRecintoButaca) {
                butacasDelJSON["data-tipo-butaca"] = cadaButaca.TipoBTC;
                butacasDelJSON.PKIDRB = cadaButaca.IdRB;
                butacasDelJSON.NombreRecinto = cadaButaca.NombreRecinto;
                butacasDelJSON.EstadoInicial = cadaButaca.EstadoInicial;
                if (butacasDelJSON.butacaPasillo === "1") {
                  if (!this.butacasPasillo.includes(+butacasDelJSON.PKIDRB)) {
                    this.butacasPasillo.push(+butacasDelJSON.PKIDRB);
                  }
                }
              }
            });
          });
        });
        this.postRecintosPorSesion(recinto);
      });
  }

  postRecintosPorSesion(recinto) {
    this.httpPostRecintosPorSesion().subscribe((resultado: any) => {
      if (
        resultado &&
        resultado.DatosResult !== null &&
        resultado.DatosResult !== undefined
      ) {
        this.listaRecintosPorSesion = resultado.DatosResult;
        for (let i = 0; i < resultado.DatosResult.length; i++) {
          if (resultado.DatosResult[i].Numeradas === "1") {
            this.listaIdRecintosNUMERADOS.push(
              resultado.DatosResult[i].RecintoID
            );
          } else {
            this.listaIdRecintosNONUMERADOS.push(
              resultado.DatosResult[i].RecintoID
            );
          }
        }
      }
      this.postTodasButacasDetalles(recinto);
    });
  }

  postTodasButacasDetalles(recinto) {
    this.httpPostTodasButacasDetalles(this.listaButacasDisponibles).subscribe(
      (detalle: any) => {
        if (detalle.DatosResult[0])
          this.prefijo = detalle.DatosResult[0].Prefijo;
        this.noNumeradaSubject.next(false);

        this.recintoRemoveAll(recinto);
        this.leyenda = [];
        this.leyenda.push({
          Descripcion: this.getTranslate().data.MARCADA.toString(),
          Color: "#0000FF",
        });
        this.butacasConPrecio = this.butacasConPrecio.map((item) => {
          const tarifas = [];
          const precios = [];
          detalle.DatosResult.forEach((x) => {
            if (
              item["data-tipo-butaca"] == x.TipoButacaId &&
              x.idRecinto == item.dataIdRecinto
            ) {
              tarifas.push(x);
              precios.push(parseInt(x.PrecioInternet, 10));
            }
          });
          item.tarifas = tarifas;
          if (precios.length > 0) {
            item.precioMaximo = Math.max(...precios);
            item.precioMinimo = Math.min(...precios);
          }
          return item;
        });

        //Tambien se configura el filtro para el slice de precios
        const preciosFiltro = detalle.DatosResult.map((item) => {
          return parseInt(item.PrecioInternet, 10);
        });
        this.maxFiltro = Math.max(...preciosFiltro);
        this.minFiltro = Math.min(...preciosFiltro);
        if (this.maxFiltro !== this.minFiltro) {
          this.filtroPreparado.next(true);
        }

        this.pintarMapa(recinto);
      }
    );
  }

  pintarMapa(recinto) {
    this.listaRecintosPorSesion
      .filter((r) => +r.Numeradas === 0)
      .forEach((recintosNoNumerados) => {
        let totalDisponible = recintosNoNumerados.ButacasDisponible;
        const IdRecinto = recintosNoNumerados.RecintoID;
        if (
          this.listaIdRecintosNUMERADOS.length === 0 &&
          this.listaIdRecintosNONUMERADOS.length === 1
        ) {
          this.clicRecintosNoNumerados(IdRecinto);
        }
        if (
          totalDisponible === undefined ||
          totalDisponible == null ||
          totalDisponible === ""
        ) {
          totalDisponible = 0;
        } else {
          totalDisponible = +totalDisponible;
        }
        if (this.modificarValue() === true) {
          totalDisponible += this.espectaculosService
            .compraReservaInicial()
            .filter(
              (producto) =>
                producto.producto.butacas[0].EspectaculoId ===
                  this.ventaService.espectaculoValue.EspectaculosId &&
                producto.producto.butacas[0].FechaSesion ===
                  this.ventaService.fechaSeleccionadaValue.format(
                    "YYYY/MM/DD"
                  ) &&
                producto.producto.butacas[0].HoraSesion ===
                  this.ventaService.horaSeleccionadaValue.format("HH:mm") &&
                producto.producto.butacas[0].idRecinto === +IdRecinto
            ).length;
        }

        recinto
          .selectAll('[id="R' + IdRecinto + '"]')
          .selectAll("title")
          .remove();
        recinto
          .selectAll('[id="R' + IdRecinto + '"]')
          .style("cursor", "pointer")
          .style("fill", totalDisponible > 0 ? "#ffffff" : "grey")
          .on("mouseover", function () {
            d3.select(this).style("fill-opacity", "0.7");
          })
          .on("mouseout", function () {
            d3.select(this).style("fill-opacity", "1");
          })
          .on("click", () => {
            if (totalDisponible > 0) {
              this.clicRecintosNoNumerados(IdRecinto);
            } else {
              this.toast.info(
                this.getTranslate().data.ERR_RECINTO_NO_DISPONIBLE,
                "Info",
                {
                  timeOut: 5000,
                  positionClass: "toast-top-center",
                }
              );
            }
          })
          .insert("svg:title")
          .text(
            recintosNoNumerados.NombreRecinto +
              " - " +
              this.getTranslate()
                .data.TITLE_NO_NUMERADA.toString()
                .replace("{cantidad}", totalDisponible)
          );
      });
    if (this.listaIdRecintosNONUMERADOS.length > 0) {
      this.leyenda.push({
        Descripcion: this.getTranslate().data.RECINTO_NO_NUMERADO_LEYENDA,
        Color: "#FFFFFF",
      });
    }

    if (this.idRBCarroOriginal && this.idRBCarroOriginal.length > 0) {
      this.butacasConPrecio.forEach((butaca) => {
        this.idRBCarroOriginal.forEach((e) => {
          if (+e === +butaca.PKIDRB) {
            butaca.estado = butaca.EstadoInicial;
          }
        });
      });
    }
    // corte

    this.butacasConPrecio.forEach((butaca) => {
      this.butacaSeleccionada = false;
      if (this.espectaculosService.comprobarSiButacaAgregada(butaca) === true) {
        this.butacaSeleccionada = true;
      }
      const tooltipText =
        butaca.NombreRecinto +
        " - " +
        this.getTranslate().data.TITLE_FILA +
        " " +
        butaca.dataNumFila +
        " - " +
        this.getTranslate().data.BUTACA +
        " " +
        butaca.dataNumButaca;

      const esNoNumerada = this.listaIdRecintosNONUMERADOS.includes(
        butaca.dataIdRecinto
      );
      this.c = recinto
        .append("circle")
        .attr("_pkid", butaca.idRecintoButaca)
        .attr("_PKIDRB", butaca.PKIDRB)
        .style(
          "fill",
          this.butacaSeleccionada === true
            ? "rgb(0,0,255)"
            : this.getColorButaca(butaca, esNoNumerada, this.coloresReserva)
        )
        .style("fill-opacity", 1)
        .style("display", () => {
          return esNoNumerada ? "none" : "block";
        })
        .style("cursor", () => {
          let displayValue = "pointer";
          this.estadoButacasInicial.forEach((item) => {
            const status = this.getStatusButacaById(butaca.idRecintoButaca);
            if (item.EstadoButaca == status && +item[this.clic] === 0) {
              displayValue = "move";
            }
          });
          return displayValue;
        })
        // .style("pointer-events", () => {
        //   let disabled = 'auto';
        //   this.estadoButacasInicial.forEach((item) => {
        //     const status = this.getStatusButacaById(butaca.idRecintoButaca);
        //     if (item.EstadoButaca == status && +item[this.clic] === 0) {
        //       disabled = 'none';
        //     }
        //   });
        //   return disabled;
        // })
        .attr("cx", butaca.cx)
        .attr("cy", butaca.cy)
        .attr("r", butaca.r)
        .on("click", () => {
          this.clickButacaNumerada(butaca);
        })
        .on("mouseover", function () {
          d3.select(this).insert("svg:title").text(tooltipText);
        })
        .on("mouseout", function () {
          const listaHijos = this.childNodes;
          const longitud = this.childNodes.length;
          for (let index = 0; index < longitud; index++) {
            this.removeChild(listaHijos[index]);
          }
        });
      if (this.butacaSeleccionada) {
        const butacaDom = document.querySelector(
          "circle[_PKIDRB='" + butaca.PKIDRB + "']"
        );

        butacaDom.classList.add("marcada");
      }
    });
    this.leyenda.sort((a, b) => {
      if (a.Descripcion > b.Descripcion) {
        return 1;
      }
      if (a.Descripcion < b.Descripcion) {
        return -1;
      }
      return 0;
    });
    this.leyendaSubject.next(this.leyenda);
  }

  clickButacaNumerada(butaca) {
    if (this.espectaculosService.comprobarSiButacaAgregada(butaca) === true) {
      this.desmarcarButaca(butaca);
    } else {
      if (this.comprobarDisponibilidadButaca(butaca)) {
        const observables = [];
        observables.push(
          this.ventaService.modificandoReservaValue
            ? new Observable((e) => {
                e.next(undefined);
                e.complete();
              })
            : this.getButacaTarifasEstado(butaca.PKIDRB)
        );
        observables.push(this.getInfoButacaClick(butaca));

        forkJoin(observables).subscribe((res1) => {
          const respuestaTarifasEstado: any = res1[0];
          const respuestaInfo: any = res1[1];
          butaca.info = respuestaInfo;
          if (respuestaTarifasEstado && respuestaTarifasEstado.TarifasEstados) {
            let tarifasDeshabilitadas;

            respuestaTarifasEstado.TarifasEstados.forEach((respuetatarifas) => {
              if (+respuetatarifas.Estado === 0) {
                tarifasDeshabilitadas = respuetatarifas.IdTarifas;
              }
            });
            const updateTarifas = butaca.tarifas.map((tarifasButaca) => {
              if (
                tarifasDeshabilitadas &&
                tarifasDeshabilitadas
                  .map((e) => +e)
                  .includes(+tarifasButaca.IdTarifa)
              ) {
                tarifasButaca.Habilitado = 0;
              } else {
                tarifasButaca.Habilitado = 1;
              }
              return tarifasButaca;
            });

            if (updateTarifas && updateTarifas.length > 0) {
              butaca.tarifas = updateTarifas;
            } else {
              butaca.tarifas = butaca.tarifas.map((tarifa) => {
                tarifa.Habilitado = 1;
                return tarifa;
              });
            }
          } else {
            butaca.tarifas = butaca.tarifas.map((tarifa) => {
              tarifa.Habilitado = 1;
              return tarifa;
            });
          }

          if (this.butacasMultiService.checkMultipleValue) {
            this.butacasMultiService.setButacaMarcadaValue(butaca);
            this.modalService.open("selectorMultiple");
          } else {
            this.setButacaMarcadaValue(butaca);
            this.modalService.open("selector");
          }

          // manieva Si se selecciono una butaca, arranca a contar el timer
          //this.timerService.start();
        });
      }
    }
  }
  desmarcarButaca(butaca: any) {
    if (this.butacasMultiService.checkMultipleValue) {
      return false;
    }
    if (!this.uncheckSpecialRates(butaca)) {
      return false;
    }
    if (this.butacasSeleccionadas.length >= 1) {
      for (let i = 0; i < this.butacasSeleccionadas.length; i++) {
        if (this.butacasSeleccionadas[i].toString() === butaca.PKIDRB) {
          this.butacasSeleccionadas.splice(i, 1);
          this.ComprobarNoDejarButacasLibres();
          break;
        }
      }
    }

    if (
      sessionStorage.getItem("admin") &&
      sessionStorage.getItem("reservaOriginal")
    ) {
      const producto = this.espectaculosService.existeButaca(butaca);
      const initialReservation = JSON.parse(
        sessionStorage.getItem("reservaOriginal")
      ).compras;
      const seatsFound: Array<any> = [];
      const purchasePosition = this.espectaculosService.posicionVentaValue;
      let existMoreProductsAlsoReservation = false;

      const findPurchasePositionInProductsByGroup = initialReservation.filter(
        (res) => {
          if (+res.posicionVenta === +purchasePosition) {
            return res;
          }
        }
      );
      if (
        findPurchasePositionInProductsByGroup.length > 0 &&
        findPurchasePositionInProductsByGroup[0] == undefined
      ) {
        /** Aplica para cuando añades mas actividades siendo admin y no hay reservas */
        existMoreProductsAlsoReservation = this.compraReservaInicial().some(
          (e) => +e.idRB === +butaca.PKIDRB
        );
      } else {
        findPurchasePositionInProductsByGroup.forEach((product) => {
          product.butacas.forEach((seat) => {
            if (+seat.idRB === +butaca.PKIDRB) {
              seatsFound.push(butaca);
            }
          });
        });
      }

      if (seatsFound.length === 0) {
        this.httpPostMarcarDesmarcarButaca(butaca, false).subscribe(() => {});
      }
      if (existMoreProductsAlsoReservation === true && !this.modificarValue()) {
        this.httpPostMarcarDesmarcarButaca(butaca, false).subscribe(() => {});
      }
      this.eliminarButacaDesdeMapa(butaca, producto);
      this.c.style("fill-opacity", 1);
      const butacaDom = document.querySelector(
        "circle[_PKIDRB='" + butaca.PKIDRB + "']"
      );
      butacaDom.classList.remove("marcada");

      if (this.butacasSeleccionadas.length === 0) {
        this.butacasMultiService.setSiButacasValue(false);
        this.butacasMultiService.setlistDesmarcar([]);
        this.butacasMultiService.arrMarcadas = [];
        this.butacasMultiService.setCheckMultipleValue(false);
        this.butacasMultiService.setDisabledMultiple(false);
      }
    } else {
      const producto = this.espectaculosService.existeButaca(butaca);
      if (!this.modificarValue()) {
        this.httpPostMarcarDesmarcarButaca(butaca, false).subscribe(() => {});
      }
      this.eliminarButacaDesdeMapa(butaca, producto);
      this.c.style("fill-opacity", 1);
      const butacaDom = document.querySelector(
        "circle[_PKIDRB='" + butaca.PKIDRB + "']"
      );
      butacaDom.classList.remove("marcada");

      if (this.butacasSeleccionadas.length === 0) {
        this.butacasMultiService.setSiButacasValue(false);
        this.butacasMultiService.setlistDesmarcar([]);
        this.butacasMultiService.arrMarcadas = [];
        this.butacasMultiService.setCheckMultipleValue(false);
        this.butacasMultiService.setDisabledMultiple(false);
      }
    }
  }

  recintoRemoveAll(recinto) {
    recinto.selectAll("circle").remove();
  }

  eliminarButacaDesdeMapa(butaca: any, producto) {
    this.espectaculosService.eliminarButacaDesdeMapa(producto);
    return this.deletButaca(butaca);
  }

  removeButaca(butaca: any): boolean {
    this.espectaculosService.removeButaca(butaca);
    return this.deletButaca(butaca);
  }

  deletButaca(butaca) {
    if (this.getPlataforma() === "4") {
      this.clic = "ClicReserva";
      this.mostrar = "MostrarReserva";
    }
    d3.select(`circle[_pkid = "${butaca.idRecintoButaca}" ]`).style(
      "fill",
      () => {
        let color = "";
        this.estadoButacasInicial.forEach((item) => {
          if (
            +item[this.clic] === 1 &&
            +item[this.mostrar] === 1 &&
            butaca["data-tipo-butaca"] == item.TipoButaca &&
            butaca["EstadoInicial"] === item.EstadoButaca
          ) {
            color = item.Color;
          }
        });
        return color;
      }
    );
    return true;
  }

  removeAllButaca(): void {
    this.espectaculosService.removeAllButaca();
  }

  getCantidadPorProducto(butaca) {
    return this.espectaculosService.getCantidadPorProducto(butaca);
  }

  eliminarButacaDesdeCarrito(butaca) {
    let producto = this.espectaculosService.obtenerProductoButaca(butaca);
    this.httpPostMarcarDesmarcarDesdeCarrito(producto, false);
    this.espectaculosService.eliminarButacaDesdeCarrito(producto);
  }

  modificarValue() {
    let valor = this.espectaculosService.modificarValue();
    return valor;
    //return this.ventaService.modificarValue;
  }

  compraReservaInicial() {
    return this.espectaculosService.compraReservaInicial();
  }

  setPosicionVenta(valor) {
    this.espectaculosService.setPosicionVenta(valor);
  }

  getPosicionVenta() {
    return this.espectaculosService.posicionVentaValue;
  }

  resetValues() {
    this.ventaService.setFechaSeleccionadaValue(moment());
    this.calendarService.currentDate = moment();
    sessionStorage.removeItem("descuento");
    sessionStorage.removeItem("factura");
    sessionStorage.removeItem("pedido");
    sessionStorage.removeItem("localizador");
    sessionStorage.removeItem("payment");
    sessionStorage.removeItem("reservaOriginal");
    this.ventaService.setCompra(new Compra());
    this.ventaService.sesionesReservadas = [];
    this.ventaService.setModificarValue(false);
    this.ventaService.setDescuentoObjeto(undefined);
    this.ventaService.setDescuentoCod(undefined);
    this.ventaService.setDescuento(undefined);
    this.ventaService.setDescuentoPer(undefined);
    this.ventaService.setDescuentoPersonalizado(undefined);
    this.ventaService.setDescuentoTotales(undefined);
    this.ventaService.setDescuentoClienteAsociado(undefined);
    this.ventaService.setDescuentoClienteAsociadoCod(undefined);
    this.ventaService.setDescuentoClienteAsociadoObjeto(undefined);
    this.productosService.setFiltroTipoValue("");
    this.productosService.setFiltroGrupoValue("");
    this.productosService.setFiltroNivelValue("");
    this.ventaService.setCambios(false);
    this.paymentService.setMostrarBotonesPagar(false);
    this.paymentService.setMostrarBotonesReservar(false);
    this.paymentService.setMostrarBotonesPhone(false);
    this.paymentService.setMostrarBotonesBizum(false);
    this.paymentService.setmostrarBotonesPaypal(false);
    this.paymentService.setMostrarDescuento(false);
    this.paymentService.setPaymentGateway(PaymentGateway.REDSYS);
    sessionStorage.setItem("payment", JSON.stringify(PaymentGateway.REDSYS));
  }
}
