import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarService } from '../business/ticket-sales/pages/date-selector/calendar/calendar.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TranslateService {
  data: any = {};
  // tslint:disable-next-line:variable-name
  public _locale: string;

  public _langDefault:string;

  public filename:string;


  setLocale(value: string) {
    this._locale = value;
    this.calendarService.setMeses(value);
  }

  setLangDefault(value:string){
    this._langDefault = value;
  }

  getLocale(): string {
    return this._locale || this._langDefault || 'es-ES';
  }

  getFilename() {
    return this.filename;
  }

  setFilename(value:string) {
    this.filename=value;
  }

  constructor(@Optional() @Inject('TRANS_PARAM') public param: string, private http: HttpClient, private calendarService: CalendarService) {
    this.filename = null;
    if (param) {
      this.setFilename(param);  
    }
   }

  use(lang: string, proyecto: string): Promise<{}> {
    if (lang === 'en') {
      lang = 'en-GB';
    }
    // else if (lang !== 'es-ES' && lang !== 'en-GB' && lang !== 'ca' && lang != 'fr-FR') {
    //    lang = 'es-ES';
    //   }
    return new Promise<{}>((resolve) => {   
      this.fileLangExists(lang, proyecto).subscribe(existsLangPath =>
        {          
          if(!existsLangPath){
            lang = this._langDefault ||'es-ES';
          }         
          this.setLocale(lang);
          if (this.getFilename()) 
            var thefile = this.getFilename()+"_"+(lang || this._langDefault || 'es-ES');          
          const langPath = `./assets/i18n/${proyecto}/${thefile || lang || this._langDefault || 'es-ES'}.json`;
          this.http.get<{}>(langPath).subscribe(
            translation => {
              this.data = Object.assign({}, translation || {});
              //this.calendarService.setMeses(this.getLocale());
              resolve(this.data);
            },
            () => {
              this.data = {};
              resolve(this.data);
            }
            );
          });
        });
      }
  
  fileLangExists(lang:string, proyecto:string){
    const url = `./assets/i18n/${proyecto}/${lang}.json`; 
    return this.fileExists(url);
  }

  fileExists(url: string): Observable<boolean> {     
    return this.http.get(url)
      .pipe(
          map(response => {         
              return true;
          }),
          catchError(error => {          
            return of(false);
          })
      );
  }

}

