import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppConfiguration } from 'src/app/app.configuration';
import { ProductosService } from 'src/app/business/productos.service';
import { InitService } from 'src/app/core/init/init.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { CompraCarrito } from 'src/app/models/compra-carrito.model';
import { TranslateService } from 'src/app/shared/translate.service';
import { CalendarService } from '../../date-selector/calendar/calendar.service';
import { VentaService } from '../../../../../core/services/venta.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-calendars-selector',
  templateUrl: './calendars-selector.component.html',
  styleUrls: ['./calendars-selector.component.scss']
})
export class CalendarsSelectorComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  
  entradas: any[] = [];
  compra: CompraCarrito;
  controlHora: any;
  noAforo = this.sesionService.noAforoValue;
  recinto;
  sesionesReservadas: any[] = [];
  recintos;
  idiomaForm: FormGroup;
  sesionesPorRecinto = [];
  tieneAforo = true;
  idiomaSesion = this.translate.getLocale();
  proyecto = this.config.getProyecto();
  pantallas: [];
  mostrar = this.calendarService.mostrarValue;
  mostrarElegirProfesor = false;
  mostrarDisponibilidad;
  loading;
  mostrarProductos;
  numerosCalendario = Array(parseInt(this.productosService.filtroDiasValue)).fill(1).map((x, i) => i + 1);
  sesionesSeleccionadas;
  mensajeMismaSesion = [];

  constructor(
    private config: AppConfiguration,
    private ventaService: VentaService,
    private sesionService: SesionService,
    private productosService: ProductosService,
    private calendarService: CalendarService,
    private initService: InitService,
    private translate: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.cargarArrayCalendarioMostrar();
    this.calendarService.setMostrar(true);
    this.productosService.cargandoComponente$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(carga => this.mostrarProductos = carga); 
    this.productosService.cargandoComponente2$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(carga => this.loading = carga);
    this.mostrar = this.calendarService.mostrarValue;
    this.pantallas = this.initService.chCalendarioValue.CaracteristicasCalendario.GINoMostrarCalendario;
    const mostrarCalendarioPaso = this.initService.chCalendarioValue.CaracteristicasCalendario.Visibilidad;
    if (mostrarCalendarioPaso !== 'Paso2') {
      this.calendarService.setMostrar(false);
    } else {
      if (this.pantallas) {
        this.pantallas.find(res => {
          if (res === parseInt(this.productosService.getGrupoInternet().gi, 10)) {
            this.calendarService.setMostrar(false);
            return this.mostrar;
          }
        });
      }
    }
    this.calendarService.mostrar$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mostrar => this.mostrar = mostrar);

    this.noAforo = false;
    if (this.calendarService.calendarioPaso2Value) {
      this.calendarService.sesionesCargadas$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cargadas => {
        if (cargadas) {
          this.sesionesPorProductoSesionesPorRecinto();
        }
      });
    } else {
      this.sesionesPorProductoSesionesPorRecinto();
    }
  }

  cargarArrayCalendarioMostrar() {
    if (this.numerosCalendario) {
      this.sesionesSeleccionadas = [];
      if (this.ventaService.modificarValue) {
        this.ventaService.compraValue.productos.forEach(item => {
          this.numerosCalendario.forEach((numero, index) => {
            if (numero === 1) {
              this.sesionesSeleccionadas.push({ numero, mostrar: true, sesion: item.sesionesSeleccionadas[index] });
            } else {
              this.sesionesSeleccionadas.push({ numero, mostrar: false, sesion: item.sesionesSeleccionadas[index] });
            }
          });
        });
        this.ventaService.setSesionesSeleccionadas(this.sesionesSeleccionadas);
      } else {
        this.numerosCalendario.forEach(numero => {
          if (numero === 1) {
            this.sesionesSeleccionadas.push({ numero, mostrar: true, sesion: '' });
          } else {
            this.sesionesSeleccionadas.push({ numero, mostrar: false, sesion: '' });
          }
        });
        this.ventaService.setSesionesSeleccionadas(this.sesionesSeleccionadas);
      }
    }
  }

  estaOculto(numero) {
    const mostrarCalendario = this.sesionesSeleccionadas.find(num => num.numero === numero);
    return mostrarCalendario.mostrar;
  }

  mostrarCalendario(numero) {
    this.sesionesSeleccionadas.forEach((element) => {
      if (numero - 2 < 0 || this.sesionesSeleccionadas[numero - 2].sesion !== '') {
        if (element.numero === numero) {
          element.mostrar = true;
        } else {
          element.mostrar = false;
        }
      }
    });
  }

  sesionesPorProductoSesionesPorRecinto() {
    this.sesionesPorRecinto = [];
    const producto = this.ventaService.compraValue.productos[0];
    this.ventaService.horariosList = this.sesionService.getSesionesPorProducto(producto.producto);
    this.productosService.recintos.forEach(recinto => {
      const sesiones = [];
      this.ventaService.horariosList.forEach(sesion => {
        if (recinto.codRecinto === sesion.IdRecinto) {
          sesiones.push(sesion);
        }
      });
      if (sesiones.length) {
        this.sesionesPorRecinto.push(sesiones);
      }
    });
  }

  // HORA POR RECINTO
  isSelectedPorRecinto(sesion, numero) {
    let seleccionada = false;
    this.ventaService.compraValue.productos.forEach(item => {
      if (item.sesionesSeleccionadas) {
        if (item.sesionesSeleccionadas.length) {
          item.sesionesSeleccionadas.forEach((s, index) => {
            if (s.HoraInicio === sesion.HoraInicio && sesion.IdRecinto === s.IdRecinto && s.Fecha === sesion.Fecha && numero === index + 1) {
              seleccionada = true;
            }
          });
        }
      }
    });
    return seleccionada;
  }

  // HORA POR RECINTO
  selectSesionPorRecinto(sesion, numero, index?) {
    this.mensajeMismaSesion = [];
    if (!this.ventaService.sesionesReservadas.length && this.ventaService.modificarValue) {
      const prods = this.ventaService.compraValue.productos;
      this.ventaService.sesionesReservadas = prods[0].sesionesSeleccionadas;
    }
    this.ventaService.sesionesReservadas.forEach((s, index) => {
      if (sesion.IdRecinto === s.IdRecinto && numero === (index + 1)) {
        this.productosService.cancelarAforo(s, numero).pipe(take(1)).subscribe();
      }
    });
    this.productosService.reservaAforo(sesion, numero).pipe(take(1)).subscribe(res => {
      if (res.DatosResult) {
        res.DatosResult.Producto.forEach(producto => {
          const s = { ...sesion, TotalAforoUnidad: producto.AforosConsumidos.TotalAforoUnidad };
          const horaNoValida = this.ventaService.compraValue.comprobarSesiones(s);
          if (!horaNoValida) {
            const sesionesArray = [];
            this.ventaService.sesionesSeleccionadasValue.forEach(sesion => {
              if (sesion.mostrar === true) {
                sesion.sesion = s;
                this.ventaService.compraValue.agregarSesionATodosProducto1Varios(this.ventaService.sesionesSeleccionadasValue);
              }
              if (sesion.sesion !== '') {
                sesionesArray.push(sesion.sesion);
              }
            });
            this.ventaService.sesionesReservadas = sesionesArray;
          }
          if(horaNoValida){
            this.mensajeMismaSesion[index] = true;
          }
          this.sesionService.setNoAforo(false);
        });
      } else {
        this.sesionService.setNoAforo([true, sesion.IdRecinto]);
        this.sesionService.noAforo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(aforo => this.noAforo = aforo);
      }
    });
  }

  controlAforo(sesion) {
    if (this.sesionService.noAforoValue) {
      if (this.sesionService.noAforoValue[0] && this.sesionService.noAforoValue[1] === sesion[0].IdRecinto) {
        this.tieneAforo = false;
        return true;
      }
    }
  }

  isOcupada(sesion) {
    let ocupada = false;
    if (sesion.IdiomaSesion !== '') {
      if (sesion.IdiomaSesion !== this.idiomaSesion) {
        ocupada = true;
      }
    } else {
      if (sesion.Disponible === '0') {
        ocupada = true;
      }
    }
    return ocupada;
  }

  horaSesion(sesion) {
    const mostrarAforoDisponible = JSON.parse(this.productosService.getEnlaceMenuId().CaracteristicasAdicionales).CaracteristicasAdicionales.MostrarAforoDisponible;
    if (mostrarAforoDisponible === '1') {
      this.mostrarDisponibilidad = true;
    } else {
      this.mostrarDisponibilidad = false;
    }
    if (this.mostrarDisponibilidad) {
      return sesion.HoraInicio + ' (' + sesion.Disponible + ')';
    } else {
      return sesion.HoraInicio;
    }
  }

  devolverSesion(numero) {
    const sesion = this.ventaService.sesionesSeleccionadasValue.find(sesion => sesion.numero === numero);
    if (sesion.sesion !== '') {
      return sesion.sesion.Fecha + ' - ' + sesion.sesion.HoraInicio;
    }

  }
  getProductosService(){
    return this.productosService;
  }

}
