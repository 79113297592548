import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Subject } from "rxjs";
import { map, take, tap, takeUntil } from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";
import { ButacasService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.service";
import { ProductosService } from "src/app/business/productos.service";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { InitService } from "src/app/core/init/init.service";
import { PaymentService } from "src/app/core/payment/payment.service";
import { TimerService } from "src/app/core/services/timer.service";
import { VentaService } from "src/app/core/services/venta.service";
import { CompraCarrito } from "src/app/models/compra-carrito.model";
import { Compra } from "src/app/models/compra.model";
import { ModalService } from "src/app/shared/modal.service";
import { TranslateService } from "src/app/shared/translate.service";
import { ButacasMultiplesService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.multiples.service";

@Component({
  selector: "app-carrito",
  templateUrl: "./carrito.component.html",
  styleUrls: ["./carrito.component.scss"],
})
export class CarritoComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  arr = Array;
  listaButacasMarcadasParaComprar = [];
  listaCarrito = [];
  proyecto = this.config.getProyecto();
  descuento;
  totalDescuento;
  comision;
  IVAComision;
  precioFinal;
  descuentoCliente;
  timeoutHandler;
  timeMouse: number = 0;
  disabledMove = false;
  gestionarComision = "0";
  incluirComision = true;
  estaPagada;
  tieneComision = "0";
  public modificarReserva;
  public modificarReservaPagada;
  public modificarReservaNoPagada;

  constructor(
    private router: Router,
    private initService: InitService,
    private ventaService: VentaService,
    private configurationService: ConfigurationService,
    private productosService: ProductosService,
    private translateService: TranslateService,
    private config: AppConfiguration,
    private paymentService: PaymentService,
    private butacasService: ButacasService,
    public dialog: MatDialog,
    public modalService: ModalService,
    private timerService: TimerService,
    private butacasMultiService: ButacasMultiplesService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  selectColor() {
    return this.disabledMove == true ? "red !important" : "green !important";
  }
  ngOnInit() {
    this.estaPagada = sessionStorage.getItem("estaPagada");
    this.tieneComision = sessionStorage.getItem("tieneComision");
    this.ventaService.setIncluirComision(true); //Tiene que estar siempre seleccionado cuando inicie la ventana
    this.iniciarboton();
    this.listaCarrito = this.ventaService.validaCesta();
    this.ventaService.descuentoTotales$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((descuento) => {
        if (descuento) {
          this.descuento = descuento.descuento;
          this.totalDescuento = descuento.totalDescuento;
          this.precioFinal = this.getPrecioTotalconComision();
        }
      });
    this.ventaService.gestionarComision$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((gestiona) => (this.gestionarComision = gestiona));
    this.ventaService.incluirComision$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((incluye) => (this.incluirComision = incluye));

    this.comision = this.getComision();

    if (!this.comision) {
      this.incluirComision = false;
    } else {
      if (!this.tieneComision && this.estaPagada) {
        this.comision = null;
        this.incluirComision = false;
      }
    }

    this.IVAComision = this.getIVAComision();
    this.precioFinal = this.getPrecioTotalconComision();
    this.descuentoCliente = this.getDescuentoCliente();

    if (
      this.ventaService.modificandoReservaValue ||
      JSON.parse(sessionStorage.getItem("admin"))?.length > 0
    ) {
      this.modificarReserva = true;
      if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
        this.modificarReservaPagada = true;
      } else {
        this.modificarReservaNoPagada = true;
      }
    }

    //manieva - timer - TODO encontrar la manera de resumir cuando el componente finalize
    setTimeout(() => this.timerService.start(), 2000);
    this.ventaService.setNumCarrito();
  }

  formatDate(date) {
    let dateFormat = moment(date, "DD/MM/YYYY h:mm:ss");
    return dateFormat.format("DD/MM/YYYY");
  }

  iniciarboton() {
    if (screen.width > 667) {
      this.disabledMove = false;
    } else {
      this.disabledMove = true;
    }
  }

  camelCaseGenerator(inputString) {
    const words = inputString.split(" ");
    let camelCase = "";
    for (let i = 0; i < words.length; i++) {
      camelCase +=
        words[i].charAt(0).toUpperCase() +
        words[i].slice(1).toLowerCase() +
        " ";
    }
    return camelCase.trim(); // Trim to remove extra space at the end
  }

  precioTotal() {
    return this.ventaService.carritoValue.calcularPrecioTotal();
  }

  precioTotalGrupo(grupo) {
    return this.ventaService.carritoValue.calcularPrecioTotalGrupoCarrito(
      grupo
    );
  }

  getCantidadPorProducto(compra) {
    return this.ventaService.carritoValue.getCantidadPorProducto(compra);
  }

  getPrecioPorProducto(compra) {
    return this.ventaService.carritoValue.getPrecioPorProducto(compra);
  }

  mostrarPapelera(item, j) {
    if (!item.producto.ProductosBono.length) {
      return true;
    }
    if (item.producto.ProductosBono.length) {
      const numEntradasNominadas = item.producto.ProductosBono.filter(
        (bono) => bono.CuentaNominada === "1"
      )
        .map((entrada) => parseInt(entrada.NumeroEntradasBono, 10))
        .reduce((sum, current) => sum + current);
      if (j % numEntradasNominadas === 0) {
        return true;
      }
    }
  }

  /**
   * Processes the data and sorts it internally.
   *
   * @param {CdkDragDrop<string[]>} event - The event triggered by the drop action.
   * @return {void} This function does not return anything.
   */
  drop(event: CdkDragDrop<string[]>) {
    /** processing data and sorted internally */
    this.listaCarrito.forEach((grupo, i) => {
      grupo.forEach((item) => {
        let posicionVentaAnterior = item.posicionVenta;
        if (
          this.ventaService.fechaSeleccionadaSinSessionValue &&
          this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
        ) {
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
            (element) => {
              if (
                element.posicionVenta == posicionVentaAnterior &&
                item.producto.ProductoId == element.productoId
              ) {
                element.posicionVenta = i + 1;
              }
            }
          );
        }
        item.posicionVenta = i + 1;
      });
      this.ventaService.guardarFechaSeleccionadasSinSesion();
    });
    this.listaCarrito = this.ventaService.ordenarCarrito();
    this.ventaService.guardarCesta();

    /** Then we move the item in the array with a moveItemInArray component */
    moveItemInArray(this.listaCarrito, event.previousIndex, event.currentIndex);

    /** Another's processing */
    this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
    this.productosService.reservaAforoCarrito();
    this.timeMouse = 0;
  }

  activarDragDrop() {
    return false;
    /*let iLogueo = '0';
    if (this.productosService.datosInitValue) {
      if (this.productosService.getEnlaceMenuId()) {
        iLogueo = this.productosService.getEnlaceMenuId().iLogueo;
      }
    }
    if (iLogueo === '0') {
      return true;
    } else {
      return false;
    }*/
  }

  idiomaSesion(idioma) {
    if (idioma)
      if (this.translateService.data[idioma]) {
        return ". " + this.translateService.data[idioma];
      } else {
        return ". " + idioma;
      }
    return "";
  }

  mostrarComprarMas(posicionVenta) {
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === posicionVenta[0].producto.enlaceMenu
    );
    if (enlaceMenu) {
      const comprarMas = JSON.parse(enlaceMenu.chBotones).ComprarMas;
      if (!comprarMas || comprarMas === 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  mostrarModificar(posicionVenta) {
    if (this.modificarReserva) {
      if (
        posicionVenta[0].producto.idEspectaculoAsociado > 0 &&
        JSON.parse(sessionStorage.getItem("estaPagada"))
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  borrarTodo(grupoCompra) {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.borrarDescuentos();
    //Yaribel 20230123  Controlamos los registros en FechaSeleccionadaSinSession
    this.ventaService.filtrarFechaSeleccionadaSinSesion(
      grupoCompra[0].posicionVenta
    );
    const espectaculoId = grupoCompra[0].producto.idEspectaculoAsociado;

    if (!this.ventaService.modificandoReservaValue) {
      if (espectaculoId && espectaculoId > 0) {
        //Yaribel 20230308 llamar a desmarcar
        this.butacasService.DesmarcarTodoCarrito().pipe(take(1)).subscribe();
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
      }
    }

    this.ventaService.carritoValue.eliminarTodasEntradasPorTipoEntrada(
      grupoCompra
    );
    this.ventaService.setCarrito(this.ventaService.carritoValue);
    this.ventaService.guardarCesta();
    this.listaCarrito = this.ventaService.ordenarCarrito();
    this.listaCarrito.forEach((grupo, i) => {
      grupo.forEach((item) => {
        let posicionVentaAnterior = item.posicionVenta;
        if (
          this.ventaService.fechaSeleccionadaSinSessionValue &&
          this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
        ) {
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
            (element) => {
              if (
                element.posicionVenta == posicionVentaAnterior &&
                item.producto.ProductoId == element.productoId
              ) {
                element.posicionVenta = i + 1;
              }
            }
          );
        }
        item.posicionVenta = i + 1;
      });
      this.ventaService.guardarFechaSeleccionadasSinSesion();
    });
    // this.productosService.reservaAforoCarrito(); //Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta
    if (!this.ventaService.modificandoReservaValue) {
      if (espectaculoId && espectaculoId > 0) {
        //Yaribel 20230308 llamar a marcar todas las butacas del carrito
        this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
      } else {
        this.productosService.reservaAforoCarrito();
        //Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta y tenemos en cuenta que lo haga solo en la compra que es cuando cancelo el aforo >>
      }
    }
  }

  async borrarTodoAsync(grupoCompra) {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.borrarDescuentos();
    //Yaribel 20230123  Controlamos los registros en FechaSeleccionadaSinSession
    this.ventaService.filtrarFechaSeleccionadaSinSesion(
      grupoCompra[0].posicionVenta
    );
    const espectaculoId = grupoCompra[0].producto.idEspectaculoAsociado;

    if (
      !this.butacasService.modificarValue() &&
      !sessionStorage.getItem("admin")
    ) {
      if (espectaculoId && espectaculoId > 0) {
        this.ventaService.setFechaSeleccionadaValue(
          moment(grupoCompra[0].producto.butacas[0].FechaSesion, "YYYY/MM/DD")
        );
        await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => {
          this.continuProcesoBorraTodo(grupoCompra);
          this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
        });
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
        this.continuProcesoBorraTodo(grupoCompra);
        this.productosService.reservaAforoCarrito();
      }
    }
    if (
      this.butacasService.modificarValue() &&
      !sessionStorage.getItem("admin")
    ) {
      if (espectaculoId && espectaculoId > 0) {
        this.ventaService.setFechaSeleccionadaValue(
          moment(grupoCompra[0].producto.butacas[0].FechaSesion, "YYYY/MM/DD")
        );
        await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => {
          this.continuProcesoBorraTodo(grupoCompra);
          this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
        });
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
        this.continuProcesoBorraTodo(grupoCompra);
        this.productosService.reservaAforoCarrito();
      }
    }
    if (
      this.butacasService.modificarValue() &&
      sessionStorage.getItem("admin") &&
      sessionStorage.getItem("reservaOriginal")
    ) {
      if (espectaculoId && espectaculoId > 0) {
        this.ventaService.setFechaSeleccionadaValue(
          moment(grupoCompra[0].producto.butacas[0].FechaSesion, "YYYY/MM/DD")
        );
        await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => {
          this.continuProcesoBorraTodo(grupoCompra);
          this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
        });
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
        this.continuProcesoBorraTodo(grupoCompra);
        this.productosService.reservaAforoCarrito();
      }
    }
    if (
      this.butacasService.modificarValue() &&
      sessionStorage.getItem("admin") &&
      !sessionStorage.getItem("reservaOriginal")
    ) {
      if (espectaculoId && espectaculoId > 0) {
        this.ventaService.setFechaSeleccionadaValue(
          moment(grupoCompra[0].producto.butacas[0].FechaSesion, "YYYY/MM/DD")
        );
        await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => {
          this.continuProcesoBorraTodo(grupoCompra);
          this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
        });
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
        this.continuProcesoBorraTodo(grupoCompra);
        this.productosService.reservaAforoCarrito();
      }
    }
    if (
      !this.butacasService.modificarValue() &&
      sessionStorage.getItem("admin")
    ) {
      if (espectaculoId && espectaculoId > 0) {
        this.ventaService.setFechaSeleccionadaValue(
          moment(grupoCompra[0].producto.butacas[0].FechaSesion, "YYYY/MM/DD")
        );
        await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => {
          this.continuProcesoBorraTodo(grupoCompra);
          this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
        });
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
        this.continuProcesoBorraTodo(grupoCompra);
        this.productosService.reservaAforoCarrito();
      }
    }
    this.ventaService.setNumCarrito();
  }

  continuProcesoBorraTodo(grupoCompra) {
    this.ventaService.carritoValue.eliminarTodasEntradasPorTipoEntrada(
      grupoCompra
    );

    this.ventaService.setCarrito(this.ventaService.carritoValue);
    this.ventaService.guardarCesta();
    this.listaCarrito = this.ventaService.ordenarCarrito();
    this.listaCarrito.forEach((grupo, i) => {
      grupo.forEach((item) => {
        let posicionVentaAnterior = item.posicionVenta;
        if (
          this.ventaService.fechaSeleccionadaSinSessionValue &&
          this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
        ) {
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
            (element) => {
              if (
                Number(element.posicionVenta) ===
                  Number(posicionVentaAnterior) &&
                item.producto.ProductoId == element.productoId
              ) {
                element.posicionVenta = i + 1;
              }
            }
          );
        }

        item.posicionVenta = i + 1;
      });
      this.ventaService.guardarFechaSeleccionadasSinSesion();
    });

    this.shouldResetTimer();
  }

  shouldResetTimer() {
    //reiniciamos el timer solamente sino tenemos mas productos
    if (this.listaCarrito.length === 0) this.timerService.reset();
  }

  deleteTipoEntrada(compra: CompraCarrito) {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.borrarDescuentos();
    this.ventaService.filtrarFechaSeleccionadaSinSesion(compra.posicionVenta); //Yaribel 20230123  Controlamos los registros en FechaSeleccionadaSinSession
    const espectaculoId = compra.producto.idEspectaculoAsociado;

    if (this.ventaService.modificandoReservaValue) {
      if (this.tieneEntradasAgrupadas(compra, true)) {
        this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(
          compra
        );
      } else {
        this.ventaService.carritoValue.eliminarEntradasTipo(compra);
      }
    } else {
      if (this.tieneEntradasAgrupadas(compra, true)) {
        this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(
          compra
        );
      } else {
        this.ventaService.carritoValue.eliminarEntradasTipo(compra);
      }

      if (espectaculoId && espectaculoId > 0) {
        //Yaribel 20230309 llamar a desmarcar
        this.butacasService.DesmarcarTodoCarrito().pipe(take(1)).subscribe();
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
      }
      //this.productosService.reservaAforoCarrito(); //Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta
    }

    this.ventaService.setCarrito(this.ventaService.carritoValue);
    this.ventaService.guardarCesta();
    this.listaCarrito = this.ventaService.ordenarCarrito();
    this.listaCarrito.forEach((grupo, i) => {
      grupo.forEach((item) => {
        let posicionVentaAnterior = item.posicionVenta;
        if (
          this.ventaService.fechaSeleccionadaSinSessionValue &&
          this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
        ) {
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
            (element) => {
              if (
                element.posicionVenta == posicionVentaAnterior &&
                item.producto.ProductoId == element.productoId
              ) {
                element.posicionVenta = i + 1;
              }
            }
          );
        }
        item.posicionVenta = i + 1;
      });
      this.ventaService.guardarFechaSeleccionadasSinSesion();
    });
    //<< Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta y tenemos en cuenta que lo haga solo en la compra que es cuando cancelo el aforo
    if (!this.ventaService.modificandoReservaValue)
      if (espectaculoId && espectaculoId > 0) {
        //Yaribel 20230309 llamar a marcar todas las butacas del carrito
        this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
      } else {
        this.productosService.reservaAforoCarrito();
      }

    this.shouldResetTimer();
  }

  async deleteTipoEntradaAsync(compra, posicionVenta) {
    if (compra.producto.idEspectaculoAsociado) {
      this.deleteButacaAsync(null, posicionVenta);
      return;
    }
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.borrarDescuentos();
    this.ventaService.filtrarFechaSeleccionadaSinSesion(compra.posicionVenta); //Yaribel 20230123  Controlamos los registros en FechaSeleccionadaSinSession
    const espectaculoId = compra.producto.idEspectaculoAsociado;

    if (this.ventaService.modificandoReservaValue) {
      if (this.tieneEntradasAgrupadas(compra, true)) {
        this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(
          compra
        );
      } else {
        this.ventaService.carritoValue.eliminarEntradasTipo(compra);
      }
      this.continuProceso();
      if (!this.ventaService.modificandoReservaValue)
        this.productosService.reservaAforoCarrito();
    } else {
      if (this.tieneEntradasAgrupadas(compra, true)) {
        this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(
          compra
        );
      } else {
        this.ventaService.carritoValue.eliminarEntradasTipo(compra);
      }

      if (espectaculoId && espectaculoId > 0) {
        //Yaribel 20230309 llamar a desmarcar
        //this.butacasService.DesmarcarTodoCarrito().pipe(take(1)).subscribe();

        await this.butacasService.DesmarcarTodoCarritoPromesa().then((data) => {
          this.continuProceso();
          this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
        });
      } else {
        this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
        this.continuProceso();
        if (!this.ventaService.modificandoReservaValue)
          this.productosService.reservaAforoCarrito();
      }
    }

    this.shouldResetTimer();
  }

  continuProceso() {
    this.ventaService.setCarrito(this.ventaService.carritoValue);
    this.ventaService.guardarCesta();
    this.listaCarrito = this.ventaService.ordenarCarrito();
    this.listaCarrito.forEach((grupo, i) => {
      grupo.forEach((item) => {
        let posicionVentaAnterior = item.posicionVenta;
        if (
          this.ventaService.fechaSeleccionadaSinSessionValue &&
          this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
        ) {
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
            (element) => {
              if (
                element.posicionVenta == posicionVentaAnterior &&
                item.producto.ProductoId == element.productoId
              ) {
                element.posicionVenta = i + 1;
              }
            }
          );
        }
        item.posicionVenta = i + 1;
      });
      this.ventaService.guardarFechaSeleccionadasSinSesion();
    });
  }

  deleteEntrada(compra, datosProducto?, datosSeleccionados?) {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.borrarDescuentos();

    //Yaribel 20230123  Controlamos los registros en FechaSeleccionadaSinSession  que cuando borre una entrada que solo hay una y elimine el registro porque ya no existira el tipo de entrada
    if (compra.cantidad <= 1) {
      this.ventaService.filtrarFechaSeleccionadaSinSesion(compra.posicionVenta);
    }
    if (this.ventaService.modificandoReservaValue) {
      if (compra.sesionesSeleccionadas) {
        if (compra.sesionesSeleccionadas[0]) {
          if (
            compra.sesionesSeleccionadas[0].TipoAforo === "SP" ||
            this.tieneEntradasAgrupadas(compra)
          ) {
            this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(
              compra
            );
            this.ventaService.setCarrito(this.ventaService.carritoValue);
            this.ventaService.guardarCesta();
            this.listaCarrito = this.ventaService.ordenarCarrito();
            this.listaCarrito.forEach((grupo, i) => {
              grupo.forEach((item) => {
                let posicionVentaAnterior = item.posicionVenta;
                if (
                  this.ventaService.fechaSeleccionadaSinSessionValue &&
                  this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
                ) {
                  this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
                    (element) => {
                      if (
                        element.posicionVenta == posicionVentaAnterior &&
                        item.producto.ProductoId == element.productoId
                      ) {
                        element.posicionVenta = i + 1;
                      }
                    }
                  );
                }
                item.posicionVenta = i + 1;
              });
              this.ventaService.guardarFechaSeleccionadasSinSesion();
            });
            return;
          }
        }
      }
      this.ventaService.carritoValue.eliminarEntrada(
        compra,
        datosProducto,
        datosSeleccionados
      );
    } else {
      this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
      if (compra.sesionesSeleccionadas) {
        if (compra.sesionesSeleccionadas[0]) {
          if (
            compra.sesionesSeleccionadas[0].TipoAforo === "SP" ||
            this.tieneEntradasAgrupadas(compra)
          ) {
            this.ventaService.carritoValue.eliminarTodasEntradasPorTipoAforoSP(
              compra
            );
            this.productosService.reservaAforoCarrito();
            this.ventaService.setCarrito(this.ventaService.carritoValue);
            this.ventaService.guardarCesta();
            this.listaCarrito = this.ventaService.ordenarCarrito();
            this.listaCarrito.forEach((grupo, i) => {
              grupo.forEach((item) => {
                let posicionVentaAnterior = item.posicionVenta;
                if (
                  this.ventaService.fechaSeleccionadaSinSessionValue &&
                  this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
                ) {
                  this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
                    (element) => {
                      if (
                        element.posicionVenta == posicionVentaAnterior &&
                        item.producto.ProductoId == element.productoId
                      ) {
                        element.posicionVenta = i + 1;
                      }
                    }
                  );
                }
                item.posicionVenta = i + 1;
              });
              this.ventaService.guardarFechaSeleccionadasSinSesion();
            });
            return;
          }
        }
      }

      this.ventaService.carritoValue.eliminarEntrada(
        compra,
        datosProducto,
        datosSeleccionados
      );
      //this.productosService.reservaAforoCarrito(); //Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta
    }
    this.ventaService.setCarrito(this.ventaService.carritoValue);
    this.ventaService.guardarCesta();
    this.listaCarrito = this.ventaService.ordenarCarrito();
    this.listaCarrito.forEach((grupo, i) => {
      grupo.forEach((item) => {
        let posicionVentaAnterior = item.posicionVenta;
        if (
          this.ventaService.fechaSeleccionadaSinSessionValue &&
          this.ventaService.fechaSeleccionadaSinSessionValue.length > 0
        ) {
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(
            (element) => {
              if (
                element.posicionVenta == posicionVentaAnterior &&
                item.producto.ProductoId == element.productoId
              ) {
                element.posicionVenta = i + 1;
              }
            }
          );
        }
        item.posicionVenta = i + 1;
      });
      this.ventaService.guardarFechaSeleccionadasSinSesion();
    });
    //<< Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta y tenemos en cuenta que lo haga solo en la compra que es cuando cancelo el aforo
    if (!this.ventaService.modificandoReservaValue)
      this.productosService.reservaAforoCarrito();
    //Yaribel 20210511 Cambiamos la reserva de aforo cuando reordene las posiciones venta y tenemos en cuenta que lo haga solo en la compra que es cuando cancelo el aforo >>

    //manieva - reiniciamos el timer solamente sino tenemos mas productos
    if (this.listaCarrito.length === 0) this.timerService.reset();

    this.ventaService.setNumCarrito();
  }

  borrarDescuentos() {
    this.paymentService.borrarDescuentos();
  }

  // manieva - lo llevo para el service de paymentService
  // borrarDescuentos() {
  //   // Borrar todos descuentos si una vez aplicado se cambia
  //   this.ventaService.setDescuentoObjeto(undefined);
  //   this.paymentService.setHayDescuento(false);
  //   this.ventaService.setDescuentoCod(undefined);
  //   this.ventaService.setDescuento(undefined);
  //   this.ventaService.setDescuentoPer(undefined);
  //   this.ventaService.setDescuentoPersonalizado(undefined);
  //   this.ventaService.setDescuentoTotales(undefined);
  //   this.ventaService.setDescuentoClienteAsociado(undefined);
  //   this.ventaService.setDescuentoClienteAsociadoCod(undefined);
  //   this.ventaService.setDescuentoClienteAsociadoObjeto(undefined);
  //   sessionStorage.removeItem("descuento");
  // }

  tieneEntradasAgrupadas(compra, borrarTipoEntrada?): boolean {
    let borrarConjunto = false;
    const compraPorPosicionVenta =
      this.ventaService.carritoValue.compras.filter(
        (com) => com.posicionVenta === compra.posicionVenta
      );
    const productoAgrupar = compraPorPosicionVenta.find(
      (c) => c.producto.Agrupar === "1"
    );
    if (compra.producto.Agrupar === "1") {
      return borrarConjunto;
    }
    if (!productoAgrupar) {
      return borrarConjunto;
    }
    if (productoAgrupar) {
      const productos = compraPorPosicionVenta.filter(
        (c) => c.producto.Agrupar === "0"
      );
      if (productos.length > 1 && !borrarTipoEntrada) {
        return borrarConjunto;
      } else {
        if (
          compra.cantidad > parseInt(compra.producto.MinimoTarifa, 10) &&
          !borrarTipoEntrada
        ) {
          return borrarConjunto;
        } else {
          borrarConjunto = true;
          return borrarConjunto;
        }
      }
    }
  }

  anadir(grupoCompra) {
    this.butacasService.isModificar = false;
    this.butacasService.addMore = false;
    this.butacasService.selectedSpecialSeats = [];
    this.butacasService.setPosicionVenta(grupoCompra[0].posicionVenta);
    this.productosService.setCargandoValue(true);
    this.ventaService.setcomprarMasValue(false);
    this.ventaService.setCompra(new Compra());
    this.ventaService.setModificarValue(false);
    this.butacasMultiService.setlistDesmarcar([]);
    this.butacasMultiService.arrMarcadas = [];
    this.butacasMultiService.setCheckMultipleValue(false);
    this.butacasMultiService.setDisabledMultiple(false);
    this.butacasMultiService.setSiButacasValue(false);
    const gInternet = grupoCompra[0].producto.GrupoInternet;
    const eMenu = grupoCompra[0].producto.enlaceMenu;
    let espectaculoId;

    if (
      sessionStorage.getItem("admin") &&
      grupoCompra[0]?.producto?.butacas?.length > 0
    ) {
      espectaculoId = grupoCompra[0].producto.butacas[0].EspectaculoId;
    } else {
      espectaculoId = grupoCompra[0].producto?.idEspectaculoAsociado
        ? grupoCompra[0].producto?.idEspectaculoAsociado
        : 0;
    }

    if (espectaculoId && espectaculoId > 0) {
      this.ventaService.setFechaSeleccionadaValue(
        grupoCompra[0].producto.Fecha
      );
      this.ventaService.setHoraSeleccionadaValue(grupoCompra[0].producto.Hora);
    }

    this.initValues(gInternet, eMenu, espectaculoId, false);
  }

  modificar(grupoCompra) {
    this.productosService.setCargandoValue(true);
    this.ventaService.setCompra(new Compra());
    this.ventaService.setModificarValue(true);
    this.ventaService.setcomprarMasValue(true);
    this.butacasService.isModificar = true;
    this.butacasService.addMore = false;
    this.butacasMultiService.setCheckMultipleValue(false);
    this.butacasMultiService.setSiButacasValue(false);
    this.butacasMultiService.setDisabledMultiple(true);

    let gInternet;
    const eMenu = grupoCompra[0].producto.enlaceMenu;
    let espectaculoId = grupoCompra[0].producto.idEspectaculoAsociado;

    if (espectaculoId && +espectaculoId > 0) {
      this.ventaService.setFechaSeleccionadaValue(
        moment(grupoCompra[0].producto.butacas[0].FechaSesion, "YYYY/MM/DD")
      );
      this.ventaService.setHoraSeleccionadaValue(
        moment(grupoCompra[0].producto.butacas[0].HoraSesion, "HH:mm")
      );
      this.butacasService.setPosicionVenta(grupoCompra[0].posicionVenta);
    }
    gInternet = grupoCompra[0].producto.GrupoInternet;

    grupoCompra.forEach((producto) => {
      this.ventaService.sendProductoModificar(producto);
    });

    if (grupoCompra[0].datos) {
      if (grupoCompra[0].datos.length) {
        //productos con sesion y nominativos
        if (
          grupoCompra[0].datos[0].cboRangoEdad ||
          grupoCompra[0].datos[0].cboTipoTabla ||
          grupoCompra[0].datos[0].cboNivel
        ) {
          this.productosService.setFiltroGrupoValue(
            grupoCompra[0].datos[0].cboRangoEdad
          );
          this.productosService.setFiltroTipoValue(
            grupoCompra[0].datos[0].cboTipoTabla
          );
          this.productosService.setFiltroNivelValue(
            grupoCompra[0].datos[0].cboNivel
          );
        }
        if (grupoCompra[0].sesionesSeleccionadas) {
          if (grupoCompra[0].sesionesSeleccionadas.length) {
            this.productosService.setFiltroDiasValue(
              grupoCompra[0].sesionesSeleccionadas.length
            );
          }
        }
        if (grupoCompra[0].datos[0].cboIdiomaVisita) {
          this.productosService.setFiltroIdiomaVisitaValue(
            grupoCompra[0].datos[0].cboIdiomaVisita
          );
        }
      } else {
        // productos con sesion y con filtros
        if (
          grupoCompra[0].datos.cboRangoEdad ||
          grupoCompra[0].datos.cboTipoTabla ||
          grupoCompra[0].datos.cboNivel ||
          grupoCompra[0].datos.cboIdiomaVisita
        ) {
          this.productosService.setFiltroGrupoValue(
            grupoCompra[0].datos.cboRangoEdad
          );
          this.productosService.setFiltroTipoValue(
            grupoCompra[0].datos.cboTipoTabla
          );
          this.productosService.setFiltroNivelValue(
            grupoCompra[0].datos.cboNivel
          );
        }
        if (grupoCompra[0].sesionesSeleccionadas) {
          if (grupoCompra[0].sesionesSeleccionadas.length) {
            this.productosService.setFiltroDiasValue(
              grupoCompra[0].sesionesSeleccionadas.length
            );
          }
        }
        if (grupoCompra[0].datos.cboIdiomaVisita) {
          this.productosService.setFiltroIdiomaVisitaValue(
            grupoCompra[0].datos.cboIdiomaVisita
          );
        }
      }
    }

    this.initValues(gInternet, eMenu, espectaculoId, true);
    // timer - intentamos resumir si existe un contador
    this.timerService.resume();
  }

  initValues(gInternet, eMenu, espectaculoId, isModificar) {
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === eMenu
    );
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(eMenu);
    if (gInternet === 7 && enlaceMenuNombre === "SKI_SNOW") {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else {
      this.productosService.setOtrosProductosValue(enlaceMenuNombre);
    }
    if (sessionStorage.getItem("token")) {
      const datosUrl = JSON.parse(this.productosService.datosInitValue);
      this.productosService.setDatosInitValue({
        empresa: datosUrl.empresa,
        m: enlaceMenuNombre,
        gi: gInternet,
        userId: JSON.parse(this.productosService.datosInitValue).userId,
        lg: datosUrl.lg,
        u: JSON.parse(this.productosService.datosInitValue).u,
        p: JSON.parse(this.productosService.datosInitValue).p,
        espectaculoId: espectaculoId,
      });
      sessionStorage.setItem(
        "token",
        this.initService.funcionEncript(this.productosService.datosInitValue)
      );
    } else {
      this.productosService.setDatosInitValue({
        empresa: this.proyecto,
        m: enlaceMenuNombre,
        gi: gInternet,
        espectaculoId: espectaculoId,
      });
      sessionStorage.setItem(
        "token",
        this.initService.funcionEncript(this.productosService.datosInitValue)
      );
    }
    if (espectaculoId && espectaculoId > 0) {
      this.productosService
        .getEspectaculos(espectaculoId)
        .pipe(
          map((res) => {
            if (res) {
              const espectaculo = res.EspectaculosDisponible.find(
                (x) =>
                  x.EspectaculosId == espectaculoId &&
                  x.EnlaceMenuId == eMenu &&
                  x.GrupoInternetId == gInternet
              );
              this.productosService.setnombreGrupoValue(
                espectaculo.NombreGrupo
              );
              this.productosService.setEspectaculoValue(espectaculo);
              this.productosService.setInfoGrupoValue(espectaculo);
            }
          })
        )
        .pipe(take(1))
        .subscribe(() => {
          this.butacasService.getEstadosButacasInicial();
          this.productosService.setCargandoValue(false);
          this.productosService.setVentaEspectaculo(true);
          if (isModificar) {
            this.router.navigate(["/tienda/espectaculo/2"]);
          } else {
            this.router.navigate(["/tienda/espectaculo/1"]);
          }
        });
    } else {
      this.productosService
        .getProductos()
        .pipe(
          tap((res) => {
            if (res && res?.DictTarifasEntradas) {
              this.productosService.setInfoGrupoValue(
                res.DictTarifasEntradas[
                  this.productosService.getGrupoInternet().gi
                ]
              );
              this.productosService.setnombreGrupoValue(
                res.DictTarifasEntradas[
                  this.productosService.getGrupoInternet().gi
                ]?.NombreGrupo
              );
              // this.productosService.setProductosValue(
              //   res.DictTarifasEntradas[gInternet].DictTarifasProductos,
              //   eMenu,
              //   parseInt(gInternet, 10),
              //   res.DictTarifasEntradas[gInternet].TipoReservaId,
              //   res.DictTarifasEntradas[
              //     this.productosService.getGrupoInternet().gi
              //   ]?.NombreGrupo
              // );
            }
          }),
          take(1)
        )
        .subscribe((res) => {
          if (res && res?.DictTarifasEntradas) {
            this.productosService.setProductosValue(
              res.DictTarifasEntradas[gInternet].DictTarifasProductos,
              eMenu,
              parseInt(gInternet, 10),
              res.DictTarifasEntradas[gInternet].TipoReservaId,
              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ]?.NombreGrupo
            );
          }
          setTimeout(() => {
            this.productosService.setCargandoValue(false);
            this.productosService.setVentaEspectaculo(false);
            this.productosService.setEspectaculoValue("");
            if (enlaceMenu && enlaceMenu.iPadre) {
              this.router.navigate(["/tienda/producto/1"], {
                queryParams: { iPadre: enlaceMenu.iPadre },
              });
            } else {
              this.router.navigate(["/tienda/producto/1"]);
            }
          }, 1000);
        });
    }
  }

  datosCarrito(posicionVenta) {
    let encontrado = false;
    let datos = [];
    posicionVenta.forEach((carrito) => {
      if (!encontrado && carrito.datos) {
        encontrado = true;
        datos.push(carrito.datos);
      }
    });
    return datos;
  }

  //se reemplazo por obtenerVisitas
  sesionesSeleccionadasCarrito(posicionVenta) {
    let encontrado = false;
    let datos = [];
    posicionVenta.forEach((carrito) => {
      if (
        !encontrado &&
        carrito.sesionesSeleccionadas &&
        carrito.sesionesSeleccionadas.length > 0
      ) {
        encontrado = true;
        datos.push(carrito.sesionesSeleccionadas);
      }
    });

    if (!encontrado) {
      let fecha =
        this.ventaService.fechaSeleccionadaSinSessionValue &&
        this.ventaService.fechaSeleccionadaSinSessionValue.length > 0 &&
        this.ventaService.fechaSeleccionadaSinSessionValue.find(
          (find) =>
            find.posicionVenta == posicionVenta[0].posicionVenta &&
            find.productoId == posicionVenta[0].producto.ProductoId
        )
          ? moment(
              this.ventaService.fechaSeleccionadaSinSessionValue.find(
                (find) =>
                  find.posicionVenta == posicionVenta[0].posicionVenta &&
                  find.productoId == posicionVenta[0].producto.ProductoId
              ).fecha,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : "";
      if (fecha && fecha != "")
        datos.push([
          {
            NombreRecinto: this.translateService.data.FechaVisita,
            Fecha: fecha,
          },
        ]);
    }
    return datos;
  }

  obtenerInfoSesion(sesion) {
    let info = "";
    info += sesion.Fecha;
    if (sesion.TipoAforo === "SD") {
      info += " " + sesion.HoraInicio + "-" + sesion.HoraFin;
    } else {
      if (sesion.HoraInicio) info += "-" + sesion.HoraInicio;
    }
    if (sesion.IdiomaSesion) info += this.idiomaSesion(sesion.IdiomaSesion);

    return info;
  }

  getDivisasValue() {
    return this.configurationService.divisasValue;
  }

  getVentaService() {
    return this.ventaService;
  }

  getComision() {
    return this.ventaService.getComision();
  }
  getIVAComision() {
    return this.ventaService.getIVAComision();
  }

  getDescuentoCliente() {
    return this.ventaService.getDescuentoCliente();
  }

  getPrecioTotalconComision() {
    return this.ventaService.getPreciofinal();

    // let total = this.precioTotal() - (this.ventaService.descuentoValue?this.ventaService.descuentoValue.Descuento || 0 : 0) +  this.ventaService.getComision() + this.ventaService.getIVAComision() - this.getDescuentoCliente();
    // return total;
  }

  calcularPrecios() {
    this.ventaService.setIncluirComision(!this.incluirComision);
    this.comision = this.getComision();
    this.IVAComision = this.getIVAComision();
    this.precioFinal = this.getPrecioTotalconComision();
    this.descuentoCliente = this.getDescuentoCliente();
  }

  // se reemplazo por obtenerEspectaculos
  sesionesSeleccionadasButacasCarrito(posicionVenta) {
    let encontrado = false;
    let datos = [];
    posicionVenta.forEach((carrito) => {
      if (
        !encontrado &&
        carrito.producto.idEspectaculoAsociado &&
        carrito.producto.idEspectaculoAsociado > 0 &&
        carrito.producto.butacas &&
        carrito.producto.butacas.length > 0
      ) {
        encontrado = true;
        datos.push([
          {
            NombreEspectaculo: carrito.producto.butacas[0].NombreEspectaculo,
            Fecha: carrito.producto.butacas[0].FechaSesion,
            HoraInicio: carrito.producto.butacas[0].HoraSesion,
            NombreGruposRecinto:
              carrito.producto.butacas[0].NombreGruposRecinto,
          },
        ]);
      }
    });

    return datos;
  }

  /**
   * Obtains the list of spectacles based on the given position of sale.
   *
   * @param {type} posicionVenta - Type of position of sale
   * @return {type} description of return value
   */
  obtenerEspectaculos(posicionVenta) {
    const espectaculos = posicionVenta
      .map((compra) => compra.producto)
      // .filter(
      //   //NOTE: cuando se carga la compra no hay idEspectaculoAsociado al final del proceso de borrar todo, este parametro se pierde, buscar otra cosa para validar
      //   (producto) =>
      //     (producto.idEspectaculoAsociado && producto.idEspectaculoAsociado > 0)
      // )
      .filter((producto) => producto.butacas && producto.butacas.length > 0)
      .map((producto) => producto.butacas[0])
      .map((butaca) => {
        return {
          NombreEspectaculo: butaca.NombreEspectaculo,
          Fecha: butaca.FechaSesion,
          HoraInicio: butaca.HoraSesion,
          NombreGruposRecinto: butaca.NombreGruposRecinto,
        };
      });
    if (espectaculos.length > 0) return [espectaculos];
  }

  obtenerVisitas(posicionVenta) {
    const visitas = posicionVenta
      .filter(
        (compra) =>
          compra.sesionesSeleccionadas &&
          compra.sesionesSeleccionadas.length > 0
      )
      .filter(
        (compra) =>
          !compra.producto.butacas || compra.producto.butacas.length == 0
      )
      .map((compra) => compra.sesionesSeleccionadas)
      .filter((sessiones, i) => i == 0);

    // para la visualizacion solo se queda con el primer elemento
    if (visitas.length > 0) return visitas;

    const visitasSinFecha = posicionVenta
      .filter(
        (compra) =>
          compra.posicionVenta ==
          this.ventaService.fechaSeleccionadaSinSessionValue.posicionVenta
      )
      .filter(
        (compra) =>
          compra.producto.ProductoId ==
          this.ventaService.fechaSeleccionadaSinSessionValue.productoId
      )
      .map((compra) => {
        let visita = this.ventaService.fechaSeleccionadaSinSessionValue.find(
          (e) =>
            e.posicionVenta == compra.posicionVenta &&
            e.productoId == compra.producto.ProductoId
        );

        return {
          NombreRecinto: this.translateService.data.FechaVisita,
          Fecha: visita
            ? moment(visita.fecha, "DD/MM/YYYY").format("DD/MM/YYYY")
            : "",
        };
      });

    if (visitasSinFecha.length > 0) return [visitasSinFecha];

    return [];
  }

  obtenerInfoSesionButacas(sesion) {
    let info = "";
    let arrDate = sesion.Fecha.split("/");
    const dateFormat = `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}`;

    info += dateFormat;
    if (sesion.HoraInicio) info += "-" + sesion.HoraInicio;
    if (sesion.IdiomaSesion) info += this.idiomaSesion(sesion.IdiomaSesion);
    return info;
  }

  esEspectaculo(producto) {
    return (
      // producto.idEspectaculoAsociado &&
      // producto.idEspectaculoAsociado > 0 &&
      producto.butacas && producto.butacas.length > 0
    );
  }

  private checkDelete = (id: string): boolean => {
    if (this.listaCarrito.length === 0 || !id) {
      return;
    }
    let seats = [];
    this.listaCarrito.forEach((car) => {
      car.forEach((item) => {
        item.producto.butacas.forEach((seat) => {
          seat["posicionVenta"] = item.posicionVenta;
          seat["grupoInternet"] = item.producto.GrupoInternet;
          seats = [...seats, seat];
        });
      });
    });
    const seatSelect = seats.find((seat) => seat.idRB === id);
    const position = seatSelect["posicionVenta"];
    this.butacasService.findButacas(position);
    let seatDelete = this.butacasService.selectedSpecialSeats.find(
      (seat) => seat.PKIDRB === +id
    );
    seatDelete = {
      ...seatDelete,
      EnlaceMenuId: seatSelect.EnlaceMenuId,
      grupoInternet: seatSelect.grupoInternet,
    };
    const result = this.butacasService.uncheckSpecialRates(seatDelete);

    return result;
  };

  async deleteButacaAsync(compra, posicionVenta) {
    this.modalService.open("modalCarrito", false, compra, posicionVenta);
  }
}
