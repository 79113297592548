export class Paginator{
    page: number;
    pagelements: number;
    orderby: string;
    ordertype: string;


  constructor(
    page: number, 
    pagelements: number, 
    orderby?: string, 
    ordertype?: string
) {
    this.page = page
    this.pagelements = pagelements
    this.orderby = orderby
    this.ordertype = ordertype
  }
    
}