import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import * as d3 from "d3";
import { ButacasService } from "../servicios/butacas.service";
import { InfoMapasService } from "../servicios/info-mapas.service";

declare const InstallTrigger: any;

@Component({
  selector: "iacpos-mapa-recinto",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./mapa-recinto.component.html",
  styleUrls: ["./mapa-recinto.component.css"],
})
export class MapaRecintoComponent implements OnInit, AfterViewInit, OnChanges {
  width: number;
  height: number;
  domRect: any;
  svg: any;
  tooltip: any;
  popupmenu = false;
  contextMenuShowing = false;

  @Input() nombreRecinto: string;
  @Input() minimapaZoomTransformation: Event;
  @Input() entradasSeleccionadas: Event;
  @Input() contextual: boolean = false;

  @Output() eventMapTransformation = new EventEmitter<Event>();

  constructor(
    private infoMapasService: InfoMapasService,
    private butacasService: ButacasService
  ) {}

  ngOnInit(): void {
    this.butacasService.nombreRecinto = this.nombreRecinto;
  }

  interactionMap() {

    d3.select(".principal").style("width", "100%");

    d3.select("#popuprender").on("click", (event) => {
      this.getmapacontext(event);
    });

    d3.select("#popuprendermenos").on("click", (event) => {
      this.zoomout();
    });

    d3.select("#popuprendermas").on("click", (event) => {
      this.zoomin();
    });


    ["scroll", "resize"].forEach((eventType) => {
      document.addEventListener(eventType, (event) => {
        if (this.contextMenuShowing) this.getmapacontext(event);
      });
    });

    window.addEventListener("resize", (event) => {
      if (this.contextMenuShowing) this.getmapacontext(event);
    });

    d3.select(".principal").on("contextmenu", (event) => {
      event.preventDefault();
      if (this.contextMenuShowing) {
        d3.select("#minimapadiv").style("display", "none");
        this.contextMenuShowing = false;
      } else {
        let d3_target = d3.select(event.target);

        this.contextMenuShowing = true;


        let canvas = d3.select("#contextualzone");
        let popupmen = d3.select("#minimapadiv");
        popupmen.style("display", "block");
        popupmen.style("position", "fixed");
        popupmen
          .style("left", event.clientX + "px")
          .style("top", event.clientY + "px");
        canvas.append(function () {
          return popupmen.node();
        });
      }
    });
    //http://jsfiddle.net/thudfactor/dzw9h/
  }

  getmapacontext(event) {
    if (this.contextMenuShowing) {
      d3.select("#minimapadiv").style("display", "none");
      this.contextMenuShowing = false;
    } else {
      d3.select("#Mapvisor").style("display", "block");

      this.contextMenuShowing = true;
      let canvas = d3.select("#contextualzone");
      let popupmen = d3.select("#minimapadiv");
      popupmen
        .style("display", "block")
        .style("position", "fixed")
        .style("left", event.clientX + "px")
        .style("top", event.clientY + "px");
      canvas.append(function () {
        return popupmen.node();
      });
    }
  }

  resetSalt() {
    this.infoMapasService.saltCoord.x = 0;
    this.infoMapasService.saltCoord.y = 0;
  }

  setSvgSize(): void {
    this.infoMapasService
      .getSVG(this.nombreRecinto)
      .subscribe((data: string) => {
        const svgMock = d3
          .select("#minimapa")
          .attr("preserveAspectRatio", "xMidYMid meet")
          // .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)

          .attr("width", this.infoMapasService.boxWidth)
          .attr("height", this.infoMapasService.boxHeight)
          .style("cursor", "pointer");

        const g = svgMock.append("g");
        g.attr("id", "mmRecinto");
        const rec = document.getElementById("mmRecinto");
        if (rec) {
          rec.insertAdjacentHTML("beforeend", data);
          const rect = rec.getBoundingClientRect();

          this.width = 1.2 * rect.width;
          this.height = 1.5 * rect.height;
          this.infoMapasService.altoAncho.next([
            1.5 * rect.height,
            1.2 * rect.width,
          ]);
          if (typeof InstallTrigger !== "undefined") {
            // firefox
            const elementos = document.getElementsByTagName("path");
            const medidas = [];
            let medidaB = 0;
            let medidaT = 0;
            let medidaR = 0;
            let medidaL = 0;
            for (let index = 0; index < elementos.length; index++) {
              medidas.push(elementos[index].getBoundingClientRect());
            }
            medidaT = medidas[0]["top"];
            medidaL = medidas[0]["left"];
            for (let index = 0; index < medidas.length; index++) {
              if (medidas[index]["bottom"] > medidaB) {
                medidaB = medidas[index]["bottom"];
              }
              if (medidas[index]["top"] < medidaT) {
                medidaT = medidas[index]["top"];
              }
              if (medidas[index]["right"] > medidaR) {
                medidaR = medidas[index]["right"];
              }
              if (medidas[index]["left"] < medidaL) {
                medidaL = medidas[index]["left"];
              }
            }

            const Alto = medidaB - medidaT;
            const ancho = medidaR - medidaL;
            this.width = 1.2 * ancho;
            this.height = 1.5 * Alto;
            this.infoMapasService.altoAncho.next([1.5 * Alto, 1.2 * ancho]);
          }
        }

        this.agregarEstructura();

        // this.agregarSVG();
        // antigua funcion agregarSVG (comentada mas abajo)
        const rec2 = document.getElementById("recinto");
        if (rec2) {
          rec2.insertAdjacentHTML("beforeend", data);
        }

        this.agregarTooltip();
        this.butacasService.getEstadosButacas(
          this.infoMapasService.recinto,
          this.nombreRecinto
        );
      });
  }

  ngAfterViewInit(): void {
    this.setSvgSize();
    if (this.contextual) this.interactionMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.infoMapasService.recinto) {
      let transformed;
      if (changes.minimapaZoomTransformation.currentValue) {
        const data = changes.minimapaZoomTransformation.currentValue;
        transformed = {
          k: 1 / data.k,
          x: data.x - data.x * 2,
          y: data.y - data.y * 2,
        };
      } else {
        transformed = {
          k: 1,
          x: 0,
          y: 0,
        };
      }
      this.infoMapasService.recinto.attr(
        "transform",
        "scale(" +
          transformed.k +
          ") translate(" +
          transformed.x +
          ", " +
          transformed.y +
          ") "
      );
    }
  }

  recargarMapa() {
    this.butacasService.getEstadosButacas(
      this.infoMapasService.recinto,
      this.nombreRecinto
    );
  }

  // map dragged
  zoomed = (event) => {
    // MMM
    const transformed = event.transform;
    if (this.infoMapasService.lastMapPressed !== "map") {
      this.infoMapasService.saltCoord.x = 2 * transformed.x;
      this.infoMapasService.saltCoord.y = 2 * transformed.y;
      transformed.k = 1 / this.infoMapasService.rectTranslation.k;
      transformed.x = -this.infoMapasService.rectTranslation.x;
      transformed.y = -this.infoMapasService.rectTranslation.y;
      this.infoMapasService.lastMapPressed = "map";
    }
    this.infoMapasService.lastEvent = transformed;
    this.infoMapasService.recinto.attr("transform", transformed);
    this.eventMapTransformation.emit(transformed);
    // tslint:disable-next-line: semicolon
  };

  zoomin() {
    this.infoMapasService.lastEvent.k += 0.1;
    this.zoomed({ type: "zoom", transform: this.infoMapasService.lastEvent });
  }

  zoomout() {
    this.infoMapasService.lastEvent.k -= 0.1;
    this.zoomed({ type: "zoom", transform: this.infoMapasService.lastEvent });
  }

  agregarEstructura() {
    const zoom = d3
      .zoom()
      .scaleExtent([0.9, 10])
      .translateExtent([
        [0, 0],
        [this.width, this.height],
      ])
      .extent([
        [0, 0],
        [this.width, this.height],
      ])
      .on("zoom", this.zoomed);

    this.svg = d3
      .select("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      /*.attr('viewBox', '0 0 700 550')*/
      .attr("viewBox", "0 0 " + this.width + " " + this.height)
      .attr("width", this.infoMapasService.boxWidth)
      .attr("height", this.infoMapasService.boxHeight)
      .on("mousedown", () => {
        this.resetSalt();
      })
      .on("touchend", () => {
        this.resetSalt();
      })
      .call(zoom);
    this.infoMapasService.recinto = this.svg
      .append("g")
      .attr("id", "recinto")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("transform", "translate(-20,0) scale(1.3)")
      .attr("x", "0")
      .attr("y", "0");
  }

  agregarTooltip() {
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
  }

  updateBox(w: number, h: number) {
    this.svg.attr("width", w).attr("height", h);
  }

  onResize(event) {
    this.svg
      .attr("width", this.infoMapasService.boxWidth)
      .attr("height", this.infoMapasService.boxHeight);
  }
}
