import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DateSelectorComponent } from './pages/date-selector/date-selector.component';
import { HourSelectorComponent } from './pages/hour-selector/hour-selector.component';
import { ClientDataComponent } from './pages/client-data/client-data.component';
import { ShopGuardGuard } from '../shop-guard.guard';

const routes: Routes = [
    { path: '1', component: DateSelectorComponent, canActivate: [ShopGuardGuard] },
    { path: '2', component: HourSelectorComponent, canActivate: [ShopGuardGuard] },
    { path: '3', component: ClientDataComponent, canActivate: [ShopGuardGuard] },
    { path: '**', redirectTo: 'reservarEntradas' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TicketSalesRoutingModule { }
