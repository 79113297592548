import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  private configurationSubject: BehaviorSubject<any>;
  public configuration$: Observable<any>;

  private divisasSubject: BehaviorSubject<any>;
  public divisas$: Observable<any>;

  private divisasISOSubject: BehaviorSubject<any>;
  public divisasISO$: Observable<any>;

  constructor(private http: HttpClient, private config: AppConfiguration) {
    this.configurationSubject = new BehaviorSubject(undefined);
    this.configuration$ = this.configurationSubject.asObservable();

    this.divisasSubject = new BehaviorSubject(undefined);
    this.divisas$ = this.divisasSubject.asObservable();

    this.divisasISOSubject = new BehaviorSubject(undefined);
    this.divisasISO$ = this.divisasSubject.asObservable();
  }

  public get configurationValue() {
    return this.configurationSubject.value;
  }

  setConfigurationValue(configuration) {
    this.configurationSubject.next(configuration);
  }

  public get divisasValue() {
    return this.divisasSubject.value;
  }

  setDivisasValue(divisas) {
    this.divisasSubject.next(divisas);
  }

  public get divisasISOValue() {
    return this.divisasISOSubject.value;
  }

  setDivisasISOValue(divisas) {
    this.divisasISOSubject.next(divisas);
  }

  getDivisas() {
    let ISO = this.divisasISOValue.CodigoISO;

    const body = {
      Nombre: "",
      ISO: ISO,
    };
    return this.http
      .post(`${this.config.getConfig("API_URL")}/api/Maestros/GetDivisas`, body)
      .pipe(
        map((res: any) => {
          this.setDivisasValue(res.DatosResult.ListaDivisas[0]);
        })
      );
  }

  getCaracteristicasAdicionalesProyecto() {
    if (this.configurationValue && this.configurationValue.datosProyecto) {
      let caracteristicasAdicionales = JSON.parse(
        this.configurationValue.datosProyecto.CaracteristicasAdicionales
      );
      if (
        caracteristicasAdicionales != null &&
        caracteristicasAdicionales.CaracteristicasAdicionales != null
      )
        return caracteristicasAdicionales.CaracteristicasAdicionales;
    }
    return [];
  }

  cancelarAforoCarrito() {
    const body = {
      Accion: 0,
      SesionInternetId: this.configurationValue.currentSession.sessionId,
      RecintosSesionesId: "",
      IdEntrada: "",
    };
    return this.http
      .post(
        `${this.config.getConfig("API_URL")}/api/CancelarReservaAforo`,
        body
      )
      .pipe(
        map((res: any) => {
          return res.DatosResult;
        })
      );
  }
}
