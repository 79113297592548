import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

@Component({
  selector: "dialogo-popUp",
  templateUrl: "./dialogo-popup-layout.html",
  styleUrls: ["./dialogo-popup-layout.scss"],
})
export class DialogoPopUp {
  datos;
  constructor(
    public dialogRef: MatDialogRef<DialogoPopUp>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.datos = data;
  }

  onNoClick(): void {
    this.dialogRef.close("NO");
  }

  onSiClick(): void {
    this.dialogRef.close("SI");
  }
}
