import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account/my-account.component';
import { SignupComponent } from './signup/signup.component';
import { MyShoppingComponent } from './my-shopping/my-shopping.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserComponent } from './user.component';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserRoutingModule } from './user-routing.module';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from '../core/http-helpers/http-request.interceptor';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings,RecaptchaFormsModule } from 'ng-recaptcha';
import { NewPasswordComponent } from './new-password/new-password.component';
import { SharedModule } from '../shared/shared.module';
import { TicketSalesModule } from '../business/ticket-sales/ticket-sales.module';
import { MatTabsModule } from '@angular/material/tabs';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../user/login/login.component';
import { MyOperationsComponent } from './my-operations/my-operations.component';
import { FilterComponent } from '../shared/components/filter/filter.component';
import { TableComponent } from '../shared/components/tables/table/table.component';
import { MaterialModule } from '../shared/material/material.module';
import { InputFilterComponent } from '../shared/components/input-filter/input-filter.component';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { PanelCustoman } from '../shared/panel-customan/panel.customan';
import { ModalMoreInformationComponent } from '../shared/dialogs/modal-more-information/modal-more-information.component';

@NgModule({
  declarations: [MyAccountComponent, MyOperationsComponent, FilterComponent, TableComponent, PanelCustoman, InputFilterComponent, AlertComponent, MyShoppingComponent, ForgotPasswordComponent, UserComponent,
    ChangePasswordComponent,LoginComponent,SignupComponent,NewPasswordComponent,ModalMoreInformationComponent],
  imports: [
    UserRoutingModule,
    RouterModule,
    CommonModule,
    TicketSalesModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    MatTabsModule,
    MaterialModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment["siteKey"],
      } as RecaptchaSettings,
    }
  ],
  exports: [MyAccountComponent, MyOperationsComponent, FilterComponent, TableComponent, PanelCustoman, InputFilterComponent, AlertComponent, MyShoppingComponent, ForgotPasswordComponent, UserComponent,  ChangePasswordComponent ,LoginComponent,SignupComponent,ModalMoreInformationComponent ]
})
export class UserModule {
  // constructor() {
  //   library.add(fas, far, fab);
  // }
  constructor(library: FaIconLibrary, config: FaConfig) {
    config.fallbackIcon = faBan;
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fab);
  }
}
