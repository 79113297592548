import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { VentaService } from "../../../../core/services/venta.service";
import { SesionService } from "src/app/core/services/sesion.service";
import { ProductosService } from "src/app/business/productos.service";
import { Compra } from "src/app/models/compra.model";
import { CompraCarrito } from "src/app/models/compra-carrito.model";
import { Location } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { CalendarService } from "../date-selector/calendar/calendar.service";
import { InitService } from "src/app/core/init/init.service";
import { TranslateService } from "src/app/shared/translate.service";
import { AppConfiguration } from "src/app/app.configuration";
import { take } from "rxjs/internal/operators/take";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalService } from "src/app/shared/modal.service";
import { CalendarModalService } from "./calendar-modal/calendar-modal.service";
import { GoogleAnalyticsEventsServiceService } from "src/app/shared/google-analytics-events-service.service";

@Component({
  selector: "app-hour-selector",
  templateUrl: "./hour-selector.component.html",
  styleUrls: ["./hour-selector.component.scss"],
})
export class HourSelectorComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  private ngUnsubscribe = new Subject();

  entradas: any[] = [];
  compra: CompraCarrito;
  controlHora: any;
  noAforo = this.sesionService.noAforoValue;
  recinto;
  recintos;
  idiomaForm: FormGroup;
  sesionesPorRecinto = [];
  tieneAforo = true;
  idiomaSesion = this.translate.getLocale();
  proyecto = this.config.getProyecto();
  pantallas: [];
  mostrar = this.calendarService.mostrarValue;
  mostrarElegirProfesor = false;
  mostrarDisponibilidad;
  loading;
  mostrarProductos;
  variosCalendarios = this.calendarService.mostrarFiltroDiasValue;
  mensajeMismaSesion = [];
  productos = [];
  mensajeErrorModificar;
  cargado;
  tieneCalendarioPorRecinto;
  idiomas;
  mostrarIdiomaSesion = 0;

  constructor(
    private config: AppConfiguration,
    private _location: Location,
    private router: Router,
    private ventaService: VentaService,
    private sesionService: SesionService,
    private productosService: ProductosService,
    private calendarService: CalendarService,
    private initService: InitService,
    private translate: TranslateService,
    private modalService: ModalService,
    private calendarModalService: CalendarModalService,
    private GAService: GoogleAnalyticsEventsServiceService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.initService.getIdiomas().subscribe((idiomas) => {
      if (idiomas.DatosResult) {
        this.idiomas = JSON.parse(
          JSON.stringify(idiomas.DatosResult.ListaIdiomas)
        );
        this.idiomas.reverse();
      }
    });
    this.mensajeErrorModificar = false;
    this.calendarService.setMostrar(true);
    this.productosService.cargandoComponente$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((carga) => (this.mostrarProductos = carga));
    this.productosService.cargandoComponente2$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((carga) => (this.loading = carga));
    this.mostrarIdiomaSesion =
      JSON.parse(
        this.productosService.getEnlaceMenuId().CaracteristicasAdicionales
      ).CaracteristicasAdicionales.MostrarIdiomaSesion || 0;
    // this.productosService.getEnlaceMenuId().MostrarIdiomaSesion || 0;
    const caracteristicasCalendario =
      this.initService.chCalendarioValue.CaracteristicasCalendario;
    const grupoInternet = parseInt(
      this.productosService.getGrupoInternet().gi,
      10
    );
    this.calendarService.mostrarCalendario(
      "Paso2",
      caracteristicasCalendario,
      grupoInternet
    );
    this.productosService.recintosTodos$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((recintos) =>
        this.calendarService.calendarioPorRecinto(
          caracteristicasCalendario,
          grupoInternet,
          recintos
        )
      );
    this.calendarService.mostrar$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mostrar) => (this.mostrar = mostrar));
    this.calendarService.tieneCalendarioPorRecinto$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (tieneCalendario) => (this.tieneCalendarioPorRecinto = tieneCalendario)
      );
    this.noAforo = false;
    if (this.calendarService.calendarioPaso2Value) {
      this.calendarService.sesionesCargadas$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((cargadas) => {
          if (cargadas) {
            this.sesionesPorProductoSesionesPorRecinto();
            this.selectPorRecintoBefore();
          }
        });
    } else {
      this.sesionesPorProductoSesionesPorRecinto();
      this.selectPorRecintoBefore();
    }

    if (this.sesionesPorRecinto) {
      let sd;
      this.sesionesPorRecinto.forEach((arr) => {
        sd = arr.find((item) => item.TipoAforo === "SD");
      });
      if (sd) {
        this.selectSesionPorRecinto(sd, 0);
      }
    }
  }

  ngAfterContentInit() {
    if (this.initService.aceptaCookieGoolgeValue) {
      this.GAService.emitEvent("event", "paso-2"); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
      this.GAService.callStepView("hourSelector");
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  sesionesPorProductoSesionesPorRecinto() {
    this.sesionesPorRecinto = [];
    this.ventaService.recintosList = [];
    if (this.productosService.muestraSesionesPorProducto) {
      this.ventaService.horariosList =
        this.ventaService.compraValue.productos.map((producto) => {
          return {
            sesiones: this.sesionService.getSesionesPorProducto(
              producto.producto
            ),
            producto,
          };
        });
      if (this.ventaService.horariosList) {
        this.ventaService.horariosList.forEach((recinto) => {
          if (recinto.sesiones) {
            recinto.sesiones.forEach((sesion) => {
              if (
                !this.ventaService.recintosList.includes(sesion.NombreRecinto)
              ) {
                this.ventaService.recintosList.push(sesion.NombreRecinto);
              }
            });
          }
        });
      }
      this.mostrarProfesores();
    } else if (!this.productosService.muestraSesionesPorProducto) {
      const producto = this.ventaService.compraValue.productos[0];
      this.ventaService.horariosList =
        this.sesionService.getSesionesPorProducto(producto.producto);
      if (this.productosService.recintos) {
        this.productosService.recintos.forEach((recinto) => {
          const sesiones = [];
          this.ventaService.horariosList.forEach((sesion) => {
            if (recinto.codRecinto === sesion.IdRecinto) {
              sesiones.push(sesion);
            }
          });
          if (sesiones.length) {
            this.sesionesPorRecinto.push(sesiones);
          }
        });
      }
    }
  }

  mostrarProfesores() {
    if (JSON.parse(this.productosService.datosInitValue).userId) {
      this.productosService.mostrarElegirProfesor$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((mostrar) => (this.mostrarElegirProfesor = mostrar));
      this.ventaService.horariosList.forEach((producto) => {
        producto.sesiones.forEach((sesion) => {
          if (sesion.Profesores.length) {
            this.productosService.setMostrarElegirProfesorValue(true);
          } else {
            this.productosService.setMostrarElegirProfesorValue(false);
          }
        });
      });
    }
  }

  compararProductosReserva(posicionVenta) {
    this.productos = [];
    const reservaOriginal = JSON.parse(
      sessionStorage.getItem("reservaOriginal")
    );
    reservaOriginal.compras.forEach((producto) => {
      if (producto.posicionVenta === posicionVenta) {
        this.agregarProductoCarritoModificar(producto);
      }
    });
    if (this.productos.length > 0) {
      const cantidadCarrito =
        this.productos.length > 0
          ? this.productos
              .map((p) => p.cantidad)
              .reduce((sum, current) => sum + current)
          : 0;
      const cantidadComprando = this.ventaService.compraValue.productos
        .map((p) => p.cantidad)
        .reduce((sum, current) => sum + current);
      const sesionesIguales = [];
      const productosReservar = [];
      this.ventaService.compraValue.productos.forEach((prodComprando) => {
        const prodCarrito = this.productos.find(
          (prod) =>
            prod.producto.ProductoId === prodComprando.producto.ProductoId
        );
        if (!prodCarrito) {
          productosReservar.push(prodComprando);
          const otroProductoCompra = this.productos.find(
            (prod) => prod?.sesionesSeleccionadas
          );
          otroProductoCompra.sesionesSeleccionadas.forEach((s) => {
            if (
              prodComprando.sesionesSeleccionadas &&
              prodComprando.sesionesSeleccionadas.every(
                (se) => se.IdRecinto !== s.IdRecinto
              )
            ) {
              sesionesIguales.push(s);
            }
          });
        } else if (cantidadCarrito < cantidadComprando) {
          if (prodCarrito.cantidad < prodComprando.cantidad) {
            productosReservar.push(prodComprando);
            prodComprando.sesionesSeleccionadas.forEach((s) => {
              prodCarrito.sesionesSeleccionadas.forEach((se) => {
                if (se.Fecha === s.Fecha && se.HoraInicio === s.HoraInicio) {
                  const sesionConId = this.ventaService.horariosList.find(
                    (ses) =>
                      ses.IdRecinto === s.IdRecinto &&
                      ses.HoraInicio === s.HoraInicio &&
                      ses.Fecha === s.Fecha
                  );
                  if (
                    !sesionesIguales.find(
                      (s) => s.RecintosSesionId === sesionConId.RecintosSesionId
                    )
                  ) {
                    sesionesIguales.push(sesionConId);
                  }
                }
              });
            });
          }
        }
      });
      if (productosReservar.length && sesionesIguales.length) {
        const listadoDeProductos = [];
        //SE LE PASA EL PRIMER PRODUCTO PORQUE DA IGUAL CON QUE PRODUCTO SE RESERVE, LO IMPORTANTE ES RESERVAR LAS PLAZAS
        const prod =
          productosReservar[0].producto.TipoProducto +
          "," +
          productosReservar[0].producto.ProductoId +
          "," +
          (cantidadComprando - cantidadCarrito);
        listadoDeProductos.push(prod);
        const peticionesInsercion = sesionesIguales.map((s) =>
          this.productosService.reservaAforoModificarReserva(
            s,
            listadoDeProductos,
            posicionVenta
          )
        );
        forkJoin(peticionesInsercion).subscribe((res) => {
          res.forEach((r) => {
            if (!r.res.DatosResult) {
              this.mensajeErrorModificar = true;
              return;
            } else {
              r.res.DatosResult.Producto.forEach((producto) => {
                const ses = {
                  ...r.sesion,
                  TotalAforoUnidad: producto.AforosConsumidos.TotalAforoUnidad,
                  ProductoId: producto.Identificador.split(",")[1],
                };
                this.ventaService.compraValue.agregarSesionATodosProducto1(ses);
              });
            }
          });
          if (!this.mensajeErrorModificar) {
            if (this.productosService.sinCamposValue) {
              this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
                posicionVenta
              );
              this.forwardClickPaso2(posicionVenta);
            } else {
              // if(this.initService.aceptaCookieGoolgeValue)
              // this.GAService.emitEvent('event', 'paso-2'); //Yaribel 20210514 añadimos la petición al servicio para registrar el evento
              this.router.navigate(["/tienda/producto/3"]);
            }
          }
        });
      } else {
        if (this.productosService.sinCamposValue) {
          this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
            posicionVenta
          );
          this.forwardClickPaso2(posicionVenta);
        } else {
          this.router.navigate(["/tienda/producto/3"]);
        }
      }
    } else {
      this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
        posicionVenta
      );
      this.forwardClickPaso2(posicionVenta);
    }
  }

  agregarProductoCarritoModificar(producto) {
    this.productos.push({
      posicionVenta: producto.posicionVenta,
      producto: producto.producto,
      datos: producto.producto.datos,
      sesionesSeleccionadas: producto.sesionesSeleccionadas,
      cantidad: producto.cantidad,
    });
  }

  forwardClick() {
    if (!this.areAllSesionsSelected()) {
      let posicionVenta = this.ventaService.compraValue.productos[0]
        .posicionVenta
        ? this.ventaService.compraValue.productos[0].posicionVenta
        : this.ventaService.calcularposicionVenta();
      this.ventaService.sesionesReservadas = [];
      if (this.productosService.sinCamposValue) {
        if (
          this.ventaService.modificarValue &&
          this.ventaService.modificandoReservaValue
        ) {
          this.compararProductosReserva(posicionVenta);
        } else if (this.ventaService.modificarValue) {
          this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
            posicionVenta
          );
          this.forwardClickPaso2(posicionVenta);
        } else {
          this.forwardClickPaso2(posicionVenta);
        }
      } else {
        if (
          this.ventaService.modificarValue &&
          this.ventaService.modificandoReservaValue
        ) {
          this.compararProductosReserva(posicionVenta);
        } else if (this.ventaService.modificarValue) {
          this.router.navigate(["/tienda/producto/3"]);
        } else {
          this.router.navigate(["/tienda/producto/3"]);
        }
      }
    }
  }

  forwardClickPaso2(posicionVenta) {
    let datosFiltros = null;
    if (this.calendarService.filtrosValue) {
      datosFiltros = {
        cboNivel: this.productosService.filtroNivelValue,
        cboTipoTabla: this.productosService.filtroTipoValue,
        cboRangoEdad: this.productosService.filtroGrupoValue,
        cboIdiomaVisita: this.productosService.filtroIdiomaVisitaValue,
      };
    }
    this.ventaService.compraValue.productos.forEach((producto) => {
      const datos = datosFiltros;
      this.compra = {
        cantidad: producto.cantidad,
        posicionVenta,
        producto: producto.producto,
        datos,
        sesionesSeleccionadas: producto.sesionesSeleccionadas,
      };
      this.ventaService.sendCompra(this.compra);
    });
    this.ventaService.setCompra(new Compra());
    this.ventaService.guardarCesta();
    this.router.navigate(["/tienda/resumen"]);
  }

  areAllSesionsSelected() {
    const recintosObligatorios = this.productosService.recintosTodosValue
      .filter((r) => r.EsOpcional === "0")
      .map((r) => r.IdRecinto);
    let allSesionsNotSelected = false;
    if (!this.tieneAforo) {
      allSesionsNotSelected = true;
    } else {
      if (!this.productosService.muestraSesionesPorProducto) {
        if (
          this.tienenTodosSesionesSeleccionadas(
            this.ventaService.compraValue.productos
          )
        ) {
          // if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
          if (this.productosService.filtroDiasValue) {
            if (
              parseInt(this.productosService.filtroDiasValue) !==
              this.ventaService.compraValue.productos[0].sesionesSeleccionadas
                .length
            ) {
              allSesionsNotSelected = true;
            }
          } else {
            // Jordi
            let sesionesSelec = [];
            this.ventaService.compraValue.productos.forEach((element) => {
              if (element.sesionesSeleccionadas) {
                sesionesSelec = element.sesionesSeleccionadas;
              }
            });
            //const sesionesSelec = this.ventaService.compraValue.productos[0].sesionesSeleccionadas;
            const sesObligatorias = sesionesSelec
              .filter((s) => recintosObligatorios.includes(s.IdRecinto))
              .map((r) => r.IdRecinto);
            const actividades = this.productosService.recintosTodosValue
              .filter((r) => r.EsOpcional === "1")
              .map((r) => r.IdRecinto);
            if (
              sesObligatorias.sort().toString() !==
              recintosObligatorios.sort().toString()
            ) {
              allSesionsNotSelected = true;
            } else if (actividades.length) {
              const actividadSeleccionada =
                this.ventaService.compraValue.productos[0].sesionesSeleccionadas.find(
                  (s) => actividades.includes(s.IdRecinto)
                );
              if (!actividadSeleccionada) {
                allSesionsNotSelected = true;
              }
            }
          }
        } else {
          allSesionsNotSelected = true;
        }
      } else if (this.productosService.muestraSesionesPorProducto) {
        this.ventaService.compraValue.productos.forEach((item) => {
          if (!item.sesionesSeleccionadas) {
            allSesionsNotSelected = true;
          }
        });
      }
    }
    return allSesionsNotSelected;
  }

  // No se controla aforo en estos productos. CLASES PARTICULARES Y CURSILLOS
  isSelected(sesion, producto) {
    let seleccionada = false;
    this.ventaService.compraValue.productos.forEach((item) => {
      if (item.producto.ProductoId === producto.ProductoId) {
        if (item.sesionesSeleccionadas) {
          if (
            item.sesionesSeleccionadas[0].HoraInicio === sesion.HoraInicio &&
            sesion.IdRecinto === item.sesionesSeleccionadas[0].IdRecinto
          ) {
            seleccionada = true;
          }
        }
      }
    });
    return seleccionada;
  }

  selectPorRecintoBefore() {
    let sesion;
    let index;
    this.ventaService.compraValue.productos.forEach((item) => {
      if (item.sesionesSeleccionadas) {
        if (item.sesionesSeleccionadas.length) {
          item.sesionesSeleccionadas.forEach((s) => {
            this.sesionesPorRecinto.forEach((session, i) => {
              let find = session.find((sessionFind) => {
                if (
                  s.HoraInicio === sessionFind.HoraInicio &&
                  sessionFind.IdRecinto === s.IdRecinto &&
                  s.Fecha === sessionFind.Fecha
                ) {
                  return sessionFind;
                }
              });

              if (find) {
                sesion = find;
                index = i;
              }
            });

            if (sesion && index != -1) {
              this.selectSesionPorRecinto(sesion, index);
            }
          });
        }
      }
    });
  }
  // HORA POR RECINTO
  isSelectedPorRecinto(sesion, index) {
    let seleccionada = false;
    if (sesion.TipoAforo === "SD") {
      seleccionada = true;
    }
    this.ventaService.compraValue.productos.forEach((item) => {
      if (item.sesionesSeleccionadas) {
        if (item.sesionesSeleccionadas.length) {
          item.sesionesSeleccionadas.forEach((s) => {
            if (
              s.HoraInicio === sesion.HoraInicio &&
              sesion.IdRecinto === s.IdRecinto &&
              s.Fecha === sesion.Fecha
            ) {
              seleccionada = true;
            }
          });
        }
      }
    });
    return seleccionada;
  }

  // No se controla aforo en estos productos. CLASES PARTICULARES Y CURSILLOS
  selectSesionPorProducto(sesion, producto) {
    if (sesion !== "0") {
      if (sesion.Disponible !== "0") {
        if (this.productosService.muestraSesionesPorProducto) {
          const p = this.ventaService.compraValue.productos.find(
            (prod) => prod.producto.ProductoId === producto.ProductoId
          );
          if (p.cantidad <= parseInt(sesion.Disponible)) {
            this.ventaService.compraValue.agregarSesionAProducto(
              sesion,
              producto
            );
          } else {
            this.sesionService.setNoAforo([true, producto.ProductoId]);
            this.sesionService.noAforo
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((aforo) => (this.noAforo = aforo));
          }
        }
      }
    }
  }
  isSelectable(sesion) {
    return sesion.IdiomaSesion === this.idiomaSesion;
  }

  // HORA POR RECINTO
  selectSesionPorRecinto(sesion, index) {
    if (this.isOcupada(sesion)) {
      return;
    }
    // COMPROBAR QUE LA SESSION ES DEL MISMO DIA
    this.mensajeErrorModificar = false;
    this.sesionService.setNoAforo(false);
    this.tieneAforo = true;
    this.mensajeMismaSesion = [];
    // ESTO FUERA CUANDO SE HAGA EN MULTIPLE CALENDARIO
    /*if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
      if (this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY') !== this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].Fecha) {
        this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(s => {
          this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
          this.ventaService.compraValue.productos.forEach(producto => {
            this.ventaService.compraValue.quitarSesionAProducto(null, producto);
          });
        });
      }
    }*/
    if (this.mostrarIdiomaSesion === 1) {
      if (!this.isSelectable(sesion)) {
        return;
      }
    }
    // COMPROBAR QUE NO SEA LA MISMA HORA O SOLAPEN HORARIOS
    if (this.ventaService.compraValue.comprobarSesiones(sesion)) {
      this.mensajeMismaSesion[index] = true;
      return;
    }
    const prods = this.ventaService.compraValue.productos;
    //MODIFICAR EN UNA VENTA NORMAL
    if (
      this.ventaService.modificarValue &&
      !this.ventaService.modificandoReservaValue
    ) {
      if (prods[0].sesionesSeleccionadas) {
        if (prods[0].sesionesSeleccionadas.length) {
          prods[0].sesionesSeleccionadas.forEach((s) => {
            if (
              s.IdRecinto === sesion.IdRecinto ||
              (parseInt(sesion.GrupoRecintoId) > 0 &&
                sesion.GrupoRecintoId === s.GrupoRecintoId)
            ) {
              this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
            }
          });
        }
      }
      //COMPRA y MODIFICAR EN UNA VENTA YA RESERVADA
    } else if (this.ventaService.modificandoReservaValue) {
      if (prods[0].sesionesSeleccionadas) {
        prods[0].sesionesSeleccionadas.forEach((s) => {
          if (
            s.IdRecinto === sesion.IdRecinto ||
            (parseInt(sesion.GrupoRecintoId) > 0 &&
              sesion.GrupoRecintoId === s.GrupoRecintoId)
          ) {
            this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
          }
        });
      }
    } else {
      this.ventaService.sesionesReservadas.forEach((s) => {
        if (
          sesion.IdRecinto === s.IdRecinto ||
          (parseInt(sesion.GrupoRecintoId) > 0 &&
            sesion.GrupoRecintoId === s.GrupoRecintoId)
        ) {
          this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
        }
      });
    }
    if (
      this.ventaService.modificandoReservaValue &&
      this.mismaHoraReserva(sesion)
    ) {
      this.ventaService.compraValue.agregarSesionATodosProducto1(sesion);
      this.sesionService.setNoAforo(false);
      const sesionesFitradas = this.ventaService.sesionesReservadas.filter(
        (sRes) => sRes.IdRecinto !== sesion.IdRecinto
      );
      this.ventaService.sesionesReservadas = sesionesFitradas;
    } else {
      this.productosService
        .reservaAforo(sesion)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.DatosResult) {
            res.DatosResult.Producto.forEach((producto) => {
              const s = {
                ...sesion,
                TotalAforoUnidad: producto.AforosConsumidos.TotalAforoUnidad,
                ProductoId: producto.Identificador.split(",")[1],
              };
              this.ventaService.compraValue.agregarSesionATodosProducto1(s);
              this.sesionService.setNoAforo(false);
              if (this.ventaService.modificandoReservaValue) {
                const sesionesFitradas =
                  this.ventaService.sesionesReservadas.filter(
                    (sRes) => sRes.IdRecinto !== s.IdRecinto
                  );
                sesionesFitradas.push(s);
                this.ventaService.sesionesReservadas = sesionesFitradas;
              } else {
                this.ventaService.compraValue.productos.forEach((element) => {
                  if (element.sesionesSeleccionadas) {
                    this.ventaService.sesionesReservadas =
                      element.sesionesSeleccionadas;
                  }
                });
                // this.ventaService.sesionesReservadas = this.ventaService.compraValue.productos[0].sesionesSeleccionadas;
              }
            });
          } else {
            this.ventaService.compraValue.quitarSesionAProductoSinAforo(sesion);
            this.sesionService.setNoAforo([true, sesion.IdRecinto]);
            this.sesionService.noAforo
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((aforo) => (this.noAforo = aforo));
          }
        });
    }
  }

  mismaHoraReserva(sesion) {
    let mismaHora = false;
    const posicionVenta =
      this.ventaService.compraValue.productos[0].posicionVenta;
    const reservaOriginal = JSON.parse(
      sessionStorage.getItem("reservaOriginal")
    );
    reservaOriginal.compras.forEach((producto) => {
      if (producto.posicionVenta === posicionVenta) {
        if (
          producto.sesionesSeleccionadas &&
          producto.sesionesSeleccionadas.find(
            (s) =>
              s.HoraInicio === sesion.HoraInicio &&
              s.Fecha === sesion.Fecha &&
              s.IdRecinto === sesion.IdRecinto
          )
        ) {
          mismaHora = true;
        }
      }
    });
    return mismaHora;
  }

  controlAforo(sesion) {
    if (this.sesionService.noAforoValue) {
      if (
        this.sesionService.noAforoValue[0] &&
        this.sesionService.noAforoValue[1] === sesion[0].IdRecinto
      ) {
        this.tieneAforo = false;
        return true;
      }
    }
  }

  backClicked() {
    if (!this.ventaService.modificarValue) {
      this.ventaService.sesionesReservadas.forEach((sesion) => {
        this.productosService.cancelarAforo(sesion).pipe(take(1)).subscribe();
      });
      this.ventaService.compraValue.productos.forEach((producto) => {
        this.ventaService.compraValue.quitarSesionAProducto(null, producto);
      });
    }
    this._location.back();
    this.ventaService.sesionesReservadas = [];
  }

  setLang(value) {
    this.idiomaSesion = value;
  }

  isOcupada(sesion) {
    let ocupada = false;
    if (this.mostrarIdiomaSesion == 1) {
      if (sesion.IdiomaSesion !== "") {
        if (sesion.IdiomaSesion !== this.idiomaSesion) {
          ocupada = true;
        }
      }
    } else {
      if (sesion.Disponible === "0") {
        ocupada = true;
      }
    }
    return ocupada;
  }

  horaSesion(sesion) {
    const mostrarAforoDisponible = JSON.parse(
      this.productosService.getEnlaceMenuId().CaracteristicasAdicionales
    ).CaracteristicasAdicionales.MostrarAforoDisponible;
    if (mostrarAforoDisponible === "1") {
      this.mostrarDisponibilidad = true;
    } else {
      this.mostrarDisponibilidad = false;
    }
    if (this.mostrarDisponibilidad) {
      return sesion.TipoAforo === "SD"
        ? `${sesion.HoraInicio} - ${sesion.HoraFin} (${sesion.Disponible})`
        : sesion.HoraInicio + " (" + sesion.Disponible + ")";
    } else {
      return sesion.TipoAforo === "SD"
        ? `${sesion.HoraInicio} - ${sesion.HoraFin}`
        : sesion.HoraInicio;
    }
  }

  abrirCalendario(recinto) {
    this.calendarModalService.setRecinto(recinto[0]);
    this.modalService.open("calendar");
  }

  fechaPorRecinto(recinto: Array<any>): string {
    const idRecinto = recinto[0].IdRecinto;
    return this.ventaService.fechaPorRecinto(idRecinto);
  }

  tienenTodosSesionesSeleccionadas(productos) {
    let tienenSesiones = true;
    productos.forEach((producto) => {
      if (
        !producto.sesionesSeleccionadas &&
        Object.keys(producto.producto.DictTarifasRecintos).length > 0
      ) {
        tienenSesiones = false;
      }
    });
    return tienenSesiones;
  }

  getVentaService() {
    return this.ventaService;
  }
  getProductosService() {
    return this.productosService;
  }
}
