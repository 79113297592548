import { Component, OnInit } from '@angular/core';
import { TokenManagerService } from '../core/token/token-manager.service';
import { InitService } from '../core/init/init.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(
    private tokenService: TokenManagerService,
    private initService: InitService
    ) { }

  ngOnInit() {

  }

}
