import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AppConfiguration } from 'src/app/app.configuration';
import * as moment from 'moment';
import { ModalService } from 'src/app/shared/modal.service';
import { InitService } from 'src/app/core/init/init.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from 'src/app/shared/translate.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private ngUnsubscribe = new Subject();
  year = moment().year();

  proyecto = this.config.getProyecto();

  cargado;
  translation: any;
  internalpol: any;

  constructor(private config: AppConfiguration,
              private modalService: ModalService, 
              private initService: InitService,
              private _sanitizer: DomSanitizer,
              private translateService: TranslateService) {}

  ngOnInit() {

    this.translateService.use(this.translateService.getLocale(), this.config.getProyecto()).then( i =>  {
      this.internalpol = i["CONFIG_POLITICAS_INTERNAS"];
    });
      
  }

  ngAfterContentInit() {
    
  }
  

  public get footerLink() : SafeHtml {

    return this._sanitizer.bypassSecurityTrustHtml(this.translateService.data.FOOTER);
 }
 
  abrirCookies() {
    this.modalService.open('cookies');
    this.initService.setModalCookiesValue(true);
  }

  openModalCookies(){
    /* 
    this.initService.modalCookies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cargado => {
 
      this.cargado = cargado;
      if(this.cargado){
        this.initService.setModalCookiesValue(false);
        this.initService.setAceptaCookieGoolge(false);
        this.modalService.open("cookies")
        this.cargado=false

      }
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }); */
    this.modalService.open("cookies",true)
  }


  openModalPoliticas(){
    this.modalService.open("politicas",true)
  }

}
