import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[pasteDirective]'
})
export class PasteDirective {

  constructor() { }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

}
