import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { ModalService } from 'src/app/shared/modal.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { UserService } from '../user.service';
import { GlobalService } from 'src/app/shared/services/global/global.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  @Input() id: string;
  private element: any;

  registroForm: FormGroup;
  loading: boolean;
  show: boolean;
  show2: boolean;
  campos
  isLoading = true;
  modal = false;
  showPasswordInput = false;
  showPasswordInputModal = false;
  verificarRegistro =0;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toast: ToastrService,
    private translateService: TranslateService,
    private productosService: ProductosService,
    private ventaService: VentaService,
    private el: ElementRef,
    private modalService: ModalService,
    private global: GlobalService,
  ) {
    this.element = el.nativeElement;
    
    if (this.userService.currentUserValue) {
      //this.router.navigate(['/shop']);
    }
  }
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit() {
    this.cargarCampos();
    this.show = false;
    //this.cargarCamposDinamicos(this.productosService.getEnlaceMenuId())
    this.cargarCamposWait();
    /* this.show = false;
    this.registroForm = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.maxLength(255), Validators.pattern('^[A-zÀ-ÿ\\s]*$')]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')]],
      contrasena: ['', [Validators.required, Validators.maxLength(255)]],
      telefono: ['', [Validators.required]],
      privacidad: [false, [Validators.requiredTrue]],
      recaptchaReactive: [null, Validators.required]
    }); */
    const modal = this;
    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);

  }

  toggleShowPasswordInput(isModal: Boolean = false) {
    if (isModal) {
      this.showPasswordInputModal = !this.showPasswordInputModal;
    } else {
      this.showPasswordInput = !this.showPasswordInput;
    }
  }

  open(): void {
    this.element.style.display = 'block';
    this.modal = true
    document.body.classList.add('jw-modal-open');
  }
  close(): void {
    this.element.style.display = 'none';
    this.modal = false;
    document.body.classList.remove('jw-modal-open');
  }
  cargarCampos() {
    // this.tokenManager.currentConexionId$.subscribe(res => {
    //   if(res)
    //   this.productosService.traerEnlacesMenuPorConexionId(this.tokenManager.currentConexionIdValue).subscribe(res => {
    //   })
    // })
  }
  cargarCamposWait() { 
    this.ventaService.enlaceMenuCarrito$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(em => {
      if(em){
        if(this.productosService.getEnlaceMenuId()){
          this.verificarRegistro = this.productosService.getEnlaceMenuId().iSolicitaVerificarRegistro;
          this.cargarCamposDinamicos(this.productosService.getEnlaceMenuId())
        }
      }
    })
  }
  cargarCamposDinamicos(enlaceMenu) {

    if(enlaceMenu && enlaceMenu.CaracteristicasAdicionales && JSON.parse(enlaceMenu.CaracteristicasAdicionales) && JSON.parse(enlaceMenu.CaracteristicasAdicionales).CaracteristicasAdicionales && JSON.parse(enlaceMenu.CaracteristicasAdicionales).CaracteristicasAdicionales.DatosRegistro){
      
    this.campos = JSON.parse(enlaceMenu.CaracteristicasAdicionales).CaracteristicasAdicionales.DatosRegistro;
    /*   if (this.campos.find(campo => campo.busqueda)) {
        this.buscador();
      } */
    this.registroForm = this.formBuilder.group({
      items: this.createFormItems(this.campos),
      privacidad: [false, [Validators.requiredTrue]],
      publicidad: [false],
      recaptchaReactive: [null, Validators.required]
    });
  }
    const a = this.getControls(this.registroForm);
    if(a){
      const controls: any = a.controls;
      if (controls.tipodocumento) {
        controls.tipodocumento.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(checked => {
          if (checked) {
            controls.documento.setValidators([Validators.pattern(this.documentoValidar(controls.tipodocumento.value)),this.ValidarDocNieDni('tipodocumento')]);
          }
        });
      } 
    } 
    /* this.datosClienteForm.emit(this.datosForm);
    this.datosForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.datosClienteForm.emit(this.datosForm)); */
  }

  getControls(datosForm: FormGroup) {
    if(datosForm){
      return (<FormArray>datosForm.controls.items);
    }
  }

  documentoValidar(tipoDocumento: string) {
    const control = tipoDocumento;
    const nifReg = /^(\d{8})([A-Z]|[a-z])$/;
    const nieReg = /^[XYZ][0-9]{7}[A-Z]$/;
    if (control === 'NIE') {
      return nieReg;
    }
    if (control === 'DNI') {
      return nifReg;
    }
    if (control === 'Otro') {
      return null;
    }
  }
  createFormItems(campos): FormGroup {
    //const cliente = this.ventaService.clienteValue;  
    const group = {};

    if(campos){
      campos.forEach((campo: any) => {
        if (campo.visible) {
          campo["password"]=false
          switch (campo.name) {
            case 'tipocliente':
              this.productosService.GetTipoClientes(this.translateService.getLocale()).subscribe(res=>{
                campo.options= res.DatosListas ? res.DatosListas : []
                
              })
              return group[campo.name] = new FormControl(
                this.productosService.getEnlaceMenuId()? this.productosService.getEnlaceMenuId().chTiposClientes:"1",
                campo.required ? Validators.required : Validators.nullValidator);
            case 'razonsocial':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\.\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\.\\s]*$'));
            case 'nombreresponsable':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
            case 'nombre':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
            case 'apellidos':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
            case 'apellido2contacto':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')] : Validators.pattern('^[A-zÀ-ÿ\\s]*$'));
            case 'tipodocumento':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'documento':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, this.ValidarDocNieDni('tipodocumento')]: [Validators.nullValidator,this.ValidarDocNieDni('tipodocumento')]);
            case 'sexo':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'emailconfirmacion':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$'), this.repetirValidar('email')] : [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-_]+\.[a-zA-Z]{2,3}$'), this.repetirValidar('email')]);
            case 'email':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')] : Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-_]+\.[a-zA-Z]{2,3}$'));
            case 'password':
              campo["password"]=true
              campo["type"]="input"
              campo["inputType"]="password"
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required: Validators.nullValidator);
            case 'passwordconfirmacion':
              campo["password"]=true
              campo["type"]="input"
              campo["inputType"]="password"
              
              return group[campo.name] = new FormControl(
                '',
                campo.required ? [Validators.required,this.repetirValidar('password')] : [Validators.nullValidator,this.repetirValidar('password')]);
            case 'direccion':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'ciudad':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'codigopostal':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'provincia':
              this.productosService.GetProvincias(this.translateService.getLocale()).subscribe(res=>{
                campo.options= res.DatosListas ? res.DatosListas : []
                
              })
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'pais':
              this.productosService.GetPaises(this.translateService.getLocale()).subscribe(res=>{
                campo.options= res.DatosListas ? res.DatosListas : []                
              })
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'telefono':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'publicidad':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'notas':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'notasinternas':
              return group[campo.name] = new FormControl(
                "",
                campo.required ? Validators.required : Validators.nullValidator);
            case 'tipocentro':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'curso':
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
            case 'pais':
              group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
  
              group['codigoPais'] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
              return;
            default:
              return group[campo.name] = new FormControl(
                '',
                campo.required ? Validators.required : Validators.nullValidator);
          }
  
        }
      }
      );
    }
   
   /*  if(group["password"]){

      apiFormGroup = this.formBuilder.group({group},{validator: repetirValidar('password', 'passwordconfirmacion')});
    }else{
 */
      const apiFormGroup = new FormGroup(group);

   /*  } */
    this.isLoading = false;
    return apiFormGroup;
  }
  repetirValidar(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const a = control.root['controls'];
      let otherControl: AbstractControl;
      if (a) {
        otherControl = a.items.get(otherControlName);
      }
      if (otherControl) {
        const subscription: Subscription = otherControl
          .valueChanges
          .subscribe(() => {
            control.updateValueAndValidity();
            subscription.unsubscribe();
          });
      }
      return (otherControl && control.value !== otherControl.value) ? { match: true } : null;
    };
  }
  ValidarDocNieDni(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const a = control.root['controls'];
      let otherControl: AbstractControl;
      if (a) {
        otherControl = a.items.get(otherControlName);
      }
      if (otherControl) {
        const subscription: Subscription = otherControl
          .valueChanges
          .subscribe(() => {
            control.updateValueAndValidity();
            subscription.unsubscribe();
          });
      }

      let documento = control? (control.value == undefined? "":control.value):"";;
       let tipoDocumento = otherControl ? (otherControl.value == undefined? "":otherControl.value):"";
        return (!this.ValidarDocumento(tipoDocumento, documento)) ? { match: true } : null;
    };
  }

  
  ValidarDocumento(tipoDocumento: string,numeroDocumento : string) {
    let numValido = true;
    const control = tipoDocumento;
    if (control === 'NIE') {
      numValido = this.global.VerificarNIE(numeroDocumento)
    }
    if (control === 'DNI') {
      numValido = this.global.VerificarDNI(numeroDocumento)
    }
    if (control === 'CIF') {
      numValido = this.global.VerificarDNI(numeroDocumento)
    }
    return numValido;
  }
  
  campoTipo(chTipoControl: string) {
    switch (chTipoControl) {
      case 'radiobutton': return 'dropDown';
      case 'select': return 'dropDown';
      case 'input': return 'text';
      case 'password': return 'password';
      default: return chTipoControl;
    }
  }
  nombreCampo(nombreCampo) {
    switch (nombreCampo.toLowerCase()) {
      case 'nombrecentro': return this.translateService.data.NOMBRECENTRO;
      case 'nombre': return this.translateService.data.NOMBRE;
      case 'nombreresponsable': return this.translateService.data.NOMBRERESPONSABLE;
      case 'curso': return this.translateService.data.CURSO;
      case 'tipocentro': return this.translateService.data.TIPOCENTRO;
      case 'email': return this.translateService.data.EMAIL;
      case 'codigopostal': return this.translateService.data.CODIGOPOSTALR;
      case 'tipodocumento': return this.translateService.data.TIPODOCUMENTO;
      case 'documento': return this.translateService.data.DOCUMENTO;
      case 'cifodni': return this.translateService.data.DOCUMENTO;
      case 'telefono': return this.translateService.data.TELEFONO;
      case 'telefonomovil': return this.translateService.data.TELEFONOMOVIL;
      case 'direccion': return this.translateService.data.DIRECCION;
      case 'ciudad': return this.translateService.data.CIUDAD;
      case 'notas': return this.translateService.data.NOTAS;
      case 'notasinternas': return this.translateService.data.NOTASINTERNAS;
      case 'pais': return this.translateService.data.PAIS;
      case 'provincia': return this.translateService.data.PROVINCIA;
      case 'nombrecontacto': return this.translateService.data.NOMBRECONTACTO;
      case 'apellidos': return this.translateService.data.APELLIDO1;
      case 'apellido2contacto': return this.translateService.data.APELLIDO2;
      case 'sexo': return this.translateService.data.SEXO;
      case 'nacionalidad': return this.translateService.data.NACIONALIDAD;
      case 'codigopais': return this.translateService.data.CODIGOPAIS;
      case 'edad': return this.translateService.data.EDAD;
      case 'fechanacimiento': return this.translateService.data.FECHANACIMIENTO;
      case 'emailconfirmacion': return this.translateService.data.EMAILCONFIRMACION;
      case 'passwordconfirmacion': return this.translateService.data.PASSWORDCONFIRMACION;
      case 'tipocliente': return this.translateService.data.TIPOCLIENTE;
      case 'password': return this.translateService.data.CONTRASENA;
      default: return nombreCampo;
    }
  }
  get f() {
    return this.registroForm.controls;
  }
  showForm(){    
  }
  registroClicked() {
    const param = 1;
    this.loading = true;
    
    if (this.registroForm.invalid) {
      return;
    }    
    this.userService.registro(this.registroForm.get("items").value, param,"signup",this.registroForm.get("publicidad").value,this.translateService.getLocale(),this.verificarRegistro)
      .subscribe(res => {
        if (res.DatosResult.Avisos!=null) {
         // this.router.navigate(['/account/login']);
         if(this.verificarRegistro == 1)
         {
          this.toast.success(this.translateService.data.SolicitudEnProceso, '', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
          if(this.modal){
            this.registroForm.reset();
            this.close();
          }
          this.modalService.close("LoginAndRegister");
         }else{
          this.toast.success(this.translateService.data.registradoCorrectamente, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          this.login();
         }

        } else {
          
          this.toast.error(this.userService.getErrorTranslate(res["Mensajes"][0]["CodigoMensaje"]), 'Error', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        this.loading = false;
      }
      );
  }

  login(){
    this.userService.login(this.registroForm.get("items").value,1,1,true)
      .subscribe(res => {
        if (res && res.DatosResult) {
          if(this.productosService.getEnlaceMenuId().chTiposClientes==res.DatosResult.TipoClienteId){
          this.userService.setDatosUserValue(res.DatosResult)
          this.ventaService.mapCliente(res.DatosResult);
          this.ventaService.setEnlaceMenuCarrito(this.ventaService.enlaceMenuCarritoValue)
          if(this.modal){
            this.close()
          }
          this.modalService.close("LoginAndRegister")
          /* this.userService.setDatosUserValue(res.DatosResult)
          this.ventaService.mapCliente(res.DatosResult);
          this.ventaService.setEnlaceMenuCarrito(this.ventaService.enlaceMenuCarritoValue) */
          // this.router.navigate(['/tienda/producto/1']);
        }else{
          this.toast.error(this.translateService.data.usarioNoCorresponde, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          this.userService.logout(false);
        }
        } else {          
          this.toast.error(this.userService.getErrorTranslateLogin(res.Mensajes[0]["CodigoMensaje"]), '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        this.loading = false;
      }, err => {
        this.toast.error(this.translateService.data[err], 'Error', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.loading = false;
      }
      );
  }
  password() {
    this.show = !this.show;
  }
  passwordValid() {
    this.show2 = !this.show2;
  }

  focusOut() {

  }

}
