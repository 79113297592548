import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyShoppingComponent } from './my-shopping/my-shopping.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoggedGuard } from './logged-guard.guard';
import { NoLoggedGuard } from './noLogged-guard.guard';
import { NewPasswordComponent } from './new-password/new-password.component';
import { MyOperationsComponent } from './my-operations/my-operations.component';


const routes: Routes = [
  {path: 'new-password', component: NewPasswordComponent},
  {path: 'login', component: LoginComponent,canActivate: [NoLoggedGuard]},
   {path: 'signup', component: SignupComponent, canActivate: [NoLoggedGuard]},
  {path: 'my', component: MyAccountComponent, canActivate: [LoggedGuard]},
  {path: 'operations', component: MyOperationsComponent, canActivate: [LoggedGuard]},
  {path: 'purchases', component: MyShoppingComponent, canActivate: [LoggedGuard]},
  {path: 'recover', component: ForgotPasswordComponent, canActivate: [NoLoggedGuard]},
  {path: 'reset-password', component: ChangePasswordComponent, canActivate: [LoggedGuard]},
  {path: '**', redirectTo: 'my'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
