import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from './services/global/global.service';

const PADDING = '000000';

@Pipe({
  name: 'myCurrencyV2'
})
export class MyCurrencyPipeV2 implements PipeTransform {

  private DECIMAL_SEPARATOR: string;
  private FRACTION_SIZE: number;

  constructor(
    private globalService: GlobalService
  ) {
    this.DECIMAL_SEPARATOR = ',';
    this.FRACTION_SIZE = 2;
  }

  transform(value: number | string, separadorDecimal, numeroDecimales): string {
    this.DECIMAL_SEPARATOR = separadorDecimal;
    this.FRACTION_SIZE = numeroDecimales;

    const valueRounded = this.globalService.roundFourDecimals(value);

    // tslint:disable-next-line:prefer-const
    let [integer, fraction = ''] = (valueRounded || 0).toString().split('.');

    fraction = this.FRACTION_SIZE > 0
      ? this.DECIMAL_SEPARATOR + (fraction).substring(0, this.FRACTION_SIZE)
      : '';

    return integer + fraction;
  }
}