import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InitService } from '../init/init.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  pedido;
  constructor(private route: ActivatedRoute, private initService: InitService) { }

  ngOnInit() {
    if (this.route.snapshot.params.datosPago){
      let datosPago = JSON.parse(this.initService.funcionDesencript(this.route.snapshot.params.datosPago));
      this.pedido = datosPago.pedido;
    } else if(this.route.snapshot.params.pedido){
      this.pedido = this.route.snapshot.params.pedido;
    }else if(sessionStorage.getItem('pedido')){
      this.pedido = sessionStorage.getItem('pedido');
    }
    sessionStorage.removeItem('factura');
    sessionStorage.removeItem('descuento');
    sessionStorage.removeItem('pedido');
    sessionStorage.removeItem('localizador');
    sessionStorage.removeItem('payment');
    sessionStorage.removeItem('estaPagada');
    sessionStorage.removeItem('aceptaCookies');    
  }
}
