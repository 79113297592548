import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../modal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from 'src/app/core/payment/payment.service';
import { PaymentGateway } from 'src/app/core/payment/paymentGateway.enum';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { Router } from '@angular/router';
import { TranslateService } from '../translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-phonesell-modal',
  templateUrl: './phonesell-modal.component.html',
  styleUrls: ['./phonesell-modal.component.scss']
})
export class PhonesellModalComponent implements OnInit {
  @Input() id: string;
  modalForm: FormGroup;
  private element: any;
  mensaje;
  img;
  mostrarMensaje = false;
  mostrar = false;
  proyecto = this.config.getProyecto();
  enviado: boolean;
  enviandoPeticion: boolean;
  constructor(
    private config: AppConfiguration,
    private router: Router,
    private tokenService: TokenManagerService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private translateService: TranslateService,
    private el: ElementRef) { this.element = el.nativeElement; }

  ngOnInit(): void {

    this.modalForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')]],
      telefono: ['', [Validators.required]],
    });

    const modal = this;
    if (!this.id) {
      //console.error('modal must have an id');
      return;
    }

    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  get f() {
    return this.modalForm.controls;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.enviandoPeticion = false;
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.mostrarMensaje = false;
    this.img = '';
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  enviar() {
    if (!this.modalForm.value.email && !this.modalForm.value.telefono) {
      return;
    }

    this.enviandoPeticion = true;
    this.paymentService.setPaymentGateway(PaymentGateway.PHONEANDSELL);
    sessionStorage.setItem('payment', JSON.stringify(PaymentGateway.PHONEANDSELL));
    sessionStorage.setItem('clienteContacto', JSON.stringify(this.modalForm.value));
    this.paymentService.phoneNsellInsercionUnica().pipe(take(1)).subscribe(res => {
      this.enviandoPeticion = false;
      this.mostrarMensaje = true;
      if (!res) {
        this.mensaje = this.translateService.data.PAGOENVIADOERROR;
        this.img = 'assets/img/KO.jpg';
      } else if (res.DatosResult) {
        this.enviado = true;
        this.mensaje = this.translateService.data.PAGOENVIADOOK;
        this.img = 'assets/img/ok.jpg';
      } else {
        this.mensaje = this.translateService.data.PAGOENVIADOERROR;
        this.img = 'assets/img/KO.jpg';
      }
    });
  }

  reenviar() {
    this.paymentService.setPaymentGateway(PaymentGateway.PHONEANDSELL);
    this.paymentService.reenviarPasarelaPago().pipe(take(1)).subscribe(res => {
      this.mostrarMensaje = true;
      if (res.DatosResult) {
        this.mensaje = this.translateService.data.PAGOENVIADOOK;
        this.img = 'assets/img/ok.jpg';
      } else {
        this.mensaje = this.translateService.data.PAGOENVIADOERROR;
        this.img = 'assets/img/KO.jpg';
      }
    });
  }

  comprobar() {
    const tokenCompra = JSON.parse(sessionStorage.getItem('currentToken'));
    const pedido = sessionStorage.getItem('pedido');
    this.paymentService.confirmarPagoPhoneAndSell(pedido).pipe(take(1)).subscribe((res: any) => {
      this.mostrarMensaje = true;
      if (res.DatosResult === 1) {
        this.paymentService.confirmarVenta(pedido, tokenCompra).pipe(take(1)).subscribe((resu: any) => {
          if (resu.DatosResult.MensajeRespuesta === 'OK') {
            this.mostrar = true;
            this.mensaje = this.translateService.data.PAGOOK;
            this.img = 'assets/img/ok.jpg';
          }
        });
      } else {
        this.mensaje = this.translateService.data.PAGOERROR;
        this.img = 'assets/img/KO.jpg';
      }
    });
  }

  finalizar() {
    this.close();
    sessionStorage.removeItem('cesta');
    sessionStorage.removeItem('fechaSeleccionadaSinSession');
    this.router.navigate(['/thankyou']);
  }

}
