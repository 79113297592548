import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';

@Component({
  selector: 'app-my-shopping',
  templateUrl: './my-shopping.component.html',
  styleUrls: ['./my-shopping.component.scss']
})
export class MyShoppingComponent implements OnInit {
  misCompras;

  constructor(
    private userService: UserService,
    private tokenService: TokenManagerService) { }

  ngOnInit() {
    this.tokenService.currentToken$.subscribe(token => {
      if (token) {
        this.userService.comprasUsuario().subscribe(res => {
          this.misCompras = res.DatosResult.Reservas;
        });
      }
    });
  }
}

